import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme, varianttype, fontSize, fontWeight, ogsize, sizebutton, fullWidth }) => {

    const fixedWidthStyles = [
        { name: "icon", width: "42px !important"},
        { name: "s", width: "132px"},
        { name: "m", width: "154px"},
        { name: "l", width: "198px"},
        { name: "xl", width: "254px"},
        { name: "xxl", width: "310px"},
        { name: "auto", width: "auto"},
    ]

    const common = {
        fontWeight: fontWeight || 600,
        borderRadius: 6,
        height: ogsize === "small" || varianttype === "primaryText" || varianttype === "errorText" ? "inherit" : 42,
        fontSize: fontSize || 14,
        whiteSpace: "nowrap",
        width: !fullWidth ? fixedWidthStyles.find((item) => item.name === ( sizebutton ?? "auto")).width : "100%",
        minWidth: !fullWidth ? fixedWidthStyles.find((item) => item.name === ( sizebutton ?? "auto")).width : "100%",
    }

    switch (varianttype) {
        case "primaryContained":
            return {...common};
        case "primaryText":
            return {...common};
        case "errorText":
            return {
                color: theme.palette.secondary.main, // Rosa
                ...common
            };
        case "primaryOutlinedHover":
            return {
                ...common,
                border: "1px solid #361A52",
                "&:hover": {
                    backgroundColor: "#FFF",
                    color: "#361A52",
                },
            };
        case "primaryOutlined":
            return {...common};
        case "filtersButton":
            return {
                ...common,
                // border: "0.5px solid",
                // color: "#361A52",
                // backgroundColor: "#fff",
                // cursor: "pointer",
                width: { xs: "100%", md: fixedWidthStyles.find((item) => item.name === "m").width },
                "&:disabled": {
                    border: "1px solid #dadadb",
                    pointerEvents: "none"
                },
                "&:hover": {
                    backgroundColor: "#361A52",
                    color: "#fff",
                },
                "&:hover #filterButton": {
                    backgroundColor: "#FFF",
                    color: "#361A52",
                }
            };
        case "convertContainedButton":
            return {
                ...common,
                justifyContent: "space-between",
                "&:disabled": {
                    color: "#FFF",
                    backgroundColor: "rgba(189, 189, 189, 1)",
                },
            };
        case "convertOutlinedButton":
            return {
                ...common,
                justifyContent: "space-between",
                backgroundColor: "#FFF",
                "&:hover": {
                    backgroundColor: "#FFF",
                },
                "&:disabled": {
                    color: "#FFF",
                    backgroundColor: "rgba(189, 189, 189, 1)",
                },
            };
        case "primaryTextButton":
            return {...common};    
        case "errorTextButton":
            return {
                color: theme.palette.secondary.main, // Rosa
                ...common
            };
        default:
            return {};
    }
});

const BaseButton = (props) => {
    const {
        children,
        id,
        onClick,
        size,
        type,
        fullWidth,
        variant,
        className,
        disabled,
        variantType = "primaryContained",
        sx,
        fontSize,
        fontWeight,
        sizebutton,
        anchorRefBtnColumns,
        ...rest
    } = props;

    const fTextSmall = variantType === "primaryText" || variantType === "errorText"
    const fText = fTextSmall || variantType === "primaryTextButton" || variantType === "errorTextButton"
    const fContained = variantType === "primaryContained" || variantType === "primaryOutlinedHover" || variantType === "convertContainedButton"
    const fOutlined = variantType === "primaryOutlined" || variantType === "convertOutlinedButton" || variantType === "filtersButton"

    // Si la variante es "primaryText", forzar el tipo de variante a "text"
    const computedVariant = fText ? "text" : fContained ? "contained" : fOutlined ? "outlined": variant;
    const computedSize = fTextSmall ? "small" : size;

    return (
            <StyledButton
                id={id}
                onClick={onClick}
                size={computedSize}
                type={type}
                fullWidth={fullWidth}
                variant={computedVariant}
                className={className}
                disabled={disabled}
                varianttype={variantType}
                fontSize={fontSize}
                fontWeight={fontWeight}
                ogsize={size}
                sx={sx}
                sizebutton={sizebutton}
                ref={anchorRefBtnColumns}
                {...rest}
            >
                {children}
            </StyledButton>
    );
};

export default BaseButton;