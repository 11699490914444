import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import classes from "../../dashboard/DashboardBreadcrumbs.module.css";

import moment from "moment";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PreviewIcon from "@mui/icons-material/Preview";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";
import InvoicesSync from "./InvoicesSync";
import InvoicesHistSync from "./InvoicesHistSync";
import InvoicesHistSyncDetails from "./InvoicesHistSyncDetails";

import companyService from "../../../services/company";
import conversionService from "../../../services/conversion";
import invoicesService from "../../../services/invoices";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LightTooltip from "../../ui/LightTooltip";
import Slider from "react-slick";
import { fetchFileFromS3, zeroPad } from "../../../utils/utils";
import BaseButton from "../../ui/BaseButton";

const boxRed = { backgroundColor: "#FFD9DF", px: 2, py: 0.5, borderRadius: 2 };

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

function Arrows(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", pointerEvents: isDisabled ? "none" : "all" }}
            onClick={onClick}
        />
    );
}

const ConsolidatedInvoicesPreview = (props) => {
    const {
        removeBanner,
        handleClickHelp,
        isHistory,
        setRowsModal,
        reloadInvoices,
        setShowHistSync,
        setShowSyncDetails,
        showSyncDetails,
        fileNameFull,
        setIsLoading,
        conversionData,
        setOpenPreview,
        setFileData,
        setBasePreviewData,
        setFileNameFull,
        setShowSync,
        showSync,
        showHistSync,
        setCorrelativeSync,
        replaceInvBill,
        setModalType,
        setOpenModalGrouped,
        setOpenModalGroupedHist,
        setCorrelativeModalGrouped
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [companyInfo, setCompanyInfo] = useState(null);
    const [franchiseInfo, setFranchiseInfo] = useState(null);
    const [invoiceFields, setInvoiceFields] = useState({});
    const [dataHist, setDataHist] = useState([]);
    const [conversionDataNew, setConversionDataNew] = useState(conversionData);
    const [detailsHist, setDetailsHist] = useState([]);
    const [invoicesStatus, setInvoicesStatus] = useState({
        success: 0,
        failed: 0,
        total: 0,
    });

    const settings = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <Arrows isDisabled={conversionData.headers[0].length <= 4} />,
        prevArrow: <Arrows isDisabled={conversionData.headers[0].length <= 4} />,
        draggable: conversionData.headers[0].length > 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        (async () => {
            await companyService
                .getFranchiseDetails(todos.franchiseID)
                .then((data) => {
                    setFranchiseInfo(data);
                })
                .catch((error) => {
                    console.log(error);
                });

            await companyService
                .getCompanyDetails(todos.companyID)
                .then((data) => {
                    setCompanyInfo(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        })();
    }, [todos.companyID, todos.franchiseID]);

    const breadcrumbs = [
        <Typography key="1" variant="h1" color="#361A52" className={classes.breadcrumbs}>
            {franchiseInfo?.franchise_name}
        </Typography>,
        <Typography key="2" variant="h1" color="#361A52" className={classes.breadcrumbs}>
            {companyInfo?.company_name}
        </Typography>,
        <Typography key="3" variant="h1" color="primary" className={classes.breadcrumbs}>
            {t("converter.loadConsDoc")}
        </Typography>,
    ];

    const handleGoBack = () => {
        removeBanner();
        setRowsModal([]);
        setShowSyncDetails(false);
        setShowHistSync(false);
        setInvoicesStatus({
            success: 0,
            failed: 0,
            total: 0,
        });
        reloadInvoices();
    };

    const syncConsolidatedInvoices = async () => {
        setIsLoading(true);

        let finalHeaders = Object.keys(invoiceFields);

        let finalLineItems = [];
        conversionData?.all_line_items.forEach((e) => {
            let item = {};

            finalHeaders.forEach((header) => {
                if (invoiceFields[header]) {
                    item[invoiceFields[header]] = e[header] ?? "";
                }
            });

            finalLineItems.push(item);
        });

        // Sincronizamos las facturas
        const params = {
            line_items: finalLineItems,
            invoiceConvID: conversionData._id,
        };

        await conversionService
            .syncConsolidatedInvoices(params)
            .then(async (response) => {
                let params = {
                    sync_id: response.sync_id,
                    invoice_id: response.invoice_id
                };

                await handleHistSync(params);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const handleHistSync = async (params) => {
        setIsLoading(true);
        await invoicesService
            .getHistorySync({ company_id: todos.companyID, invoice_type: 2, conversion_type: 1, ...params })
            .then(async (data) => {
                let currentData = data.syncs.map((sync) => {
                    return {
                        ...sync,
                        name:
                            sync.grouped_invoice_id && sync.grouped_invoice_id !== null
                                ? "GROUP-" + zeroPad(sync.grouped_invoice_id.correlative ?? 0, 5)
                                : "#" + zeroPad(sync.consolidated_invoices[0].correlative ?? 0, 5),
                    };
                });

                setDataHist(currentData);

                let newData = null;

                let params2 = { invoice_id: params.invoice_id };
                await invoicesService
                    .getConsolidatedInvoiceDetails(params2)
                    .then((data) => {
                        newData = data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (newData.invoices) {
                    const newArr = newData.invoices.map((item) => {
                        const foundInv = currentData.invoices.find((aux) => aux._id === item._id);
                        if (foundInv) {
                            item.id_status = foundInv.id_status;
                        }
                        return item;
                    });
                    setConversionDataNew({ ...newData, invoices: newArr });
                } else {
                    setConversionDataNew({ ...newData, id_status: currentData[0].consolidated_invoices[0].id_status });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setShowSync(false);
        setShowHistSync(true);
        setIsLoading(false);
    };

    const handleGoList = () => {
        setShowSyncDetails(false);
        setShowHistSync(true);
    };

    const goToSync = (type) => {
        let newInv = { ...conversionDataNew };
        if (conversionDataNew.consolidated_invoices) {
            if (type === 0) {
                newInv.invoices = conversionDataNew.consolidated_invoices.filter((item) => item.id_status === 3);
            } else {
                newInv.invoices = conversionDataNew.consolidated_invoices.filter((item) => item.id_status === 6);
            }
        }

        setConversionDataNew(newInv);
        setShowHistSync(false);
        setShowSync(false);
        setShowSyncDetails(false);
        //setShowPrevConsolidated(true);
    };

    const downloadInv = async () => {
        setIsLoading(true);
        // Sincronizamos las facturas
        const params = {
            invoiceConvID: conversionData._id,
        };
        const newArr = Object.entries(invoiceFields);

        for (let i = 0; i < newArr.length; i++) {
            params[newArr[i][0]] = newArr[i][1];
        }

        await conversionService
            .downloadConsolidatedInvoice(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const handlePreviewDoc = async () => {
        setIsLoading(true);

        const data = await fetchFileFromS3(conversionData.file_name);
        setIsLoading(false);

        if (data) {
            setFileData(data);
        }

        setFileNameFull(conversionData.file_name);

        setBasePreviewData({
            id: conversionData._id,
            type: "consolidated",
        });

        setIsLoading(false);
        setOpenPreview(true);
    };

    const handleChangeInvField = (event) => {
        setInvoiceFields({ ...invoiceFields, [event.target.name]: event.target.value });
    };

    const requiredFields = [
        { value: "InvoiceNum", name: t("converter.invoiceNumber") },
        { value: "Contact", name: t("subscription.contact") },
        { value: "Date", name: t("payment.date") },
        { value: "Description", name: t("payment.description") },
        { value: "UnitAmount", name: t("payment.unitPrice") },
        { value: "AccountCode", name: t("accounts.accountNum2") },
        { value: "TaxType", name: t("converter.typeTax") },
    ];

    const allFields = [
        { value: "InvoiceNum", name: t("converter.invoiceNumber") },
        { value: "Contact", name: t("subscription.contact") },
        { value: "Date", name: t("payment.date") },
        { value: "Description", name: t("payment.description") },
        { value: "LineAmount", name: t("converter.lineTotal") },
        { value: "Quantity", name: t("converter.qty") },
        { value: "UnitAmount", name: t("payment.unitPrice") },
        { value: "AccountCode", name: t("accounts.accountNum2") },
        { value: "TaxType", name: t("converter.typeTax") },
        { value: "DueDate", name: t("converter.dueDate") },
        { value: "Reference", name: t("accounts.ref") },
        { value: "ItemCode", name: "Item code" },
        { value: "Currency", name: t("dashboard.currency") },
        { value: "Discount", name: t("converter.discount") },
        { value: "Tracking 1", name: "Tracking 1" },
        { value: "Tracking 2", name: "Tracking 2" },
        { value: "TaxID", name: "Tax ID" },
        { value: "ContactNum", name: t("converter.contactNum") },
        { value: "Email", name: t("converter.email") },
        { value: "Address", name: t("converter.address") },
    ];

    const isValid = requiredFields.every((item) => Object.values(invoiceFields).includes(item.value));

    const showConversions = (data, type) => {
        setModalType(type);
        setOpenModalGrouped(true);
        setOpenModalGroupedHist(true);
        setCorrelativeModalGrouped(data[0]?.correlative);

        const newRows = data.map((item) => ({
            id: item._id,
            invoice: item.invoice_id ?? "-",
            contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
            date: item.formatted_invoice_date ? moment.utc(item.formatted_invoice_date).format(todos.dateFormat) : "-",
            amount: item.formatted_invoice_total
                ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                    minimumFractionDigits: 2,
                })
                : "-",
            number: item.correlative,
            file_name: item.uploaded_file,
            file_name_s3: item.file_name,
            period: "",
            status: item.id_status,
        }));
        setRowsModal(newRows);
    };

    return (
        <>
            <Container maxWidth="xl" sx={{ backgroundColor: "white" }}>
                {(showSyncDetails || showSync || showHistSync) && <Stack direction="row" justifyContent="space-between" pt={5} mb={3}>
                    <Stack direction="row" spacing={2}>
                        <BaseButton
                            sizebutton={showSyncDetails ? "m" : "l"}
                            disableElevation
                            onClick={showSyncDetails ? handleGoList : handleGoBack}
                        >
                            {showSyncDetails ? t("inbox.goBackList") : t("converter.goBackConver")}
                        </BaseButton>
                        {showHistSync && (
                            <>
                                {(conversionDataNew.id_status === 3 ||
                                    conversionDataNew.invoices?.find((item) => item.id_status === 3)) && (
                                        <BaseButton variantType="primaryOutlined" sizebutton="l" onClick={() => goToSync(0)}>
                                            {t("converter.toBeSynch")}{" "}
                                            {conversionDataNew.invoices?.filter((item) => item.id_status === 3).length &&
                                                `(${conversionDataNew.invoices?.filter((item) => item.id_status === 3).length
                                                })`}
                                        </BaseButton>
                                    )}
                                {(conversionDataNew.id_status === 6 ||
                                    conversionDataNew.invoices?.find((item) => item.id_status === 6)) && (
                                        <BaseButton variantType="primaryOutlined" sizebutton="l" onClick={() => goToSync(1)}>
                                            {t("converter.synced")}{" "}
                                            {conversionDataNew.invoices?.filter((item) => item.id_status === 6).length &&
                                                `(${conversionDataNew.invoices?.filter((item) => item.id_status === 6).length
                                                })`}
                                        </BaseButton>
                                    )}
                            </>
                        )}
                    </Stack>
                    <Typography gutterBottom>
                        <Button onClick={() => handleClickHelp("conversion", "invoice", conversionDataNew._id)}>
                            {t("accounts.needHelp")}
                        </Button>
                    </Typography>
                </Stack>}
                {showSync ? (
                    <InvoicesSync
                        setIsLoading={setIsLoading}
                        handleGoBack={handleGoBack}
                        // downloadInv={handleClickDownload}
                        invoicesData={conversionDataNew}
                        // returnInvoiceModal={returnInvoiceModal}
                        setOpenPreview={setOpenPreview}
                        setFileData={setFileData}
                        handleHistSync={handleHistSync}
                        setBasePreviewData={setBasePreviewData}
                        // setOpenHeavy={setOpenHeavy}
                        replaceInvBill={replaceInvBill}
                        conversionType={1}
                    />
                ) : showHistSync ? (
                    <InvoicesHistSync
                        dataHist={dataHist}
                        showConversions={showConversions}
                        setIsLoading={setIsLoading}
                        setCorrelativeSync={setCorrelativeSync}
                        setShowSyncDetails={setShowSyncDetails}
                        setShowHistSync={setShowHistSync}
                        setDetailsHist={setDetailsHist}
                        setInvoicesStatus={setInvoicesStatus}
                        replaceInvBill={replaceInvBill}
                        isConsolidated={true}
                    />
                ) : showSyncDetails ? (
                    <InvoicesHistSyncDetails
                        detailsHist={detailsHist}
                        invoicesStatus={invoicesStatus}
                        handleGoBack={handleGoBack}
                        replaceInvBill={replaceInvBill}
                    />
                ) : (
                    <>
                        <Box pt={5} mb={3}>
                            <BaseButton variantType="primaryOutlined" sizebutton="m" onClick={handleGoBack}>
                                {isHistory ? t("inbox.goBackList") : t("converter.goBackConver")}
                            </BaseButton>
                        </Box>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                        <Button color="primary" sx={{ mt: 1 }} endIcon={<PreviewIcon />} onClick={handlePreviewDoc}>
                            {fileNameFull}
                        </Button>
                        <Box sx={{ backgroundColor: "#F8F8F9", px: 3, py: 2, borderRadius: 4, mt: 2 }}>
                            <Grid container justifyContent="space-between" spacing={2}>
                                <Grid item>
                                    <Stack direction="row" justifyContent="center" spacing={1} alignItems="center">
                                        <Typography>{t("converter.consoliAssign")}</Typography>
                                        <LightTooltip title={t("converter.tooltipAssign")}>
                                            <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                                        </LightTooltip>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="h1">{t("converter.haveProblem")}</Typography>
                                        <Button onClick={() => handleClickHelp("conversion", "consolidated", conversionData._id)}>
                                            {t("converter.contactUs")}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" spacing={2} sx={{ my: 0.2 }}>
                                <Grid item>
                                    <Typography variant="h2">{t("converter.requiredFields")}:</Typography>
                                </Grid>
                                {isValid && (
                                    <Grid item>
                                        <Typography variant="h3" sx={{ color: "#34BD6A" }}>
                                            {t("converter.readyToSync")}
                                        </Typography>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("InvoiceNum") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("converter.invoiceNumber")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("Contact") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("subscription.contact")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("Date") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("payment.date")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("Description") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("payment.description")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("UnitAmount") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("payment.unitPrice")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("AccountCode") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("accounts.accountNum2")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {!Object.values(invoiceFields).includes("TaxType") && (
                                    <Grid item>
                                        <Box sx={boxRed}>
                                            <Typography variant="h2">{t("converter.typeTax")}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            <Box sx={{ pl: 2, width: { md: 1200, lg: 1700, xl: 2300 } }}>
                                <Slider {...settings}>
                                    {Object.entries(conversionData.headers[0]).map((item, index) => (
                                        <Box key={item[0]} sx={{ p: 2 }}>
                                            <LightTooltip title={item[1]}>
                                                <Typography
                                                    variant="h2"
                                                    sx={{
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                    }}
                                                    gutterBottom
                                                >
                                                    {item[1]}
                                                </Typography>
                                            </LightTooltip>
                                            <FormControl variant="filled" fullWidth error={!invoiceFields[item[0]]}>
                                                <InputLabel
                                                    id="simple-select-label-all"
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                >
                                                    {t("converter.assignField")}
                                                </InputLabel>
                                                <Select
                                                    labelId="simple-select-label-all"
                                                    name={item[0]}
                                                    value={invoiceFields[item[0]] ?? ""}
                                                    onChange={handleChangeInvField}
                                                    size="small"
                                                    sx={{
                                                        bgcolor: invoiceFields[item[0]] ? "#dbf4e5" : "auto",
                                                        "&.Mui-focused": {
                                                            backgroundColor: invoiceFields[item[0]] ? "#dbf4e5" : "auto",
                                                        },
                                                    }}
                                                    MenuProps={MenuProps}
                                                >
                                                    {allFields.map((e) => (
                                                        <MenuItem value={e.value} key={e.value}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {conversionData.body.map((itemEntry, index) => (
                                                <Box
                                                    key={"row" + index}
                                                    sx={{
                                                        p: 2,
                                                        backgroundColor: "white",
                                                        borderRadius: 4,
                                                        mb: 2,
                                                        mt: 2,
                                                        height: 50,
                                                    }}
                                                >
                                                    {Object.entries(itemEntry).map(
                                                        (aux) =>
                                                            aux[0] === item[0] && (
                                                                <Typography
                                                                    key={aux[0]}
                                                                    sx={{
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                    }}
                                                                >
                                                                    {aux[1]}
                                                                </Typography>
                                                            )
                                                    )}
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Slider>
                            </Box>
                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                <BaseButton variantType="primaryOutlined" sizebutton="m" endIcon={<DownloadIcon />} onClick={downloadInv} disabled={!isValid}>
                                    {t("payment.download")}
                                </BaseButton>
                                <BaseButton
                                    sizebutton="m" 
                                    disableElevation
                                    endIcon={<SyncIcon />}
                                    onClick={syncConsolidatedInvoices}
                                    disabled={!isValid}
                                >
                                    {t("converter.sync")}
                                </BaseButton>
                            </Stack>
                        </Box>
                    </>
                )}
            </Container>
        </>

    );
};

export default ConsolidatedInvoicesPreview;
