/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import introJs from "intro.js";

/** MUI icons */
import EditIcon from "@mui/icons-material/Edit";
import accountsService from "../../services/accounts";
// import ArchiveIcon from "@mui/icons-material/Archive";
import AlertDialog from "../ui/AlertDialog";
import FormDialog from "../ui/FormDialog";

/** MUI imports */
import { Box, FormControlLabel, Stack, Switch, Typography, Skeleton, InputAdornment } from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import FiltersButton from "./FiltersButton";
import SimpleBackdrop from "../ui/SimpleBackdrop";
import AccountDetails from "./AccountDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addTutorial } from "../../redux/userSlice";
import BaseButton from "../ui/BaseButton";
import { useGlobalContext } from "../../context/GlobalContext";
import NoddokTable from "../ui/NoddokTable";
import CustTextField from "../ui/CustTextField";
import SearchIcon from "@mui/icons-material/Search";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        name: <Skeleton variant="text" animation="wave" />,
        country: <Skeleton variant="text" animation="wave" />,
        currency: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        platform: <Skeleton variant="text" animation="wave" />,
        transMonitoring: <Skeleton variant="text" animation="wave" />,
        edit: <Skeleton variant="text" animation="wave" />,
    });
}

const AccountSetupTable = (props) => {
    const {
        accounts,
        setAccounts,
        setReload,
        setAlertType,
        setTexto,
        setShow,
        banksFilters,
        banksFiltersConfig,
        currenciesFilters,
        countriesFilters,
        companyID,
        franchiseID,
        grupal,
        integration,
        showSkeleton,
        setShowSkeleton,
        allAccounts,
        setCountriesFilters,
        setCurrenciesFilters,
        setBanksFiltersConfig,
        handleClickHelp,
        disabledCount,
        setdisabledCount,
    } = props;
    const { t, todos } = useGlobalContext();
    const location = useLocation();

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [selectedAcc, setSelectedAcc] = useState({});
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [rows, setRows] = useState([]);
    const [editMode, setEditMode] = useState(true);
    const [rowsOG, setRowsOG] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);

    window.history.replaceState(null, "");

    const bodyMess = (
        <>
            <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 1, textAlign: "justify", mb: 2 }}
            >
                <Typography
                    fontStyle="normal"
                    fontSize={14}
                    fontWeight={300}
                    sx={{
                        color: "#131F3E",
                    }}
                >
                    {t("accounts.disabledMsg")}
                </Typography>
            </Stack>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    textAlign: "center",
                    mb: 2,
                }}
            >
                {t("dialog.warningConfirm2")}
            </Typography>
        </>
    );

    const bodyMess2 = (
        <>
            <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 2, textAlign: "justify", mb: 3 }}
            >
                <Typography
                    fontStyle="normal"
                    fontSize={14}
                    fontWeight={300}
                    sx={{
                        color: "#131F3E",
                    }}
                >
                    {t("accounts.reactivateMsg")}
                </Typography>
            </Stack>
        </>
    );

    const handleOpenEdit = (item) => {
        setSelectedAcc(item);
        setOpenEdit(true);
    };

    const handleClose = () => {
        setOpenEdit(false);
        setIsError(false);
        setSelectedAcc({});
        setEditMode(true);
    };

    const handleClickDelete = () => {
        setOpenAlert(true);
    };

    const handleClickActivate = () => {
        setOpenAlert(true);
    };

    useEffect(() => {
        (async () => {
            if (location.state?.addAccount) {
                setEditMode(false);
                setOpenEdit(true);
            }
        })();
    }, [location]);

    useEffect(() => {
        if (todos.steps.step5 && !todos.dontShowAgain) {
            let isDone = false;

            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} 5`,
                                element: "#account3-tutorial",
                                intro: t("miscellaneous.tutorialAcc3"),
                            },
                        ],
                    })
                    .oncomplete(() => {
                        isDone = true;
                        dispatch(addTutorial({ ...todos.steps, step5: false, step6: true }));
                    })
                    .onexit(() => {
                        if (!isDone) {
                            dispatch(addTutorial({ ...todos.steps, step5: false }));
                        }
                    })
                    .start();
            }, 3000);
        }
    }, [dispatch, t, todos.dontShowAgain, todos.steps]);

    useEffect(() => {
        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            const goToConvert = async (id) => {
                navigate("/convert", { state: { account_id: id } });
            };

            const fRows = accounts
            ? accounts.map((item, index) => ({
                  id: item._id,
                  name: checked ? (
                      item.account_name
                  ) : (
                      <BaseButton
                          id={index === 0 ? "account3-tutorial" : ""}
                          onClick={() => goToConvert(item._id)}
                          variantType="errorText"
                      >
                          {item.account_name}
                      </BaseButton>
                  ),
                  nameSearch: item.account_name,
                  country: item.country_name,
                  currency: (
                      <LightTooltip title={item.currency_short_name}>
                          <>{item.currency_iso_name}</>
                      </LightTooltip>
                  ),
                  bank: item.bank_name,
                  platform:
                      item.system_integration === 1
                          ? "Xero"
                          : item.system_integration === 2
                          ? "Quickbooks"
                          : "Manual",
                  transMonitoring: item.system_integration === 3 ? "N/A" : item.frequency,
                  edit: (
                      <>
                          {!checked && (
                              <EditIcon
                                  fontSize="small"
                                  sx={{ color: "#361A52", cursor: "pointer" }}
                                  onClick={() => handleOpenEdit(item)}
                              />
                          )}
                          {/* <ArchiveIcon fontSize="small" sx={{ color: "#361A52", cursor: "pointer" }} /> */}
                      </>
                  ),
              }))
            : []
            setRows(fRows);
            setRowsOG(fRows);
        }
    }, [showSkeleton, accounts, checked, navigate]);

    const columns = [
        //{ name: "id", title: "ID" },
        { name: "name", title: t("accounts.description") },
        { name: "bank", title: t("dashboard.bank") },
        // { name: "nameSearch", title: t("login.name") },
        { name: "country", title: t("register.country") },
        { name: "currency", title: t("dashboard.currency") },
        { name: "platform", title: t("accounts.platform") },
        ...(integration === 1
            ? [
                  {
                      name: "transMonitoring",
                      title: (
                          <LightTooltip title={t("accounts.transMonitoring1")}>
                              <Typography
                                  sx={{ lineHeight: "1.15rem", cursor: "default", fontSize: "15px" }}
                                  fontWeight={600}
                              >
                                  {t("accounts.transMonitoring")}
                              </Typography>
                          </LightTooltip>
                      ),
                  },
              ]
            : []),
        {
            name: "edit",
            title: " ",
        },
    ];

    // const tableColumnExtensions = [
    //     { columnName: "name", width: "25%" },
    //     { columnName: "bank" },
    //     { columnName: "country", width: "14%" },
    //     { columnName: "currency" },
    //     { columnName: "platform", width: "10%" },
    //     { columnName: "transMonitoring", wordWrapEnabled: true },
    //     { columnName: "edit", width: "5%" },
    // ];

    const handleCloseDialog = () => {
        setOpenAlert(false);
    };

    const deleteAccounts = async () => {
        setIsLoading(true);

        const params = {
            accounts: selected,
        };

        await accountsService
            .disableAccounts(params)
            .then(() => {
                setReload();
                setSelected([]);
                setAlertType("success");
                if (selected.length > 1) {
                    setTexto(t("accounts.deleteSuccess"));
                } else {
                    setTexto(t("accounts.deleteSuccessSing"));
                }
                setShow(true);
                handleCloseDialog();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const activateAccounts = async () => {
        setIsLoading(true);

        const params = {
            accounts: selected,
        };
        await accountsService
            .reactivateAccounts(params)
            .then(() => {
                setReload();
                setSelected([]);
                setAlertType("success");
                setChecked(false);
                if (selected.length > 1) {
                    setTexto(t("accounts.reactivateSuccess"));
                } else {
                    setTexto(t("accounts.reactivateSuccessSing"));
                }
                setShow(true);
                handleCloseDialog();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const handleUpdate = async () => {
        if (
            !selectedAcc.account_name ||
            !selectedAcc.country_id ||
            !selectedAcc.currency_id ||
            !selectedAcc.bank_id ||
            (selectedAcc.system_integration === 1 &&
                selectedAcc.account_id_system !== null &&
                selectedAcc.account_id_system !== undefined &&
                selectedAcc.account_id_system !== "" &&
                !selectedAcc.frequency)
        ) {
            setIsError(true);
            setTexto(t("accounts.requireFields"));
            setAlertType("warning");
            setShow(true);
            return;
        }

        setIsLoading(true);

        let bank_id = selectedAcc.bank_id;

        if (selectedAcc.bank_id.includes(",")) {
            let banksId = selectedAcc.bank_id.split(",");
            let finalBank = banksFilters.find(
                (bank) =>
                    banksId.includes(bank._id) &&
                    selectedAcc.country_id === bank.country_id &&
                    selectedAcc.currency_id === bank.currency_id
            );
            bank_id = finalBank._id;
        }

        const params = {
            account_id: selectedAcc._id,
            account_name: selectedAcc.account_name,
            company_id: selectedAcc.company_id,
            country_id: selectedAcc.country_id,
            currency_id: selectedAcc.currency_id,
            bank_id: bank_id,
            frequency: selectedAcc.frequency,
        };

        await accountsService
            .updateAccount(params)
            .then(() => {
                setTexto(t("accounts.successUpdate"));
                setAlertType("success");
                setShow(true);
                handleClose();
                setReload();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const handleNew = async () => {
        if (
            !selectedAcc.account_name ||
            !selectedAcc.country_id ||
            !selectedAcc.currency_id ||
            !selectedAcc.bank_id ||
            (selectedAcc.system_integration === 1 &&
                selectedAcc.account_id_system !== null &&
                selectedAcc.account_id_system !== undefined &&
                selectedAcc.account_id_system !== "" &&
                !selectedAcc.frequency)
        ) {
            setIsError(true);
            setTexto(t("accounts.requireFields"));
            setAlertType("warning");
            setShow(true);
            return;
        }

        setIsLoading(true);

        let bank_id = selectedAcc.bank_id;

        if (selectedAcc.bank_id.includes(",")) {
            let banksId = selectedAcc.bank_id.split(",");
            let finalBank = banksFilters.find(
                (bank) =>
                    banksId.includes(bank._id) &&
                    selectedAcc.country_id === bank.country_id &&
                    selectedAcc.currency_id === bank.currency_id
            );
            bank_id = finalBank._id;
        }

        const params = {
            account_name: selectedAcc.account_name,
            company_id: companyID,
            country_id: selectedAcc.country_id,
            currency_id: selectedAcc.currency_id,
            bank_id: bank_id,
            frequency: selectedAcc.frequency,
        };

        await accountsService
            .addAccount(params)
            .then(() => {
                setTexto(t("accounts.successNew"));
                setAlertType("success");
                setShow(true);
                handleClose();
                setReload();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const editAccButtons = (
        <GridUI container justifyContent="space-between">
            <GridUI item>
                <BaseButton sizebutton="m" variantType="primaryTextButton" onClick={handleClose}>{t("team.cancel")}</BaseButton>
            </GridUI>
            <GridUI item>
                <BaseButton sizebutton="m" onClick={editMode ? handleUpdate : handleNew}>
                    {t("team.save")}
                </BaseButton>
            </GridUI>
        </GridUI>
    );

    const handleChangeSwitch = async (event) => {
        setChecked(event.target.checked);
        setShowSkeleton(true);
        setPage(0);
        setAccounts({
            ...allAccounts,
            setup: [],
        });
        setSelected([]);

        if (event.target.checked) {
            await accountsService
                .getAccounts({ company_id: companyID, active: false, language: sessionStorage.getItem("lng") })
                .then(async (response) => {
                    setAccounts(response);
                    setdisabledCount(response.disabledCount);
                    await accountsService
                        .getBanks()
                        .then((data) => {
                            setBanksFiltersConfig(
                                data.filter((item) =>
                                    response.setup.some((item2) => item2.bank_id === item._id)
                                )
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    await accountsService
                        .getCurrencies()
                        .then((data) => setCurrenciesFilters(data))
                        .catch((error) => {
                            console.log(error);
                        });
                    await accountsService
                        .getCountries()
                        .then((data) => setCountriesFilters(data))
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await accountsService
                .getAccounts({ company_id: companyID, language: sessionStorage.getItem("lng") })
                .then(async (response) => {
                    setAccounts(response);
                    setdisabledCount(response.disabledCount);
                    await accountsService
                        .getBanks()
                        .then((data) => {
                            setBanksFiltersConfig(
                                data.filter((item) =>
                                    response.setup.some((item2) => item2.bank_id === item._id)
                                )
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    await accountsService
                        .getCurrencies()
                        .then((data) => setCurrenciesFilters(data))
                        .catch((error) => {
                            console.log(error);
                        });
                    await accountsService
                        .getCountries()
                        .then((data) => setCountriesFilters(data))
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setShowSkeleton(false);
    };

    const handleChangeSearchValue = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchValue(query);
        
        if (!event.target.value) {
            setRows(rowsOG);
        } else {
            // Filtrar filas basadas en cualquier campo
            const filterCases = rows.filter((row) =>
                Object.values(row).some(
                    (value) =>
                        value !== null &&
                        value !== undefined &&
                        value.toString().toLowerCase().includes(query)
                )
            );
            setRows(filterCases);
        }
    };

    return (
        <>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={!checked ? "warning" : "info2"}
                title={!checked ? t("dialog.warning") : t("login.confirm")}
                message={!checked ? bodyMess : bodyMess2}
                agreeAction={!checked ? () => deleteAccounts() : () => activateAccounts()}
                maxWidth="sm"
            />
            <SimpleBackdrop open={isLoading} />
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    pt: 3,
                }}
            >
                <Typography variant="h2" gutterBottom>{t("accounts.accSetup")}</Typography>
                <GridUI container spacing={2} justifyContent="space-between" display="flex" my={1.5}>
                    <GridUI item>
                        <FormControlLabel
                            sx={{ alignItems: "center" }}
                            control={
                                <Switch
                                    id="disabledAccounts"
                                    checked={checked}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            }
                            label={
                                <Typography fontSize={16} noWrap>
                                    {t("accounts.viewDisabled")} {disabledCount > 0 ? `(${disabledCount})` : ""}
                                </Typography>
                            }
                        />
                    </GridUI>
                    <GridUI item sx={{ flexGrow: 1 }}>
                        {selected.length > 0 ? (
                            !checked ? (
                                <BaseButton
                                    variantType="primaryOutlined"
                                    color="error"
                                    onClick={handleClickDelete}
                                    sizebutton="m"
                                >
                                    {t("accounts.disable")} {`(${selected.length})`}
                                </BaseButton>
                            ) : (
                                <BaseButton
                                    variantType="primaryOutlined"
                                    onClick={handleClickActivate}
                                    sizebutton="m"
                                >
                                    {t("accounts.activate")} {`(${selected.length})`}
                                </BaseButton>
                            )
                        ) : (
                            <Box sx={{height: "42px"}} component="span"></Box>
                        )}
                    </GridUI>
                    <GridUI item>
                        <FiltersButton
                            banks={banksFiltersConfig}
                            currencies={currenciesFilters.filter((item) =>
                                allAccounts.setup.some((item2) => item2.currency_id === item._id)
                            )}
                            countries={countriesFilters.filter((item) =>
                                allAccounts.setup.some((item2) => item2.country_id === item._id)
                            )}
                            setShowSkeleton={setShowSkeleton}
                            setAccounts={setAccounts}
                            companyID={companyID}
                            franchiseID={franchiseID}
                            grupal={grupal}
                            showSkeleton={showSkeleton}
                        />
                    </GridUI>
                    <GridUI item>
                        <CustTextField
                            id="input-with-icon-textfield"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            isSearch
                            hiddenLabel
                            placeholder={t("team.search")}
                            value={searchValue}
                            onChange={handleChangeSearchValue}
                        />
                    </GridUI>
                </GridUI>
                <GridUI container spacing={2}>
                    <GridUI item xs={12}>
                        <NoddokTable
                            columns={columns}
                            rows={rows}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            selected={selected}
                            setSelected={setSelected}
                            showCheckbox={true}
                        />
                    </GridUI>
                </GridUI>
            </Box>
            <FormDialog
                open={openEdit}
                handleClose={handleClose}
                maxWidth="md"
                title={
                    editMode ? (
                        <Stack direction="row" justifyContent="space-between">
                            {t("team.edit")} {selectedAcc.account_name}
                            <BaseButton variantType="errorText" onClick={handleClickHelp}>{t("accounts.needHelp")}</BaseButton>
                        </Stack>
                    ) : (
                        <Stack direction="row" justifyContent="space-between">
                            {t("accounts.createAccount1")}
                            <BaseButton variantType="errorText" onClick={handleClickHelp}>{t("accounts.needHelp")}</BaseButton>
                        </Stack>
                    )
                }
                content={
                    <AccountDetails
                        data={selectedAcc}
                        setSelectedAcc={setSelectedAcc}
                        countries={countriesFilters}
                        currencies={currenciesFilters}
                        banks={banksFilters}
                        accounts={accounts}
                        isError={isError}
                        setIsLoading={setIsLoading}
                        editMode={editMode}
                        handleClickHelp={handleClickHelp}
                    />
                }
                actions={editAccButtons}
            />
        </>
    );
};

export default AccountSetupTable;
