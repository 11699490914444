/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI icons */
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Loading from "../../../assets/loading.png";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";

/** MUI imports */
import { Box, Stack, Typography, Skeleton, IconButton, InputAdornment } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import FiltersButton from "../FiltersButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import ChatIcon from "@mui/icons-material/Chat";
import BaseSnackbar from "../../ui/BaseSnackbar";
import { useDispatch, useSelector } from "react-redux";
import conversionFormats from "../../Js/conversionFormats";
import conversionService from "../../../services/conversion";
import casesService from "../../../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import ChatModal from "../../ui/Chat";
import { addTutorial } from "../../../redux/userSlice";

import accountsService from "../../../services/accounts";

import { fetchFileFromS3, getFileExtension, zeroPad } from "../../../utils/utils";

import { read, utils } from "xlsx";
import SimpleDialog from "../../ui/SimpleDialog";
import BaseButton from "../../ui/BaseButton";
import CustTextField from "../../ui/CustTextField";
import NoddokTable from "../../ui/NoddokTable";
import NoddokTabsHeader from "../../ui/NoddokTabsHeader";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        uploadDate: <Skeleton variant="text" animation="wave" />,
        bankStatement: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        balance: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

const ConverterTable = (props) => {
    const {
        conversions,
        accounts,
        usersFilters,
        company_id,
        setConversions,
        franchise_id,
        grupal,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        setFilters,
        filters,
        tutorial,
        setCorrelative,
        setConversionId,
        setFileNameSaved,
        setAccount,
        setShowPrev,
        setFileData,
        setFileNameFull,
        setConversionData,
        setHTML,
        download,
        setDownload,
        setConvFormat,
        setAccountNum,
        setAccountType,
        setBankInst,
        setEndPoint,
        setFormats,
        setAccountDet,
        finalQBOBanks,
        account,
        setGroupConversions,
        groupConversions,
        setOpenModalGroupedHist,
        setGroupedData,
        setGroupedPeriods,
        setOpenModalGrouped,
        setValue,
        value,
        setModalType,
        setRowsModal,
        setBasePreviewData,
        setIntegration,
        setOpenPreview,
    } = props;

    const [t] = useTranslation("global");
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchValue] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [rows, setRows] = useState([]);
    const [rowsOG, setRowsOG] = useState([]);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState("");
    const [caseInfo, setCaseInfo] = useState({});
    const [showModalHeavy, setShowModalHeavy] = useState(false);
    const [heavyConv, setHeavyConv] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleCloseHeavyModal = () => {
        setHeavyConv(null);
        setShowModalHeavy(false);
    };

    const columns = [
        { name: "number", title: t("subscription.conversion"), width: "7%" },
        { name: "account", title: t("converter.account"), align: "left" },
        { name: "uploadDate", title: t("converter.uploadDate"), align: "right", width: "10%"  },
    ];

    if (conversions.length > 0) {
        columns.push({ name: "bankStatement", title: t("converter.bankStatement"), align: "left" });
    }

    columns.push(
        { name: "period", title: t("payment.period"), align: "center", width: "10%" },
        { name: "balance", title: t("converter.balance"), align: "right", width: "10%" },
        { name: "user", title: t("converter.user"), align: "left", width: "10%" },
        { name: "status", title: t("converter.status"), align: "center", width: "10%" },
        { name: "chat", title: <></>, width: "5%", align: "center" }
    );

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    useEffect(() => {
        if ((todos.steps.step8 || todos.steps.step9) && tutorial && download && !todos.dontShowAgain) {
            let number = "";

            if (todos.steps.step8) {
                number = "8";
            } else {
                number = "9";
            }
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} ${number}`,
                                element: "#account8-tutorial",
                                intro: t("miscellaneous.tutorialConv5"),
                            },
                        ],
                    })
                    .onexit(() => {
                        if (todos.steps.step8) {
                            dispatch(addTutorial({ ...todos.steps, step8: false }));
                        } else {
                            dispatch(addTutorial({ ...todos.steps, step9: false }));
                        }
                        setDownload(false);
                    })
                    .start();
            }, 1000);
        }
    }, [dispatch, download, setDownload, t, todos.dontShowAgain, todos.steps, tutorial]);

    const handleConfirmHeavy = async () => {
        const params = { conversion_id: heavyConv._id };

        setIsLoading(true);

        await conversionService
            .getBankStatementsByConversion(params)
            .then(async () => {
                await accountsService
                    .getAccountDetails(heavyConv.account_id)
                    .then((response) => {
                        const formats = response.bank.file_format.map((item) => {
                            return { id: item, name: item };
                        });
                        setAccountNum(response.account_number_qb ?? "");
                        setAccountType(response.account_type_qb ?? "");
                        setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                        setEndPoint(response.bank.endpoint);
                        setFormats(formats);
                        setAccountDet(response);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                setGroupedData(null);
                await conversionService
                    .getTempConversionDetails(params)
                    .then(async (response) => setConversionData(response))
                    .catch((err) => console.log(err));

                await conversionService
                    .getFileS3(params)
                    .then(async (response) => {
                        setFileData(response.data);
                        setConvFormat(conversionFormats);
                        setConversionId(heavyConv._id);
                        setFileNameFull(heavyConv.uploaded_file);
                        setCorrelative(heavyConv.correlative);
                        setFileNameSaved(heavyConv.uploaded_file.replace(/\.[^/.]+$/, ""));

                        if (
                            getFileExtension(heavyConv.uploaded_file) === "xlsx" ||
                            getFileExtension(heavyConv.uploaded_file) === "xls"
                        ) {
                            const f = await response.data.arrayBuffer();

                            /* parse workbook */
                            const workbook = read(f, { type: "binary" });
                            /* get first worksheet */
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            /* generate and display HTML */
                            const table = utils.sheet_to_html(worksheet);
                            setHTML(table);
                        }
                    })
                    .catch((err) => {
                        setTexto(t("converter.dnldError"));
                        setAlertType("error");
                        setShow(true);
                        console.log(err);
                    });

                setAccount(heavyConv.account_id);
                setShowPrev(true);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        setHeavyConv(null);
        setShowModalHeavy(false);
    };

    useEffect(() => {
        const handleShowPrev = async (convID, AccID, fileName, correlative, isGrouped, firstConvID, item) => {
            let params = {};

            setIntegration(item.account_id.system_integration);

            if (item.large_conversion && item.id_status === 2) {
                if (isGrouped) {
                    params.grouped_conversion_id = convID;
                } else {
                    params.conversion_id = convID;
                }

                setIsLoading(true);

                await conversionService
                    .getConversionDetails(params)
                    .then((data) => {
                        setHeavyConv(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                setIsLoading(false);
                setShowModalHeavy(true);
            } else {
                setIsLoading(true);

                await accountsService
                    .getAccountDetails(AccID)
                    .then((response) => {
                        const formats = response.bank.file_format.map((item) => {
                            return { id: item, name: item };
                        });
                        setAccountNum(response.account_number_qb ?? "");
                        setAccountType(response.account_type_qb ?? "");
                        setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                        setEndPoint(response.bank.endpoint);
                        setFormats(formats);
                        setAccountDet(response);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (isGrouped) {
                    params = {
                        conversion_id: firstConvID,
                    };
                    await conversionService
                        .getGroupedConversionDetails({ grouped_conversion_id: convID })
                        .then((response) => {
                            setGroupedData(response);
                            setFileNameSaved("GROUP-" + zeroPad(response.correlative ?? 0, 5));
                            const filteredConv = response.conversions.filter(
                                (item) => item.id_status === 2 || item.id_status === 3
                            );
                            setGroupedPeriods(filteredConv);
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    setBasePreviewData({
                        id: firstConvID,
                        type: "bankStatement",
                    });
                } else {
                    params = {
                        conversion_id: convID,
                    };
                    setGroupedData(null);
                    await conversionService
                        .getTempConversionDetails({ conversion_id: convID })
                        .then(async (response) => setConversionData(response))
                        .catch((err) => console.log(err));

                    setBasePreviewData({
                        id: convID,
                        type: "bankStatement",
                    });
                }

                await conversionService
                    .getFileS3(params)
                    .then(async (response) => {
                        setFileData(response.data);
                        setConvFormat(conversionFormats);
                        setConversionId(isGrouped ? firstConvID : convID);
                        setFileNameFull(fileName);
                        setCorrelative(correlative);
                        setFileNameSaved(fileName.replace(/\.[^/.]+$/, ""));

                        if (getFileExtension(fileName) === "xlsx" || getFileExtension(fileName) === "xls") {
                            const f = await response.data.arrayBuffer();

                            /* parse workbook */
                            const workbook = read(f, { type: "binary" });
                            /* get first worksheet */
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            /* generate and display HTML */
                            const table = utils.sheet_to_html(worksheet);
                            setHTML(table);
                        }
                    })
                    .catch((err) => {
                        setTexto(t("converter.dnldError"));
                        setAlertType("error");
                        setShow(true);
                        console.log(err);
                    });

                setAccount(AccID);
                setShowPrev(true);
                setIsLoading(false);
            }
        };

        // const downloadFile = async (id, file_name) => {
        //     let params = {
        //         conversion_id: id,
        //     };
        //     setIsLoading(true);
        //     await conversionService
        //         .getFileUrlS3(params)
        //         .then((response) => {
        //             // Crea un enlace temporal y haz clic en él para iniciar la descarga
        //             const link = document.createElement("a");
        //             link.href = response.url;
        //             document.body.appendChild(link);
        //             link.click();

        //             // Limpia el enlace temporal
        //             document.body.removeChild(link);
        //         })
        //         .catch((err) => {
        //             setTexto(t("converter.dnldError"));
        //             setAlertType("error");
        //             setShow(true);
        //             console.log(err);
        //         });
        //     setIsLoading(false);
        // };

        const previewEECC = async (convID, file, file_s3) => {
            setIsLoading(true);
            const finalData = await fetchFileFromS3(file_s3);
            setIsLoading(false);

            if (!finalData) {
                setTexto(t("converter.dnldError"));
                setAlertType("error");
                setShow(true);
            } else {
                setFileData(finalData);
            }
            setFileNameFull(file);
            setBasePreviewData({
                id: convID,
                type: "bankStatement",
            });

            setOpenPreview(true);
        };

        const connectSocket = async (case_id, correlative, status) => {
            let typeChat = "general";

            if (status === 2 || status === 5) {
                typeChat = "conversion";
            }

            setRoom(case_id);
            initiateSocket(case_id, todos.userInfo, typeChat, correlative);
            subscribeToChat((err, data) => {
                if (err) return;
                setChat((oldChats) => [...oldChats, data]);
            });
            newUserResponse((err, data) => {
                if (err) return;
                setUsers(data);
            });
            setOpen(true);
        };

        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            const handleClickChat = async (statusConv, conversionID, correlative) => {
                setIsLoading(true);

                let params = {
                    company_id: todos.companyID,
                    franchise_id: todos.franchiseID,
                    user_email: todos.userInfo.email,
                    type: "conversion",
                    conversion_id: conversionID,
                    conversion_type: "bankStatement",
                };

                let caseInfo = null;

                await casesService
                    .getCaseInfo(params)
                    .then(async (response) => {
                        if (response) {
                            setCaseInfo(response.caseInfo);
                            caseInfo = response.caseInfo;
                            setStatus("");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (!caseInfo) {
                    await casesService
                        .createNewCase(params)
                        .then(async () => {
                            await casesService
                                .getCaseInfo(params)
                                .then((data) => {
                                    setCaseInfo(data.caseInfo);
                                    caseInfo = data.caseInfo;
                                    setStatus(1);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    setStatus(caseInfo.case_reason);
                }

                await connectSocket(caseInfo._id, correlative, statusConv);

                setIsLoading(false);
            };

            const showConversions = (index) => {
                setModalType("bankStatement");
                setOpenModalGroupedHist(true);
                setOpenModalGrouped(true);
                let newRows = groupConversions[index].conversions.map((item) => ({
                    id: item._id,
                    number: item.correlative,
                    file_name: item.uploaded_file,
                    file_name_s3: item.uploaded_file_s3,
                    period:
                        item.id_status !== 2 && item.id_status !== 3
                            ? "-"
                            : `${moment.utc(item.initial_date).format(todos.dateFormat)} - ${moment
                                  .utc(item.end_date)
                                  .format(todos.dateFormat)}`,
                    status: item.id_status === 1 || item.id_status === 6 ? 1 : item.id_status,
                }));
                setRowsModal(newRows);
            };

            const successConv = conversions.filter((item) => item.id_status === 3).map((item) => item._id);
            const lastSuccess = successConv.length > 0 ? successConv[0] : null;

            if (conversions.length > 0) {
                let fConv = conversions.map((item) => ({
                    id: item._id,
                    number:
                        item.correlative != null && item.correlative !== undefined
                            ? "#" + zeroPad(item.correlative, 5)
                            : "",
                    account: item.account_id.account_name,
                    uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                    bankStatement: item.uploaded_file_s3 ? (
                        <BaseButton
                            variantType="errorText"
                            onClick={() => previewEECC(item._id, item.uploaded_file, item.uploaded_file_s3)}
                        >
                            {item.uploaded_file}
                        </BaseButton>
                    ) : (
                        item.uploaded_file
                    ),
                    period:
                        item.initial_date && item.end_date
                            ? `${moment.utc(item.initial_date).format(todos.dateFormat)} - ${moment
                                  .utc(item.end_date)
                                  .format(todos.dateFormat)}`
                            : "N/A",
                    user: item.user_name,
                    balance:
                        item.id_status === 6
                            ? ""
                            : item.balance === null
                            ? ""
                            : item.balance || parseFloat(item.balance) === 0
                            ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              })
                            : "",
                    status: (
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            {item.id_status === 1 && <img src={Loading} alt="loading" width={20} />}
                            {item.id_status === 2 && <DownloadIcon sx={{ color: "#361A52" }} fontSize="inherit" />}
                            {item.id_status === 3 && <CheckIcon sx={{ color: "#00B147" }} fontSize="inherit" />}
                            {item.id_status === 4 && (
                                <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                            )}
                            {item.id_status === 5 && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                            &nbsp;
                            <Typography>
                                {item.id_status === 1
                                    ? t("converter.inProc")
                                    : item.id_status === 2
                                    ? t("converter.convertible")
                                    : item.id_status === 3
                                    ? t("converter.successful")
                                    : item.id_status === 4
                                    ? t("converter.inReview")
                                    : t("converter.invalid")}
                            </Typography>
                        </Stack>
                    ),
                    chat:
                        item.id_status !== 5 ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <LightTooltip title={t("converter.tooltipChat")} position="top">
                                    <IconButton
                                        onClick={() =>
                                            handleClickChat(
                                                item.id_status,
                                                item._id,
                                                `#${zeroPad(item.correlative, 5)}`
                                            )
                                        }
                                        size="small"
                                        color="primary"
                                    >
                                        <ChatIcon fontSize="small" />
                                    </IconButton>
                                </LightTooltip>
                                {(item.id_status === 2 || item.id_status === 3) && (
                                    <IconButton
                                        onClick={() =>
                                            handleShowPrev(
                                                item._id,
                                                item.account_id._id,
                                                item.uploaded_file,
                                                item.correlative,
                                                false,
                                                null,
                                                item
                                            )
                                        }
                                        size="small"
                                        color="primary"
                                        id={
                                            lastSuccess !== null && lastSuccess === item._id
                                                ? "account8-tutorial"
                                                : ""
                                        }
                                    >
                                        {item.id_status === 3 ? (
                                            <DownloadIcon fontSize="small" />
                                        ) : (
                                            <VisibilityIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (
                            ""
                        ),
                }))
                setRows(fConv);
                setRowsOG(fConv);
            } else if (groupConversions.length > 0) {

                let fConvG = groupConversions.map((item, index) => {
                    const groupedPeriods = item.conversions.filter(
                        (item) => item.id_status === 2 || item.id_status === 3
                    );

                    // 1: En proceso, 2: Por descargar, 3: Exitoso, 4: En revisión, 5: Inválida
                    let finalStatus = 0;
                    let partial = 0;
                    if (item.conversions.every((value) => value.id_status === 3)) {
                        // Exitoso (Estatus final)
                        finalStatus = 3;
                    } else if (item.conversions.every((value) => value.id_status === 5)) {
                        // Inválida
                        finalStatus = 5;
                    } else if (item.conversions.some((value) => value.id_status === 4)) {
                        // En revisión
                        finalStatus = 4;
                        if (item.conversions.some((value) => value.id_status === 3 || value.id_status === 2)) {
                            partial = 1;
                        }
                    } else if (item.conversions.some((value) => value.id_status === 2)) {
                        // Por descargar
                        finalStatus = 2;
                    } else if (item.conversions.some((value) => value.id_status === 1)) {
                        // En proceso
                        finalStatus = 1;
                    }

                    finalStatus = item?.completed_conversion ? finalStatus : 1;

                    return {
                        id: item._id,
                        number: (
                            <BaseButton variantType="errorText" onClick={() => showConversions(index)}>
                                {item.correlative != null && item.correlative !== undefined
                                    ? "GROUP-" + zeroPad(item.correlative, 5)
                                    : ""}
                            </BaseButton>
                        ),
                        account: item.conversions[0].account_id.account_name,
                        uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                        period:
                            groupedPeriods.length !== 0 &&
                            groupedPeriods[0].initial_date &&
                            groupedPeriods[groupedPeriods.length - 1].end_date
                                ? `${moment.utc(groupedPeriods[0].initial_date).format(todos.dateFormat)} - ${moment
                                      .utc(groupedPeriods[groupedPeriods.length - 1].end_date)
                                      .format(todos.dateFormat)}`
                                : "N/A",
                        user: item.conversions[0].user_name,
                        balance:
                            finalStatus === 5 || item.conversions[item.conversions.length - 1].balance === null
                                ? ""
                                : item.conversions[item.conversions.length - 1].balance ||
                                  parseFloat(item.conversions[item.conversions.length - 1].balance) === 0
                                ? parseFloat(item.conversions[item.conversions.length - 1].balance).toLocaleString(
                                      todos.amountFormat,
                                      {
                                          minimumFractionDigits: 2,
                                      }
                                  )
                                : "",
                        status: (
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                {finalStatus === 1 && <img src={Loading} alt="loading" width={20} />}
                                {finalStatus === 2 && <DownloadIcon sx={{ color: "#361A52" }} fontSize="inherit" />}
                                {finalStatus === 3 && <CheckIcon sx={{ color: "#00B147" }} fontSize="inherit" />}
                                {finalStatus === 4 && (
                                    <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {finalStatus === 5 && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                                &nbsp;
                                <Typography>
                                    {finalStatus === 1
                                        ? t("converter.inProc")
                                        : finalStatus === 2
                                        ? t("converter.convertible")
                                        : finalStatus === 3
                                        ? t("converter.successful")
                                        : finalStatus === 4
                                        ? t("converter.inReview")
                                        : t("converter.invalid")}
                                </Typography>
                            </Stack>
                        ),
                        chat:
                            finalStatus !== 5 ? (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* <LightTooltip title={t("converter.tooltipChat")} position="top">
                                        <IconButton
                                            onClick={() =>
                                                handleClickChat(
                                                    finalStatus,
                                                    item._id,
                                                    `#${zeroPad(item.correlative, 5)}`
                                                )
                                            }
                                            size="small"
                                            color="primary"
                                        >
                                            <Box component="img" src={ChatIcon} sx={{ width: 17 }} />
                                        </IconButton>
                                    </LightTooltip> */}
                                    {(finalStatus === 2 || finalStatus === 3 || partial === 1) && (
                                        <IconButton
                                            onClick={() =>
                                                handleShowPrev(
                                                    item._id,
                                                    item.conversions[0].account_id._id,
                                                    item.conversions[0].uploaded_file,
                                                    item.correlative,
                                                    true,
                                                    item.conversions[0]._id,
                                                    item
                                                )
                                            }
                                            size="small"
                                            color="primary"
                                            id={
                                                lastSuccess !== null && lastSuccess === item._id
                                                    ? "account8-tutorial"
                                                    : ""
                                            }
                                        >
                                            {finalStatus === 3 || partial === 1 ? (
                                                <DownloadIcon fontSize="small" />
                                            ) : (
                                                <VisibilityIcon fontSize="small" />
                                            )}
                                        </IconButton>
                                    )}
                                </Stack>
                            ) : (
                                ""
                            ),
                    };
                })
                setRows(fConvG);
                setRowsOG(fConvG);
            } else {
                setRows([]);
                setRowsOG([]);
            }
        }
    }, [
        conversions,
        finalQBOBanks,
        groupConversions,
        setAccount,
        setAccountDet,
        setAccountNum,
        setAccountType,
        setBankInst,
        setConvFormat,
        setConversionData,
        setConversionId,
        setCorrelative,
        setEndPoint,
        setFileData,
        setFileNameFull,
        setFileNameSaved,
        setFormats,
        setGroupedData,
        setGroupedPeriods,
        setHTML,
        setIsLoading,
        setModalType,
        setOpenModalGrouped,
        setOpenModalGroupedHist,
        setShowPrev,
        showSkeleton,
        t,
        todos.amountFormat,
        todos.companyID,
        todos.dateFormat,
        todos.franchiseID,
        todos.userInfo,
        setRowsModal,
        setBasePreviewData,
        setIntegration,
        setOpenPreview,
    ]);

    const downloadReport = async () => {
        setIsLoading(true);

        let fileName = `StatementsHistory-${moment().format("MMDDYYYY")}`;

        await conversionService
            .generateConversionsReport({
                company_id: todos.companyID,
                origin: "converter",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName + ".csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const attributes = [
        { id: 1, name: t("dashboard.bank"), query: "account_id_filter" },
        { id: 2, name: t("converter.uploadDate"), query: "uploaded" },
        { id: 3, name: t("converter.format"), query: "format" },
        { id: 4, name: t("converter.bankStatementDate"), query: "period" },
        { id: 5, name: t("converter.user"), query: "user_name" },
        { id: 6, name: t("converter.status"), query: "id_status" },
    ];

    const handleChangeTab = async (event, newValue) => {
        setShowSkeleton(true);
        setValue(newValue);

        let params = { company_id: todos.companyID, account_id: account };

        if (filters.filtersArray.filter((e) => e.type !== "")?.length > 0) {
            let objectFinal = {};
            let aux = [...filters.filtersArray];

            aux.forEach((item) => {
                if (item.value && item.type) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD");
                }
            });

            params = {
                ...params,
                ...objectFinal,
            };

            if (params?.account_id) {
                params.account_id_filter = params.account_id;
                params.account_id = "";
            }
        }

        if (newValue === 1) {
            await conversionService
                .getGroupedConversions(params)
                .then((data) => {
                    setConversions([]);
                    setGroupConversions(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await conversionService
                .getConversions(params)
                .then((response) => {
                    setGroupConversions([]);
                    setConversions(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setShowSkeleton(false);
    };

    const handleChangeSearchValue = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchValue(query);
        
        if (!event.target.value) {
            setRows(rowsOG);
        } else {
            // Filtrar filas basadas en cualquier campo
            const filterCases = rows.filter((row) =>
                Object.values(row).some(
                    (value) =>
                        value !== null &&
                        value !== undefined &&
                        value.toString().toLowerCase().includes(query)
                )
            );
            setRows(filterCases);
        }
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    pt: 3,
                }}
            >
                <GridUI container justifyContent="space-between" alignItems="end">
                    <GridUI item xs={12}>
                        <Typography variant="h2" color="secondary" pl={4}>
                            {t("converter.conversionHist")}
                        </Typography>
                        <NoddokTabsHeader
                            value={value}
                            onChange={handleChangeTab}
                            labels={[t("converter.singles"), t("converter.groupedConvs")]}
                        />
                    </GridUI>
                    <GridUI item xs={12}  bgcolor="#FFF" p={3}>
                        <GridUI container spacing={2} justifyContent="end" display="flex">
                            <GridUI item>
                                <BaseButton
                                    sizebutton="m"
                                    onClick={downloadReport}
                                    disableElevation
                                    endIcon={<DownloadIcon fontSize="inherit" />}
                                >
                                    {t("converter.download")}
                                </BaseButton>
                            </GridUI>
                            <GridUI item>
                                <FiltersButton
                                    accounts={accounts}
                                    setAccount={setAccount}
                                    setIsLoading={setIsLoading}
                                    users={usersFilters}
                                    company={company_id}
                                    setConversions={setConversions}
                                    setShowSkeleton={setShowSkeleton}
                                    franchise={franchise_id}
                                    grupal={grupal}
                                    setFilters={setFilters}
                                    filters={filters}
                                    setGroupConversions={setGroupConversions}
                                    tabValue={value}
                                />
                            </GridUI>
                            <GridUI item>
                                <CustTextField
                                    id="input-with-icon-textfield"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    isSearch
                                    hiddenLabel
                                    placeholder={t("team.search")}
                                    value={searchValue}
                                    onChange={handleChangeSearchValue}
                                />
                            </GridUI>
                        </GridUI>
                    </GridUI>
                    <GridUI item xs={12} bgcolor="#FFF" px={3}>
                        <NoddokTable
                            columns={columns}
                            rows={rows}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            showCheckbox={false}
                        />
                    </GridUI>
                </GridUI>
            </Box>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            {open && (
                <ChatModal
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type={status}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    setCaseInfo={setCaseInfo}
                    caseInfo={caseInfo}
                />
            )}
            {showModalHeavy && (
                <SimpleDialog
                    open={showModalHeavy}
                    handleClose={handleCloseHeavyModal}
                    maxWidth="sm"
                    content={
                        <Box sx={{ px: 4 }}>
                            <Typography variant="h2" color="primary" sx={{ textAlign: "center", pb: 2 }}>
                                {t("converter.wishtoConvert")}
                            </Typography>
                            <Typography sx={{ pb: 2 }}>
                                {t("converter.heavyModal1")}
                                <b>{`${parseFloat(heavyConv?.total_transactions).toLocaleString(todos.amountFormat)} ${t(
                                    "dashboard.transacc"
                                )}`}</b>
                                {t("converter.heavyModal2")}
                                <b>{`${heavyConv?.large_conversion_cost} ${t("register.conversions")}.`}</b>
                                <LightTooltip title={t("converter.heavyTooltip")}>
                                    <InfoIcon sx={{ color: "#361A52", fontSize: 16 }} />
                                </LightTooltip>
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                sx={{ pb: 2 }}
                                spacing={2}
                            >
                                <BaseButton variantType="primaryTextButton" sizebutton="s" onClick={handleCloseHeavyModal}>{t("register.back")}</BaseButton>
                                <LightTooltip
                                    title={
                                        todos.credits - heavyConv?.large_conversion_cost < 0
                                            ? t("converter.noCreditsModalTool")
                                            : ""
                                    }
                                >
                                    <span>
                                        <BaseButton
                                            sizebutton="s"
                                            onClick={handleConfirmHeavy}
                                            disabled={todos.credits - heavyConv?.large_conversion_cost < 0}
                                        >
                                            {t("subscription.confirm")}
                                        </BaseButton>
                                    </span>
                                </LightTooltip>
                            </Stack>
                        </Box>
                    }
                />
            )}
        </>
    );
};

export default ConverterTable;
