import { Paper, Typography, Box, Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import BaseButton from "../ui/BaseButton";

const HeaderBar = (props) => {
    const {
        plansInfo,
        currentPlan,
        currentPlanInfo,
        setOpenAlertReactivate,
        handleClickReactivateChange,
        freePlan,
        lastChange,
        wasCancel,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const [displayHeader, setDisplayHeader] = useState(false);
    const [level, setLevel] = useState({}); // Nivel de plan elegido para downgrade.

    useEffect(() => {
        const filterPlan = plansInfo.find((plan) => plan.levels.some((level) => level.id === currentPlanInfo?.planId));

        const filterLevel = filterPlan?.levels.find((level) => level.id === currentPlanInfo?.selectLevel);
        setLevel(filterLevel);
    }, [plansInfo, currentPlanInfo]);

    const reactivatePlan = () => {
        setOpenAlertReactivate(true);
    };

    const reactivateSubsInactive = () => {
        const franchise = todos?.allComData.find((data) => data._id === todos?.franchiseID);
        const data =
            franchise?.id_last_status === 3
                ? plansInfo.find((item) => item.planId === currentPlanInfo.subscription_plan)
                : undefined;

        handleClickReactivateChange(data, franchise?.id_last_status === 2);
    };

    // Obtenemos la fecha de próximo corte de facturación
    const getDueDate = moment.utc(currentPlanInfo?.since_date).format(todos.dateFormat);

    useEffect(() => {
        if (todos?.subscription?.value === 6 || currentPlanInfo?.last_cancel || lastChange || wasCancel || freePlan) {
            setDisplayHeader(true);
        } else {
            setDisplayHeader(false);
        }
    }, [currentPlanInfo?.last_cancel, lastChange, wasCancel, freePlan, todos?.subscription?.value]);

    return (
        <Paper
            elevation={0}
            square={false}
            sx={{
                px: 4,
                py: 2,
                mt: 2,
                width: "100%",
                boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.10)",
                display: displayHeader ? "block" : "none",
            }}
        >
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                {todos.subscription?.value === 6 && (
                    <>
                        <Stack direction="column" spacing={1}>
                            <Typography fontSize={18} fontWeight={600}>
                                {t("subscription.cancelInactive")}{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#361A52",
                                        fontWeight: "600",
                                    }}
                                >
                                    {t("subscription.inactive")}
                                </Box>
                            </Typography>
                            <Stack direction="row" spacing={0.5}>
                                <Typography fontSize={14} fontWeight={600}>
                                    {t("subscription.cancelInactiveMsg")}
                                </Typography>
                            </Stack>
                        </Stack>

                        {!freePlan && (
                            <>
                                <Box
                                    bgcolor="rgba(1, 172, 69, 0.80)"
                                    paddingY={2}
                                    paddingX={2.5}
                                    borderRadius="10px"
                                    onClick={reactivateSubsInactive}
                                    component={Button}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "rgba(1, 172, 69, 0.80)",
                                            border: "rgba(1, 172, 69, 0.80)",
                                        },
                                    }}
                                >
                                    <Typography fontSize={28} fontWeight={600} alignItems="center" display="flex">
                                        <Box
                                            component="span"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: 16,
                                                color: "#FFF",
                                            }}
                                        >
                                            {t("subscription.reactivateLastPlan")}
                                        </Box>
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </>
                )}
                {!currentPlan && todos.subscription?.value !== 6 && (
                    <>
                        <Stack direction="column" spacing={1}>
                            <Typography fontSize={18} fontWeight={600}>
                                {!currentPlan && todos.subscription.value !== 2 && todos.subscription.value !== 5
                                    ? t("subscription.currentlySubs")
                                    : todos.subscription.value !== 5
                                    ? t("subscription.currentlySubsOnDemand")
                                    : t("subscription.currentlyNoSubs")}{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#361A52",
                                        fontWeight: "600",
                                    }}
                                >
                                    {!currentPlan && todos.subscription.value !== 2 && todos.subscription.value !== 5
                                        ? t("subscription.freePlan")
                                        : todos.subscription.value !== 5
                                        ? "Plan " + t("navBar.onDemand").toLowerCase()
                                        : t("subscription.activePlan")}
                                </Box>
                            </Typography>
                            <Typography fontSize={14}>
                                {!currentPlan && todos.subscription.value !== 2
                                    ? t("subscription.purchaseCredits")
                                    : t("subscription.onDemandMsg")}
                            </Typography>
                        </Stack>
                        <Box bgcolor="#e4def9" paddingY={1} paddingX={2} borderRadius={2}>
                            <Typography fontSize={28} fontWeight={600} alignItems="center" display="flex">
                                <Box component="span">{todos.credits}</Box>
                                <Box
                                    component="span"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: 14,
                                        ml: 1,
                                    }}
                                >
                                    {t("subscription.remainingConver")}
                                </Box>
                            </Typography>
                        </Box>
                    </>
                )}
                {wasCancel && (
                    <>
                        <Stack direction="column" spacing={1}>
                            <Typography fontSize={18} fontWeight={600}>
                                {t("subscription.cancelMsg")}{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#361A52",
                                        fontWeight: "600",
                                    }}
                                >
                                    {!currentPlan
                                        ? t("subscription.freePlan")
                                        : "Plan " +
                                          currentPlanInfo?.planTitle +
                                          " " +
                                          level?.pages +
                                          " " +
                                          t("plans.pageMonth")}
                                </Box>
                            </Typography>
                            <Stack direction="row" spacing={0.5}>
                                <Typography fontSize={14} fontWeight={600}>
                                    {t("subscription.cancelMsg1")}
                                </Typography>
                                <Typography fontSize={14} color="#361A52" fontWeight={600}>
                                    {getDueDate}
                                </Typography>
                                <Typography fontSize={14} fontWeight={600}>
                                    {t("subscription.cancelMsg2")}.
                                </Typography>
                            </Stack>
                        </Stack>
                        <BaseButton disableElevation onClick={reactivatePlan}>
                            {t("subscription.reactivateSubsMsg")}
                        </BaseButton>
                    </>
                )}
                {lastChange && (
                    <>
                        <Stack direction="column" spacing={1} sx={{ maxWidth: "80%" }}>
                            <Typography fontSize={18} fontWeight={600}>
                                {t("subscription.changePlanMsg")}{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#361A52",
                                        fontWeight: "600",
                                    }}
                                >
                                    {"Plan " +
                                        currentPlanInfo?.planTitle +
                                        " " +
                                        level?.pages +
                                        " " +
                                        t("plans.pageMonth")}
                                    ?
                                </Box>
                            </Typography>
                            <Typography fontSize={14} fontWeight={400}>
                                {t("subscription.changePlanMsg1")}{" "}
                                <Typography fontSize={14} color="#361A52" fontWeight={600} component="span">
                                    {getDueDate}{" "}
                                </Typography>
                                <Typography fontSize={14} fontWeight={400} component="span">
                                    {t("subscription.changePlanMsg2")}.
                                </Typography>
                            </Typography>
                        </Stack>
                        <BaseButton disableElevation onClick={reactivatePlan}>
                            {t("subscription.reactivateLastSubs")}
                        </BaseButton>
                    </>
                )}
            </Stack>
        </Paper>
    );
};

export default HeaderBar;
