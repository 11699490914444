import React, { useEffect, useRef, useState } from "react";
import { disconnectSocket, sendMessage, emitMessage } from "../../services/Socket";
import chatService from "../../services/chat";
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Slide,
    DialogTitle,
    IconButton,
    Box,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveIcon from "@mui/icons-material/Remove";
import CancelIcon from "@mui/icons-material/Cancel";
/** MUI imports */
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import casesService from "../../services/case";
import conversionService from "../../services/conversion";
import ITTeam from "../Js/ITTeam";
import PDFIcon from "../../assets/pdf.svg";
import ImageIcon from "../../assets/image.png";
import ExcelIcon from "../../assets/microsoft-excel.svg";
import TxtIcon from "../../assets/txtFileIcon.svg";

const PREFIX = "DialogAlerts";

const classes = {
    icon: `${PREFIX}-icon`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "30px 30px 0px",
    },
    "& .MuiDialog-paper": {
        borderRadius: "25px",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.icon}`]: {
        marginRight: 10,
        height: 30,
    },
}));

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MAX_COUNT = 3;

const ChatTransaction = ({
    room,
    setRoom,
    open,
    setOpen,
    setChat,
    chat,
    type,
    setIsLoading,
    users,
    franchiseName,
    transInfo,
    setTransInfo,
    accountInfo
}) => {

    const todos = useSelector((state) => state.value);

    const listRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const inputRef = useRef(null);

    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const onClose = async () => {
        setOpen(false);
        setSelectedFiles([]);
        setFileLimit(false);
        if (room) {
            setIsLoading(true);
            disconnectSocket(room, todos.userInfo, 'transaction');
            // Se elimina el chat, si no escribe nada
            if (chat.length === 0) {
                await chatService.deleteChat({ transaction_id: room }).catch((err) => {
                    console.log(err);
                });
            }
            setRoom(null);
            setChat([]);
            if (inputRef.current) {
                inputRef.current.value = "";
            }
            setIsLoading(false);
            if (transInfo) {
                setTransInfo({})
            }
        }
    };

    const handleFileChange = (e) => {
        const chosenFiles = e._reactName === "onDrop" ? Array.prototype.slice.call(e.dataTransfer.files) : Array.prototype.slice.call(e.target.files);

        const uploaded = [...selectedFiles];
        let limitExceeded = false;

        chosenFiles.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push({ filename: file.name, size: file.size, buffer: file });
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    limitExceeded = true;
                }
            }
        });
        if (!limitExceeded) setSelectedFiles(uploaded);
    };

    const deleteFiles = (index) => {
        const uploaded = [...selectedFiles];

        uploaded.splice(index, 1);

        if (uploaded.length < MAX_COUNT) {
            setFileLimit(false);
        }

        setSelectedFiles(uploaded);
    };

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView();
    }, [chat]);

    useEffect(() => { }, [users]);

    const [t] = useTranslation("global");

    const downloadFile = async (originalname, file_name) => {
        let params = {
            uploaded_file_s3: file_name,
        };
        setIsLoading(true);

        await casesService
            .getChatFileS3(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", originalname);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const getIcon = (filename) =>
        filename.substring(filename.lastIndexOf(".") + 1) === "pdf"
            ? PDFIcon
            : filename.substring(filename.lastIndexOf(".") + 1) === "xlsx" ||
                filename.substring(filename.lastIndexOf(".") + 1) === "csv" ||
                filename.substring(filename.lastIndexOf(".") + 1) === "xls"
                ? ExcelIcon
                : filename.substring(filename.lastIndexOf(".") + 1) === "txt"
                    ? TxtIcon
                    : ImageIcon;

    const onKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            if (selectedFiles.length > 0) {
                sMessageF(event)
            } else {
                sMessage(event)
            }
        }
    };

    const handleKeyUp = (event) => {
        if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault();
            if (!inputRef.current.value.endsWith("\n")) {
                const updatedMessage = `${inputRef.current.value} \n `;
                inputRef.current.value = updatedMessage;
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const sMessageF = async (event) => {
        event.preventDefault()
        const value = inputRef.current.value;

        const data = new FormData();

        if (selectedFiles.length > 0) {
            for (let x = 0; x < selectedFiles.length; x++) {
                data.append("files", selectedFiles[x].buffer);
            }
        }

        data.append("message", value);
        data.append("room", room);
        data.append("user", todos.userInfo._id);
        data.append("type", type);
        data.append("franchise_name", franchiseName);
        data.append("lang", t("language.locale"));

        if (selectedFiles.length > 0) {
            await conversionService
                .uploadFilesChat(data, {})
                .then((messages) => {
                    emitMessage(room, messages);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setFileLimit(false);

        inputRef.current.value = ""
        setSelectedFiles([])

    }

    const sMessage = async (event) => {
        event.preventDefault()
        const value = inputRef.current.value;
        sendMessage(
            event,
            room,
            value,
            todos.userInfo,
            inputRef,
            type,
            franchiseName
        )
    }

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            maxWidth="md"
            PaperProps={{
                width: "100%",
                height: "80vh",
            }}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#4A22D4" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ bgColor: "#4A22D4" }}>
                    <Stack direction="column">
                        <Typography fontSize={18} fontWeight={600} color="#FFF">
                            {accountInfo.account_name}
                        </Typography>
                        <Typography fontSize={13} color="#FFF" sx={{ mt: 0.3 }}>
                            {users.length === 0
                                ? ""
                                : users.length === 1
                                    ? users[0].name + " " + t("inbox.connectedChat")
                                    : users.length === 2
                                        ? users.map((item) => item.name).join(t("inbox.and")) + " " + t("inbox.connectedChat2")
                                        : users
                                            .map((item) => item.name)
                                            .slice(0, users.length - 2)
                                            .join(", ") +
                                        ", " +
                                        users
                                            .map((item) => item.name)
                                            .slice(users.length - 2, users.length)
                                            .join(t("inbox.and")) +
                                        " " +
                                        t("inbox.connectedChat2")}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                // position: "absolute",
                                // right: 16,
                                // top: 10,
                                color: "#fff",
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ padding: "0px" }}>
                {room && (
                    <form
                        onSubmit={
                            selectedFiles.length > 0
                                ? (event) =>
                                    sMessageF(event)
                                : (event) =>
                                    sMessage(event)
                        }
                    >
                        <List
                            sx={{
                                height: "40vh",
                                overflowY: "auto",
                                //border: "1px solid rgba(0, 0, 0, 0.12)",
                                bgcolor: "#FFF",
                            }}
                            ref={listRef}
                        >
                            {chat.map((m, index) => (
                                <>
                                    <ListItem
                                        key={"ListItem" + index}
                                        sx={{
                                            justifyContent: m.user && todos.userInfo._id === m.user._id ? "end" : "start",
                                        }}
                                    >
                                        {Object.keys(m).user}
                                        <Box
                                            sx={{
                                                bgcolor:
                                                    m.user && todos.userInfo._id === m.user._id
                                                        ? "#efefef" // Usuario actual, gris
                                                        : m.converter_bot === true && (m.message.includes("La conversión no ha sido completada con éxito") || m.message.includes("The conversion has not been completed successfully") || m.message.includes("ha regresado el caso a desarrollo") || m.message.includes("has returned the case to development")) ? "#FDE8F1" //Convertidor de pruebas, mensaje de error, rojo
                                                            : m.converter_bot === true && (m.message.includes("La conversión se ha completado de manera exitosa") || m.message.includes("The conversion has been completed successfully") || m.message.includes("confirmó que la conversión es exitosa") || m.message.includes("confirmó que la conversión es exitosa") || m.message.includes("confirmed that the conversion is successful")) ? "#E6F3E9" //Convertidor de pruebas, mensaje de exito, azul
                                                                : m.converter_bot === true ? "#FFF2DA" //Convertidor de pruebas, naranja
                                                                    : (!ITTeam.includes(todos.userInfo.email) &&
                                                                        m.user &&
                                                                        !ITTeam.includes(m.user.email)) ||
                                                                        (ITTeam.includes(todos.userInfo.email) &&
                                                                            (!("user" in m) || (m.user && ITTeam.includes(m.user.email))))
                                                                        ? "#E5D1FF" // Que el usuario actual no sea de ITTeam. Otro user diferente de ITTeam el color es lila O que el usuario actual sea de ITTeam. Bot o user de ITTeam el color es lila
                                                                        : "#00FFFF40",
                                                py: 1,
                                                px: 2,
                                                borderRadius: 3,
                                                ml: m.user && todos.userInfo._id === m.user._id ? 20 : 0,
                                                mr: m.user && todos.userInfo._id === m.user._id ? 0 : 20,
                                                minWidth: "20%",
                                            }}
                                        >
                                            <Stack direction="column">
                                                <ListItemText
                                                    primary={
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography fontWeight={600}>
                                                                {m.user ? m.user.user_name : (m.converter_bot ? "Convertidor de pruebas" : "KiiperBot")}{" "}
                                                                {t("inbox.commented")}:
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                ></ListItemText>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            {m.message.includes("\n") ? (
                                                                m.message.split("\n").map((line, index) => (
                                                                    <>
                                                                        {line}
                                                                        <br />
                                                                    </>
                                                                ))
                                                            ) : m.message.includes("<b>") ? (
                                                                <Typography className={classes.expiredText}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: m.message,
                                                                    }}>
                                                                </Typography>
                                                            ) : (
                                                                m.message
                                                            )}
                                                            {m.attachments &&
                                                                m.attachments.map((item, index) => (
                                                                    <Stack
                                                                        key={"stack_" + index}
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        justifyContent="end"
                                                                        width="fit-content"
                                                                        sx={{
                                                                            backgroundColor: "#FFF",
                                                                            borderRadius: 2,
                                                                            py: 2,
                                                                            pl: 3,
                                                                            pr: 4,
                                                                            my: 1,
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            downloadFile(item.originalname, item.filenames3)
                                                                        }
                                                                    >
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={2}
                                                                            alignItems="center"
                                                                            justifyContent="start"
                                                                        >
                                                                            <Box
                                                                                component="img"
                                                                                sx={{
                                                                                    height: 40,
                                                                                    width: 40,
                                                                                    maxHeight: { xs: 40, md: 40 },
                                                                                    maxWidth: { xs: 40, md: 40 },
                                                                                }}
                                                                                src={getIcon(item.originalname)}
                                                                            />
                                                                            <Box sx={{ textAlign: "start" }}>
                                                                                <Typography variant="h3">
                                                                                    {item.originalname}
                                                                                </Typography>
                                                                                <Typography fontSize={12} fontWeight={600}>
                                                                                    {formatBytes(item.size)}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>
                                                                    </Stack>
                                                                ))}
                                                        </>
                                                    }
                                                ></ListItemText >
                                                <ListItemText
                                                    secondary={
                                                        <Typography fontSize={12} color="gray">
                                                            {moment(convertTZ(m.date, "America/New_York")).format(
                                                                `${todos.dateFormat} hh:mm A`
                                                            )}
                                                        </Typography>
                                                    }
                                                ></ListItemText>
                                            </Stack>
                                        </Box>
                                    </ListItem>
                                </>
                            ))}
                        </List>
                        <Divider />
                        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
                            <Grid item xs={10} sx={{ px: 3, py: 2 }}>
                                <TextField
                                    inputRef={inputRef}
                                    variant="standard"
                                    placeholder={t("inbox.queryHere")}
                                    fullWidth
                                    name="name"
                                    disabled={!room}
                                    InputProps={{ disableUnderline: true }}
                                    onKeyPress={onKeyPress}
                                    onKeyUp={handleKeyUp}
                                    multiline
                                    sx={{
                                        input: {
                                            // "&::placeholder": {
                                            //     opacity: 1,
                                            // },
                                            "&.Mui-disabled": {
                                                "&::placeholder": {
                                                    opacity: 1,
                                                },
                                            },
                                        },
                                    }}
                                    onDragOver={handleDragOver}
                                    onDrop={handleFileChange}
                                />
                            </Grid>
                            <Grid item sx={{ pr: 3 }}>
                                <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={1}>
                                    <IconButton component="label" disabled={!room || fileLimit}>
                                        <AttachFileIcon fontSize="small" sx={{ color: "#131F3EB2" }} />
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={handleFileChange}
                                            multiple
                                            accept="application/pdf, image/*, .csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        />
                                    </IconButton>
                                    <IconButton type="submit" disabled={!room}>
                                        <SendIcon sx={{ color: "#131F3EB2" }} fontSize="small" />
                                    </IconButton>
                                </Stack>
                            </Grid>
                            {selectedFiles.map((item, index) => (
                                <Grid
                                    item
                                    key={item.name}
                                    xs={12}
                                    sx={{
                                        padding:
                                            selectedFiles.length - 1 === index
                                                ? "5px 25px 15px 25px"
                                                : "0px 25px 5px 25px",
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ backgroundColor: "#D9D9D9", borderRadius: 2, padding: "10px 10px" }}
                                    >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 40,
                                                    width: 40,
                                                    maxHeight: { xs: 40, md: 40 },
                                                    maxWidth: { xs: 40, md: 40 },
                                                }}
                                                src={getIcon(item.filename)}
                                            />
                                            <Box>
                                                <Typography variant="h3">{item.filename}</Typography>
                                                <Typography fontSize={12} fontWeight={600}>
                                                    {formatBytes(item.size)}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <IconButton onClick={() => deleteFiles(index)}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </CustomDialog>
    );
};

export default ChatTransaction;
