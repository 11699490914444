/* Componentes */
import { useState, useEffect } from "react";
import BackGeneral from "../components/layout/BackGeneral";
import computerVision from "../services/computer_vision";
import {
    Container,
    Typography,
    Grid,
    Stack,
    MenuItem,
    FilledInput,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import BaseButton from "../components/ui/BaseButton";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const ComputerVisionDocumentClassification = () => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [azureModels, setAzureModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState("");
    const [finalDocumentType, setFinalDocumentType] = useState("");

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setData([]);
        setFinalDocumentType("");
        setError(false);

        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("model", selectedModel);

        try {
            const response = await computerVision.documentClassification(formData);
            // console.log("response", response);

            setData(response?.data);

            let docDetected = response?.finalDocumentType === 1
                ? "Factura"
                : response?.finalDocumentType === 2
                    ? "Cheque"
                    : response?.finalDocumentType === 3
                        ? "Estado de cuenta"
                        : response?.finalDocumentType === 4
                            ? "Talonarios de cheques"
                            : "Sin detección";

            setFinalDocumentType(docDetected);
        } catch (error) {
            setError(true);
            console.error("Error:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await computerVision
                .getCustomModelsDocumentClassification()
                .then((data) => {
                    setAzureModels(data);
                    setSelectedModel(data[0]);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, []);

    const handleChangeModel = (event) => {
        setSelectedModel(event.target.value);
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 45 * 4.5 + 8,
                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
            },
            sx: {
                borderRadius: 2,
                mt: 0.7,
            },
        },
        variant: "menu",
        getcontentanchorel: null,
    };

    return (
        <>
            <BackGeneral colorBg="#fff">
                <Container maxWidth="lg">
                    <Grid container spacing={6}>
                        <Grid item xs={12} sx={{ marginTop: 4, textAlign: "center" }}>
                            <Typography variant="h5" gutterBottom>
                                Modelo IA - Document Classification
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Stack direction="column" justifyContent="center" spacing={2} mb={2}>
                                    <div>
                                        <BaseButton
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Cargar documento
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={handleChangeFile}
                                                accept="application/pdf, image/*"
                                                required
                                            />
                                        </BaseButton>
                                    </div>
                                    {file && (
                                        <div>
                                            <Typography variant="h2">{file.name}</Typography>
                                        </div>
                                    )}
                                    <div>
                                        <FormControl
                                            variant="filled"
                                            sx={{ width: { xs: "100%", md: "30%" } }}
                                        >
                                            <InputLabel
                                                id="demo-multiple-checkbox-label"
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            >
                                                Modelo custom
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                value={selectedModel}
                                                displayEmpty
                                                onChange={handleChangeModel}
                                                input={
                                                    <FilledInput
                                                        label={"Modelo custom"}
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    />
                                                }
                                                MenuProps={MenuProps}
                                                defaultValue=""
                                                name="model"
                                            >
                                                {azureModels.map((item) => (
                                                    <MenuItem value={item} key={item}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Stack>
                                <BaseButton sizebutton="m" type="submit">
                                    Enviar
                                </BaseButton>
                            </form>
                            <Grid container spacing={2} sx={{ textAlign: "left" }}>
                                {finalDocumentType && (<Grid item xs={6} md={6}>
                                    <Typography key={finalDocumentType} sx={{ mt: 1 }} fontWeight={600}>
                                        Tipo de documento detectado: {finalDocumentType}
                                    </Typography>
                                </Grid>)}
                                <Grid item xs={6} md={6}>
                                    {data.map((item, index) => (
                                        <>
                                            <Typography key={"title" + index} sx={{ mt: 1 }} fontWeight={600}>
                                                Información obtenida del archivo {file?.name}:
                                            </Typography>
                                            <Typography key={"body" + index} sx={{ ml: 1 }}>
                                                <pre>{JSON.stringify(item, null, 2)}</pre>
                                            </Typography>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {error && (
                        <>
                            <Typography fontWeight={600} sx={{ mt: 4 }} color="red" textAlign="center">
                                Ha ocurrido un error al extraer el tipo de documento
                            </Typography>
                        </>
                    )}
                </Container>
            </BackGeneral>
            <SimpleBackdrop open={isLoading} />
        </>
    );
};

export default ComputerVisionDocumentClassification;
