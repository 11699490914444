import { Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import payService from "../../services/payment_methods";
import { useTranslation } from "react-i18next";
import EditPayForm from "./EditPayForm";
import LightTooltip from "../ui/LightTooltip";
import DeleteIcon from '@mui/icons-material/Delete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
        padding: "20px 30px",
    },
    "& .MuiDialogTitle-root": {
        fontSize: 20,
        fontWeight: 600,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
        justifyContent: "space-between",
    },
}));

export default function EditPaymentDialog(props) {
    const { open, handleClose, maxWidth, setMessage, setShow, setAlertType, addressData, reloadData, setIsLoading, setShowAlert, setShowEditPay } = props;
    const [stripePromise, setStripePromise] = useState(null);

    const [t] = useTranslation("global");

    useEffect(() => {
        (async () => {
            await payService.getConfigStripe().then((response) => {
                setStripePromise(loadStripe(response.publishableKey));
            });
        })();
    }, []);

    const options = {
        appearance: {
            theme: "flat",
            variables: { colorPrimary: "#4a22d4", colorText: "#131f3e" },
        },
        locale: t("language.locale")
    };

    const deleteCard = async () => {
        setShowEditPay(false);
        setShowAlert(true)
    };

    return (
        <BootstrapDialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {`${t("team.edit")} ${addressData.brand.charAt(0).toUpperCase() + addressData.brand.slice(1)} *${addressData.card_number.split("-").pop()}`}
                    <LightTooltip title={t("miscellaneous.deleteCard")}>
                        <IconButton
                            edge="end"
                            aria-label="archive"
                            onClick={deleteCard}
                        >
                            <DeleteIcon sx={{ color: "#361A52" }} />
                        </IconButton>
                    </LightTooltip>
                </Stack>
            </DialogTitle>
            {stripePromise && (
                <Elements stripe={stripePromise} options={options}>
                    <EditPayForm
                        handleClose={handleClose}
                        setMessage={setMessage}
                        setShow={setShow}
                        setAlertType={setAlertType}
                        cardData={addressData}
                        reloadData={reloadData}
                        setIsLoading={setIsLoading}
                    />
                </Elements>
            )}
        </BootstrapDialog>
    );
}
