import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Box, Container, Grid, Typography } from "@mui/material";
import CheckoutForm from "./CheckoutForm";

import payService from "../../services/payment_methods";
import { useLocation, useNavigate } from "react-router-dom";
import FormDialog from "../ui/FormDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SimpleBackdrop from "../ui/SimpleBackdrop";

import subscriptionService from "../../services/subscription";
import companyService from "../../services/company";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addCredits, addSubscription } from "../../redux/userSlice";
import BaseButton from "../ui/BaseButton";

function AutoPay() {
    const [stripePromise, setStripePromise] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [t] = useTranslation("global");
    const location = useLocation();
    const navigate = useNavigate();

    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const totalAmount = parseInt(location.state?.price.toFixed(2).toString().replace(".", "") ?? 0);

    useEffect(() => {
        (async () => {
            await payService.getConfigStripe().then((response) => {
                setStripePromise(loadStripe(response.publishableKey));
            });
        })();
    }, []);

    const handleCloseModalPay = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpenModal(false);
        }
    };

    const resetCart = async () => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        await subscriptionService
            .getFranchiseCredits({ company_id: todos.companyID })
            .then((response) => dispatch(addCredits(response)))
            .catch((err) => console.log(err));

        await companyService
            .getFranchiseDetails(todos.franchiseID)
            .then((data) => {
                dispatch(
                    addSubscription({
                        value: data.id_status,
                        last_value: data.id_last_status,
                        plan: data.subscription_plan,
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        navigate("/subscription");
    };

    const options = {
        mode: "payment",
        amount: totalAmount,
        currency: "usd",
        setup_future_usage: "off_session",
        // Fully customizable with appearance API.
        appearance: {
            theme: "flat",
            variables: { colorPrimary: "#4a22d4", colorText: "#131f3e" },
        },
        locale: t("language.locale"),
    };

    const creditsButtons = (
        <Grid container justifyContent="center">
            <Grid item>
                <BaseButton sizebutton="m" onClick={resetCart}>
                    {t("dialog.accept")}
                </BaseButton>
            </Grid>
        </Grid>
    );

    const creditsContent = (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <CheckCircleIcon fontSize="large" sx={{ color: "#00B147" }} />
            </Grid>
            <Grid item>
                <Typography variant="h4">{t("dialog.successPay")}</Typography>
                <Typography variant="h4">
                    {`${location.state?.credits === 1 ? t("dialog.creditsNew1") : t("dialog.creditsNew")} `}
                    <span style={{ color: "#4a22d4" }}>{`${location.state?.credits} ${
                        location.state?.credits === 1 ? t("dialog.creditsText1") : t("dialog.creditsText")
                    }`}</span>
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <Container maxWidth="sm">
            <Box sx={{ p: 4 }}>
                {stripePromise && (
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm
                            setOpenModal={setOpenModal}
                            totalAmount={totalAmount}
                            setIsLoading={setIsLoading}
                        />
                    </Elements>
                )}
            </Box>
            <FormDialog
                open={openModal}
                handleClose={handleCloseModalPay}
                maxWidth="xs"
                content={creditsContent}
                actions={creditsButtons}
            />
            <SimpleBackdrop open={isLoading} />
        </Container>
    );
}

export default AutoPay;
