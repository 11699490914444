/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Icons/images */

/** MUI imports */
import { Box, Grid, Typography } from "@mui/material";
import BaseButton from "../components/ui/BaseButton";

const Error404 = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    return (
        <Box component="main" sx={{ backgroundColor: "rgba(3, 24, 81, 0.03)", height: "100%" }}>
            <Grid container justifyContent="center" spacing={2} sx={{ pt: 15 }}>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        Oops
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        {t("error.notFound")}
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <BaseButton component={Link} to="/">
                        {t("miscellaneous.goBack")}
                    </BaseButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Error404;
