import { TableCell, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const NoddokTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      color: theme.palette.primary,
      fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  },
}));

export default NoddokTableCell;