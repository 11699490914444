import {
    Box,
    Chip,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

/* Estilos */
import classes from "./ShoppingItems.module.css";

/* Iconos */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from "react-redux";
import { getPlanInfo, getPlanLevelInfo } from "../../../utils/utils";

const ShoppingItemsReg = (props) => {

    const { deleteItem, currentPlan, currentLevel, origin, activePlan, smbInfo } = props;

    const [t] = useTranslation("global");

    const isCancelled = "subscription" in smbInfo && smbInfo.subscription !== null && "last_cancel" in smbInfo.subscription && smbInfo.subscription.last_cancel !== null;

    const noPlan = (!("subscription" in smbInfo) || smbInfo.subscription === null);

    const todos = useSelector((state) => state.value);

    const getCurrentPlan = getPlanInfo(currentLevel);

    const getCurrentLevel = getPlanLevelInfo(currentLevel);

    const getActivePlan = getPlanInfo(activePlan) ?? null;

    const getActiveLevel = getPlanLevelInfo(activePlan) ?? null;

    const isActivation = (origin === "addCompany" || origin === "register" || isCancelled || noPlan)

    const isChangePlan = (origin === "editCompany" || origin === "updateSusbcription") && !isCancelled

    const isDowngrade = isChangePlan && activePlan > currentLevel && !isCancelled

    const isUpgrade = isChangePlan && activePlan < currentLevel && !isCancelled

    const isCancel = currentPlan.type === "cancel";

    const isCustomActivation = currentPlan?.custom === true;

    const verifyDiff = (key) => {
        return isChangePlan && getActivePlan !== null && getCurrentLevel[key] !== getActiveLevel[key]
    }
    const theme = useTheme();

    const planFeatures = [
        {
            icon: isUpgrade ? (verifyDiff('pages') ? <ArrowUpwardIcon sx={{ color: theme.palette.E100 }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />) : (verifyDiff('pages') ? <ArrowDownwardIcon sx={{ color: "#ff5e77" }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />),
            text: <>
                {verifyDiff('pages') && !isCancel ?
                    <>
                        <b style={{ color: theme.palette.secondary.main }}>{parseFloat(getActiveLevel.pages).toLocaleString(todos.amountFormat)}</b> {t("plans.pageMonth")} <ArrowForwardIcon color="primary" fontSize="small" sx={{ mb: "-6px", mx: 0.5 }} /> <b style={{ color: theme.palette.secondary.main }}>{parseFloat(isCustomActivation? smbInfo.request.final_pages : getCurrentLevel.pages).toLocaleString(todos.amountFormat)}</b>{" "}{t("plans.pageMonth")}
                    </>
                    : <>
                        <b style={{ color: theme.palette.secondary.main }}>{parseFloat(isCustomActivation? smbInfo.request.final_pages : getCurrentLevel.pages).toLocaleString(todos.amountFormat)}</b>{" "}{t("plans.pageMonth")}
                    </>}
            </>
        },
        {
            icon: isUpgrade ? (verifyDiff('users') ? <ArrowUpwardIcon sx={{ color: theme.palette.E100 }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />) : (verifyDiff('users') ? <ArrowDownwardIcon sx={{ color: "#ff5e77" }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />),
            text: <>
                {t("plans.users1")} <b style={{ color: theme.palette.secondary.main }}>{getCurrentPlan.users === Infinity ? t("plans.unlimited1") : "2 " + t("plans.users")}</b>
            </>
        },
        {
            icon: isUpgrade ? (verifyDiff('item1') ? <ArrowUpwardIcon sx={{ color: theme.palette.E100 }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />) : (verifyDiff('item1') ? <ArrowDownwardIcon sx={{ color: "#ff5e77" }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />),
            text:
                <>
                    {verifyDiff('item1') ?
                        <>
                            {t("plans.training")}{" "} <b style={{ color: theme.palette.secondary.main }}>{t(getActivePlan.item1).toLowerCase()}</b> <ArrowForwardIcon color="primary" fontSize="small" sx={{ mb: "-6px", mx: 0.5 }} />
                            <b style={{ color: theme.palette.secondary.main }}>{t(getCurrentPlan.item1).toLowerCase()}</b>
                        </>
                        : <>
                            {t("plans.training")}{" "}
                            <b style={{ color: theme.palette.secondary.main }}>{t(getCurrentPlan.item1).toLowerCase()}</b>
                        </>}

                </>
        },
        {
            icon: <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />,
            text: <>{t("plans.integSys")}</>
        },
        {
            icon: isUpgrade ? (verifyDiff('item4') ? <ArrowUpwardIcon sx={{ color: theme.palette.E100 }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />) : (verifyDiff('item4') ? <ArrowDownwardIcon sx={{ color: "#ff5e77" }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />),
            text:
                <>
                    {verifyDiff('item4') ?
                        <>
                            {t("plans.productUpda")}{" "} <b style={{ color: theme.palette.secondary.main }}>{t(getActivePlan.item4).toLowerCase()}</b> <ArrowForwardIcon color="primary" fontSize="small" sx={{ mb: "-6px", mx: 0.5 }} />
                            <b style={{ color: theme.palette.secondary.main }}>{t(getCurrentPlan.item4).toLowerCase()}</b>
                        </>
                        : <>
                            {t("plans.productUpda")}{" "}
                            <b style={{ color: theme.palette.secondary.main }}>{t(getCurrentPlan.item4).toLowerCase()}</b>
                        </>}

                </>
        },
        {
            icon: isUpgrade ? (verifyDiff('extension') ? <ArrowUpwardIcon sx={{ color: theme.palette.E100 }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />) : (verifyDiff('extension') ? <ArrowDownwardIcon sx={{ color: "#ff5e77" }} fontSize="small" /> : <CheckIcon sx={{ color: theme.palette.E100 }} fontSize="small" />),
            text:
                <>
                    {verifyDiff('extension') ?
                        <>
                            {t("plans.extend")}{" "} <b style={{ color: theme.palette.secondary.main }}>{t(getActivePlan.extension)} {t("miscellaneous.days")}</b> <ArrowForwardIcon color="primary" fontSize="small" sx={{ mb: "-6px", mx: 0.5 }} />
                            <b style={{ color: theme.palette.secondary.main }}>{t(getCurrentPlan.extension)} {t("miscellaneous.days")}</b>
                        </>
                        : <>
                            {t("plans.extend")}{" "}
                            <b style={{ color: theme.palette.secondary.main }}>{t(getCurrentPlan.extension)} {t("miscellaneous.days")}</b>
                        </>}

                </>
        },
    ];

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper variant="outlined" className={classes.cardItemsPlan}>
                    <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box className={classes.description}>
                                <Typography color="primary" variant="h1" fontSize={28}>
                                    Plan
                                </Typography>
                                <Typography color="primary" variant="h1" fontSize={28}>
                                    {getCurrentLevel?.custom === true ? t("plans.custom"): getCurrentPlan.planTitle}
                                </Typography>
                                {(isActivation || getActivePlan == null) && (
                                    <Chip
                                        label={t("subscription.activation")}
                                        size="small"
                                        className={classes.chipCust}
                                        variant="filled"
                                    />
                                )}
                                {isDowngrade && !isCancel && getActivePlan !== null && (
                                    <Chip
                                        label={t("subscription.downgrade")}
                                        size="small"
                                        className={classes.chipCustCancel}
                                        variant="filled"
                                    />
                                )}
                                {isUpgrade && !isCancel && getActivePlan !== null && (
                                    <Chip
                                        label={t("subscription.upgrade")}
                                        size="small"
                                        className={classes.chipCust}
                                        variant="filled"
                                    />
                                )}
                                {isCancel && getActivePlan !== null && (
                                    <Chip
                                        label={t("subscription.cancel")}
                                        size="small"
                                        className={classes.chipCustCancel}
                                        variant="filled"
                                    />
                                )}
                            </Box>
                            <Box className={classes.description2}>
                                <List>
                                    {planFeatures.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                {currentPlan.type === "cancel" ? (
                                                    <CloseIcon sx={{ color: "#FF5E77" }} fontSize="small" />
                                                ) : (
                                                    item.icon
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Stack>
                        <IconButton aria-label="delete" onClick={deleteItem} sx={{ p: 0 }}>
                            <CancelIcon sx={{ color: "#d0d2d8" }} fontSize="large" />
                        </IconButton>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ShoppingItemsReg;
