import {
    Autocomplete,
    Box,
    ButtonGroup,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Popper,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useSelector } from "react-redux";
import conversionService from "../../../services/conversion";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import invoicesService from "../../../services/invoices";

import SearchIcon from "@mui/icons-material/Search";

import UploadFilePopover from "./UploadFilePopover";
import AddIcon from "@mui/icons-material/Add";
import NewInvoiceTableItems from "./NewInvoiceTableItems";
import NewInvoiceTableSummary from "./NewInvoiceTableSummary";
import { useEffect, useRef, useState } from "react";
import BaseButton from "../../ui/BaseButton";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { getContactIcon, getContactIcon2, similarity } from "../../../utils/utils";
import BaseSelect2 from "../../ui/BaseSelect2";
import CustTextField from "../../ui/CustTextField";
import CustomDatePicker from "../../ui/CustomDatePicker";
import countriesProject from "../../Js/countries";

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "15px",
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const NewInvoiceTab1 = (props) => {
    const {
        setShowCompanyDet,
        invInfo,
        setInvInfo,
        setCurrency,
        systemCurrency,
        setRate,
        setRows,
        setTexto,
        setAlertType,
        setShow,
        rows,
        xeroTaxRates,
        setIsLoading,
        setPreviewFile,
        setOpenPreview,
        contactsXeroOriginal,
        currency,
        rate,
        allCurrencies,
        selected,
        setSelected,
        accountsXero,
        replaceInvBill,
        setRowsModal,
        rowsModal,
        conversion_type,
        setCurrentInv,
        currentInv,
        handleAlertModalSendInv,
        goBack,
        setOpenAlertSendInv,
        invChanges,
        previousInvInfoRef,
        previousRowsRef,
        handleModalupdateInvoice,
        updateInvoice,
        handleEditRow,
        conversionData,
        xeroItems,
        integration,
        setSelectedContact,
        selectedContact,
        trackingCategories,
        setCompanyInfo,
        totalsInfo,
        setAccountPrev,
        companyInfo,
        previewFile,
        trackingConfig,
        canApprove
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const anchorRefBtnGroup = useRef(null);
    const [openBtnGroupSend, setOpenBtnGroupSend] = useState(false);
    const [selectedIndexBtnGroup, setSelectedIndexBtnGroup] = useState(null);
    const [contactsXero, setContactsXero] = useState([]);
    const [selectedAllAcc, setSelectedAllAcc] = useState("");
    const [selectedAllTaxes, setSelectedAllTaxes] = useState("");
    const [allTrackingCategories, setAllTrackingCategories] = useState({});

    const optionsGroupSend = [{ data: t("invoicing.saveAndApprove"), id: 0 }];

    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 9);

    const insertLine = {
        new: true,
        _id: generateUniqueId(),
        item_code: "",
        description: "",
        amount: 0,
        unit_price: 0,
        original_unit_price: 0,
        quantity: 0,
        account_code: "",
        tax_type: "",
        tax_amount: 0,
        discount: 0,
    };

    const showModalComp = () => {
        setShowCompanyDet(true);
    };

    const handleChangInvData = (event) => {
        event.preventDefault();

        setInvInfo({
            ...invInfo,
            [event.target.name]: event.target.value,
            // currency: contactsXeroOriginal.find((item) => item.value === event.target.value).currency,
        });
    };

    /** Countries */
    const allCountries = [...countriesProject];

    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    const handleChangeSelectContact = async (newValue) => {
        setIsLoading(true);
        setSelectedContact(newValue);

        const fContact = contactsXeroOriginal.find((item) => item._id === newValue._id);

        if (fContact) {
            let codeFilter1 = allCountries.find((country) => country.code === fContact?.contact_country);
            let codeFilter2 = allCountries.find((country) => country.code === fContact?.contact_country_2);

            setCompanyInfo({
                ...companyInfo,
                _id: fContact._id,
                company_name: fContact.contact_name,
                company_tax_number: fContact?.contact_tax_number ?? conversionData?.formatted_vendor_tax_id ?? conversionData?.vendor_tax_id ?? "",
                company_email: fContact?.contact_email ?? conversionData?.formatted_vendor_email ?? conversionData?.vendor_email ?? "", 
                company_address: fContact?.contact_address ?? conversionData?.formatted_vendor_address ?? conversionData?.vendor_address ?? "", 
                company_country: codeFilter1?.country_code ?? "",
                company_phone: fContact?.contact_phone ?? conversionData?.formatted_vendor_phone ?? conversionData?.vendor_phone ?? "",
                company_country_code: codeFilter1?.phone_code ?? "",

                // Información del contacto
                contact_name_2: fContact?.contact_name_2 ?? "",
                contact_email_2: fContact?.contact_email_2 ?? "",
                contact_country_2: codeFilter2?.country_code ?? "",
                contact_phone_2: fContact?.contact_phone_2 ?? "",
                contact_role_2: fContact?.contact_role_2 ?? "",
                contact_country_code: codeFilter2?.phone_code ?? "",
            });
        } else {
            setCompanyInfo({
                ...companyInfo,
                _id: "",
                company_name: newValue.name,
                company_tax_number: conversionData?.formatted_vendor_tax_id ?? conversionData?.vendor_tax_id ?? "",
                company_email: conversionData?.formatted_vendor_email ?? conversionData?.vendor_email ?? "", 
                company_address: conversionData?.formatted_vendor_address ?? conversionData?.vendor_address ?? "", 
                company_phone: conversionData?.formatted_vendor_phone ?? conversionData?.vendor_phone ?? "",
            });
        }

        const copyInv = {...invInfo};
        
        let fData = {
            ...copyInv,
            contactXeroID: newValue?.value ?? "",
            contactXeroName: newValue?.name ?? "",
        };

        let updatedRows = rows;

        if (newValue.value !== "first" && newValue.value !== undefined) {
            const newContactCurr = contactsXeroOriginal.find((item) => item._id === newValue._id)?.currency;

            if (newContactCurr) {
                setCurrency(newContactCurr);

                if (currency !== newContactCurr && integration !== 3) {
                    try {
                        const data = await conversionService.getXECurrencyRate({
                            from: systemCurrency,
                            to: newContactCurr,
                            date: moment.utc(copyInv?.invoiceDate).format("YYYY-MM-DD"),
                        });

                        setRate(data.amount.toString());
                    } catch (err) {
                        console.log(err);
                    }
                }
            }

            const newContactID = contactsXeroOriginal.find((item) => item._id === newValue._id)?.idSys;

            if (newContactID && integration !== 3) {
                let newSuggestKiiper = {};
                const params = {
                    company_id: todos.companyID,
                    contact_id_system: newContactID,
                    invoiceType: conversionData.conversion_type,
                };

                try {
                    const response = await conversionService.getLastInvoicesFromContactArrays(params);
                    if (response) {
                        newSuggestKiiper = response;
                    }
                } catch (err) {
                    console.log(err);
                }

                let defaultTax = xeroTaxRates?.find((item) => item.isZeroTax)?.value ?? "";

                updatedRows = updatedRows.map((line) => {
                    if (!line.account_code && newSuggestKiiper.contact_account_code?.length === 1) {
                        line.account_code = newSuggestKiiper.contact_account_code[0].AccountCode;
                    }

                    if (!line.tax_type) {
                        if (newSuggestKiiper.contact_tax_type?.length === 1) {
                            line.tax_type = newSuggestKiiper.contact_tax_type[0].TaxType;
                        } else {
                            line.tax_type = defaultTax;
                        }

                        if (xeroTaxRates.length > 0) {
                            const taxRate = xeroTaxRates.find((item) => item.value === line.tax_type)?.taxRate || 0;
                            line.tax_amount = (taxRate * calcSubtotal(line)) / 100;
                        }
                    }

                    return line;
                });

                fData = {
                    ...fData,
                    contact_account_code: newSuggestKiiper.contact_account_code,
                    contact_tax_type: newSuggestKiiper.contact_tax_type,
                };
            }
        }

        setRows(updatedRows);
        setInvInfo(fData);
        setIsLoading(false);
    };

    const handleChangeDate = async (event, key, updateCurrencyRate) => {
        setInvInfo({
            ...invInfo,
            [key]: event,
        });

        if (updateCurrencyRate) {
            await conversionService
                .getXECurrencyRate({
                    from: systemCurrency,
                    to: currency,
                    date: moment.utc(event).format("YYYY-MM-DD"),
                })
                .then((data) => {
                    setRate(data.amount.toString());
                    setRows((prevRows) =>
                        prevRows.map((row) => ({
                            ...row,
                            unit_price: parseFloat(
                                data.amount ? row.original_unit_price * data.amount : row.original_unit_price
                            ).toFixed(2),
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeCurrency = async (event) => {
        setCurrency(event.target.value);
        if (integration === 3) {
            setRate("");
        } else {
            if (event.target.value === systemCurrency) {
                setRate("");
    
                setRows((prevRows) =>
                    prevRows.map((row) => {
                        const newPrice = row.new ? row.unit_price : row.original_unit_price;
    
                        return {
                            ...row,
                            unit_price: newPrice,
                        };
                    })
                );
            } else {
                await conversionService
                    .getXECurrencyRate({
                        from: systemCurrency,
                        to: event.target.value,
                        date: moment.utc(invInfo.invoiceDate).format("YYYY-MM-DD"),
                    })
                    .then((data) => {
                        setRate(data.amount.toString());
    
                        setRows((prevRows) =>
                            prevRows.map((row) => {
                                const newPrice = row.new ? row.unit_price : row.original_unit_price;
    
                                return {
                                    ...row,
                                    unit_price: parseFloat(data.amount ? newPrice * data.amount : newPrice).toFixed(2),
                                };
                            })
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };

    const handleChangeRate = (event) => {
        setRate(event.target.value);
        setRows((prevRows) =>
            prevRows.map((row) => ({
                ...row,
                unit_price: parseFloat(
                    event.target.value ? row.original_unit_price * event.target.value : row.original_unit_price
                ).toFixed(2),
            }))
        );
    };

    const handleNewRow = () => {
        let newId = generateUniqueId();
        let newLine = {
            ...insertLine,
            _id: newId,
            new: true,
        };

        setRows([...rows, newLine]);
        handleEditRow(newId, false, [...rows, newLine], true);
    };

    const handleCloseBtnGroup = (event) => {
        if (anchorRefBtnGroup.current && anchorRefBtnGroup.current.contains(event.target)) {
            return;
        }

        setOpenBtnGroupSend(false);
    };

    const handleMenuItemClickBtnGroup = async (event, index) => {
        setSelectedIndexBtnGroup(index);
        setOpenBtnGroupSend(false);
        if (index === 0) {
            await saveAndApp();
        }
    };

    const handleToggleBtnGroup = () => {
        setOpenBtnGroupSend((prevOpenBtnGroupSend) => !prevOpenBtnGroupSend);
    };

    const calcSubtotal = (obj) => {
        return parseFloat(obj.unit_price ?? 0) * parseFloat(obj.quantity ?? 1);
    };

    const calcTax = (obj) => {
        if (xeroTaxRates.length > 0 && "tax_type" in obj && obj.tax_type !== "") {
            const taxRate = xeroTaxRates.find((item) => item.value === obj.tax_type).taxRate;
            return (taxRate * calcSubtotal(obj)) / 100;
        }
    };

    const calcTotal = (obj) => {
        return calcTax(obj) + calcSubtotal(obj);
    };

    const saveAndApp = async () => {
        await saveInvoice(true);
        setOpenAlertSendInv(true);
    };

    function validateValue(value) {
        return value !== undefined && value !== null && value !== "";
    }

    const saveInvoice = async (setApprove = false) => {
        if (currentInv?._id) {
            await updateInvoice(setApprove);
        } else {
            const copyInv = JSON.parse(JSON.stringify(invInfo));
            const copyRows = [...rows];

            let codeFilter1 = allCountries.find((country) => country.country_code === companyInfo?.company_country);
            let codeFilter2 = allCountries.find((country) => country.country_code === companyInfo?.contact_country_2);

            const fInvInfo = {

                contact_updated_id: selectedContact ?? "",
                contactXeroID: copyInv.contactXeroID !== "first" ? copyInv.contactXeroID : undefined,
                contactXeroName: copyInv.contactXeroName,
                currency: currency,
                // total: "",
                company_id: todos?.companyID,
                observations: copyInv.details,
                conversion_type: conversion_type,
                invoice_type: copyInv.invoiceType,
                invoice_id: copyInv.invoiceNumber,
                control_number: copyInv.controlNumber,
                invoice_date: copyInv.invoiceDate,
                due_date: copyInv.invoiceDueDate,
                currency_rate: rate, //Tasa de cambio.
                sub_total: totalsInfo.subtotal, //Subtotal.
                invoice_tax: totalsInfo.tax, //Tax.
                invoice_discount: totalsInfo.discount, //Descuento.
                invoice_total: totalsInfo.total, //Total.
                // line_items: //Array de objetos con las siguientes propiedades:
                approve: setApprove,

                company_name: validateValue(companyInfo.company_name) ? companyInfo.company_name : undefined,
                company_tax_number: validateValue(companyInfo.company_tax_number) ? companyInfo.company_tax_number : undefined,
                company_email: validateValue(companyInfo.company_email) ? companyInfo.company_email : undefined,
                company_address: validateValue(companyInfo.company_address) ? companyInfo.company_address : undefined,
                company_country: codeFilter1?.code && codeFilter1.code !== "" ? codeFilter1.code : undefined,
                company_phone: validateValue(companyInfo.company_phone) ? companyInfo.company_phone : undefined,

                contact_name: validateValue(companyInfo.contact_name_2) ? companyInfo.contact_name_2 : undefined,
                contact_email: validateValue(companyInfo.contact_email_2) ? companyInfo.contact_email_2 : undefined,
                contact_country: codeFilter2?.code && codeFilter2.code !== "" ? codeFilter2.code : undefined,
                contact_phone: validateValue(companyInfo.contact_phone_2) ? companyInfo.contact_phone_2 : undefined,
                contact_role: validateValue(companyInfo.contact_role_2) ? companyInfo.contact_role_2 : undefined,
                line_items: copyRows.map((item) => ({
                    code: item.code, //Código.
                    description: item.description, //Descripción.
                    quantity: item.quantity, //Cantidad.
                    unit_price: item.unit_price, //Precio unitario.
                    account_code: item.account_code, //Cuenta contable.
                    discount: item.discount, //Descuento.
                    tracking1: item.tracking1, //Tracking 1,
                    tracking2: item.tracking2, //Tracking 2,
                    tax_type: item.tax_type,
                    tax_amount: calcTax(item), //Monto de impuestos.
                    amount: calcTotal(item), //Monto total de la línea
                })),
            };

            setIsLoading(true);
            await invoicesService
                .createNewInvoice(fInvInfo)
                .then((response) => {
                    setCurrentInv(response.data);
                    previousInvInfoRef.current = invInfo;
                    let fRows = response.data.line_items.map((item) => {
                        const zeroTaxAccountCode = xeroTaxRates.find((tax) => tax.isZeroTax)?.value ?? "";
                        return {
                            ...item,
                            _id: item._id,
                            item_code: item?.code ?? "",
                            description: item?.description ?? "",
                            amount: item?.formatted_amount ?? 0,
                            unit_price: item?.formatted_unit_price ?? 0,
                            original_unit_price: item?.formatted_unit_price ?? 0,
                            quantity: item?.formatted_quantity ?? 0,
                            account_code: item?.account_code ?? "",
                            discount: item?.discount ?? 0,
                            tax_type: item?.tax_type_xero ?? zeroTaxAccountCode,
                            tax_amount: item?.tax_amount ?? 0,
                        };
                    });
                    setRows(fRows);
                    previousRowsRef.current = fRows;
                    setTexto(t("register.saveSuccessfully"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
    };

    const notApprove = async () => {
        setIsLoading(true);
        await invoicesService
            .markInvoiceAsNotApproved({
                invoice_id: currentInv._id,
            })
            .then((response) => {
                setCurrentInv(response.data);
                setTexto(t("register.saveSuccessfully"));
                setAlertType("success");
                setShow(true);
                goBack();
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const handleClearContact = async (newValue) => {
        setSelectedContact({});

        setInvInfo({
            ...invInfo,
            contactXeroID: "",
            contactXeroName: "",
        });
    };

    useEffect(() => {
        (async () => {
                let newContacts = [];
                let selectedContactNew = {};

                if (contactsXeroOriginal.length > 0 && Object.keys(conversionData).length > 0) {
                    if (conversionData.contact_id || conversionData.contact_updated_id) {
                        let findContactSim1 = {};
                        if (conversionData.contact_updated_id) {
                            findContactSim1 = contactsXeroOriginal.find((contact) => contact._id.toString() === conversionData.contact_updated_id.toString());
                        } else {
                            findContactSim1 = contactsXeroOriginal.find((contact) => contact._id.toString() === conversionData.contact_id.toString());
                        }


                        if (!findContactSim1 || Object.keys(findContactSim1).length === 0) {
                            const contactName =
                                conversionData.conversion_type === 1
                                    ? conversionData.final_customer_name
                                    : conversionData.final_vendor_name;
        
                            if (!contactName) {
                                if (Object.keys(selectedContact).length > 0) {
                                    selectedContactNew = selectedContact;
                                } else {
                                    selectedContactNew = {};
                                }
                                
                                newContacts = [...contactsXeroOriginal];
                            } else {
                                selectedContactNew = {
                                    value: "first",
                                    name: contactName,
                                    _id: "first",
                                };
        
                                const findContactSim = contactsXeroOriginal.find((item) => item.name === contactName);
                
                                if (findContactSim) {
                                    newContacts = [...contactsXeroOriginal];
                                    selectedContactNew.value = findContactSim.value;
                                    selectedContactNew._id = findContactSim._id;
        
                                } else {
                                    newContacts = [
                                        {
                                            value: "first",
                                            name: contactName,
                                            _id: "first",
                                        },
                                        ...contactsXeroOriginal,
                                    ];
                                    // Si no se consigue el contacto exacto, se busca una coincidencia aproximada
                                    const foundSimilar = contactsXeroOriginal.find((item) => similarity(item.name, contactName) >= 0.85);
                                    if (foundSimilar) {
                                        selectedContactNew.value = foundSimilar.value;
                                        selectedContactNew.name = foundSimilar.name;
                                        selectedContactNew._id = foundSimilar._id;
                                    }
                                }
                            }
                        } else {
        
                            selectedContactNew.name = findContactSim1.name;
                            selectedContactNew.value = findContactSim1.value;
                            selectedContactNew._id = findContactSim1._id;
                            newContacts = [...contactsXeroOriginal];
                        }
                    } else {
                        const contactXero = conversionData.contactXeroID;

                        if (contactXero && contactXero !== null && contactXero !== "first") {
                            // Existe contacto sugerido o ya existe la sincronización
                            const findContactSim1 = contactsXeroOriginal.find((contact) => contact.value === contactXero);
        
                            selectedContactNew.name = findContactSim1.name;
                            selectedContactNew.value = findContactSim1.value;
                            selectedContactNew._id = findContactSim1._id;
                            newContacts = [...contactsXeroOriginal];
                        } else {
                            const contactName =
                                conversionData.conversion_type === 1
                                    ? conversionData.final_customer_name
                                    : conversionData.final_vendor_name;
        
                            if (!contactName) {
                                if (Object.keys(selectedContact).length > 0) {
                                    selectedContactNew = selectedContact;
                                } else {
                                    selectedContactNew = {};
                                }
                                newContacts = [...contactsXeroOriginal];
                            } else {
                                selectedContactNew = {
                                    value: "first",
                                    name: contactName,
                                    _id: "first",
                                };
        
                                const findContactSim = contactsXeroOriginal.find((item) => item.name === contactName);
                
                                if (findContactSim) {
                                    newContacts = [...contactsXeroOriginal];
                                    selectedContactNew.value = findContactSim.value;
                                    selectedContactNew._id = findContactSim._id;
        
                                } else {
                                    newContacts = [
                                        {
                                            value: "first",
                                            name: contactName,
                                            _id: "first",
                                        },
                                        ...contactsXeroOriginal,
                                    ];
                                    // Si no se consigue el contacto exacto, se busca una coincidencia aproximada
                                    const foundSimilar = contactsXeroOriginal.find((item) => similarity(item.name, contactName) >= 0.85);
                                    if (foundSimilar) {
                                        selectedContactNew.value = foundSimilar.value;
                                        selectedContactNew.name = foundSimilar.name;
                                        selectedContactNew._id = foundSimilar._id;
                                    }
                                }
                            }
                        }
                    }

                    setContactsXero(newContacts);
                    setSelectedContact(selectedContactNew);

                    const fContact = contactsXeroOriginal.find((item) => item._id === selectedContactNew._id);

                    if (fContact) {
                        let codeFilter1 = allCountries.find((country) => country.code === fContact?.contact_country);
                        let codeFilter2 = allCountries.find((country) => country.code === fContact?.contact_country_2);
            
                        setCompanyInfo({
                            ...companyInfo,
                            _id: fContact._id,
                            company_name: fContact.contact_name,
                            company_tax_number: fContact?.contact_tax_number ?? conversionData?.formatted_vendor_tax_id ?? conversionData?.vendor_tax_id ?? "",
                            company_email: fContact?.contact_email ?? conversionData?.formatted_vendor_email ?? conversionData?.vendor_email ?? "", 
                            company_address: fContact?.contact_address ?? conversionData?.formatted_vendor_address ?? conversionData?.vendor_address ?? "", 
                            company_country: codeFilter1?.country_code ?? "",
                            company_phone: fContact?.contact_phone ?? conversionData?.formatted_vendor_phone ?? conversionData?.vendor_phone,
                            company_country_code: codeFilter1?.phone_code ?? "",
            
                            // Información del contacto
                            contact_name_2: fContact?.contact_name_2 ?? "",
                            contact_email_2: fContact?.contact_email_2 ?? "",
                            contact_country_2: codeFilter2?.country_code ?? "",
                            contact_phone_2: fContact?.contact_phone_2 ?? "",
                            contact_role_2: fContact?.contact_role_2 ?? "",
                            contact_country_code: codeFilter2?.phone_code ?? "",
                        });
                    } else {
                        setCompanyInfo({
                            ...companyInfo,
                            _id: "",
                            company_name: selectedContactNew.name,
                            company_tax_number: conversionData?.formatted_vendor_tax_id ?? conversionData?.vendor_tax_id ?? "",
                            company_email: conversionData?.formatted_vendor_email ?? conversionData?.vendor_email ?? "", 
                            company_address: conversionData?.formatted_vendor_address ?? conversionData?.vendor_address ?? "", 
                            company_phone: conversionData?.formatted_vendor_phone ?? conversionData?.vendor_phone ?? "",
                        });
                    }

                    setInvInfo({
                        ...invInfo,
                        contactXeroID: selectedContactNew?.idSys ?? "",
                        contactXeroName: selectedContactNew.name ?? "",
                    })

                    previousInvInfoRef.current = {
                        ...invInfo,
                        contactXeroID: selectedContactNew?.idSys ?? "",
                        contactXeroName: selectedContactNew.name ?? "",
                    }
                } else {
                    const contactName =
                        conversionData?.conversion_type === 1
                            ? conversionData?.final_customer_name
                            : conversionData?.final_vendor_name;

                    if (contactName) {
                        selectedContactNew = {
                            value: "first",
                            name: contactName,
                        };

                        newContacts = [
                            {
                                value: "first",
                                name: contactName,
                            },
                            ...contactsXeroOriginal,
                        ];
                        setSelectedContact(selectedContactNew);
                    } else{
                        newContacts = [...contactsXeroOriginal]
                    }

                    setCompanyInfo({
                        ...companyInfo,
                        _id: "",
                        company_name: contactName,
                        company_tax_number: conversionData?.formatted_vendor_tax_id ?? conversionData?.vendor_tax_id ?? "",
                        company_email: conversionData?.formatted_vendor_email ?? conversionData?.vendor_email ?? "", 
                        company_address: conversionData?.formatted_vendor_address ?? conversionData?.vendor_address ?? "", 
                        company_phone: conversionData?.formatted_vendor_phone ?? conversionData?.vendor_phone ?? "",
                    });

                    setContactsXero(newContacts);
                }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversionData, contactsXeroOriginal, setSelectedContact]);

    const handleChangeSelectAll = async (event) => {
        setSelectedAllAcc(event.target.value);
        const allLineItems = rows.map((aux) => ({
            ...aux,
            account_code: event.target.value,
        }));
        setRows(allLineItems);
    };

    const handleChangeSelectAllTaxes = async (event) => {
        setSelectedAllTaxes(event.target.value);

        const taxRate = xeroTaxRates.find((item) => item.value === event.target.value).taxRate;
        const newRows = rows.map((aux) => {
            return {
                ...aux,
                tax_type: event.target.value,
                tax_amount: (taxRate * (aux?.unit_price ?? 0 * aux?.quantity ?? 0)) / 100,
                amount: getAmount(aux?.unit_price ?? 0, aux?.quantity ?? 0, aux?.discount ?? 0, event.target.value),
            };
        });
        setRows(newRows);
    };

    const getAmount = (unit_price, quantity, discount = 0, tax = 0) => {
        const subTotal = Number(unit_price) * Number(quantity);
        const taxRate = Number(xeroTaxRates.find((item) => item?.value === tax)?.taxRate || 0);
        const discountRate = Number(discount);

        const totalTax = subTotal * (taxRate / 100);
        const totalDiscount = subTotal * (discountRate / 100);

        return subTotal + totalTax - totalDiscount;
    };

    const isCommonEmpty = 
        !invInfo.invoiceNumber || 
        !invInfo.invoiceDate || 
        !invInfo.invoiceDueDate || 
        !selectedContact?._id || 
        !currency;
    
        console.log(invInfo.invoiceNumber)
        console.log(invInfo.invoiceDate)
        console.log(currency)
    console.log(selectedContact)
    console.log(isCommonEmpty)

    const isEmptyInvoice = integration === 3
        ? isCommonEmpty || rows.filter((item) => !item.deleted).some((row) => !row.description || !row.quantity || row.unit_price == null)
        : integration === 1
        ? isCommonEmpty || rows.filter((item) => !item.deleted).some((row) => !row.description || !row.quantity || row.unit_price == null || !row.account_code || !row.tax_type)
        : isCommonEmpty || rows.filter((item) => !item.deleted).some((row) => !row.description || !row.quantity || row.unit_price == null || !row.account_code);

    const handleChangeTrackingCategory = (event, categoryId) => {
        setAllTrackingCategories((prev) => ({
            ...prev,
            [categoryId]: event.target.value,
        }));
        const newRows = rows.map((aux) => {
            return {
                ...aux,
                tracking: {
                    _id: categoryId,
                    name: event.target.value,
                },
            };
        });
        setRows(newRows);
    };

    return (
        <Box px={3} pb={3} bgcolor="#FFF">
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box my={2}>
                            <Typography variant="h1" fontSize={20}>
                                {Object.keys(currentInv).length === 0 &&
                                    (conversion_type === 1 ? t("invoicing.newInv") : t("invoicing.newBill"))}
                                {Object.keys(currentInv).length > 0 &&
                                    (conversion_type === 1 ? t("invoicing.bill") : t("invoicing.invoice"))}
                                {Object.keys(currentInv).length > 0 && (
                                    <Typography component="span" color="primary" variant="h1" fontSize={20}>
                                        {invInfo.invoiceNumber !== "" ? " " + invInfo.invoiceNumber : ""}
                                    </Typography>
                                )}
                                {Object.keys(currentInv).length > 0 && currentInv.id_status === 2 && (
                                    <Chip label={t("invoicing.pendingApproval")} size="small" sx={{ px: 3, ml: 1 }} />
                                )}
                                {Object.keys(currentInv).length > 0 && currentInv.id_status === 4 && (
                                    <Chip
                                        label={t("invoicing.approved")}
                                        size="small"
                                        sx={{ px: 3, ml: 1, bgcolor: "#dbf4e5" }}
                                    />
                                )}
                            </Typography>
                            {Object.keys(currentInv).length > 0 && (
                                <Typography variant="body">
                                    {t("invoicing.createdOn")}{" "}
                                    {moment.utc(currentInv.current_date).format(todos?.dateFormat ?? "DD/MM/YYYY")}
                                </Typography>
                            )}
                        </Box>
                        <UploadFilePopover
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setIsLoading={setIsLoading}
                            setPreviewFile={setPreviewFile}
                            setOpenPreview={setOpenPreview}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                            currentInv={currentInv}
                            setAccountPrev={setAccountPrev}
                            integration={integration}
                            previewFile={previewFile}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} mb={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Autocomplete
                            disableClearable
                            PopperComponent={StyledPopper}
                            sx={{ minWidth: "100%", height: 42 }}
                            options={contactsXero}
                            getOptionLabel={(option) => (option.name ? option.name : "")}
                            value={selectedContact}
                            onChange={(event, newValue, reason) => {
                                if (reason === "clear") {
                                    handleClearContact();
                                } else {
                                    handleChangeSelectContact(newValue);
                                }
                            }}
                            renderOption={(props, option, { index }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                        key={key + index}
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...optionProps}
                                    >
                                        <Typography color="#131f3e">{option.name}</Typography>
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <CustTextField
                                    {...params}
                                    label={selectedContact?._id === "first"
                                        ? t("converter.createContact")
                                        : t("converter.chooseContact")}
                                    error={!selectedContact?._id}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                                <Box sx={{ right: "44px", position: "absolute", top: "50%" }}>
                                                    <InputAdornment position="end">
                                                        {conversionData?.match_contact_type ? getContactIcon2(
                                                            conversion_type === 1
                                                                ? conversionData.final_customer_name
                                                                : conversionData.final_vendor_name,
                                                            conversionData.match_contact_type,
                                                            conversionData.contact_updated_id ?? conversionData.contact_id,
                                                            selectedContact,
                                                            t
                                                        ): getContactIcon(
                                                            conversion_type === 1
                                                                ? conversionData.final_customer_name
                                                                : conversionData.final_vendor_name,
                                                            contactsXeroOriginal,
                                                            contactsXero,
                                                            selectedContact,
                                                            t
                                                        )}
                                                    </InputAdornment>
                                                </Box>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => {
                                if (!value || Object.keys(value).length === 0) return false;
                                return option._id === value._id;
                            }}
                            noOptionsText={
                                <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                    <Typography fontWeight={600} fontSize={13} mt={0.5} color="#131f3e">
                                        {t("converter.noContact")}
                                    </Typography>
                                </Box>
                            }
                        />
                        <IconButton
                            onClick={showModalComp}
                            sx={{
                                ml: 2,
                            }}
                        >
                            <SearchIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <CustTextField
                        fullWidth
                        label={replaceInvBill(t("converter.invoiceNumber"))}
                        name="invoiceNumber"
                        required
                        onChange={handleChangInvData}
                        value={invInfo.invoiceNumber}
                        error={!invInfo?.invoiceNumber?.trim()}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <CustTextField
                        fullWidth
                        label={t("converter.controlNum")}
                        name="controlNumber"
                        onChange={handleChangInvData}
                        value={invInfo.controlNumber}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <CustomDatePicker
                        label={replaceInvBill(t("converter.invoiceDate"))}
                        value={invInfo?.invoiceDate}
                        onChange={(e) => handleChangeDate(e, "invoiceDate", true)}
                        isError={!invInfo?.invoiceDate}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <CustomDatePicker
                        label={replaceInvBill(t("converter.dueDate"))}
                        value={invInfo?.invoiceDueDate}
                        onChange={(e) => handleChangeDate(e, "invoiceDueDate")}
                        isError={!invInfo?.invoiceDueDate}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <BaseSelect2
                        error={!currency}
                        required
                        label={t("dashboard.currency")}
                        value={currency ?? ""}
                        onChange={handleChangeCurrency}
                        name="currency"
                        values={allCurrencies}
                        idName="id"
                        valueName="name"
                    />
                </Grid>
                {invInfo.currency !== currency && integration !== 3 && (
                    <Grid item xs={12} md={2}>
                        <CustTextField
                            fullWidth
                            label={t("converter.rate")}
                            name="rate"
                            onChange={handleChangeRate}
                            value={rate}
                            type="text"
                            disabled={rate === ""}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="h1" fontSize={20} mt={3}>
                        {replaceInvBill(t("converter.invItems"))}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <BaseSelect2
                                label={t("converter.chooseAccCont")}
                                labelId="simple-select-label-all"
                                name="account_code"
                                value={selectedAllAcc}
                                onChange={(event) => handleChangeSelectAll(event)}
                            >
                                <Typography variant="h3" pl={1} gutterBottom>
                                    {t("converter.kiiperSug")}
                                </Typography>
                                {accountsXero.filter((item) =>
                                    invInfo.contact_account_code?.map((val) => val.AccountCode).includes(item.value)
                                ).length === 0 ? (
                                    <MenuItem disabled value="">
                                        <em>{t("converter.noHist")}</em>
                                    </MenuItem>
                                ) : (
                                    accountsXero
                                        .filter((item) =>
                                            invInfo.contact_account_code
                                                ?.map((val) => val.AccountCode)
                                                .includes(item.value)
                                        )
                                        .map((val) => (
                                            <MenuItem key={val.value} value={val.value}>
                                                {val.value} - {val.name}
                                            </MenuItem>
                                        ))
                                )}
                                <Divider />
                                <Typography variant="h3" pl={1} gutterBottom>
                                    {t("converter.otherAcc")}
                                </Typography>
                                {accountsXero
                                    .filter(
                                        (item) =>
                                            !invInfo.contact_account_code
                                                ?.map((val) => val.AccountCode)
                                                .includes(item.value)
                                    )
                                    .map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.value} - {item.name}
                                        </MenuItem>
                                    ))}
                            </BaseSelect2>
                        </Grid>
                        {!(integration === 2 && conversion_type === 2) && (
                            <Grid item xs={12} md={3}>
                                <BaseSelect2
                                    label={t("converter.chooseTax")}
                                    labelId="simple-select-label-all"
                                    name="account_code"
                                    value={selectedAllTaxes}
                                    onChange={(event) => handleChangeSelectAllTaxes(event)}
                                >
                                    <Typography variant="h3" pl={1} gutterBottom>
                                        {t("converter.kiiperSug")}
                                    </Typography>
                                    {xeroTaxRates.filter((item) =>
                                        invInfo.contact_tax_type?.map((val) => val.TaxType).includes(item.value)
                                    ).length === 0 ? (
                                        <MenuItem disabled value="">
                                            <em>{t("converter.noHist")}</em>
                                        </MenuItem>
                                    ) : (
                                        xeroTaxRates
                                            .filter((item) =>
                                                invInfo.contact_tax_type?.map((val) => val.TaxType).includes(item.value)
                                            )
                                            .map((val) => (
                                                <MenuItem key={val.value} value={val.value}>
                                                    {val.name}
                                                </MenuItem>
                                            ))
                                    )}
                                    <Divider />
                                    <Typography variant="h3" pl={1} gutterBottom>
                                        {t("converter.otherAcc")}
                                    </Typography>
                                    {xeroTaxRates
                                        .filter(
                                            (item) =>
                                                !invInfo.contact_tax_type
                                                    ?.map((val) => val.TaxType)
                                                    .includes(item.value)
                                        )
                                        .map((item) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </BaseSelect2>
                            </Grid>
                        )}
                        {trackingConfig && trackingCategories.map((category) => (
                            <Grid item xs={12} md={2} key={category._id}>
                                <BaseSelect2
                                    id={`tracking-category-${category._id}`}
                                    label={category.tracking_category_name_xero}
                                    name={category.tracking_category_name_xero}
                                    value={allTrackingCategories[category._id] || ""}
                                    onChange={(event) => handleChangeTrackingCategory(event, category._id)}
                                    MenuProps={MenuProps}
                                >
                                    {category.tracking_category_options.map((option) => (
                                        <MenuItem key={option.TrackingOptionId} value={option.Name}>
                                            {option.Name}
                                        </MenuItem>
                                    ))}
                                </BaseSelect2>
                            </Grid>
                        ))}
                        <Grid item xs={12} md={6}>
                            <Typography width={"100%"}>{t("converter.selectAccount")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <NewInvoiceTableItems
                        rows={rows}
                        setRows={setRows}
                        xeroTaxRates={xeroTaxRates}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        setShow={setShow}
                        selected={selected}
                        setSelected={setSelected}
                        accountsXero={accountsXero}
                        xeroItems={xeroItems}
                        invInfo={invInfo}
                        integration={integration}
                        conversion_type={conversion_type}
                        trackingCategories={trackingCategories}
                        trackingConfig={trackingConfig}
                    />
                </Grid>
                <Grid item xs={12} mt={4}>
                    <Stack direction="row" alignItems="start" justifyContent="space-between">
                        <Stack direction="column" spacing={2} width="50%">
                            <BaseButton
                                variantType="primaryOutlined"
                                sx={{ justifyContent: "space-between" }}
                                disableElevation
                                endIcon={<AddIcon />}
                                onClick={handleNewRow}
                                sizebutton="xl"
                            >
                                {t("converter.addRow")}
                            </BaseButton>
                            <CustTextField
                                id="outlined-multiline-flexible"
                                label={t("converter.obs")}
                                multiline
                                maxRows={4}
                                fullWidth
                                name="details"
                                value={invInfo.details}
                                onChange={handleChangInvData}
                                inputProps={{ style: { resize: "vertical" } }}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} width="25%">
                            <NewInvoiceTableSummary totalsInfo={totalsInfo} />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                            {Object.keys(currentInv).length > 0 && (
                                <>
                                    {t("invoicing.lastChangesSavedOn")}{" "}
                                    {moment
                                        .utc(currentInv?.updated_date ?? currentInv?.current_date)
                                        .format(todos?.dateFormat + " - HH:mm A" ?? "DD/MM/YYYY - HH:mm A")}
                                </>
                            )}
                        </Box>
                        <Box>
                            {Object.keys(currentInv).length === 0 && (
                                <Box display="flex" justifyContent="end">
                                    <Stack spacing={2} direction="row">
                                        {/* <Button variant="text" disableElevation size="large" sx={{ px: 5 }}  color="error" onClick={deleteInv}>
                                            Borrar factura
                                        </Button> */}
                                        <ButtonGroup
                                            disableElevation
                                            variant="outlined"
                                            ref={anchorRefBtnGroup}
                                            aria-label="split button"
                                            color="inherit"
                                        >
                                            <BaseButton
                                                disableElevation
                                                color="primary"
                                                sizebutton="m"
                                                onClick={() => saveInvoice(false)}
                                                disabled={invInfo.invoiceNumber === "" || !selectedContact?._id}
                                            >
                                                {t("team.save")}
                                            </BaseButton>
                                            <BaseButton
                                                size="small"
                                                aria-controls={openBtnGroupSend ? "split-button-menu" : undefined}
                                                aria-expanded={openBtnGroupSend ? "true" : undefined}
                                                aria-label="select merge strategy"
                                                aria-haspopup="menu"
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={handleToggleBtnGroup}
                                                disabled={invInfo.invoiceNumber === "" || !selectedContact?._id}
                                            >
                                                <ArrowDropDownIcon />
                                            </BaseButton>
                                        </ButtonGroup>
                                        <Popover
                                            open={openBtnGroupSend}
                                            anchorEl={anchorRefBtnGroup.current}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                        >
                                            <ClickAwayListener onClickAway={handleCloseBtnGroup}>
                                                <Paper style={{ minWidth: 200 }}>
                                                    <MenuList id="split-button-menu" autoFocusItem>
                                                        {optionsGroupSend.map(({ id, data }, index) => (
                                                            <MenuItem
                                                                key={"convertOption_" + id}
                                                                selected={index === selectedIndexBtnGroup}
                                                                onClick={(event) =>
                                                                    handleMenuItemClickBtnGroup(event, index)
                                                                }
                                                                sx={{ color: "#4a22d4" }}
                                                            >
                                                                {data}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Paper>
                                            </ClickAwayListener>
                                        </Popover>
                                    </Stack>
                                </Box>
                            )}
                            {/* Factura por aprobar */}

                            {/* Factura aprobada, se oculta mientras tanto el botón de anular */}
                            {/* {Object.keys(currentInv).length > 0 && currentInv.id_status === 4 && 
                                <Box display="flex" justifyContent="end">
                                    <Stack spacing={2} direction="row">
                                        <Button variant="text" disableElevation size="large" sx={{ px: 5 }}  color="error">
                                            Anular factura
                                        </Button>
                                    </Stack>
                                </Box>
                            } */}

                            <Box display="flex" justifyContent="end">
                                <Stack spacing={2} direction="row">
                                    {Object.keys(currentInv).length > 0 && (
                                        <>
                                            {/* Botón "No Aprobar" (estado 4 o 2) */}
                                            {(currentInv.id_status === 4 || currentInv.id_status === 2) && (
                                                <BaseButton
                                                    variantType="errorText"
                                                    disableElevation
                                                    onClick={notApprove}
                                                    sizebutton="m"
                                                >
                                                    {t("invoicing.doNotApprove")}
                                                </BaseButton>
                                            )}

                                            {/* Botón "Aprobar" (sin cambios, estado 2, contacto válido) */}
                                            {currentInv.id_status === 2 && !invChanges && selectedContact?._id !== "first" && Object.keys(selectedContact).length > 0  && (
                                                    <BaseButton
                                                        disableElevation
                                                        onClick={handleAlertModalSendInv}
                                                        sizebutton="m"
                                                        disabled={isEmptyInvoice || (!canApprove && (!selectedContact?.value || selectedContact?.value === "" || selectedContact?.value === null))}
                                                    >
                                                        {t("converter.aprove")}
                                                    </BaseButton>
                                                )}

                                            {/* Botón "Guardar Cambios" (con cambios o contacto inicial) */}
                                            {(invChanges && (currentInv.id_status === 4 || currentInv.id_status === 2)) || selectedContact?._id === "first" || Object.keys(selectedContact).length === 0 ? (
                                                <BaseButton
                                                    disableElevation
                                                    onClick={handleModalupdateInvoice}
                                                    sizebutton="m"
                                                    disabled={isEmptyInvoice}
                                                >
                                                    {t("dialog.saveChanges")}
                                                </BaseButton>
                                            ) : ''}
                                        </>
                                    )}
                                </Stack>
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewInvoiceTab1;
