/* Componentes */
import { useState, useEffect } from "react";
import BackGeneral from "../components/layout/BackGeneral";
import computerVision from "../services/computer_vision";
import {
    Container,
    Typography,
    Grid,
    Stack,
    Box,
    MenuItem,
    FilledInput,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import ocrServices from "../services/ocr";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import BaseButton from "../components/ui/BaseButton";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const ComputerVisionInvoices = () => {
    const [file2, setFile2] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [buffers, setBuffers] = useState([]);
    const [elapsedTime, setElapsedTime] = useState("");
    const [azureModels, setAzureModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState("");

    const handleChange2 = (event) => {
        setFile2(event.target.files[0]);
    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();
        setBuffers([]);
        setError(false);

        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", file2);
        formData.append("model", selectedModel);

        try {
            const response = await computerVision.invoicesParser(formData);
            setBuffers(response.data.finalImgCropped);
            console.log(response.data.finalImgCropped);
            setElapsedTime(response.data.elapsedTime);
        } catch (error) {
            setError(true);
            console.error("Error:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await ocrServices
                .getCustomModels()
                .then((data) => {
                    setAzureModels(data);
                    setSelectedModel(data[0]);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, []);

    const handleChangeModel = (event) => {
        setSelectedModel(event.target.value);
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 45 * 4.5 + 8,
                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
            },
            sx: {
                borderRadius: 2,
                mt: 0.7,
            },
        },
        variant: "menu",
        getcontentanchorel: null,
    };

    return (
        <>
            <BackGeneral colorBg="#fff">
                <Container maxWidth="lg">
                    <Grid container spacing={6}>
                        <Grid item xs={12} sx={{ marginTop: 4, textAlign: "center" }}>
                            <Typography variant="h5" gutterBottom>
                                Modelo IA - Invoices
                            </Typography>
                            <form onSubmit={handleSubmit2}>
                                <Stack direction="column" justifyContent="center" spacing={2} mb={2}>
                                    <div>
                                        <BaseButton
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Cargar documento
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={handleChange2}
                                                accept="application/pdf, image/*"
                                                required
                                            />
                                        </BaseButton>
                                    </div>
                                    {file2 && (
                                        <div>
                                            <Typography variant="h2">{file2.name}</Typography>
                                        </div>
                                    )}
                                    <div>
                                        <FormControl
                                            variant="filled"
                                            sx={{ width: { xs: "100%", md: "30%" } }}
                                            required
                                        >
                                            <InputLabel
                                                id="demo-multiple-checkbox-label"
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            >
                                                Modelo custom
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                value={selectedModel}
                                                displayEmpty
                                                onChange={handleChangeModel}
                                                input={
                                                    <FilledInput
                                                        label={"Modelo custom"}
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    />
                                                }
                                                MenuProps={MenuProps}
                                                defaultValue=""
                                                name="model"
                                            >
                                                {azureModels.map((item) => (
                                                    <MenuItem value={item} key={item}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Stack>
                                <BaseButton sizebutton="m" type="submit">
                                    Enviar
                                </BaseButton>
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <b>Tiempo:</b> {elapsedTime}
                            </Typography>
                        </Grid>
                        {buffers.map((itemex, index) => (
                            <Grid item xs={12}>
                                <Typography key={"title" + index} sx={{ mt: 1 }} fontWeight={600}>
                                    {" "}
                                    {"\u2B24"} FACTURA CORTADA {index + 1} (Confidence: {itemex.confidence} %)
                                </Typography>
                                <Stack direction="row" spacing={6}>
                                    <Box
                                        component="img"
                                        sx={{ width: "40%" }}
                                        src={`data:image/jpeg;base64,${itemex.img}`}
                                    />
                                    <Typography key={"body" + index} sx={{ ml: 1, width: "40%" }}>
                                        <pre>{JSON.stringify(itemex.fields, null, 2)}</pre>
                                    </Typography>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                    {error && (
                        <>
                            <Typography fontWeight={600} sx={{ mt: 4 }} color="red" textAlign="center">
                                Hubo un error.
                            </Typography>
                        </>
                    )}
                </Container>
            </BackGeneral>
            <SimpleBackdrop open={isLoading} />
        </>
    );
};

export default ComputerVisionInvoices;
