import React from "react";
import { styled, Tab, Tabs } from "@mui/material";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const CustomTab = styled(Tab)(({ theme }) => ({
    // padding: "12px 48px",
    backgroundColor: "#E9E6EC",
    color: theme.palette.text.primary,
    fontWeight: 600,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    minWidth: 198,
    minHeight: 42,
    "&:not(:first-child)": {
        marginLeft: 12,
    },
    "&.Mui-selected": {
        backgroundColor: "white",
        color: theme.palette.text.primary,
    },
}));


const NoddokTabsHeader = ({ value, onChange, labels, TabProps = {} }) => (
    <Tabs
        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
        aria-label="Custom tabs"
        sx={{ mt: 2, minHeight: 42 }}
        TabIndicatorProps={{ style: { display: "none" } }}
        value={value}
        onChange={onChange}
    >
        {labels.map((label, index) => (
            <CustomTab key={index} label={label} {...a11yProps(index)} {...TabProps} />
        ))}
    </Tabs>
);

export default NoddokTabsHeader;