import {
    Button,
    Checkbox,
    Grid,
    InputAdornment,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import SearchIcon from "@mui/icons-material/Search";

import BaseSelect from "../ui/BaseSelect";
import { useState } from "react";

import accountsService from "../../services/accounts";
import { useSelector } from "react-redux";
import BaseButton from "../ui/BaseButton";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ReconTabs = (props) => {
    const {
        reconTab,
        createManualInfo,
        setCreateManualInfo,
        accounts,
        rows,
        selAccountData,
        setCheckeds,
        checkeds,
        setRows,
        rowsOG,
        showCreate,
        setShowCreate,
        createTransInfo,
        setCreateTransInfo,
        setOpenBackdrop,
        setShowDivideBox,
        showDivideBox
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchValue] = useState("");
    const [contacts, setContacts] = useState([]);
    const [accountsNum, setAccountsNum] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [amount, setAmount] = useState(0);
    const [amountOG, setAmountOG] = useState(0);
    const [showError, setShowError] = useState(false);

    const typeTrans = [
        { value: 0, name: t("accounts.sell") },
        { value: 1, name: t("accounts.buy") },
    ];

    const handleChange = (event, i) => {
        let newArr = [...checkeds];

        newArr = newArr.map((item, index) => (index === i ? event.target.checked : item));

        setCheckeds(newArr);
    };

    const handleCreateTrans = async () => {
        setShowCreate(true);

        setOpenBackdrop(true);

        await accountsService
            .getContacts({ company_id: todos.companyID })
            .then((contacts) => {
                setContacts(contacts.map((item) => ({ id: item.ContactID, name: item.Name, _id: item._id })));
            })
            .catch((err) => {
                console.log(err);
            });

        await accountsService
            .getChartOfAccounts({ company_id: todos.companyID })
            .then((chartAcc) => {
                setAccountsNum(chartAcc.map((item) => ({ id: item.AccountID, name: item.Name })));
            })
            .catch((err) => {
                console.log(err);
            });

        setOpenBackdrop(false);
    };

    const columns = [
        { name: "check", title: "", align: "center" },
        { name: "date", title: t("payment.date"), align: "center" },
        { name: "contact", title: t("subscription.contact"), align: "left" },
        { name: "divide", title: "", align: "center" },
        { name: "egress", title: t("accounts.buy"), align: "right" },
        { name: "ingress", title: t("accounts.sell"), align: "right" },
    ];

    const handleChangeManual = (event) => {
        setCreateManualInfo({ ...createManualInfo, [event.target.name]: event.target.value });
    };

    const handleChangeTransInfo = (event) => {
        setCreateTransInfo({ ...createTransInfo, [event.target.name]: event.target.value });
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rowsOG.filter((item) =>
            item.contact.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    const handleDivide = (row, index) => {
        setAmount(parseFloat(row.ingress));
        setAmountOG(parseFloat(row.ingress));
        setShowDivideBox(true);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setShowDivideBox(false);
    };

    const handleChangeAmount = (event) => {
        setAmount(event.target.value);
    };

    const handleConfirm = () => {
        if (amountOG < amount) {
            setShowError(true);
        } else {
            setShowError(false);
            setShowDivideBox(false);
            const filterCases = rowsOG.map((item, index) => {
                if (index === selectedIndex) {
                    item.ingress = parseFloat(amount).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    });
                }
                return item;
            });
            setRows(filterCases);
        }
    };

    const finalAccs = accounts
        .filter((item) => item._id !== selAccountData._id)
        .map((item) => {
            return { id: item._id, name: item.account_name };
        });

    return (
        <>
            {reconTab === 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("accounts.contactName")}
                            name="contactName"
                            onChange={(event) => handleChangeManual(event)}
                            value={createManualInfo.contactName}
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("accounts.accountNum")}
                            name="accountNum"
                            onChange={(event) => handleChangeManual(event)}
                            value={createManualInfo.accountNum}
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            fullWidth
                            label={t("payment.concept")}
                            name="concept"
                            onChange={(event) => handleChangeManual(event)}
                            value={createManualInfo.concept}
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                </Grid>
            )}
            {reconTab === 1 && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <BaseSelect
                            id="account"
                            label={t("dashboard.accounts")}
                            name="bankAcc"
                            value={createManualInfo.bankAcc}
                            values={finalAccs}
                            onChange={(event) => handleChangeManual(event)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("accounts.ref")}
                            name="reference"
                            onChange={(event) => handleChangeManual(event)}
                            value={createManualInfo.reference}
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                </Grid>
            )}
            {reconTab === 2 && (
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3">{t("dashboard.searchASel")}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="filled"
                            hiddenLabel
                            placeholder={t("dashboard.search")}
                            size="small"
                            value={searchValue}
                            onChange={handleChangeSearchValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {rows.length > 0 ? (
                            <TableContainer sx={{ maxHeight: 300 }}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            {columns.map((item) => (
                                                <StyledTableCell key={item.name} align={item.align ?? "left"}>
                                                    {item.title}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <>
                                                <StyledTableRow
                                                    key={row.id}
                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                >
                                                    <StyledTableCell align="center">
                                                        <Checkbox
                                                            checked={checkeds[index]}
                                                            size="small"
                                                            onChange={(e) => handleChange(e, index)}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">{row.date}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.contact}</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Button
                                                            disabled={showDivideBox && selectedIndex !== index}
                                                            onClick={() => handleDivide(row, index)}
                                                        >
                                                            Dividir
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">{row.egress}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {showDivideBox && selectedIndex === index ? (
                                                            <TextField
                                                                size="small"
                                                                value={amount}
                                                                type="number"
                                                                sx={{
                                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                        {
                                                                            display: "none",
                                                                        },
                                                                }}
                                                                onChange={handleChangeAmount}
                                                            ></TextField>
                                                        ) : (
                                                            row.ingress
                                                        )}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                {showDivideBox && selectedIndex === index && (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="center" colSpan={12}>
                                                            {showError && (
                                                                <Stack direction="row" justifyContent="flex-end">
                                                                    <Typography color="error" gutterBottom>
                                                                        {t("accounts.amountIng")}
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                            <Stack direction="row" justifyContent="space-between">
                                                                <Button onClick={handleClose}>
                                                                    {t("team.cancel")}
                                                                </Button>
                                                                <BaseButton sizebutton="m" onClick={handleConfirm}>
                                                                    {t("subscription.confirm")}
                                                                </BaseButton>
                                                            </Stack>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography fontSize={14} textAlign="center" sx={{ mt: 3 }}>
                                {t("dashboard.noInfo")}
                            </Typography>
                        )}
                    </Grid>
                    {!showCreate ? (
                        <>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    disabled={!checkeds.find((item) => item === true)}
                                    onClick={handleCreateTrans}
                                >
                                    {t("accounts.createTrans")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    disabled={!checkeds.find((item) => item === true)}
                                >
                                    {t("accounts.applySettings")}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Typography variant="h3">
                                        {t("navBar.totalRecon")}: <span style={{ fontWeight: 400 }}>${423.24}</span>
                                    </Typography>
                                    <Typography variant="h3">
                                        {t("accounts.reconRest")}:{" "}
                                        <span style={{ color: "#FF5968", fontWeight: 400 }}>${423.24}</span>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} sx={{ backgroundColor: "#f8f9f8", pb: 2, pr: 2, borderRadius: 2 }}>
                            <Paper elevation={0} sx={{ backgroundColor: "white", px: 3, py: 4 }}>
                                <Grid container spacing={2} justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">{t("accounts.createTrans")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <BaseSelect
                                            label={t("accounts.contactName")}
                                            name="contactName"
                                            value={createTransInfo.contactName}
                                            values={contacts}
                                            onChange={(event) => handleChangeTransInfo(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <BaseSelect
                                            label={t("accounts.accountNum")}
                                            name="accountNum"
                                            value={createTransInfo.accountNum}
                                            values={accountsNum}
                                            onChange={(event) => handleChangeTransInfo(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CustomTextField
                                            fullWidth
                                            label={t("credits.amount")}
                                            name="amount"
                                            onChange={(event) => handleChangeTransInfo(event)}
                                            value={createTransInfo.amount}
                                            variant="filled"
                                            size="small"
                                            type="number"
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <BaseSelect
                                            label={t("payment.typeTrans")}
                                            name="transType"
                                            value={createTransInfo.transType}
                                            values={typeTrans}
                                            onChange={(event) => handleChangeTransInfo(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            fullWidth
                                            label={t("payment.concept")}
                                            name="concept"
                                            onChange={(event) => handleChangeTransInfo(event)}
                                            value={createTransInfo.concept}
                                            variant="filled"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BaseButton variantType="primaryOutlined" sizebutton="m" onClick={() => setShowCreate(false)}>
                                            {t("team.cancel")}
                                        </BaseButton>
                                    </Grid>
                                    <Grid item>
                                        <BaseButton
                                            sizebutton="m"
                                            disableElevation
                                            onClick={() => setShowCreate(false)}
                                        >
                                            {t("subscription.confirm")}
                                        </BaseButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default ReconTabs;
