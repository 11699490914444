import {
    Box,
    Grid,
    InputAdornment,
    Typography,
    Skeleton,
    Stack,
    IconButton,
} from "@mui/material";
import FiltersButton from "./FiltersButton";

import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import ChatIcon from "../../../assets/chat-icon.svg";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import reportService from "../../../services/reports";
import { useSelector } from "react-redux";
import LightTooltip from "../../ui/LightTooltip";
import { fetchFileFromS3, formatAmount, formatDate, zeroPad } from "../../../utils/utils";
import casesService from "../../../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import ChatModal from "../../ui/Chat";
import BaseButton from "../../ui/BaseButton";
import NoddokTable from "../../ui/NoddokTable";
import CustTextField from "../../ui/CustTextField";
import NoddokTabsHeader from "../../ui/NoddokTabsHeader";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        convertion: <Skeleton variant="text" animation="wave" />,
        bankAcc: <Skeleton variant="text" animation="wave" />,
        date: <Skeleton variant="text" animation="wave" />,
        file_name: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        gainNe: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        actions: <Skeleton variant="text" animation="wave" />,
    });
}

const IncomeTable = (props) => {
    const {
        filters,
        setFilters,
        accounts,
        setAccount,
        setIsLoading,
        setConversionData,
        setPreviewInc,
        setConversionId,
        setTexto,
        setAlertType,
        setShow,
        setFileData,
        setFileNameFull,
        setBasePreviewData,
        usersFilters,
        tabValue,
        setOpenPreview,
        value,
        setValue,
        incomeConversions,
        showSkeleton,
        setShowSkeleton,
        setIncomeConversions
    } = props;
    const [t] = useTranslation("global");

    const columns = [
        { name: "convertion", title: t("subscription.conversion") },
        { name: "bankAcc", title: t("inbox.bankAcc") },
        { name: "date", title: t("converter.uploadDate"), align: "center" },
        { name: "file_name", title: t("converter.bankStatement") },
        { name: "period", title: t("payment.period") },
        { name: "gainNe", title: t("incomes.netIncome"), align: "right" },
        { name: "user", title: t("converter.user") },
        { name: "status", title: t("converter.status") },
        { name: "actions", title: <></>, width: "5%", align: "center" },
    ];

    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState([]);
    const [rowsOG, setRowsOG] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [caseInfo, setCaseInfo] = useState({});
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        (async () => {
            if (showSkeleton) {
                setRows(rowsSkeleton);
            } else {
                if (incomeConversions.length > 0) {
                    const connectSocket = async (case_id, correlative, status) => {
                        setRoom(case_id);
                        initiateSocket(case_id, todos.userInfo, "check", correlative);
                        subscribeToChat((err, data) => {
                            if (err) return;
                            setChat((oldChats) => [...oldChats, data]);
                        });
                        newUserResponse((err, data) => {
                            if (err) return;
                            setUsers(data);
                        });
                        setOpen(true);
                    };
    
                    const handleClickChat = async (statusConv, conversionID, correlative) => {
                        setIsLoading(true);
                
                        let params = {
                            company_id: todos.companyID,
                            franchise_id: todos.franchiseID,
                            user_email: todos.userInfo.email,
                            type: "conversion",
                            conversion_id: conversionID,
                            conversion_type: "check",
                        };
                
                        let caseInfo = null;
                
                        await casesService
                            .getCaseInfo(params)
                            .then(async (response) => {
                                if (response) {
                                    setCaseInfo(response.caseInfo);
                                    caseInfo = response.caseInfo;
                                    setStatus("");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                
                        if (!caseInfo) {
                            await casesService
                                .createNewCase(params)
                                .then(async () => {
                                    await casesService
                                        .getCaseInfo(params)
                                        .then(async (data) => {
                                            setCaseInfo(data.caseInfo);
                                            caseInfo = data.caseInfo;
                                            setStatus(1);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        } else {
                            setStatus(caseInfo.case_reason);
                        }
                
                        await connectSocket(caseInfo._id, correlative, statusConv);
                
                        setIsLoading(false);
                    };
    
                    const handleShowPrev = async (convID) => {
                        setIsLoading(true);
                        let converInfo = {};
                        let params2 = { report_id: convID };
                        await reportService
                            .getReportDetails(params2)
                            .then(async (data) => {
                                setConversionData(data);
                                // setRepeatedConv(data.repeatedConversion);
                                converInfo = data;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        // setCorrelative(converInfo.correlative);
                        setConversionId(converInfo._id);
                        // setFileNameSaved(converInfo.download_file_name.replace(/\.[^/.]+$/, ""));
                        const finalData = await fetchFileFromS3(converInfo.file_name);
                        if (!finalData) {
                            setTexto(t("converter.dnldError"));
                            setAlertType("error");
                            setShow(true);
                        } else {
                            setFileData(finalData);
                        }
                        setFileNameFull(converInfo.uploaded_file);
                        setBasePreviewData({
                            type: "report",
                            file_s3: converInfo.file_name,
                            file: converInfo.uploaded_file,
                        });
                        // setAccount(AccID);
                        setIsLoading(false);
                        setPreviewInc(true);
                    };
    
                    const getTotalIncome = (lines) => {
                        return lines
                            .filter((item) => item.line_type === 1)
                            .reduce((sum, obj) => sum + obj.unit_price * obj.formatted_quantity, 0);
                    };
                
                    const getTotalExpenses = (lines) => {
                        return lines
                            .filter((item) => item.line_type === 2)
                            .reduce((sum, obj) => sum + obj.unit_price * obj.formatted_quantity, 0);
                    };
                    
                    const getNetIncome = (row) => {
                        const sameCurrency = row?.updated_currency
                            ? row.updated_currency
                            : row.formatted_currency ?? "" === row.account_id?.currency_id_xero;
                
                        if (row.line_items) {
                            const newLines = row.line_items
                            .filter(
                                (item) => (item.formatted_credit || item.formatted_debit || item.unit_price_converter) && !item.deleted
                            )
                            .map((item) => ({
                                _id: item._id,
                                line_type: item.line_type,
                                formatted_quantity: item.quantity ?? item.formatted_quantity,
                                unit_price:
                                    item.unit_price_converter ??
                                    (item.unit_price_xero && sameCurrency
                                        ? item.unit_price_xero
                                        : item.formatted_credit ?? item.formatted_debit),
                            }));
                            return getTotalIncome(newLines) + getTotalExpenses(newLines);
                        } else {
                            return 0.00;
                        }
                    };
    
                    const previewIncome = async (file, file_s3) => {
                        setIsLoading(true);
                        const finalData = await fetchFileFromS3(file_s3);
                        setIsLoading(false);
                        
                        if (!finalData) {
                            setTexto(t("converter.dnldError"));
                            setAlertType("error");
                            setShow(true);
                        } else {
                            setFileData(finalData);
                        }
                        setFileNameFull(file);
                        setBasePreviewData({
                            type: "report",
                            file_s3: file_s3,
                            file: file,
                        });
                        
                        setOpenPreview(true);
                    };
    
                    let fData = incomeConversions.map((item) => {
                        return {
                            id: item._id,
                            convertion: "#" + zeroPad(item.correlative, 5),
                            bankAcc: item.account_id?.account_name,
                            date: formatDate(item.current_date, todos.dateFormat),
                            file_name: (
                                <BaseButton variantType="errorText" onClick={() => previewIncome(item.uploaded_file, item.file_name)}>
                                    {item.uploaded_file}
                                </BaseButton>
                            ),
                            period: formatDate(item.formatted_initial_date, todos.dateFormat) + " - " +
                            formatDate(item.formatted_end_date, todos.dateFormat),
                            gainNe: formatAmount(getNetIncome(item), todos.amountFormat),
                            user: item?.user_id?.user_name,
                            status: (<Stack direction="row" alignItems="center">
                                {item.id_status === 1 && (
                                    <AccessTimeIcon
                                        sx={{ color: "#FFB23F" }}
                                        fontSize="inherit"
                                    />
                                )}
                                {item.id_status === 2 && (
                                    <UploadFileIcon
                                        sx={{ color: "#361A52" }}
                                        fontSize="inherit"
                                    />
                                )}
                                {item.id_status === 3 && (
                                    <UploadFileIcon
                                        sx={{ color: "#34bd6a" }}
                                        fontSize="inherit"
                                    />
                                )}
                                &nbsp;
                                <Typography>
                                    {item.id_status === 1
                                        ? t("converter.inReview")
                                        : item.id_status === 2
                                        ? t("converter.toBeSynch")
                                        : t("converter.synchronized")}
                                </Typography>
                                &nbsp;
                                
                            </Stack>),
                            actions: (<Stack direction="row" alignItems="center" spacing={1}>
                                {item.id_status === 1 && (
                                    <LightTooltip
                                        title={t("converter.tooltipChat")}
                                        position="top"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                handleClickChat(
                                                    item.id_status,
                                                    item._id,
                                                    `#${zeroPad(item.correlative, 5)}`
                                                )
                                            }
                                            size="small"
                                            color="primary"
                                        >
                                            <Box
                                                component="img"
                                                src={ChatIcon}
                                                sx={{ width: 17 }}
                                            />
                                        </IconButton>
                                    </LightTooltip>
                                )}
                                {item.id_status >= 2 && (
                                    <IconButton
                                        onClick={() =>
                                            handleShowPrev(item._id, item.account_id._id)
                                        }
                                        size="small"
                                        color="primary"
                                    >
                                        {item.id_status === 3 ? (
                                            <DownloadIcon fontSize="small" />
                                        ) : (
                                            <VisibilityIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                )}
                            </Stack>)
                        };
                    })
                    setRows(fData);
                    setRowsOG(fData);
                } else {
                    setRows([]);
                    setRowsOG([]);
                }
            }
        })();
    }, [
        incomeConversions,
        t,
        todos.amountFormat,
        todos.dateFormat,
        setAlertType,
        setBasePreviewData,
        setConversionData,
        setConversionId,
        setFileData,
        setFileNameFull,
        setIsLoading,
        setOpenPreview,
        setPreviewInc,
        setShow,
        setTexto,
        todos.companyID,
        todos.franchiseID,
        todos.userInfo,
        showSkeleton
    ]);

    const handleChangeSearchValue = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchValue(query);
        
        if (!event.target.value) {
            setRows(rowsOG);
        } else {
            // Filtrar filas basadas en cualquier campo
            const filterCases = rows.filter((row) =>
                Object.values(row).some(
                    (value) =>
                        value !== null &&
                        value !== undefined &&
                        value.toString().toLowerCase().includes(query)
                )
            );
            setRows(filterCases);
        }
    };

    const handleChangeTab = async (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    pt: 3,
                }}
            >
                <Grid container justifyContent="space-between" alignItems="end">
                    <Grid item xs={12}>
                        <Typography variant="h2" color="secondary" pl={4}>
                            {t("incomes.reportIncHist")}
                        </Typography>
                        <NoddokTabsHeader
                            value={value}
                            onChange={handleChangeTab}
                            labels={[t("converter.singles")]}
                        />
                    </Grid>
                    <Grid item xs={12}  bgcolor="#FFF" p={3}>
                        <Grid container spacing={2} justifyContent="end" display="flex">
                            <Grid item>
                                <FiltersButton
                                    filters={filters}
                                    setFilters={setFilters}
                                    accounts={accounts}
                                    setAccount={setAccount}
                                    setShowSkeleton={setShowSkeleton}
                                    setIsLoading={setIsLoading}
                                    users={usersFilters}
                                    setIncomeConversions={setIncomeConversions}
                                    tabValue={tabValue}
                                    company={todos?.companyID}
                                    franchise={todos?.franchiseID}
                                    grupal={todos?.isGrouped}
                                />
                            </Grid>
                            <Grid item>
                                <CustTextField
                                    id="input-with-icon-textfield"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    isSearch
                                    hiddenLabel
                                    placeholder={t("team.search")}
                                    value={searchValue}
                                    onChange={handleChangeSearchValue}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} bgcolor="#FFF" px={3}>
                        <NoddokTable
                            columns={columns}
                            rows={rows}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            selected={selected}
                            setSelected={setSelected}
                            showCheckbox={true}
                        />
                    </Grid>
                </Grid>
                {open && (
                    <ChatModal
                        room={room}
                        setRoom={setRoom}
                        open={open}
                        setOpen={setOpen}
                        setChat={setChat}
                        chat={chat}
                        type={status}
                        users={users}
                        franchiseName={
                            todos.allComData?.find(
                                (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                            )?.franchise_name
                        }
                        setCaseInfo={setCaseInfo}
                        caseInfo={caseInfo}
                    />
                )}
            </Box>
        </>
    );
};

export default IncomeTable;
