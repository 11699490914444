/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Redux imports */
import { useDispatch } from "react-redux";
import { clearInfo } from "../../redux/userSlice";

/** MUI imports */
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from "@mui/material";
import GridUI from "@mui/material/Grid";

/** Styles */
import classes from "./DialogChangeEmail.module.css";

/* MUI Icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/** Services */
import userService from "../../services/user";
import authService from "../../services/auth";
import BaseButton from "../ui/BaseButton";

const DialogChangeEmail = (props) => {
    const {
        open,
        onClose,
        setConfirmChangeEmail,
        params,
        setAlertType,
        setIsError,
        setTexto,
        setShow,
        setOpenBackdrop,
        isError,
        texto,
        password,
        setPassword,
        existingEmail
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const dispatch = useDispatch();

    /** Component states */
    const [disabledButton, setDisabledButton] = useState(true);

    /** Component functions */
    const handleLogout = async () => {
        setOpenBackdrop(true);

        await authService
            .logout()
            .then(() => {
                dispatch(clearInfo());
            })
            .catch((err) => console.log(err));

        setOpenBackdrop(false);
    };

    const confirmSaveData = () => {
        setConfirmChangeEmail(true);
        onClose();
        setTimeout(() => {
            handleLogout();
        }, 10000);
    };

    const handleChangePassword = (event) => {
        event.preventDefault();
        setPassword({
            ...password,
            [event.target.name]: event.target.value,
        });
    };

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setPassword({
            ...password,
            showPassword: !password.showPassword,
        });
    };

    const handleClickShowRepeatPassword = (event) => {
        event.preventDefault();
        setPassword({
            ...password,
            showRepeatPassword: !password.showRepeatPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const saveData = async () => {
        setOpenBackdrop(true);

        if (!password.password || !password.repeatPassword) {
            setTexto(t("register.requiredData"));
            setAlertType("error");
            setIsError(true);
            setShow(true);
        } else if (password.password !== password.repeatPassword) {
            setTexto(t("register.passwordError"));
            setAlertType("error");
            setIsError(true);
            setShow(true);
        } else {
            params.current_password = password.password;

            if (!existingEmail) {
                await userService
                    .updateUserProfile(params)
                    .then(() => {
                        setAlertType("success");
                        setTexto(t("register.success"));
                        setShow(true);
                        confirmSaveData();
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 401:
                                setTexto(t("register.invalidPass"));
                                setDisabledButton(true);
                                break;
                            case 404:
                                setTexto(t("register.error"));
                                break;
                            default:
                                setTexto(t("register.error"));
                                break;
                        }
                        setIsError(true);
                        setAlertType("error");
                        setShow(true);
                    });
            } else {
                setAlertType("error");
                setTexto(t("register.existingEmail"));
                setShow(true);
            }
        }

        setOpenBackdrop(false);
    };

    useEffect(() => {
        if (password.password && password.repeatPassword && password.password === password.repeatPassword) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [password.password, password.repeatPassword]);

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        borderRadius: 8,
                        p: { xs: 3, md: "16px 0px 0px 0px" },
                    },
                }}
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: "#4A22D4" }}>
                    <Typography fontStyle="normal" fontSize={16} fontWeight={600}>
                        {t("register.confirmPassword")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <GridUI item xs={12}>
                                <InputLabel class={classes.labels}>{t("register.insertPassword")}</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={password.showPassword ? "text" : "password"}
                                    name={"password"}
                                    onChange={handleChangePassword}
                                    value={password.password}
                                    error={
                                        isError &&
                                        (password.password === "" ||
                                            password.password !== password.repeatPassword ||
                                            texto === t("register.invalidPass"))
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                name={"showPassword"}
                                            >
                                                {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </GridUI>
                            <GridUI item xs={12} mt={2}>
                                <InputLabel class={classes.labels}>{t("register.confirmPass")}</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={password.showRepeatPassword ? "text" : "password"}
                                    name={"repeatPassword"}
                                    onChange={handleChangePassword}
                                    value={password.repeatPassword}
                                    error={
                                        isError &&
                                        (password.repeatPassword === "" ||
                                            password.password !== password.repeatPassword ||
                                            texto === t("register.invalidPass"))
                                            ? true
                                            : false
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                name={"showRepeatPassword"}
                                            >
                                                {password.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </GridUI>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                        <Typography
                            onClick={onClose}
                            sx={{
                                color: "#4A22D4",
                                fontWeight: "600",
                                mr: "auto",
                                mt: 1,
                                cursor: "pointer",
                            }}
                        >
                            {t("register.back")}
                        </Typography>
                        <BaseButton sizebutton="m" onClick={saveData} disabled={disabledButton}>
                            {t("register.saveChanges")}
                        </BaseButton>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DialogChangeEmail;
