import {
    Box,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Switch,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ReportProblemIcon from "@mui/icons-material/ReportProblemRounded";
import SearchIcon from "@mui/icons-material/Search";
import HelpIcon from "@mui/icons-material/Help";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import classes from "../MainConverter.module.css";
import { useEffect, useState } from "react";

import moment from "moment/moment";

import BaseSelect from "../../ui/BaseSelect";
import LightTooltip from "../../ui/LightTooltip";

import { fetchFileFromS3, getFileExtension, zeroPad } from "../../../utils/utils";
import BaseActionsPreviewFile from "../BaseActionsPreviewFile";
import BaseButton from "../../ui/BaseButton";
import { useGlobalContext } from "../../../context/GlobalContext";

// This line is important for the correct functioning of the library
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ConversionPreview = (props) => {
    const {
        handleClickDownload,
        removeBanner,
        account,
        accounts,
        correlative,
        handleClickHelp,
        fileData,
        __html,
        conversionData,
        checkedInvSigngs,
        setCheckedInvSigngs,
        checkedInvDay,
        setCheckedInvDay,
        fileNameFull,
        checkedDesRef,
        setCheckedDesRef,
        isHistory,
        groupedData,
        groupedPeriods,
        setIsLoading,
        setOpenModalGrouped,
        setRowsModal,
        setOpenModalGroupedHist,
        setModalType,
        setRows,
        setBasePreviewData,
        basePreviewData,
        integration
    } = props;

    const { t, todos, theme } = useGlobalContext()

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsConv, setRowsConv] = useState([]);
    const [accountPrev, setAccountPrev] = useState(0);
    const [conversionDataNew, setConversionDataNew] = useState(groupedData ?? conversionData);
    const [conversionFile, setConversionFile] = useState(fileData);
    const [conversionFileName, setConversionFileName] = useState(fileNameFull);
    const [actualDocIndex, setActualDocIndex] = useState(-1);

    const handleGoBack = () => {
        removeBanner();
        setRows([])
        setRowsModal([])
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const nextPage = () => {
        setPageNumber((prevNum) => prevNum + 1);
    };

    const lastPage = () => {
        setPageNumber(numPages);
    };

    const prevPage = () => {
        setPageNumber((prevNum) => prevNum - 1);
    };

    const firstPage = () => {
        setPageNumber(1);
    };

    const getFile = async (file_name) => {
        const data = await fetchFileFromS3(file_name);
        if (data) {
            setConversionFile(data);
        }
    }

    const handleChange = async (event) => {
        setIsLoading(true);
        let newData = {};
        let finalIndex;

        if (event.target.value !== 0) {
            finalIndex =  groupedData.conversions.findIndex(item => item._id === event.target.value)
            newData = groupedData.conversions.find((item) => item._id === event.target.value);
        } else {
            finalIndex = -1
            newData = groupedData.conversions[0];
        }

        setActualDocIndex(finalIndex);

        setConversionFileName(newData.uploaded_file);
        setPageNumber(1);

        await getFile(newData.uploaded_file_s3)

        setBasePreviewData({
            id: newData._id,
            type: "bankStatement",
        })

        if (event.target.value !== 0) {
            setConversionDataNew(newData);
            const newRows = [newData].map((item) => {
                let debit = 0;
                let credit = 0;
                let dateFormat = todos.dateFormat;
                let descr = item.description;
                let reference = item.reference;

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                if (item.amount < 0) {
                    if (checkedInvSigngs) {
                        credit = item.amount;
                    } else {
                        debit = item.amount;
                    }
                } else if (item.amount > 0) {
                    if (checkedInvSigngs) {
                        debit = item.amount;
                    } else {
                        credit = item.amount;
                    }
                }

                if (checkedDesRef) {
                    descr = item.reference;
                    reference = item.description;
                }

                return {
                    id: item._id,
                    date: moment.utc(item.date).format(dateFormat),
                    description: descr,
                    reference: reference,
                    debit: parseFloat(debit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    credit: parseFloat(credit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    balance: item.balance
                        ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : "N/A",
                };
            });
            setRowsConv(newRows);
        } else {
            setConversionDataNew(groupedData);
            const newRows = conversionDataNew.transactions.map((item) => {
                let debit = 0;
                let credit = 0;
                let dateFormat = todos.dateFormat;
                let descr = item.description;
                let reference = item.reference;

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                if (item.amount < 0) {
                    if (checkedInvSigngs) {
                        credit = item.amount;
                    } else {
                        debit = item.amount;
                    }
                } else if (item.amount > 0) {
                    if (checkedInvSigngs) {
                        debit = item.amount;
                    } else {
                        credit = item.amount;
                    }
                }

                if (checkedDesRef) {
                    descr = item.reference;
                    reference = item.description;
                }

                return {
                    id: item._id,
                    date: moment.utc(item.date).format(dateFormat),
                    description: descr,
                    reference: reference,
                    debit: parseFloat(debit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    credit: parseFloat(credit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    balance: item.balance
                        ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : "N/A",
                };
            });
            setRowsConv(newRows);
        }
        setAccountPrev(event.target.value);
        setIsLoading(false);
    };

    const accountsPrev = groupedData
        ? [{ id: 0, name: t("converter.groupedConv") }].concat(
              groupedData.conversions.map((item) => ({ id: item._id, name: item.uploaded_file }))
          )
        : [];

    const showConversions = () => {
        setModalType("bankStatement")
        setOpenModalGrouped(true);
        setOpenModalGroupedHist(true);
        const newRows = groupedData.conversions.map((item) => ({
            id: item._id,
            number: item.correlative,
            file_name: item.uploaded_file,
            file_name_s3: item.uploaded_file_s3,
            period:
                item.id_status !== 2 && item.id_status !== 3
                    ? "-"
                    : `${moment.utc(item.initial_date).format(todos.dateFormat)} - ${moment
                          .utc(item.end_date)
                          .format(todos.dateFormat)}`,
            status: item.id_status,
        }));
        setRowsModal(newRows);
    };

    const columns = [
        { name: "date", title: t("dashboard.dateUpload"), align: "center", minWidth: 160 },
        { name: "description", title: t("accounts.description"), align: "left" },
        // { name: "balance", title: "Balance", align: "right" },
    ];

    if (integration === 1) {
        columns.push({ name: "reference", title: t("accounts.ref"), align: "left" });
    }

    columns.push(
        { name: "debit", title: t("accounts.debit"), align: "right" },
        { name: "credit", title: t("accounts.credit"), align: "right" }
    );

    const success = groupedData?.conversions.filter((item) => item.id_status === 2 || item.id_status === 3).length;

    const revision = groupedData?.conversions.filter((item) => item.id_status === 4).length;

    const errConv = groupedData?.conversions.filter((item) => item.id_status === 5).length;

    const handleChangeSwitchInv = (event) => {
        setCheckedInvSigngs(event.target.checked);
    };

    const handleChangeSwitchInvDay = (event) => {
        setCheckedInvDay(event.target.checked);
    };

    const handleChangeSwitchDesRef = (event) => {
        setCheckedDesRef(event.target.checked);
    };

    const handleNextDoc = async (direction) => {
        setIsLoading(true);
        let newData = {}
        let finalAcc;
        if (actualDocIndex + direction === -1) {
            newData = groupedData.conversions[0];
            finalAcc = 0;
        } else {
            newData = groupedData.conversions[actualDocIndex + direction];
            finalAcc = newData._id
        }

        setActualDocIndex(actualDocIndex + direction);

        setConversionFileName(newData.uploaded_file);
        setPageNumber(1);

        await getFile(newData.uploaded_file_s3)

        setBasePreviewData({
            id: newData._id,
            type: "bankStatement",
        })
        
        if (actualDocIndex + direction !== -1) {
            setConversionDataNew(newData);
            const newRows = [newData].map((item) => {
                let debit = 0;
                let credit = 0;
                let dateFormat = todos.dateFormat;
                let descr = item.description;
                let reference = item.reference;

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                if (item.amount < 0) {
                    if (checkedInvSigngs) {
                        credit = item.amount;
                    } else {
                        debit = item.amount;
                    }
                } else if (item.amount > 0) {
                    if (checkedInvSigngs) {
                        debit = item.amount;
                    } else {
                        credit = item.amount;
                    }
                }

                if (checkedDesRef) {
                    descr = item.reference;
                    reference = item.description;
                }

                return {
                    id: item._id,
                    date: moment.utc(item.date).format(dateFormat),
                    description: descr,
                    reference: reference,
                    debit: parseFloat(debit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    credit: parseFloat(credit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    balance: item.balance
                        ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : "N/A",
                };
            });
            setRowsConv(newRows);
        } else {
            setConversionDataNew(groupedData);
            const newRows = conversionDataNew.transactions.map((item) => {
                let debit = 0;
                let credit = 0;
                let dateFormat = todos.dateFormat;
                let descr = item.description;
                let reference = item.reference;

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                if (item.amount < 0) {
                    if (checkedInvSigngs) {
                        credit = item.amount;
                    } else {
                        debit = item.amount;
                    }
                } else if (item.amount > 0) {
                    if (checkedInvSigngs) {
                        debit = item.amount;
                    } else {
                        credit = item.amount;
                    }
                }

                if (checkedDesRef) {
                    descr = item.reference;
                    reference = item.description;
                }

                return {
                    id: item._id,
                    date: moment.utc(item.date).format(dateFormat),
                    description: descr,
                    reference: reference,
                    debit: parseFloat(debit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    credit: parseFloat(credit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    balance: item.balance
                        ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : "N/A",
                };
            });
            setRowsConv(newRows);
        }

        setAccountPrev(finalAcc);

        setIsLoading(false);
    };

    useEffect(() => {
        if (conversionDataNew.transactions) {
            const newRows = conversionDataNew.transactions.map((item) => {
                let debit = 0;
                let credit = 0;
                let dateFormat = todos.dateFormat;
                let descr = item.description;
                let reference = item.reference;

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                if (item.amount < 0) {
                    if (checkedInvSigngs) {
                        credit = item.amount;
                    } else {
                        debit = item.amount;
                    }
                } else if (item.amount > 0) {
                    if (checkedInvSigngs) {
                        debit = item.amount;
                    } else {
                        credit = item.amount;
                    }
                }

                if (checkedDesRef) {
                    descr = item.reference;
                    reference = item.description;
                }

                return {
                    id: item._id,
                    date: moment.utc(item.date).format(dateFormat),
                    description: descr,
                    reference: reference,
                    debit: parseFloat(debit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    credit: parseFloat(credit).toLocaleString(todos.amountFormat, {
                        minimumFractionDigits: 2,
                    }),
                    balance: item.balance
                        ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : "N/A",
                };
            });
            setRowsConv(newRows);
        }
    }, [
        checkedInvDay,
        checkedInvSigngs,
        checkedDesRef,
        t,
        todos.dateFormat,
        todos.amountFormat,
        conversionDataNew.transactions,
    ]);

    useEffect(() => {
        setCheckedInvSigngs(conversionDataNew.switch_signs ?? false);
        setCheckedInvDay(conversionDataNew.switch_day_month ?? false);
        setCheckedDesRef(conversionDataNew.switch_reverse ?? false);
    }, [
        conversionDataNew.switch_day_month,
        conversionDataNew.switch_signs,
        conversionDataNew.switch_reverse,
        setCheckedInvDay,
        setCheckedInvSigngs,
        setCheckedDesRef,
    ]);

    const debitCount = checkedInvSigngs ? conversionDataNew.credits_count : conversionDataNew.debits_count;
    const creditCount = checkedInvSigngs ? conversionDataNew.debits_count : conversionDataNew.credits_count;

    return (
        <Container maxWidth="xl">
            <Stack direction="row" justifyContent="space-between" pt={5}>
                <BaseButton variantType="primaryOutlined" onClick={handleGoBack} sizebutton="l">
                    {isHistory ? t("inbox.goBackList") : t("converter.goBackConver")}
                </BaseButton>
                {(!groupedData || (groupedData && accountPrev !== 0)) && (
                    <Typography gutterBottom>
                        <BaseButton variantType="errorText" onClick={() => handleClickHelp("conversion", "bankStatement", conversionDataNew._id)}>{t("accounts.needHelp")}</BaseButton>
                    </Typography>
                )}
            </Stack>
            <Typography variant="h2" pt={2} gutterBottom ml={0.5}>
                {`${accounts.find((item) => item.id === account)?.name} > `}
                <Typography variant="h2" component="span" color="primary" >
                    {groupedData 
                        ? "GROUP-" + zeroPad(groupedData.correlative ?? 0, 5)
                        : `${t("subscription.conversion")} #${zeroPad(correlative, 5)}`}
                </Typography>
            </Typography>
            {groupedData && (
                <Grid container spacing={3} mt={0.2}>
                    <Grid item xs={12} md={5}>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ backgroundColor: "white", p: 2, borderRadius: 4, mb: 1 }}
                        >
                            <Box sx={{ width: 250 }}>
                                <BaseSelect
                                    value={accountPrev}
                                    values={accountsPrev}
                                    onChange={handleChange}
                                    label={t("dashboard.accState")}
                                ></BaseSelect>
                            </Box>
                            <Box sx={{ textAlign: "right" }}>
                                <Typography variant="h3" gutterBottom>
                                    {groupedData.conversions.length} {t("converter.bankState")}
                                </Typography>
                                <Typography>
                                    #{zeroPad(Math.min(...groupedData.conversions.map((item) => item.correlative)), 5)} - #
                                    {zeroPad(Math.max(...groupedData.conversions.map((item) => item.correlative)), 5)}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box className={classes.backWhiteDet}>
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Box>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h3">{t("converter.conversionStatus")}</Typography>
                                        <LightTooltip title={t("miscellaneous.toolHelpConvDet")}>
                                            <HelpIcon fontSize="small" color="primary" />
                                        </LightTooltip>
                                    </Stack>
                                    <Grid container spacing={2} sx={{ pl: 2 }}>
                                        {success > 0 && (
                                            <Grid item>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography>{success}</Typography>
                                                    <CheckIcon fontSize="small" color="success" />
                                                </Stack>
                                            </Grid>
                                        )}
                                        {revision > 0 && (
                                            <Grid item>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography>{revision}</Typography>
                                                    <AccessTimeIcon fontSize="small" color="warning" />
                                                </Stack>
                                            </Grid>
                                        )}
                                        {errConv > 0 && (
                                            <Grid item>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography>{errConv}</Typography>
                                                    <CloseIcon fontSize="small" color="error" />
                                                </Stack>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                                <BaseButton
                                    endIcon={<SearchIcon style={{ width: "27px", height: "27px" }} />}
                                    onClick={showConversions}
                                    variantType="convertOutlinedButton"
                                >
                                    {t("converter.seeDetails")}
                                </BaseButton>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={3} pb={3}>
                <Grid item xs={12} md={5}>
                    {(conversionFile || groupedData) && (
                        <Box
                            sx={{
                                height: 650,
                                overflow: "auto",

                                "&::-webkit-bar": {
                                    width: "10px",
                                    height: "10px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    boxShadow: "inset 10px 10px 15px #c7c9d1",
                                    borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#404963",
                                    borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#031851",
                                },
                            }}
                        >
                            {getFileExtension(
                                conversionFileName
                                    ? conversionFileName?.toLowerCase()
                                    : conversionFile.path?.toLowerCase()
                            ) === "pdf" ? (
                                <>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                        sx={{ bgcolor: "#FFF" }}
                                    >
                                        <IconButton disabled={pageNumber === 1} onClick={firstPage}>
                                            <FirstPageIcon
                                                sx={{ color: pageNumber === 1 ? "rgba(0, 0, 0, 0.26)" : theme.palette.primary.main }}
                                            />
                                        </IconButton>
                                        <IconButton disabled={pageNumber === 1} onClick={prevPage}>
                                            <ArrowBackIcon
                                                sx={{ color: pageNumber === 1 ? "rgba(0, 0, 0, 0.26)" : theme.palette.primary.main }}
                                            />
                                        </IconButton>
                                        <Typography>
                                            {pageNumber} - {numPages}
                                        </Typography>
                                        <IconButton disabled={pageNumber === numPages} onClick={nextPage}>
                                            <ArrowForwardIcon
                                                sx={{
                                                    color: pageNumber === numPages ? "rgba(0, 0, 0, 0.26)" : theme.palette.primary.main,
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton disabled={pageNumber === numPages} onClick={lastPage}>
                                            <LastPageIcon
                                                sx={{
                                                    color: pageNumber === numPages ? "rgba(0, 0, 0, 0.26)" : theme.palette.primary.main,
                                                }}
                                            />
                                        </IconButton>
                                    </Stack>
                                    <Document file={conversionFile} onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                </>
                            ) : getFileExtension(conversionFileName ? conversionFileName : conversionFile.path) ===
                                  "xls" ||
                              getFileExtension(conversionFileName ? conversionFileName : conversionFile.path) ===
                                  "xlsx" ? (
                                __html ? (
                                    <Box className={classes.workbookContainer} dangerouslySetInnerHTML={{ __html }} />
                                ) : (
                                    <Box
                                        className={classes.workbookContainer}
                                        sx={{ bgcolor: "#CCC", maxHeight: 1000 }}
                                        justifyContent="center"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {t("converter.cannotPrev")}
                                    </Box>
                                )
                            ) : (
                                <Box
                                    className={classes.workbookContainer}
                                    sx={{ bgcolor: "#CCC", maxHeight: 1000 }}
                                    justifyContent="center"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {t("converter.cannotPrev")}
                                </Box>
                            )}
                        </Box>
                    )}
                    <Box sx={{bgcolor: "#FFF", borderRadius: 2, display: "flex", justifyContent: "end", px: 2}}>
                        <BaseActionsPreviewFile basePreviewData={basePreviewData} />
                    </Box>
                    {groupedData && (
                        <Stack direction="row" spacing={1} justifyContent="center">
                            <BaseButton
                                endIcon={<ArrowBackIcon />}
                                sx={{ color: actualDocIndex === -1 ? "rgba(0, 0, 0, 0.26)" : "#131f3e" }}
                                disabled={actualDocIndex === -1}
                                onClick={() => handleNextDoc(-1)}
                                variantType="primaryTextButton"
                            >
                                {t("converter.previous")}
                            </BaseButton>
                            <BaseButton
                                startIcon={<ArrowForwardIcon />}
                                sx={{
                                    color:
                                        actualDocIndex === groupedData.conversions.length - 1
                                            ? "rgba(0, 0, 0, 0.26)"
                                            : "#131f3e",
                                }}
                                disabled={actualDocIndex === groupedData.conversions.length - 1}
                                onClick={() => handleNextDoc(1)}
                                variantType="primaryTextButton"
                            >
                                {t("miscellaneous.next")}
                            </BaseButton>
                        </Stack>
                    )}
                </Grid>
                <Grid item xs={12} md={7} mt={2}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" color="primary" gutterBottom>
                                {t("converter.resumeConv")}
                            </Typography>
                            {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                <Typography gutterBottom>
                                    <b>{t("converter.file")}:</b> {conversionDataNew.uploaded_file}
                                </Typography>
                            )}
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 0.5 }}>
                                <Typography>
                                    <b>{t("payment.period")}:</b>{" "}
                                    {groupedData &&
                                    accountPrev !== 0 &&
                                    conversionDataNew.id_status !== 6 &&
                                    conversionDataNew.id_status !== 1
                                        ? "N/A"
                                        : `${moment
                                              .utc(
                                                  accountPrev === 0 && groupedData
                                                      ? groupedPeriods[0].initial_date
                                                      : conversionDataNew.initial_date
                                              )
                                              .format(todos.dateFormat)} - ${moment
                                              .utc(
                                                  accountPrev === 0 && groupedData
                                                      ? groupedPeriods[groupedPeriods.length - 1].end_date
                                                      : conversionDataNew.end_date
                                              )
                                              .format(todos.dateFormat)}`}
                                </Typography>
                                {groupedData?.missing_period && accountPrev === 0 && (
                                    <LightTooltip title={t("converter.toolPeriods")}>
                                        <ReportProblemIcon color="error" fontSize="small" />
                                    </LightTooltip>
                                )}
                            </Stack>
                            <Typography gutterBottom>
                                <b>{t("converter.initAmount")}:</b>{" "}
                                {conversionDataNew.large_conversion && conversionDataNew.large_conversion === true
                                    ? "N/A"
                                    : conversionDataNew.initial_balance
                                    ? checkedInvSigngs
                                        ? -1 * conversionDataNew.initial_balance
                                        : conversionDataNew.initial_balance
                                    : "N/A"}
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("converter.totalDebit")}:</b>{" "}
                                {conversionDataNew.large_conversion && conversionDataNew.large_conversion === true
                                    ? "N/A"
                                    : `${parseFloat(
                                          checkedInvSigngs
                                              ? conversionDataNew.credits_total
                                              : conversionDataNew.debits_total
                                      ).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })} (${debitCount} ${
                                          debitCount === 1 ? t("dashboard.transaccOne") : t("dashboard.transacc")
                                      })`}
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("converter.totalCredit")}:</b>{" "}
                                {conversionDataNew.large_conversion && conversionDataNew.large_conversion === true
                                    ? "N/A"
                                    : `${parseFloat(
                                          checkedInvSigngs
                                              ? conversionDataNew.debits_total
                                              : conversionDataNew.credits_total
                                      ).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })} (${creditCount} ${
                                          creditCount === 1 ? t("dashboard.transaccOne") : t("dashboard.transacc")
                                      })`}
                            </Typography>
                            <Typography>
                                <b>{t("converter.balance")}:</b>{" "}
                                {(conversionDataNew.large_conversion && conversionDataNew.large_conversion === true) ||
                                conversionDataNew.balance === null ||
                                conversionDataNew.balance === "" ||
                                (groupedData &&
                                    accountPrev !== 0 &&
                                    conversionDataNew.id_status !== 6 &&
                                    conversionDataNew.id_status !== 1)
                                    ? "N/A"
                                    : parseFloat(
                                          checkedInvSigngs ? -1 * conversionDataNew.balance : conversionDataNew.balance
                                      ).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" color="primary" gutterBottom>
                                {t("converter.transform")}
                            </Typography>
                            <LightTooltip title={groupedData && accountPrev !== 0 ? t("converter.tooltSwitch") : ""}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={checkedInvSigngs}
                                                onChange={handleChangeSwitchInv}
                                                disabled={groupedData && accountPrev !== 0}
                                            />
                                        }
                                        label={t("converter.invertSings")}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={checkedInvDay}
                                                onChange={handleChangeSwitchInvDay}
                                                disabled={groupedData && accountPrev !== 0}
                                            />
                                        }
                                        label={t("converter.invertDayMonth")}
                                    />
                                    {(integration === 1) && (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checkedDesRef}
                                                    disabled={groupedData && accountPrev !== 0}
                                                />
                                            }
                                            onChange={handleChangeSwitchDesRef}
                                            label={t("converter.descrAndReference")}
                                        />
                                    )}
                                </FormGroup>
                            </LightTooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" color="primary" gutterBottom>
                                {t("converter.preview")}
                            </Typography>
                            {conversionDataNew.large_conversion && conversionDataNew.large_conversion === true ? (
                                <Box mt={2}>{t("converter.largeConvMsg")}</Box>
                            ) : (
                                <>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    {columns.map((item) => (
                                                        <StyledTableCell
                                                            key={item.name}
                                                            align={item.align ?? "left"}
                                                            style={{ minWidth: item.minWidth }}
                                                        >
                                                            {item.title}
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsConv.map((row) => (
                                                    <StyledTableRow
                                                        key={row.id}
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                    >
                                                        <StyledTableCell align="center">{row.date}</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.description}
                                                        </StyledTableCell>
                                                        {(integration === 1) && (
                                                            <StyledTableCell align="left">
                                                                {row.reference}
                                                            </StyledTableCell>
                                                        )}
                                                        <StyledTableCell align="right">{row.debit}</StyledTableCell>
                                                        <StyledTableCell align="right">{row.credit}</StyledTableCell>
                                                        {/* <StyledTableCell align="right">{row.balance}</StyledTableCell> */}
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant="h3" pt={1}>
                                        {conversionDataNew.transactions_count} {t("converter.totalTrans")}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item>
                            <BaseButton
                                disableElevation
                                endIcon={<DownloadIcon />}
                                onClick={handleClickDownload}
                                sizebutton="m"
                            >
                                {t("payment.download")}
                            </BaseButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ConversionPreview;
