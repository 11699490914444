/* eslint-disable react-hooks/exhaustive-deps */
/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableBandHeader,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    GroupingState,
    IntegratedGrouping,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import { Box, Chip, FormControlLabel, Link, Stack, Switch, Typography, Skeleton, IconButton } from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import DashboardHeader from "./DashboardHeader";
import GridUI from "@mui/material/Grid";
import FiltersButton from "./FiltersButton";
import DashboardBreadcrumbs from "./DashboardBreadcrumbs";
import SimpleDialog from "../ui/SimpleDialog";

import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import DownloadIcon from "@mui/icons-material/Download";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";
import { addCompanyID, addCredits, addFranchiseID, addIsGrouped, addSubscription } from "../../redux/userSlice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyCredits } from "../../utils/utils";
import companyService from "../../services/user_company";
import BaseButton from "../ui/BaseButton";

const PREFIX = "Banking";

const classes = {
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
    tableCustom: `${PREFIX}-tableCustom`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontWeight: 600,
        border: "none !important",
        paddingBottom: 0,
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "white",
        fontSize: window.innerWidth > 1440 ? 15 : 14,
        fontWeight: 600,
        color: "#131F3E !important",
    },

    [`& .${classes.tableBody}`]: {
        backgroundColor: "#F8F7F9",
        borderBottom: "5px solid white",
        fontSize: window.innerWidth > 1440 ? 14 : 12,
        fontWeight: 400,
        color: "#361A52",
        whiteSpace: "normal !important",
    },

    [`& .${classes.tableCustom}`]: {
        minWidth: "1600px !important",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#361A52",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "#D8D3E5",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
        width: "100%",
    },

    [`& .${classes.searchInput}`]: {
        color: "#131F3E",
        // backgroundColor: "#f2f2f2",
        borderRadius: 6,
        borderBottom: "none",
        border: "1px solid #D8D3E5",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "20px",
        paddingRight: "40px",
        marginBottom: "45px",
        height: "42px",
        "&:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        "&:hover:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        width: "100%",
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

// const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const TableCellComponent = ({ column, row, ...props }) => {
    if (column.name === "account" && row.accountComponent) {
        return (
            <Table.Cell {...props} className={classes.tableBody}>
                {row.accountComponent}
            </Table.Cell>
        );
    }

    if (column.name === "currency" && row.currencyComponent) {
        return (
            <Table.Cell {...props} className={classes.tableBody}>
                {row.currencyComponent}
            </Table.Cell>
        );
    }

    return <Table.Cell {...props} className={classes.tableBody} />;
};

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableCustom} />;

const GroupCellComponent = ({ ...props }) => <TableGroupRow.Cell {...props} className={classes.tableBody} />;

const GroupCellContent = ({ row }) =>
    !row.value.includes("groupKipperSkeleton") ? (
        row.value.includes(">") ? (
            <span>
                {row.value.split(">")[0]}
                {">"}
                <span>{row.value.split(">")[1]}</span>
            </span>
        ) : (
            <span>{row.value}</span>
        )
    ) : (
        <Skeleton variant="text" animation="wave" width={210} sx={{ display: "inline-block" }} />
    );
const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const tableBandCellBase = ({ ...props }) => (
    <TableBandHeader.Cell {...props} className={classes.tableHeaderBand}>
        {props.column.title === " " ? (
            ""
        ) : (
            <Chip
                label={props.column.title}
                sx={{
                    width: "100%",
                    backgroundColor: "#E9E6EC",
                    color: "#361A52",
                    borderEndEndRadius: 0,
                    borderEndStartRadius: 0,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    fontSize: window.innerWidth > 1440 ? 16 : 14,
                    p: 2.5,
                }}
            />
        )}
    </TableBandHeader.Cell>
);

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        smb: `${i}-groupKipperSkeleton`,
        subscription: `${i}-groupKipperSkeleton`,
        account: <Skeleton variant="text" animation="wave" />,
        currency: <Skeleton variant="text" animation="wave" />,
        initDate: <Skeleton variant="text" animation="wave" />,
        endDate: <Skeleton variant="text" animation="wave" />,
        lastTrans: <Skeleton variant="text" animation="wave" />,
        latestBalance: <Skeleton variant="text" animation="wave" />,
        bankSys: <Skeleton variant="text" animation="wave" />,
        bookSys: <Skeleton variant="text" animation="wave" />,
        pendinAct: <Skeleton variant="text" animation="wave" />,
        updateAcc: <Skeleton variant="text" animation="wave" />,
    });
}

const DashboardTable = (props) => {
    const {
        integration,
        grupal,
        setXeroView,
        xeroView,
        franchise,
        companyInfo,
        data,
        type,
        setDashboardInfoXero,
        setDashboardInfo,
        franchiseInfo,
        companiesFilters,
        company,
        checkGrouped,
        showSkeleton,
        setShowSkeleton,
        history,
        setHistory,
        setConversions,
        setUsersFilters,
        setAccounts,
        finalBanks,
        setFinalBanks,
        banksFilters,
        downloadXeroDoc,
        downloadXeroDocRev,
        handleClickRefresh,
        handleClickRefreshAcc,
        setIsLoading,
    } = props;

    const [pageSize, setPageSize] = useState(15);
    const [pageSizes] = useState([15, 30, 50]);
    const [currentPage, setCurrentPage] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [actionText, setActionText] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [t, i18n] = useTranslation("global");
    const [language, setLanguage] = useState(sessionStorage.getItem("lng") ?? "en");

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    const [searchValue, setSearchState] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const columnsSmb = [
        { name: "account", title: t("converter.account") },
        { name: "currency", title: " " },
        { name: "initDate", title: t("filters.since2") },
        { name: "endDate", title: t("filters.until") },
        { name: "lastTrans", title: t("dashboard.lastTransacc") },
        { name: "latestBalance", title: t("dashboard.accState") },
        { name: "bankSys", title: t("dashboard.bankSys") },
        { name: "bookSys", title: t("dashboard.bookSys") },
        { name: "pendinAct", title: t("dashboard.pendingAct") },
        { name: "updateAcc", title: " " },
    ];

    const columnsFirm = [{ name: "smb", title: " " }, ...columnsSmb];

    let columns = type === "Firm" && !grupal ? columnsFirm : columnsSmb;

    columns = grupal ? [{ name: "subscription", title: " " }, ...columns] : columns;

    const tableColumnExtensions = [
        { columnName: "account", width: "15%" },
        { columnName: "currency", width: "5%", align: "center" },
        { columnName: "initDate", width: "8%", align: "center" },
        { columnName: "endDate", width: "8%", align: "center" },
        { columnName: "lastTrans", align: "center" },
        { columnName: "latestBalance", align: "right" },
        { columnName: "bankSys" },
        { columnName: "bookSys" },
        { columnName: "pendinAct", width: "18%" },
        { columnName: "updateAcc", width: "5%", align: "center" },
    ];

    const tableContentComponent = ({ column, children, ...props }) => (
        <TableHeaderRow.Content {...props}>
            {children}
            {column.name !== "account" && column.name !== "currency" && column.name !== "updateAcc" && (
                <LightTooltip
                    title={
                        column.name === "initDate"
                            ? t("dashboard.tooltInit")
                            : column.name === "endDate"
                            ? t("dashboard.tooltEnd")
                            : column.name === "lastTrans"
                            ? t("dashboard.tooltLastTran")
                            : column.name === "latestBalance"
                            ? t("dashboard.toolAccState")
                            : column.name === "bankSys"
                            ? t("dashboard.tooltSysBank")
                            : column.name === "bookSys"
                            ? t("dashboard.tooltSysBooks")
                            : t("dashboard.tooltPendAcc")
                    }
                >
                    <InfoIcon
                        sx={{
                            height: 20,
                            width: 20,
                            ml: 1,
                        }}
                    />
                </LightTooltip>
            )}
        </TableHeaderRow.Content>
    );

    const columnBandsSmb = [
        {
            title: " ",
            children: [{ columnName: "account" }],
        },
        {
            title: " ",
            children: [{ columnName: "currency" }],
        },
        {
            title: t("dashboard.balanceValidations"),
            children: [{ columnName: "initDate" }, { columnName: "endDate" }, { columnName: "lastTrans" }],
        },
        {
            title: t("dashboard.latestBalance"),
            children: [{ columnName: "bankSys" }, { columnName: "bookSys" }, { columnName: "latestBalance" }],
        },
        {
            title: t("dashboard.actions"),
            children: [{ columnName: "pendinAct" }, { columnName: "updateAcc" }],
        },
    ];

    const columnBandsFirm = [
        {
            title: " ",
            children: [{ columnName: "subscription" }],
        },
        {
            title: " ",
            children: [{ columnName: "smb" }],
        },
        ...columnBandsSmb,
    ];

    const columnBands = grupal || type === "Firm" ? columnBandsFirm : columnBandsSmb;

    const tableMessages = {
        noData: <Typography fontSize={14}>{t("dashboard.noInfo")}</Typography>,
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("dashboard.pagingOf")} ${count}`,
        rowsPerPage: t("dashboard.rowsPerPage"),
    };

    const searchMessages = {
        searchPlaceholder: t("dashboard.search"),
    };

    const Root = useCallback(
        (props) => {
            const handleDashboardSwitch = () => {
                dispatch(addIsGrouped(!todos.isGrouped));
            };

            return (
                <>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent={{ xs: "center", sm: "space-between" }}
                        spacing={2}
                    >
                        <Box>
                            <DashboardHeader
                                type={type}
                                integration={integration}
                                setXeroView={setXeroView}
                                xeroView={xeroView}
                                franchise={franchise}
                                company={company}
                                setDashboardInfoXero={setDashboardInfoXero}
                                setShowSkeleton={setShowSkeleton}
                                setDashboardInfo={setDashboardInfo}
                                grupal={grupal}
                                history={history}
                                setHistory={setHistory}
                                setConversions={setConversions}
                                setUsersFilters={setUsersFilters}
                                setAccounts={setAccounts}
                                franchiseInfo={franchiseInfo}
                                setFinalBanks={setFinalBanks}
                                banksFilters={banksFilters}
                                companyInfo={companyInfo}
                            />
                        </Box>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            alignItems={{ xs: "center", sm: "start" }}
                            spacing={{ xs: 2, sm: "25px" }}
                        >
                            {todos?.allComData?.filter((x) => x.company_name).length > 1 && (
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={<Switch checked={checkGrouped} onChange={handleDashboardSwitch} />}
                                    sx={{ pt: 1 }}
                                    label={
                                        <Typography variant="h3" noWrap>
                                            {t("navBar.grupalDashboard")}
                                        </Typography>
                                    }
                                />
                            )}
                            <Box sx={{ width: { xs: "100%", sm: "160px" } }}>
                                <FiltersButton
                                    banks={finalBanks}
                                    setShowSkeleton={setShowSkeleton}
                                    franchise={franchise}
                                    company={company}
                                    setDashboard={setDashboardInfo}
                                    grupal={grupal}
                                    companiesFilters={companiesFilters}
                                    showSkeleton={showSkeleton}
                                />
                            </Box>
                            <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                                <Toolbar.Root {...props} className={classes.toolbarSearch} />
                            </Box>
                        </Stack>
                    </Stack>
                    {(integration === 1 || integration === 2) && (
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                            <IconButton onClick={handleClickRefresh} size="small">
                                <AutorenewIcon sx={{ transform: "rotate(90deg)", color: "#361A52" }} />
                            </IconButton>
                            <Typography variant="h3">{t("miscellaneous.updateAll")}</Typography>
                        </Stack>
                    )}
                </>
            );
        },
        [
            finalBanks,
            checkGrouped,
            companiesFilters,
            company,
            franchise,
            grupal,
            history,
            integration,
            t,
            todos?.allComData,
            todos.isGrouped,
            type,
            xeroView,
        ]
    );

    const handleOpenModal = (val, item) => {
        setActionText({
            action: val,
            data: item,
        });
        setOpenModal(true);
    };

    useEffect(() => {
        if (showSkeleton) {
            setRows(rowsSkeleton);
        }
    }, [showSkeleton]);

    useEffect(() => {
        const goToConvert = async (id, company) => {
            setIsLoading(true);
            await companyService
                .getBusinessDirectoryDetails({ company_id: company._id })
                .then(async (data) => {
                    dispatch(
                        addSubscription({
                            value: data.id_status,
                            last_value: data.id_last_status,
                            plan: data.subscription.subscription_plan,
                        })
                    );

                    const credits = await getCompanyCredits(company._id);
                    dispatch(addCredits(credits));

                    dispatch(addIsGrouped(false));
                    dispatch(addFranchiseID(company.franchise_id));
                    dispatch(addCompanyID(company._id));

                    navigate("/convert", { state: { account_id: id } });
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        };

        setRows(
            data.map((item) => ({
                id: item._id,
                account: item.account_name,
                accountComponent: (
                    <Link
                        onClick={() => goToConvert(item._id, item.company_id)}
                        sx={{ color: "C090", fontWeight: 600, cursor: "pointer", textDecoration: "none" }}
                    >
                        {item.account_name}
                    </Link>
                ),
                subscription: `${item.franchise_name} > ${item.smb_name}`,
                smb: item.smb_name,
                currency: item.currency_name,
                currencyComponent: (
                    <Typography fontSize={12} fontWeight={600}>
                        {item.currency_name}
                    </Typography>
                ),
                initDate: item.last_conversion_initial_date
                    ? moment(item.last_conversion_initial_date).format(todos.dateFormat)
                    : "-",
                endDate: item.last_conversion_end_date
                    ? moment(item.last_conversion_end_date).format(todos.dateFormat)
                    : "-",
                lastTrans: item.last_transaction_date
                    ? moment(item.last_transaction_date).format(todos.dateFormat)
                    : "-",
                latestBalance: item.last_balance
                    ? parseFloat(item.last_balance).toLocaleString(todos.amountFormat, {
                          minimumFractionDigits: 2,
                      })
                    : parseFloat(0).toLocaleString(todos.amountFormat, {
                          minimumFractionDigits: 2,
                      }),
                bookSys:
                    item.system_integration === 3
                        ? t("dashboard.notConnec")
                        : item.accounting_balance_system
                        ? parseFloat(item.accounting_balance_system).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : parseFloat(0).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          }),
                bankSys:
                    item.system_integration === 3
                        ? t("dashboard.notConnec")
                        : item.system_integration === 2
                        ? t("dashboard.unavailable")
                        : item.bank_balance_system
                        ? parseFloat(item.bank_balance_system).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : parseFloat(0).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          }),
                pendinAct:
                    item.pending_update || item.pending_review || item.pending_reconcile ? (
                        <Stack direction="row" spacing={1}>
                            {item.pending_update && (
                                <LightTooltip title={t("dashboard.tooltUpd")}>
                                    <Chip
                                        label={t("dashboard.updateTable")}
                                        sx={{
                                            backgroundColor: "#DCCFFF",
                                            "&:hover": { backgroundColor: "#DFC2CE" },
                                            borderRadius: 6,
                                            fontSize: 14,
                                        }}
                                        onClick={() => handleOpenModal(1, item)}
                                    />
                                </LightTooltip>
                            )}
                            {item.pending_review && (
                                <LightTooltip title={t("dashboard.tooltRev")}>
                                    <Chip
                                        label={t("dashboard.revisiTable")}
                                        sx={{
                                            backgroundColor: "#FFD9C6",
                                            "&:hover": { backgroundColor: "#e3c5a3" },
                                            borderRadius: 6,
                                            fontSize: 14,
                                        }}
                                        onClick={() => handleOpenModal(2, item)}
                                    />
                                </LightTooltip>
                            )}
                            {item.pending_reconcile && (
                                <LightTooltip title={t("dashboard.tooltCon")}>
                                    <Chip
                                        label={t("dashboard.conciliate")}
                                        sx={{
                                            backgroundColor: "#D8F5EE",
                                            "&:hover": { backgroundColor: "#a4cad2" },
                                            borderRadius: 6,
                                            fontSize: 14,
                                        }}
                                        onClick={() => handleOpenModal(3, item)}
                                    />
                                </LightTooltip>
                            )}
                        </Stack>
                    ) : (
                        t("dashboard.noActions")
                    ),
                updateAcc:
                    item.system_integration !== 3 ? (
                        <IconButton
                            color="primary"
                            onClick={() => handleClickRefreshAcc(item._id)}
                            size="small"
                            disabled={item.updating}
                        >
                            <AutorenewIcon sx={{ transform: "rotate(90deg)" }} />
                        </IconButton>
                    ) : null,
            }))
        );
    }, [data, dispatch, navigate, todos.dateFormat, todos.amountFormat, language]);

    const downloadDoc = (item) => {
        switch (item.action) {
            case 1:
                // Actualizar
                downloadXeroDoc(item.data);
                break;
            default:
                // Revisar
                downloadXeroDocRev(item.data);
                break;
        }
    };

    const actionsContent = (
        <GridUI container spacing={2} alignItems="center" justifyContent="center">
            <GridUI item>
                <Stack direction="row" spacing={1} alignItems="center">
                    <WarningIcon color="warning" />
                    <Typography variant="h2" color="primary">
                        {t("dashboard.actionsOf")}
                        {actionText.action === 2
                            ? t("dashboard.revi")
                            : actionText.action === 3
                            ? t("dashboard.conci")
                            : t("dashboard.update")}
                        {t("dashboard.toComplete")}
                    </Typography>
                </Stack>
            </GridUI>
            <GridUI item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Typography fontWeight={600} color="primary">
                        {actionText.action === 3
                            ? t("dashboard.existPendCon")
                            : actionText.action === 1
                            ? actionText.data?.pending_update_count +
                              (actionText.data?.pending_update_count === 1
                                  ? t("dashboard.existPendImpSing")
                                  : t("dashboard.existPendImp"))
                            : actionText.data?.pending_review_count +
                              (actionText.data?.pending_update_count === 1
                                  ? t("dashboard.reviTextMainSing")
                                  : t("dashboard.reviTextMain"))}
                    </Typography>
                    {actionText.action !== 3 && (
                        <IconButton color="primary" onClick={() => downloadDoc(actionText)}>
                            <DownloadIcon />
                        </IconButton>
                    )}
                </Stack>
                <Typography>
                    {actionText.action === 2
                        ? t("dashboard.reviText")
                        : actionText.action === 3
                        ? `${t("dashboard.conText")} ${actionText.data.currency_name} ${parseFloat(
                              actionText.data?.accounting_balance_system - actionText.data?.bank_balance_system
                          ).toLocaleString(todos.amountFormat)} ${t("dashboard.conText2")}`
                        : t("dashboard.updateText")}
                </Typography>
                {actionText.action === 2 && actionText.data?.pending_review_frequency && (
                    <>
                        <Typography fontWeight={600} color="primary" sx={{ pt: 1.5 }}>
                            {t("dashboard.pendReviewFreq")}
                        </Typography>
                        <Typography>{`${t("dashboard.pendReviewFreqText")} ${
                            actionText.data.pending_review_frequency_count
                        } ${t("dashboard.pendReviewFreqText2")}`}</Typography>
                    </>
                )}
            </GridUI>
            <GridUI item xs={12}>
                <BaseButton variantType="primaryText" onClick={handleCloseModal}>
                    {t("register.back")}
                </BaseButton>
            </GridUI>
        </GridUI>
    );

    return (
        <>
            {franchise && companyInfo && !grupal && (
                <Box sx={{ px: { xs: 2, md: 5 }, mt: 5 }}>
                    <DashboardBreadcrumbs company={companyInfo} franchise={franchiseInfo} isGrouped={grupal} />
                </Box>
            )}
            <StyledContainer
                component="main"
                sx={{
                    flexGrow: 1,
                    px: { xs: 2, md: 5 },
                    py: franchise && companyInfo && !grupal ? 2 : 6,
                    width: "100%",
                }}
            >
                <GridUI
                    container
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        backgroundColor: "white",
                        px: 4,
                        py: 3,
                    }}
                >
                    <GridUI item xs={12}>
                        <Grid rows={rows} columns={columns}>
                            <SearchState value={searchValue} onValueChange={handleSearch} />
                            <IntegratedFiltering />
                            {(grupal || type === "Firm") && (
                                <GroupingState grouping={[{ columnName: grupal ? "subscription" : "smb" }]} />
                            )}
                            {(grupal || type === "Firm") && <IntegratedGrouping />}
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={setCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={setPageSize}
                            />
                            <IntegratedPaging />
                            <Table
                                columnExtensions={tableColumnExtensions}
                                cellComponent={TableCellComponent}
                                tableComponent={TableComponent}
                                messages={tableMessages}
                            />
                            <TableHeaderRow
                                cellComponent={tableHeaderCell}
                                contentComponent={tableContentComponent}
                                sx={{ textAlign: "center !important" }}
                            />
                            {(grupal || type === "Firm") && (
                                <TableGroupRow cellComponent={GroupCellComponent} contentComponent={GroupCellContent} />
                            )}
                            <TableBandHeader cellComponent={tableBandCellBase} columnBands={columnBands} />
                            <Toolbar rootComponent={Root} />
                            <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                            {rows.length > 0 && (
                                <PagingPanel
                                    containerComponent={PagingPanelContainer}
                                    messages={pagingMessages}
                                    pageSizes={pageSizes}
                                />
                            )}
                        </Grid>
                    </GridUI>
                </GridUI>
                <SimpleDialog open={openModal} handleClose={handleCloseModal} maxWidth="sm" content={actionsContent} />
            </StyledContainer>
        </>
    );
};

export default DashboardTable;
