import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BackKiiper from "../components/layout/BackKiiper";
import BaseCard from "../components/ui/BaseCard";
import { Alert, Grid, Paper, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BaseButton from "../components/ui/BaseButton";

const handleClick = () => {
    window.location.href = "/logout";
};

const ExpiredInvitation = (props) => {
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");
    const [searchParams] = useSearchParams();

    const isAccepted = searchParams.has("accepted");
    const isCancelled = searchParams.has("cancelled");
    const isFullUsers = searchParams.has("fullUsers");
    const language = searchParams.get("language");

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [i18n, language]);

    return (
        <BackKiiper>
            <BaseCard>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="warning" sx={{ justifyContent: "center" }}>
                            {isAccepted ? t("login.invitAccep") : isCancelled ? t("login.invitCancelled") : isFullUsers ? t("team.invitFullUsers") : t("team.expiredLink")}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0} sx={{ textAlign: "center", pr: 2, pl: 2 }}>
                            <Typography>{isAccepted ? t("login.inviteText") : isCancelled ? t("login.invitCancelledText") : isFullUsers ? t("team.invitFullUsersText") : t("team.expiredInvMsg")}</Typography>
                        </Paper>
                    </Grid>
                    {isAccepted ?
                        <Grid item>
                            <BaseButton fullWidth onClick={handleClick}>
                                {t("login.loginText")}
                            </BaseButton>
                        </Grid> :
                        <></>
                    }
                </Grid>
            </BaseCard>
        </BackKiiper>
    );
};

export default ExpiredInvitation;
