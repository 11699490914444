/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { addCompanyID } from "../../../redux/userSlice";

/** MUI Imports */
import { Box, Stack, Typography } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";

/** Styles */
import classes from "../NavBar.module.css";

const CreditsComponent = (props) => {
    const { credits, mobile, handleDrawerToggle, rol, setSelectedCompany } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [creditsColor, setCreditsColor] = useState("#E9E6EC");

    useEffect(() => {
        if (credits >= 1) {
            setCreditsColor("#E9E6EC");
        }

        if (credits === 0) {
            setCreditsColor("rgba(255, 0, 122, 0.15)");
        }
    }, [credits]);

    const handleClick = () => {
        if (rol !== 3) {
            if (mobile) {
                handleDrawerToggle();
            }

            if (!todos.companyID) {
                const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                dispatch(addCompanyID(newComp._id));
                setSelectedCompany(newComp);
            }

            navigate("/subscription", {
                state: { tab: 1 },
            });
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: creditsColor,
            }}
            className={mobile ? classes.mobileCredits : classes.credits}
            onClick={handleClick}
        >
            <LightTooltip
                title={`${t("navBar.have")} ${parseFloat(credits).toLocaleString(todos.amountFormat, {
                    maximumFractionDigits: 2,
                })} ${t("navBar.availableCredits")}`}
            >
                <Stack direction="row" px={2} py={1} justifyContent="space-between" alignItems="center" spacing={2}>
                    <Typography color="primary" fontWeight={600}>
                        {t("converter.pages")}
                    </Typography>
                    <Typography color="primary">
                        {parseFloat(credits).toLocaleString(todos.amountFormat, { maximumFractionDigits: 2 })}
                    </Typography>
                </Stack>
            </LightTooltip>
        </Box>
    );
};

export default CreditsComponent;
