import {
    Box,
    Checkbox,
    Grid,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import XeroLogo from "../../../assets/xero.svg";

import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import BaseButton from "../../ui/BaseButton";

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const IncomeHistSync = (props) => {
    const { handleGoBack } = props;

    const detailsHist = [];

    const [t] = useTranslation("global");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - detailsHist.length) : 0;

    const columns = [
        //{ name: "id", title: "ID" },
        { name: "description", title: t("payment.description") },
        { name: "error", title: t("converter.errorType") },
    ];

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = detailsHist.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#DBF4E5",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    spacing={2}
                >
                    <Stack direction="row" alignItems="center" spacing={1} justifyContent={{ xs: "center" }}>
                        <Typography variant="h3">{t("incomes.reportSyncSucc")}</Typography>
                        <Box
                            component="img"
                            sx={{
                                height: 20,
                                width: 20,
                            }}
                            src={XeroLogo}
                        />
                    </Stack>
                    <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={{ xs: 2, md: 4 }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                backgroundColor: "#E4F7EB",
                                borderRadius: 3,
                                px: 2,
                                py: 1,
                            }}
                        >
                            <CheckIcon color="success" />
                            <Typography>{t("incomes.successRep")}</Typography>
                        </Stack>
                        <BaseButton sizebutton="m" disableElevation onClick={handleGoBack}>
                            {t("dialog.finally")}
                        </BaseButton>
                    </Stack>
                </Stack>
            </Box>
            <Box sx={greyBoxStyles}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h1" color="primary">
                            {t("incomes.docSync")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1300 }}>
                                <TableHead>
                                    <TableRow sx={{ "& th": { border: "none" } }}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={
                                                    selected.length > 0 && selected.length < detailsHist.length
                                                }
                                                checked={
                                                    detailsHist.length > 0 && selected.length === detailsHist.length
                                                }
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        {columns.map((headCell) => (
                                            <StyledTableCell
                                                key={headCell.name}
                                                align={headCell.align ?? "left"}
                                                style={{ width: headCell.width }}
                                            >
                                                {headCell.title}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <TableCell colSpan={6}>{t("converter.noItems")}</TableCell>
                                    </StyledTableRow>
                                    {emptyRows > 0 && (
                                        <StyledTableRow>
                                            <TableCell colSpan={6} />
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, { label: t("miscellaneous.all"), value: -1 }]}
                            component="div"
                            count={detailsHist.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                            labelDisplayedRows={(page) =>
                                `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${
                                    page.count
                                }`
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default IncomeHistSync;
