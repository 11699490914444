import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Typography,
    styled,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ChatIcon from "@mui/icons-material/Chat";
import CheckIcon from "@mui/icons-material/Check";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import moment from "moment";
import ReconTabs from "./ReconTabs";

import accountsService from "../../services/accounts";
import BaseButton from "../ui/BaseButton";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push(<Skeleton variant="rounded" width="100%" height={100} />);
}

const rowsSkeletonTable = [];

for (let i = 0; i < 10; i++) {
    rowsSkeletonTable.push({
        id: i,
        date: <Skeleton variant="text" animation="wave" />,
        description: <Skeleton variant="text" animation="wave" />,
        egress: <Skeleton variant="text" animation="wave" />,
        ingress: <Skeleton variant="text" animation="wave" />,
    });
}

const CustomButton = styled(Button)({
    backgroundColor: "#F8F8F9",
    color: "#131F3E",
    width: 100,
    "&:hover": {
        backgroundColor: "#E2E2EF",
    },
});

const defaultValues = {
    contactName: "",
    accountNum: "",
    concept: "",
    bankAcc: "",
    reference: "",
};

const defaultValuesTrans = {
    contactName: "",
    accountNum: "",
    amount: "",
    transType: "",
    concept: "",
};

const BankDetails = (props) => {
    const {
        selAccountData,
        bankDetails,
        connectSocket,
        accounts,
        setOpenBackdrop,
        setShowModalRecon,
        setShow,
        setTexto,
        setAlertType,
        reloadBankDet
    } = props;

    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const [selectedType, setSelectedType] = useState(null);
    const [typeConci, setTypeConci] = useState("auto");
    const [showForms, setShowForms] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [tranSize, setTranSize] = useState(0);
    const [reconTab, setReconTab] = useState(0);
    const [checkeds, setCheckeds] = useState([]);
    const [rows, setRows] = useState(rowsSkeletonTable);
    const [rowsOG, setRowsOG] = useState([]);
    const [createManualInfo, setCreateManualInfo] = useState(defaultValues);
    const [createTransInfo, setCreateTransInfo] = useState(defaultValuesTrans);
    const [showCreate, setShowCreate] = useState(false);
    const [showDivideBox, setShowDivideBox] = useState(false);

    const handleChangeTab = async (value) => {
        setReconTab(value);
        if (value === 2) {
            await accountsService
                .getTransactionsSystem({ account_id: selAccountData._id })
                .then((transactions) => {
                    const newArrChecked = [];
                    const newData = transactions.map((item) => {
                        newArrChecked.push(false);
                        return {
                            id: item._id,
                            date: moment.utc(item.date).format(todos.dateFormat),
                            contact: item.description,
                            egress:
                                item.amount < 0
                                    ? parseFloat(item.amount).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })
                                    : "-",
                            ingress:
                                item.amount > 0
                                    ? parseFloat(item.amount).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })
                                    : "-",
                        };
                    });
                    setRows(newData);
                    setRowsOG(newData);
                    setCheckeds(newArrChecked);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setCreateManualInfo(defaultValues);
        setCreateTransInfo(defaultValuesTrans);
        setShowCreate(false);
        setShowDivideBox(false);
    };

    const handleChangeType = (value) => {
        setTypeConci(value);
        setSelectedType(null);
        handleCloseAll();
    };

    const handleReconciliate = async (value, type, bank) => {
        if (type === "auto" && typeConci === "auto") {
            setOpenBackdrop(true);
            await accountsService
                .reconcile({ transaction_id: bank.kiiper_id, xero_transaction_id: bank.xero_id })
                .then(async () => {
                    setTexto("Conciliación exitosa");
                    setAlertType("success");
                    setShow(true);
                    await reloadBankDet();
                })
                .catch((err) => {
                    console.log(err);
                    setTexto("Ha ocurrido un error al conciliar");
                    setAlertType("error");
                    setShow(true);
                });
            setOpenBackdrop(false);
        }
        setShowForms(!showForms);
        setSelectedIndex(value);
        setCreateManualInfo(defaultValues);
        setCreateTransInfo(defaultValuesTrans);
        setShowCreate(false);
    };

    const handleCloseAll = () => {
        setShowForms(false);
        setCreateManualInfo(defaultValues);
        setCreateTransInfo(defaultValuesTrans);
        setShowCreate(false);
    };

    const handdleReconAll = () => {
        setShowModalRecon(true);
    };

    useEffect(() => {
        if (bankDetails) {
            let data = [];
            if (typeConci === "auto") {
                data = bankDetails.matchedTransactions;
            } else {
                data = bankDetails.unmatchedTransactions;
            }
            setTranSize(bankDetails.matchedTransactions.length + bankDetails.unmatchedTransactions.length);
            setSelectedType(data);
        }
    }, [bankDetails, typeConci]);

    return (
        <>
            <Paper elevation={0} sx={{ backgroundColor: "white", p: 3, mt: 3, borderRadius: 3 }}>
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h1" gutterBottom>
                            {selAccountData.account_name}{" "}
                            <span style={{ color: "#4a22d4" }}>*{selAccountData.account_number}</span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <BaseButton variantType="primaryOutlined">{t("accounts.reconHist")}</BaseButton>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center" pt={2} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Paper
                                    elevation={0}
                                    sx={{ backgroundColor: "#F9F8FD", px: 2, py: 2, mt: 1, borderRadius: 3 }}
                                >
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="h2">{t("accounts.finalAmount")}</Typography>
                                            <InfoIcon color="primary" fontSize="small" />
                                        </Stack>
                                        <Typography>
                                            $
                                            {parseFloat(selAccountData.bank_balance_system).toLocaleString(
                                                todos.amountFormat,
                                                {
                                                    minimumFractionDigits: 2,
                                                }
                                            )}
                                        </Typography>
                                    </Stack>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper
                                    elevation={0}
                                    square={false}
                                    sx={{ backgroundColor: "#F9F8FD", px: 3, py: 2, mt: 1, borderRadius: 3 }}
                                >
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Typography>
                                            $
                                            {parseFloat(selAccountData.accounting_balance_system).toLocaleString(
                                                todos.amountFormat,
                                                {
                                                    minimumFractionDigits: 2,
                                                }
                                            )}
                                        </Typography>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="h2">{t("dashboard.xeroBalance")}</Typography>
                                            <InfoIcon color="primary" fontSize="small" />
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundColor:
                                    parseInt(selAccountData.unreconciled_transactions) === 0 ? "#E6F3E9" : "#FFF2DA",
                                px: 2,
                                py: 1,
                                mt: 1,
                                borderRadius: 3,
                            }}
                        >
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ textAlign: "center" }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                        <Typography variant="h2">{t("dashboard.diference")}</Typography>
                                        <InfoIcon color="primary" fontSize="small" />
                                        <Typography fontSize={16}>
                                            $
                                            {parseFloat(
                                                selAccountData.last_conversion_balance -
                                                    selAccountData.bank_balance_system
                                            ).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    {parseInt(selAccountData.unreconciled_transactions) === 0 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography fontSize={16}>{t("dashboard.reconTrans")}</Typography>
                                            <CheckIcon color="success" />
                                        </Stack>
                                    ) : (
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={12} sm={7.5}>
                                                <Typography fontSize={16}>
                                                    <span
                                                        style={{
                                                            color: "#4A22D4",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {parseInt(selAccountData.unreconciled_transactions)}
                                                    </span>
                                                    {t("dashboard.transRecon")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4.5}>
                                                <BaseButton sizebutton="m" disableElevation onClick={handdleReconAll}>
                                                    {t("dashboard.allCon")}
                                                </BaseButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container justifyContent="space-evenly" spacing={2} sx={{ p: 4 }}>
                <Grid item>
                    <Card
                        sx={{
                            backgroundColor: typeConci === "auto" ? "#D5CBF5" : "white",
                            borderRadius: 3,
                            boxShadow: "4px 4px 5px 0px rgba(74, 34, 212, 0.1)",
                        }}
                    >
                        <CardActionArea
                            sx={{
                                px: { xs: 1, sm: 6 },
                                py: 1,
                            }}
                            onClick={() => handleChangeType("auto")}
                        >
                            <CardContent>
                                <Typography variant="h1">
                                    {t("dashboard.autoConci")}{" "}
                                    {bankDetails && tranSize !== 0 && (
                                        <span style={{ fontWeight: 500 }}>
                                            ({Math.floor((bankDetails.matchedTransactions.length / tranSize) * 100)}%)
                                        </span>
                                    )}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item>
                    <Card
                        sx={{
                            backgroundColor: typeConci === "manual" ? "#D5CBF5" : "white",
                            borderRadius: 3,
                            boxShadow: "4px 4px 5px 0px rgba(74, 34, 212, 0.1)",
                        }}
                    >
                        <CardActionArea
                            sx={{
                                px: { xs: 1, sm: 6 },
                                py: 1,
                            }}
                            onClick={() => handleChangeType("manual")}
                        >
                            <CardContent>
                                <Typography variant="h1">
                                    {t("dashboard.manualCon")}{" "}
                                    {bankDetails && tranSize !== 0 && (
                                        <span style={{ fontWeight: 500 }}>
                                            ({Math.floor((bankDetails.unmatchedTransactions.length / tranSize) * 100)}%)
                                        </span>
                                    )}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            {!selectedType ? (
                rowsSkeleton.map((item, index) => (
                    <Grid key={index} container spacing={2} alignItems="center" sx={{ pb: 3 }}>
                        <Grid item xs={12} md={5}>
                            {item}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {item}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {item}
                        </Grid>
                    </Grid>
                ))
            ) : selectedType.length === 0 ? (
                <Paper elevation={0} sx={{ backgroundColor: "white", px: 3, py: 4, borderRadius: 3 }}>
                    <Typography>{t("dashboard.nothingRecon")}</Typography>
                </Paper>
            ) : (
                selectedType.map((item, index) => (
                    <Grid key={item.kiiper_id} container spacing={2} alignItems="center" sx={{ pb: 3 }}>
                        <Grid item xs={12} md={5.4}>
                            <Paper elevation={0} sx={{ backgroundColor: "white", px: 3, py: 4, borderRadius: 3 }}>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="h3">
                                        {moment.utc(item.kiiper_date).locale(t("language.locale")).format("DD MMM YYYY")}
                                    </Typography>
                                    <IconButton size="small" onClick={() => connectSocket(item)}>
                                        <ChatIcon color="primary" fontSize="small" />
                                    </IconButton>
                                </Stack>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h2">{item.kiiper_description}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            sx={{ textAlign: "right" }}
                                        >
                                            <Typography variant="h2" sx={{ minWidth: 70 }}>
                                                {t("accounts.buy")}
                                            </Typography>
                                            <Typography variant="h2" sx={{ minWidth: 70 }}>
                                                {t("accounts.sell")}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>{item.kiiper_reference}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            sx={{ textAlign: "right" }}
                                        >
                                            <Typography sx={{ minWidth: 70 }}>
                                                {item.kiiper_amount < 0
                                                    ? "$" +
                                                      parseFloat(item.kiiper_amount * -1).toLocaleString(
                                                          todos.amountFormat,
                                                          {
                                                              minimumFractionDigits: 2,
                                                          }
                                                      )
                                                    : "-"}
                                            </Typography>
                                            <Typography sx={{ minWidth: 70 }}>
                                                {item.kiiper_amount > 0
                                                    ? "$" +
                                                      parseFloat(item.kiiper_amount).toLocaleString(
                                                          todos.amountFormat,
                                                          {
                                                              minimumFractionDigits: 2,
                                                          }
                                                      )
                                                    : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={1.2} sx={{ textAlign: "center" }}>
                            <Button
                                variant="contained"
                                sx={{ px: 4 }}
                                disableElevation
                                disabled={showForms && index !== selectedIndex}
                                onClick={() => handleReconciliate(index, "auto", item)}
                            >
                                {t("dashboard.conciliate")}
                            </Button>
                            {!showForms && (
                                <Button
                                    sx={{ mt: 0.5, fontSize: 14 }}
                                    onClick={() => handleReconciliate(index, "manual", item)}
                                >
                                    {t("dashboard.manualConSec")}
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} md={5.4}>
                            {showForms && index === selectedIndex ? (
                                <Paper elevation={0} sx={{ backgroundColor: "white", px: 3, py: 4 }}>
                                    <Stack direction="row" spacing={2} pb={2}>
                                        <CustomButton
                                            variant="contained"
                                            disableElevation
                                            onClick={() => handleChangeTab(0)}
                                            sx={{ backgroundColor: reconTab === 0 ? "#E2E2EF" : "#F8F8F9" }}
                                        >
                                            {t("navBar.create")}
                                        </CustomButton>
                                        <CustomButton
                                            variant="contained"
                                            disableElevation
                                            onClick={() => handleChangeTab(1)}
                                            sx={{ backgroundColor: reconTab === 1 ? "#E2E2EF" : "#F8F8F9" }}
                                        >
                                            {t("team.transfer")}
                                        </CustomButton>
                                        <CustomButton
                                            variant="contained"
                                            disableElevation
                                            onClick={() => handleChangeTab(2)}
                                            sx={{ backgroundColor: reconTab === 2 ? "#E2E2EF" : "#F8F8F9" }}
                                        >
                                            {t("navBar.searchA")}
                                        </CustomButton>
                                    </Stack>
                                    <ReconTabs
                                        reconTab={reconTab}
                                        createManualInfo={createManualInfo}
                                        setCreateManualInfo={setCreateManualInfo}
                                        accounts={accounts}
                                        rows={rows}
                                        selAccountData={selAccountData}
                                        checkeds={checkeds}
                                        setCheckeds={setCheckeds}
                                        setRows={setRows}
                                        rowsOG={rowsOG}
                                        showCreate={showCreate}
                                        setShowCreate={setShowCreate}
                                        createTransInfo={createTransInfo}
                                        setCreateTransInfo={setCreateTransInfo}
                                        setOpenBackdrop={setOpenBackdrop}
                                        showDivideBox={showDivideBox}
                                        setShowDivideBox={setShowDivideBox}
                                    />
                                </Paper>
                            ) : (
                                <Paper elevation={0} sx={{ backgroundColor: "white", px: 3, py: 4 }}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="h3">
                                            {moment.utc(item.xero_date).locale(t("language.locale")).format("DD MMM YYYY")}
                                        </Typography>
                                        <IconButton size="small" onClick={() => connectSocket(item)}>
                                            <ChatIcon color="primary" fontSize="small" />
                                        </IconButton>
                                    </Stack>
                                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="h2">{item.xero_description}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                                sx={{ textAlign: "right" }}
                                            >
                                                <Typography variant="h2" sx={{ minWidth: 70 }}>
                                                    {t("accounts.buy")}
                                                </Typography>
                                                <Typography variant="h2" sx={{ minWidth: 70 }}>
                                                    {t("accounts.sell")}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography>{item.xero_reference}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                                sx={{ textAlign: "right" }}
                                            >
                                                <Typography sx={{ minWidth: 70 }}>
                                                    {item.xero_amount < 0
                                                        ? "$" +
                                                          parseFloat(item.xero_amount * -1).toLocaleString(
                                                              todos.amountFormat,
                                                              {
                                                                  minimumFractionDigits: 2,
                                                              }
                                                          )
                                                        : "-"}
                                                </Typography>
                                                <Typography sx={{ minWidth: 70 }}>
                                                    {item.xero_amount > 0
                                                        ? "$" +
                                                          parseFloat(item.xero_amount).toLocaleString(
                                                              todos.amountFormat,
                                                              {
                                                                  minimumFractionDigits: 2,
                                                              }
                                                          )
                                                        : "-"}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                ))
            )}
        </>
    );
};

export default BankDetails;
