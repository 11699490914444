import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";

// import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import countriesProject from "../../Js/countries";
import invoicesService from "../../../services/invoices";
/** Country flags */
import Flag from "react-world-flags";
import { useSelector } from "react-redux";
import conversionService from "../../../services/conversion";
import BaseButton from "../../ui/BaseButton";
import CustTextField from "../../ui/CustTextField";
import BaseSelect2 from "../../ui/BaseSelect2";

const CompanyData = (props) => {
    const todos = useSelector((state) => state.value);
    const { setShowCompanyDet, setIsLoading, setContactsXeroOriginal, companyInfo, setCompanyInfo, setTexto, setAlertType, setShow, setSelectedContact, selectedContact, } = props;
    const [t] = useTranslation("global");

    /** Countries */
    const allCountries = [...countriesProject];

    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    const [isError, setIsError] = useState(false);
    const [inheritData, setInheritData] = useState(false);
    const [isValidContactName, setIsValidContactName] = useState(false);
    const [isValidTaxId, setIsValidTaxId] = useState(false);

    const handleChangeInherit = (event) => {
        setInheritData(event.target.checked);
        if (event.target.checked) {
            setCompanyInfo({
                ...companyInfo,
                contact_name_2: companyInfo?.company_name ?? "",
                contact_email_2: companyInfo?.company_email ?? "",
                contact_phone_2: companyInfo?.company_phone ?? "",
                contact_country_2: companyInfo?.company_country ?? "",
                contact_country_code: companyInfo?.company_country_code ?? ""
            });
        } else {
            setCompanyInfo({
                ...companyInfo,
                contact_name_2: "",
                contact_email_2: "",
                contact_phone_2: "",
                contact_country_2: "",
                contact_country_code: ""
            });
        }
    };

    const handleChangeData = (event) => {
        setCompanyInfo({ ...companyInfo, [event.target.name]: event.target.value });
    };

    const handleChangeTaxId = (event) => {
        setCompanyInfo({ ...companyInfo, [event.target.name]: event.target.value });
        // verifyTaxId(event.target.value);
    };

    const handleChangeContactName = (event) => {
        setCompanyInfo({ ...companyInfo, [event.target.name]: event.target.value });
        // verifyContactName(event.target.value);
    };

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setCompanyInfo({ ...companyInfo, company_country: event.target.value, company_country_code: codeFilter.phone_code });
        }
    };

    const handleChangeContactPhone = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setCompanyInfo({ ...companyInfo, contact_country_2: event.target.value, contact_country_code: codeFilter.phone_code });
        }
    };

    function validateValue(value) {
        return value !== undefined && value !== null && value !== "";
    }

    const handleSaveData = async () => {
        if (companyInfo.company_name) {
            setIsLoading(true);
            const verifName = await verifyContactName(companyInfo.company_name)
            let verifTax = false;
            if (companyInfo?.company_tax_number !== "") {
                verifTax = await verifyTaxId(companyInfo.company_tax_number)
            }

            if ((selectedContact._id === "first" && !verifName && !verifTax) || Object.keys(selectedContact).length === 0) {

                let codeFilter1 = allCountries.find((country) => country.country_code === companyInfo?.company_country);
                let codeFilter2 = allCountries.find((country) => country.country_code === companyInfo?.contact_country_2);
                await invoicesService
                    .createNewContact({
                        company_id: todos.companyID,
                        company_name: validateValue(companyInfo.company_name) ? companyInfo.company_name : undefined,
                        company_tax_number: validateValue(companyInfo.company_tax_number) ? companyInfo.company_tax_number : undefined,
                        company_email: validateValue(companyInfo.company_email) ? companyInfo.company_email : undefined,
                        company_address: validateValue(companyInfo.company_address) ? companyInfo.company_address : undefined,
                        company_country: codeFilter1?.code && codeFilter1.code !== "" ? codeFilter1.code : undefined,
                        company_phone: validateValue(companyInfo.company_phone) ? companyInfo.company_phone : undefined,

                        contact_name: validateValue(companyInfo.contact_name_2) ? companyInfo.contact_name_2 : undefined,
                        contact_email: validateValue(companyInfo.contact_email_2) ? companyInfo.contact_email_2 : undefined,
                        contact_country: codeFilter2?.code && codeFilter2.code !== "" ? codeFilter2.code : undefined,
                        contact_phone: validateValue(companyInfo.contact_phone_2) ? companyInfo.contact_phone_2 : undefined,
                        contact_role: validateValue(companyInfo.contact_role_2) ? companyInfo.contact_role_2 : undefined,
                    }) 
                    .then(async (contact_data) => {
                        setTexto(t("register.saveSuccessfully"));
                        setAlertType("success");
                        setShow(true);
                        await conversionService
                            .getContacts({
                                company_id: todos.companyID,
                            })
                            .then((data) => {
                                const fData = data.map((item) => ({
                                    ...item,
                                    value: item.ContactID,
                                    name: item.Name,
                                    currency: item.currency_contact,
                                    idSys: item.contact_id_system,
                                }))

                                setContactsXeroOriginal(fData);

                                if (Object.keys(selectedContact).length === 0) {
                                    const findContact = fData.find(cont => cont._id.toString() === contact_data._id.toString())
                                    setSelectedContact(findContact);
                                }

                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        setShowCompanyDet(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (selectedContact._id !== "first" && !verifName && !verifTax) {

                let codeFilter1 = allCountries.find((country) => country.country_code === companyInfo?.company_country);
                let codeFilter2 = allCountries.find((country) => country.country_code === companyInfo?.contact_country_2);
                
                await invoicesService
                    .updateContact({
                        contact_id: selectedContact._id,
                        
                        company_id: todos.companyID,
                        
                        company_name: validateValue(companyInfo.company_name) ? companyInfo.company_name : undefined,
                        company_tax_number: validateValue(companyInfo.company_tax_number) ? companyInfo.company_tax_number : undefined,
                        company_email: validateValue(companyInfo.company_email) ? companyInfo.company_email : undefined,
                        company_address: validateValue(companyInfo.company_address) ? companyInfo.company_address : undefined,
                        company_country: codeFilter1?.code && codeFilter1.code !== "" ? codeFilter1.code : undefined,
                        company_phone: validateValue(companyInfo.company_phone) ? companyInfo.company_phone : undefined,

                        contact_name: validateValue(companyInfo.contact_name_2) ? companyInfo.contact_name_2 : undefined,
                        contact_email: validateValue(companyInfo.contact_email_2) ? companyInfo.contact_email_2 : undefined,
                        contact_country: codeFilter2?.code && codeFilter2.code !== "" ? codeFilter2.code : undefined,
                        contact_phone: validateValue(companyInfo.contact_phone_2) ? companyInfo.contact_phone_2 : undefined,
                        contact_role: validateValue(companyInfo.contact_role_2) ? companyInfo.contact_role_2 : undefined,

                    }) 
                    .then(async (contact_data) => {
                        setTexto(t("register.saveSuccessfully"));
                        setAlertType("success");
                        setShow(true);
                        await conversionService
                            .getContacts({
                                company_id: todos.companyID,
                            })
                            .then((data) => {
                                const fData = data.map((item) => ({
                                    ...item,
                                    value: item.ContactID,
                                    name: item.Name,
                                    currency: item.currency_contact,
                                    idSys: item.contact_id_system,
                                }))

                                setContactsXeroOriginal(fData);

                                const findContact = fData.find(cont => cont._id.toString() === contact_data._id.toString())
                                setSelectedContact(findContact);

                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        setShowCompanyDet(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (verifName) {
                setTexto(t("dialog.duplicatedContactName"));
                setAlertType("error");
                setShow(true);
            } else if (verifTax) {
                setTexto(t("dialog.duplicatedTaxId"));
                setAlertType("error");
                setShow(true);
            }
            setIsLoading(false);
        } else {
            setIsError(true)
        }
    };

    const verifyTaxId = async (tax_id) => {
        if (tax_id) {
            await invoicesService
                .validateContactTaxId({
                    tax_id: companyInfo.company_tax_number,
                    company_id: todos?.companyID,
                    contact_id: selectedContact?._id !== "first" ? selectedContact._id : undefined
                })
                .then((response) => {
                    setIsValidTaxId(true);
                    return true
                    // setCompanyInfo({ ...companyInfo, company_name: response.contact_name, company_tax_number: response.contact_tax_number });
                })
                .catch((error) => {
                    // console.log("error", error);
                    switch (error.response.status) {
                        case 404:
                            setIsValidTaxId(false);
                            setIsError(false);
                            break;
                        default:
                            break;
                    }
                    return false
                });
        }
        return false
    };

    const verifyContactName = async (contact_name) => {
        if (contact_name) {
            await invoicesService
                .validateContactName({
                    contact_name: companyInfo.company_name,
                    company_id: todos?.companyID,
                    contact_id: selectedContact?._id !== "first" ? selectedContact._id : undefined
                })
                .then((response) => {
                    // console.log("response", response);
                    setIsValidContactName(true);
                    return true;
                    // setCompanyInfo({ ...companyInfo, company_name: response.contact_name, company_tax_number: response.contact_tax_number });
                })
                .catch((error) => {
                    // console.log("error", error);
                    switch (error.response.status) {
                        case 404:
                            setIsValidContactName(false);
                            setIsError(false);
                            break;
                        default:
                            break;
                    }
                    return false;
                });
        }

        return false;
    };

    useEffect(() => {
        (async () => {
            if (companyInfo.company_name && setSelectedContact.value === "first") {
                await verifyContactName(companyInfo.company_name);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyInfo.company_name, setSelectedContact]);

    useEffect(() => {
        (async () => {
            if (companyInfo.company_tax_number && setSelectedContact.value === "first") {
                await verifyTaxId(companyInfo.company_tax_number);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyInfo.company_tax_number, setSelectedContact]);

    return (
        <Box sx={{ px: 2, width: 800 }}>
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <Typography variant="h2">{t("dialog.companyData")}</Typography>
                {/* <BaseButton variantType="primaryOutlined" sizebutton="l" endIcon={<DocumentScannerIcon />}>
                    Escanear RIF
                </BaseButton> */}
            </Stack>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label={t("converter.subscriptionName")}
                        name="company_name"
                        required
                        onChange={handleChangeContactName}
                        value={companyInfo.company_name}
                        error={(isError && !companyInfo?.company_name?.trim()) || isValidContactName}
                    />
                    {isValidContactName && companyInfo?.company_name?.trim() && (<Box sx={{ display: "flex", flexDirection: "row" }}>
                        {companyInfo.company_name.trim() && isValidContactName && (
                            <Typography size="xs" sx={{ color: "#ff5968" }}>
                                {t("dialog.duplicatedContactName")}
                            </Typography>
                        )}

                    </Box>)}
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label="Tax ID"
                        name="company_tax_number"
                        onChange={handleChangeTaxId}
                        value={companyInfo.company_tax_number}
                        error={isValidTaxId}
                    />
                    {isValidTaxId && companyInfo.company_tax_number.trim() && (<Box sx={{ display: "flex", flexDirection: "row" }}>
                        {companyInfo.company_tax_number.trim() && isValidTaxId && (
                            <Typography size="xs" sx={{ color: "#ff5968" }}>
                                {t("dialog.duplicatedTaxId")}
                            </Typography>
                        )}
                    </Box>)}
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label={t("plans.email")}
                        name="company_email"
                        onChange={handleChangeData}
                        value={companyInfo.company_email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label={t("invoicing.taxAddress")}
                        name="company_address"
                        onChange={handleChangeData}
                        value={companyInfo.company_address}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <BaseSelect2
                        value={companyInfo.company_country}
                        onChange={handleChangePhoneCode}
                        fullWidth
                        label={t("register.code")}
                        renderValue={() => (
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Flag code={companyInfo.company_country} height="12" width="20" />
                                <Typography>{companyInfo.company_country_code}</Typography>
                            </Stack>
                        )}
                    >
                        {allCountries.map((option) => (
                            <MenuItem value={option.country_code} key={option.country_code}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Flag code={option.country_code} height="14" width="22" />
                                    <Typography>
                                        {sessionStorage.getItem("lng") === "en" ? option.name_en : option.name_es}
                                        {" ("}
                                        {option.phone_code + ")"}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </BaseSelect2>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustTextField
                        fullWidth
                        label={t("register.phone")}
                        name="company_phone"
                        onChange={handleChangeData}
                        value={companyInfo.company_phone}
                        autoComplete="one-time-code"
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography variant="h2">{t("invoicing.contactData")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={inheritData} onChange={handleChangeInherit} size="small" />}
                        label={<Typography>{t("register.copySubsInfo")}</Typography>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label={t("login.name")}
                        name="contact_name_2"
                        onChange={handleChangeData}
                        value={companyInfo.contact_name_2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label={t("login.email")}
                        name="contact_email_2"
                        onChange={handleChangeData}
                        value={companyInfo.contact_email_2}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <BaseSelect2
                        label={t("register.code")}
                        value={companyInfo.contact_country_2}
                        onChange={handleChangeContactPhone}
                        fullWidth
                        renderValue={() => (
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Flag code={companyInfo.contact_country_2} height="12" width="20" />
                                <Typography>{companyInfo.contact_country_code}</Typography>
                            </Stack>
                        )}
                    >
                        {allCountries.map((option) => (
                            <MenuItem value={option.country_code} key={option.country_code}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Flag code={option.country_code} height="14" width="22" />
                                    <Typography>
                                        {sessionStorage.getItem("lng") === "en" ? option.name_en : option.name_es}
                                        {" ("}
                                        {option.phone_code + ")"}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </BaseSelect2>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustTextField
                        fullWidth
                        label={t("register.phone")}
                        name="contact_phone_2"
                        onChange={handleChangeData}
                        value={companyInfo.contact_phone_2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        label="Rol"
                        name="contact_role_2"
                        onChange={handleChangeData}
                        value={companyInfo.contact_role_2}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
                <BaseButton sizebutton="m" onClick={handleSaveData}>
                    {t("team.save")}
                </BaseButton>
            </Stack>
        </Box>
    );
};

export default CompanyData;
