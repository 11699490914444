import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

/* Componentes */
import BackGeneral from "../components/layout/BackGeneral";
import InvoicesConverter from "../components/converter/invoices/InvoicesConverter";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";

import BaseSnackbar from "../components/ui/BaseSnackbar";
import { Tabs, Tab, Typography, Stack, Grid, Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InvoicesPreview from "../components/converter/invoices/InvoicesPreview";

import qboBanks from "../components/Js/quickbooksBIDs";
import conversionFormats from "../components/Js/conversionFormats";
import invoicesService from "../services/invoices";
import caseService from "../services/case";
import companyService from "../services/company";
import userCompanyService from "../services/user_company";
import { initiateSocket, newUserResponse, subscribeToChat } from "../services/Socket";
import { zeroPad } from "../utils/utils";
import ChatModal from "../components/ui/Chat";
import FormDialog from "../components/ui/FormDialog";
import DownloadFormatContent from "../components/converter/DownloadFormatContent";
import DownloadFormatButtons from "../components/converter/DownloadFormatButtons";
import GroupedContent from "../components/converter/GroupedContent";
import GroupedButtons from "../components/converter/GroupedButtons";

import InfoIcon from "@mui/icons-material/Info";
import SimpleDialog from "../components/ui/SimpleDialog";
import BasePreviewFile from "../components/converter/BasePreviewFile";
import ReturnInvoiceContent from "../components/converter/invoices/ReturnInvoiceContent";
import ReturnInvoiceButtons from "../components/converter/invoices/ReturnInvoiceButtons";
import ConsolidatedInvoicesConverter from "../components/converter/invoices/ConsolidatedInvoicesConverter";
import ConsolidatedInvoicesTable from "../components/converter/invoices/ConsolidatedInvoicesTable";
import ConsolidatedInvoicesPreview from "../components/converter/invoices/ConsolidatedInvoicesPreview";
import BaseActionsPreviewFile from "../components/converter/BaseActionsPreviewFile";
import Loading from "../assets/loading.png";
import MainInvTabs from "../components/converter/invoices/MainInvTabs";
import NewInvoice from "../components/converter/invoices/NewInvoice";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
        },
    ],
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const invoiceFormats = [
    { id: 0, name: "pdf" },
    { id: 1, name: "jpg" },
    { id: 2, name: "png" },
];

const consolidatedFormats = [
    { id: 0, name: "xlsx" },
    { id: 1, name: "xls" },
    { id: 2, name: "csv" },
];

const ConverterInvoices = ({ conversion_type }) => {
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const navigate = useNavigate();

    const finalQBOBanks = qboBanks.concat({
        value: "03000",
        value2: "03000",
        value3: "03000",
        value4: "03000",
        name: `${t("converter.defaultBank")} (03000)`,
    });

    const [openPreview, setOpenPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [conversions, setConversions] = useState([]);
    const [consolidatedConversions, setConsolidatedConversions] = useState([]);
    const [groupConversions, setGroupConversions] = useState([]);
    //const [groupCheckConversions, setGroupCheckConversions] = useState([]);
    const [usersFilters, setUsersFilters] = useState([]);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [filters, setFilters] = useState(defaultFilter);
    const [tutorial, setTutorial] = useState(false);
    const [openModalDownloadF, setOpenModalDownloadF] = useState(false);
    const [convertDoneNew, setConvertDoneNew] = useState(false);
    const [correlative, setCorrelative] = useState(null);
    const [conversionId, setConversionId] = useState(null);
    const [fileNameSaved, setFileNameSaved] = useState("");
    const [fileNameFull, setFileNameFull] = useState("");
    const [showPrev, setShowPrev] = useState(false);
    const [showModalCredits, setShowModalCredits] = useState(false);
    const [resetConversionMain, setResetConversionMain] = useState(false);
    const [caseInfo, setCaseInfo] = useState({});
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [conversionData, setConversionData] = useState({});
    const [checkedInvSigngs, setCheckedInvSigngs] = useState(false);
    const [checkedInvDay, setCheckedInvDay] = useState(false);
    const [download, setDownload] = useState(false);
    const [convertDone, setConvertDone] = useState(false);
    const [convertInvalid, setConvertInvalid] = useState(false);
    const [convertRev, setConvertRev] = useState(false);
    const [status, setStatus] = useState(0); //0: Sin iniciar, 1: Error, 2: Revision, 3: Exitoso
    const [statusCase, setStatusCase] = useState();
    const [convFormat, setConvFormat] = useState(conversionFormats);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [showPrevConsolidated, setShowPrevConsolidated] = useState(false);
    const [allConversions, setAllConversions] = useState([]);
    const [disabledInput, setDisabledInput] = useState(false);
    const [groupedData, setGroupedData] = useState(null);
    const [groupedPeriods, setGroupedPeriods] = useState(null);
    const [openModalGrouped, setOpenModalGrouped] = useState(false);
    const [correlativeModalGrouped, setCorrelativeModalGrouped] = useState(0);
    const [s3FileName, setS3FileName] = useState([]);
    const [originalFileName, setOriginalFileName] = useState([]);
    const [progress, setProgress] = useState(0);
    const [endConversion, setEndConversion] = useState(false);
    const [openModalGroupedHist, setOpenModalGroupedHist] = useState(false);
    const [rows, setRows] = useState([]);
    const [value, setValue] = useState(0);
    const [valueMain, setValueMain] = useState(0);
    const [customCredits, setCustomCredits] = useState(0); // Créditos personalizados
    const [downloadFormat, setDownloadFormat] = useState("");
    const [modalType, setModalType] = useState(""); //bankStatement o check
    const [showSync, setShowSync] = useState(false);
    const [integration, setIntegration] = useState(null);
    const [openModalReturnInvoice, setOpenModalReturnInvoice] = useState(false);
    const [emailReturn, setEmailReturn] = useState("");
    const [errorReturn, setErrorReturn] = useState("");
    const [invoiceReturn, setInvoiceReturn] = useState("");
    const [showHistSync, setShowHistSync] = useState(false);
    const [correlativeSync, setCorrelativeSync] = useState(null);
    const [rowsModal, setRowsModal] = useState([]);
    const [showSyncDetails, setShowSyncDetails] = useState(false);
    const [__html, setHTML] = useState("");
    const [basePreviewData, setBasePreviewData] = useState({
        conversion_id: "",
        type: "",
        file_s3: "",
        file: "",
    });
    const [showRepeatModal, setShowRepeatModal] = useState(false);
    const [showRepeatVal, setShowRepeatVal] = useState([]);
    const [openHeavy, setOpenHeavy] = useState(false);
    const [selectedInv, setSelectedInv] = useState({ id: null, isGrouped: false });
    const [companyDetails, setCompanyDetails] = useState({});
    const [allCompanies, setAllCompanies] = useState([]);
    const [paymentType, setPaymentType] = useState("monthly");
    const [activePlan, setActivePlan] = useState(null); // Plan seleccionado

    const [conversionContacts, setConversionContacts] = useState([]);

    const controllerRef = useRef(null);
    const controllerRefCompany = useRef(null);
    const controllerRefConvUsers = useRef(null);
    const [currentInv, setCurrentInv] = useState({});

    const columns1 = [
        { name: "number", title: t("subscription.conversion"), minWidth: "5%" },
        { name: "uploadDate", title: t("inbox.creationDate"), align: "right", minWidth: "10%" },
        { name: "invoiceNumber", title: t("converter.invoiceNumber"), minWidth: "10%" },
        { name: "contact", title: t("converter.subscriptionName") },
        { name: "invoiceDate", title: t("converter.invoiceDate"), align: "right", minWidth: "10%"  },
        { name: "reference", title: t("converter.controlNum"), minWidth: "10%"  },
        { name: "amount", title: t("credits.amount"), align: "right", minWidth: "10%"  },
        { name: "status", title: t("converter.status"), minWidth: "12%"  },
        { name: "synchronized", title: t("converter.synchronized"), align: "center", minWidth: "3%" },
        { name: "chat", title: <></>, minWidth: "2%" },
    ];

    const columns2 = [
        { name: "id", title: "ID", minWidth: "5%" },
        { name: "creationDate", title: t("inbox.creationDate"), align: "right", minWidth: "10%" },
        { name: "docNumber", title: t("invoicing.docNum"), minWidth: "10%" },
        { name: "invoiceNumber", title: t("converter.controlNum"), minWidth: "10%" },
        { name: "contact", title: t("converter.subscriptionName") },
        { name: "docDate", title: t("invoicing.docDate"), align: "right", minWidth: "10%" },
        { name: "amount", title: t("credits.amount"), align: "right", minWidth: "10%" },
        { name: "user", title: t("converter.user"), minWidth: "10%" },
        { name: "status", title: t("converter.status"), minWidth: "12%" },
        { name: "synchronized", title: t("converter.synchronized"), align: "center", minWidth: "3%" },
        { name: "chat", title: <></>, minWidth: "2%" },
    ];

    const columns3 = [
        { name: "number", title: t("subscription.conversion") },
        { name: "uploadDate", title: t("inbox.creationDate"), align: "right" },
        { name: "invoiceDate", title: t("converter.invoiceDate"), align: "right" },
        { name: "file_name", title: t("converter.bankStatement") },
        { name: "amount", title: t("credits.amount"), align: "right" },
        { name: "status", title: t("converter.status") },
        { name: "chat", title: <></> },
    ];

    const [columns, setColumns] = useState(conversion_type === 1 ? columns1 : columns2);

    const [showNewInv, setShowNewInv] = useState(false);
    const [valueAddInv, setValueAddInv] = useState(0);

    useEffect(() => {
        setValue(0);
        setValueMain(0);

        if (conversion_type === 1) {
            setColumns(columns1);
        } else {
            setColumns(columns2);
        }

        setShowNewInv(false);
        setRows([]);
        setRowsModal([]);
        removeBanner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversion_type, todos.companyID]);

    const resetConversionInvoices = () => {
        setProgress(0);
        setConvertDone(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setAcceptedFiles([]);
        setDisabledInput(false);
        setEndConversion(false);
        setConversionId(null);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
        setConvertDoneNew(false);
        setRows([]);
        setRowsModal([]);
        setS3FileName([]);
    };

    window.history.replaceState(null, "");

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleGetConversions = async (valueConv, tutorial) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        const options = {
            signal: controllerRef.current.signal,
        };

        await invoicesService
            .getInvoiceConversions(
                {
                    company_id: todos.companyID,
                    conversion_type,
                },
                options
            )
            .then((response) => {
                if (response?.conversions?.length > 0) {
                    setConversions(response?.conversions);
                    setAllConversions(response);
                    if (tutorial) {
                        setTutorial(true);
                    }
                } else {
                    setConversions([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        controllerRef.current = null;
    };

    const handleCloseDialog = () => {
        setOpenModalDownloadF(false);
        setOpenModalReturnInvoice(false);
        setDownloadFormat("");
        setInvoiceReturn("");
        setErrorReturn("");
        setEmailReturn("");
    };

    const handleCloseGroupedDiag = async () => {
        setOpenModalGrouped(false);

        if (groupedData?.conversions?.length === 0 || groupedData?.invoices?.length === 0) {
            setConvertDone(false);
        }

        if (openModalGroupedHist) {
            setRowsModal([]);
            setCorrelativeSync(null);
        }
    };

    const connectSocket = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await caseService
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const downloadConversionFunc = async (params) => {
        await invoicesService
            .downloadInvoiceConversion(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClickHelp = async (type, conversion_type, conversion) => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: type,
            conversion_id: conversion !== "" ? conversion : undefined,
        };

        if (type === "conversion") {
            params.conversion_type = conversion_type;
        }

        let caseInfo = null;

        if (type !== "generalConv") {
            await caseService
                .getCaseInfo(params)
                .then(async (response) => {
                    if (response) {
                        setCaseInfo(response.caseInfo);
                        caseInfo = response.caseInfo;
                        setStatusCase("");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (!caseInfo) {
            await caseService
                .createNewCase(params)
                .then(async (response) => {
                    setCaseInfo(response);
                    caseInfo = response;
                    setStatusCase(1);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        await connectSocket(caseInfo._id);

        setIsLoading(false);
    };

    const connectSocketConv = async () => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "conversion",
            conversion_id: conversionId,
        };

        let caseInfo = null;

        await caseService
            .getCaseInfo(params)
            .then(async (response) => {
                if (response) {
                    setCaseInfo(response.caseInfo);
                    caseInfo = response.caseInfo;
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (!caseInfo) {
            await caseService
                .createNewCase(params)
                .then(async () => {
                    await caseService
                        .getCaseInfo(params)
                        .then(async (data) => {
                            setCaseInfo(data.caseInfo);
                            caseInfo = data.caseInfo;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setRoom(caseInfo._id);

        initiateSocket(caseInfo._id, todos.userInfo, "conversion", correlative);
        subscribeToChat((err, data) => {
            if (err) return;
            setChat((oldChats) => [...oldChats, data]);
        });
        newUserResponse((err, data) => {
            if (err) return;
            setUsers(data);
        });
        setOpen(true);

        setIsLoading(false);
    };

    const handleOpenDownloadModal = async (event) => {
        setOpenModalDownloadF(true);
    };

    const handleClose = () => {
        setOpenHeavy(false);
    };

    const handleClickDownload = async (event) => {
        setIsLoading(true);

        setDownloadFormat(event.target.value);
        setIsLoading(false);
    };

    // Funcion para descargar la factura
    const downloadFormatsInvoices = async () => {
        setIsLoading(true);

        let formatFile = downloadFormat === 1 ? ".xml" : downloadFormat === 4 ? ".xlsx" : ".csv";

        let currentFormatDate = todos.dateFormat;

        let fileName =
            conversionData?.conversion_type === 1
                ? `Invoice_${fileNameSaved}${formatFile}`
                : `Bill_${fileNameSaved}${formatFile}`;

        const params = {
            file_name: fileName,
            download_format: downloadFormat.toString(),
            date: checkedInvDay
                ? currentFormatDate === "DD/MM/YYYY"
                    ? "MM/DD/YYYY"
                    : "DD/MM/YYYY"
                : currentFormatDate,
            isSync: showSync,
        };

        if (groupedData) {
            params.grouped_invoice_id = groupedData._id;
        } else {
            params.conversion_id = conversionData._id;
        }

        await downloadConversionFunc(params);

        setDisabledInput(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setDownload(true);
        setConvertDone(false);
        setConvertDoneNew(false);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
        setIsLoading(false);
        setOpenModalDownloadF(false);
        setOpenModalReturnInvoice(false);
        reloadInvoices();
    };

    const reloadInvoices = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        const options = {
            signal: controllerRef.current.signal,
        };

        if (value === 0 && valueMain === 0) {
            if (conversion_type === 1) {
                setColumns(columns1);
            } else {
                setColumns(columns2);
            }

            await invoicesService
                .getInvoiceConversions(
                    {
                        company_id: todos.companyID,
                        conversion_type,
                    },
                    options
                )
                .then((response) => {
                    setConversions(response?.conversions);
                    setAllConversions(response);
                    setGroupConversions([]);
                    setConsolidatedConversions([]);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });
        } else {
            setColumns(columns3);
            await invoicesService
                .getConsolidatedInvoices(
                    {
                        company_id: todos.companyID,
                        conversion_type,
                    },
                    options
                )
                .then((response) => {
                    setConversions([]);
                    setGroupConversions([]);
                    setConsolidatedConversions(response);
                    //setGroupCheckConversions([])
                })
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });
        }
        controllerRef.current = null;
    };

    useEffect(() => {
        if (!todos.companyID) {
            navigate("/dashboard");
        }

        (async () => {
            setShowSkeleton(true);

            if (controllerRef.current) {
                controllerRef.current.abort();
            }
            const controller = new AbortController();
            controllerRef.current = controller;

            const options = {
                signal: controllerRef.current.signal,
            };

            if (valueMain === 0 && value === 0) {
                await invoicesService
                    .getInvoiceConversions(
                        {
                            company_id: todos.companyID,
                            conversion_type,
                        },
                        options
                    )
                    .then((response) => {
                        setConversions(response?.conversions);
                        setAllConversions(response);
                        setGroupConversions([]);
                        setConsolidatedConversions([]);
                        //setGroupCheckConversions([])
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.code !== "ERR_CANCELED") {
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        }
                    });
            } else if (valueMain === 1) {
                await invoicesService
                    .getConsolidatedInvoices(
                        {
                            company_id: todos.companyID,
                            conversion_type,
                        },
                        options
                    )
                    .then((response) => {
                        setConversions([]);
                        setGroupConversions([]);
                        setConsolidatedConversions(response);
                        //setGroupCheckConversions([])
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.code !== "ERR_CANCELED") {
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        }
                    });
            }
            controllerRef.current = null;

            if (controllerRefCompany.current) {
                controllerRefCompany.current.abort();
            }
            const controllerCompany = new AbortController();
            controllerRefCompany.current = controllerCompany;

            const optionsCompany = {
                signal: controllerRefCompany.current.signal,
            };

            await companyService
                .getCompanyDetails(todos.companyID, optionsCompany)
                .then((data) => {
                    setIntegration(data.system_integration);
                })
                .catch((error) => {
                    console.log(error);
                });

            controllerRefCompany.current = null;

            if (controllerRefConvUsers.current) {
                controllerRefConvUsers.current.abort();
            }
            const controllerConvUsers = new AbortController();
            controllerRefConvUsers.current = controllerConvUsers;

            const optionsConvUsers = {
                signal: controllerRefConvUsers.current.signal,
            };

            await invoicesService
                .getInvoicesConversionUsers(
                    {
                        company_id: todos.companyID,
                        tab: valueMain === 0 ? "invoices" : "consolidated",
                        conversion_type,
                    },
                    optionsConvUsers
                )
                .then((response) => setUsersFilters(response))
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });

            controllerRefConvUsers.current = null;

            setShowSkeleton(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, todos.companyID, valueMain]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await userCompanyService
                .getBusinessDirectoryDetails({ company_id: todos.companyID })
                .then(async (data) => {
                    setCompanyDetails(data);
                    setActivePlan(data.subscription.subscription_plan);
                    setPaymentType(data?.last_invoice?.line_items[0]?.line_qty === 365 ? "annual" : "monthly");
                    await userCompanyService
                        .getBusinessDirectory({ franchise_id: data.franchise_id })
                        .then((data) => {
                            const companies = data.companies.map((item) => ({
                                value: item._id,
                                name: item.company_name,
                            }));

                            setAllCompanies(companies);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
            setIsLoading(false);
        })();
    }, [setIsLoading, todos.companyID]);

    const removeBanner = () => {
        setConvertDone(false);
        setConvertDoneNew(false);
        setConvertInvalid(false);
        setShowPrev(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setCorrelative(null);
        setConversionId(null);
        setProgress(0);
        setEndConversion(false);
        setStatus(0);
        setDisabledInput(false);
        setGroupedData(null);
        setAcceptedFiles([]);
        setShowSync(false);
        setShowHistSync(false);
        setShowPrevConsolidated(false);
    };

    const handleConvertNewFiles = () => {
        setOpenModalGrouped(false);
    };

    const handleChangeTab = (event, newValue) => {
        let confirmChange = true;
        if (fileData) {
            confirmChange = window.confirm(t("converter.itsPosibleConfirm"));
        }

        if (confirmChange) {
            setShowSkeleton(true);
            setValueMain(newValue);
            setShowSkeleton(false);
            removeBanner();
            setValue(0); // Al cambiar de tab, siempre debe caer por defecto en "Individuales"
            setRows([]); // Se eliminan los registros que se muestran en las modales de "Ver detalles"
            setRowsModal([]);
            setShowRepeatVal([]);
            setShowRepeatModal(false);
        }
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
        setBasePreviewData({
            conversion_id: "",
            type: "",
            file_s3: "",
            file: "",
        });
    };

    const returnInvoiceModal = (invoice_id) => {
        setOpenModalReturnInvoice(true);
        setInvoiceReturn(invoice_id);
    };

    const returnInvoice = async (type) => {
        let params = {
            invoice_id: invoiceReturn,
            emails: emailReturn,
            description: errorReturn,
            type,
        };

        setIsLoading(true);
        await invoicesService
            .invoiceReturn(params)
            .then(async () => {
                handleCloseDialog();
                setTexto(type === 1 ? t("converter.returnInvoiceSuccess") : t("converter.returnInvoiceSuccess2"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });

        setShowPrev(false);
        reloadInvoices();
        setIsLoading(false);
    };

    const actionsContent = (
        <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
            <Grid item>
                <img src={Loading} alt="loading" />
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography variant="h1">{`${t("converter.syncInProgress")}`}</Typography>
                <Typography pt={1}>{t("converter.syncInProgressText")}</Typography>
            </Grid>
        </Grid>
    );

    const replaceInvBill = (text) => {
        return conversion_type === 1
            ? text
            : text.replace("invoices", "bills").replace("invoice", "bill").replace("Invoice", "Bill");
    };

    if (showPrev) {
        return (
            <BackGeneral colorBg="rgba(3, 24, 81, 0.03)">
                <InvoicesPreview
                    handleClickDownload={handleOpenDownloadModal}
                    removeBanner={removeBanner}
                    correlative={correlative}
                    handleClickHelp={handleClickHelp}
                    fileData={fileData}
                    conversionData={conversionData}
                    checkedInvSigngs={checkedInvSigngs}
                    setCheckedInvSigngs={setCheckedInvSigngs}
                    checkedInvDay={checkedInvDay}
                    setCheckedInvDay={setCheckedInvDay}
                    groupedData={groupedData}
                    groupedPeriods={groupedPeriods}
                    setIsLoading={setIsLoading}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setCorrelativeModalGrouped={setCorrelativeModalGrouped}
                    setRowsModal={setRowsModal}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setModalType={setModalType}
                    setShowSync={setShowSync}
                    showSync={showSync}
                    fileNameSaved={fileNameSaved}
                    integration={integration}
                    fileNameFull={fileNameFull}
                    reloadInvoices={reloadInvoices}
                    returnInvoiceModal={returnInvoiceModal}
                    setShowHistSync={setShowHistSync}
                    showHistSync={showHistSync}
                    setCorrelativeSync={setCorrelativeSync}
                    setRows={setRows}
                    setOpenPreview={setOpenPreview}
                    setFileData={setFileData}
                    setShowSyncDetails={setShowSyncDetails}
                    showSyncDetails={showSyncDetails}
                    conversion_type={conversion_type}
                    setBasePreviewData={setBasePreviewData}
                    basePreviewData={basePreviewData}
                    setOpenHeavy={setOpenHeavy}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    replaceInvBill={replaceInvBill}
                    selectedInv={selectedInv}
                    setFileNameFull={setFileNameFull}
                    setConversionContacts={setConversionContacts}
                    conversionContacts={conversionContacts}
                />
                <SimpleBackdrop open={isLoading} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                {open && (
                    <ChatModal
                        room={room}
                        setRoom={setRoom}
                        open={open}
                        setOpen={setOpen}
                        setChat={setChat}
                        chat={chat}
                        type={statusCase}
                        users={users}
                        franchiseName={
                            todos.allComData?.find(
                                (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                            )?.franchise_name
                        }
                        setCaseInfo={setCaseInfo}
                        caseInfo={caseInfo}
                    />
                )}
                <FormDialog
                    open={openModalDownloadF}
                    handleClose={handleCloseDialog}
                    maxWidth="sm"
                    title={
                        <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                            <InfoIcon fontSize="small" />
                            <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                                {t("converter.selectDownloadFormat")}
                            </Typography>
                        </Stack>
                    }
                    content={
                        <DownloadFormatContent
                            downloadFormat={downloadFormat}
                            handleChangeDownloadFormat={handleClickDownload}
                            qbFormat={false}
                            type="invoices"
                        />
                    }
                    actions={
                        <DownloadFormatButtons
                            handleCloseDialog={handleCloseDialog}
                            downloadQBO={downloadFormatsInvoices}
                            downloadFormat={downloadFormat}
                        />
                    }
                    align="center"
                />
                <FormDialog
                    open={openModalGrouped}
                    handleClose={handleCloseGroupedDiag}
                    maxWidth="md"
                    title={
                        modalType === "sync" ? (
                            <Typography variant="h3" component="span">
                                {replaceInvBill(t("converter.syncInvDetails"))} |{" "}
                                <Typography variant="h3" component="span" color="primary">
                                    {t("converter.synchronization")} {correlativeSync}
                                </Typography>
                            </Typography>
                        ) : (
                            <Typography variant="h3" component="span">
                                {replaceInvBill(t("converter.convDetailsInvoices")) +
                                    (conversionData ? " | GROUP-" + zeroPad(correlativeModalGrouped ?? 0, 5) : "")}
                            </Typography>
                        )
                    }
                    content={
                        <GroupedContent
                            groupedData={groupedData}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setConvFormat={setConvFormat}
                            acceptedFiles={acceptedFiles}
                            setAcceptedFiles={setAcceptedFiles}
                            setFileData={setFileData}
                            setGroupedData={setGroupedData}
                            setConvertDone={setConvertDone}
                            setS3FileName={setS3FileName}
                            setOriginalFileName={setOriginalFileName}
                            s3FileName={s3FileName}
                            originalFileName={originalFileName}
                            formats={invoiceFormats}
                            setRows={setRows}
                            rows={rows}
                            setOpenPreview={setOpenPreview}
                            setIsLoading={setIsLoading}
                            isHist={openModalGroupedHist}
                            setFileNameFull={setFileNameFull}
                            accountId={""}
                            setProgress={setProgress}
                            progress={progress}
                            modalType={modalType}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                            setBasePreviewData={setBasePreviewData}
                            setShowRepeatModal={setShowRepeatModal}
                            setShowRepeatVal={setShowRepeatVal}
                            setOpenModalGrouped={setOpenModalGrouped}
                            replaceInvBill={replaceInvBill}
                        />
                    }
                    actions={
                        <GroupedButtons
                            convertAll={handleConvertNewFiles}
                            handleCloseDialog={handleCloseGroupedDiag}
                            isHist={openModalGroupedHist}
                            modalType={modalType}
                        />
                    }
                />
                {openPreview && (
                    <SimpleDialog
                        open={openPreview}
                        handleClose={handleClosePreview}
                        maxWidth="md"
                        preview={true}
                        content={
                            <BasePreviewFile
                                filename={fileNameFull ? fileNameFull?.toLowerCase() : fileData?.path?.toLowerCase()}
                                filedata={fileData}
                                __html={__html}
                            />
                        }
                        actions={fileData ? <BaseActionsPreviewFile basePreviewData={basePreviewData} /> : null}
                    />
                )}
                <FormDialog
                    open={openModalReturnInvoice}
                    handleClose={handleCloseDialog}
                    maxWidth="sm"
                    title={
                        <Stack direction="row" justifyContent="center" spacing={1} alignItems="center" mx={-2}>
                            <InfoIcon fontSize="small" />
                            <Typography fontSize={16} color="primary" fontWeight={600} textAlign="center">
                                {t("converter.returnInvoiceMsg")}
                            </Typography>
                        </Stack>
                    }
                    content={
                        <ReturnInvoiceContent
                            emailReturn={emailReturn}
                            setEmailReturn={setEmailReturn}
                            errorReturn={errorReturn}
                            setErrorReturn={setErrorReturn}
                        />
                    }
                    actions={
                        <ReturnInvoiceButtons
                            emailReturn={emailReturn}
                            errorReturn={errorReturn}
                            returnInvoice={returnInvoice}
                            handleCloseDialog={handleCloseDialog}
                        />
                    }
                    align="center"
                />
            </BackGeneral>
        );
    }

    if (showPrevConsolidated) {
        return (
            <BackGeneral colorBg="rgba(3, 24, 81, 0.03)">
                <ConsolidatedInvoicesPreview
                    handleClickDownload={handleOpenDownloadModal}
                    removeBanner={removeBanner}
                    correlative={correlative}
                    handleClickHelp={handleClickHelp}
                    fileData={fileData}
                    conversionData={conversionData}
                    checkedInvSigngs={checkedInvSigngs}
                    setCheckedInvSigngs={setCheckedInvSigngs}
                    checkedInvDay={checkedInvDay}
                    setCheckedInvDay={setCheckedInvDay}
                    groupedData={groupedData}
                    groupedPeriods={groupedPeriods}
                    setIsLoading={setIsLoading}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setRowsModal={setRowsModal}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setModalType={setModalType}
                    setShowSync={setShowSync}
                    showSync={showSync}
                    fileNameSaved={fileNameSaved}
                    integration={integration}
                    fileNameFull={fileNameFull}
                    reloadInvoices={reloadInvoices}
                    returnInvoiceModal={returnInvoiceModal}
                    setShowHistSync={setShowHistSync}
                    showHistSync={showHistSync}
                    setCorrelativeSync={setCorrelativeSync}
                    setRows={setRows}
                    setOpenPreview={setOpenPreview}
                    setFileData={setFileData}
                    setShowSyncDetails={setShowSyncDetails}
                    showSyncDetails={showSyncDetails}
                    conversion_type={conversion_type}
                    setBasePreviewData={setBasePreviewData}
                    setFileNameFull={setFileNameFull}
                    replaceInvBill={replaceInvBill}
                    setCorrelativeModalGrouped={setCorrelativeModalGrouped}
                    setShowPrevConsolidated={setShowPrevConsolidated}
                />
                <SimpleBackdrop open={isLoading} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                {open && (
                    <ChatModal
                        room={room}
                        setRoom={setRoom}
                        open={open}
                        setOpen={setOpen}
                        setChat={setChat}
                        chat={chat}
                        type={statusCase}
                        users={users}
                        franchiseName={
                            todos.allComData?.find(
                                (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                            )?.franchise_name
                        }
                        setCaseInfo={setCaseInfo}
                        caseInfo={caseInfo}
                    />
                )}
                {openPreview && (
                    <SimpleDialog
                        open={openPreview}
                        handleClose={handleClosePreview}
                        maxWidth="md"
                        preview={true}
                        content={
                            <BasePreviewFile
                                filename={fileNameFull ? fileNameFull?.toLowerCase() : fileData?.path?.toLowerCase()}
                                filedata={fileData}
                                __html={__html}
                            />
                        }
                        actions={fileData ? <BaseActionsPreviewFile basePreviewData={basePreviewData} /> : null}
                    />
                )}
            </BackGeneral>
        );
    }

    if (showNewInv) {
        return (
            <BackGeneral colorBg="rgba(3, 24, 81, 0.03)">
                <NewInvoice
                    valueAddInv={valueAddInv}
                    setValueAddInv={setValueAddInv}
                    setShowNewInv={setShowNewInv}
                    setIsLoading={setIsLoading}
                    conversion_type={conversion_type}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    currentInv={currentInv}
                    replaceInvBill={replaceInvBill}
                    conversionData={conversionData}
                    fileData={fileData}
                    fileNameFull={fileNameFull}
                    setCurrentInv={setCurrentInv}
                    setConversionData={setConversionData}
                    setFileData={setFileData}
                    reloadInvoices={reloadInvoices}
                    resetConversionInvoices={resetConversionInvoices}
                    integration={integration}
                    isLoading={isLoading}
                />
                <SimpleBackdrop open={isLoading} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            </BackGeneral>
        );
    }

    return (
        <BackGeneral>
            <Box mt={6} ml={4} sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Typography variant="h1" color="secondary" gutterBottom pl={4}>
                    {conversion_type === 1 ? t("navBar.salesInvoices") : t("navBar.purchaseInvoices")}
                </Typography>
                <Tabs
                    orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    aria-label="Horizontal tabs"
                    value={valueMain}
                    onChange={handleChangeTab}
                >
                    <Tab
                        label={conversion_type === 1 ? t("converter.separateInv") : t("converter.separateBills")}
                        {...a11yProps(0)}
                        sx={{ px: 4 }}
                    />
                    {conversion_type === 1 && (
                        <Tab label={t("converter.salesReports")} {...a11yProps(1)} sx={{ px: 4 }} />
                    )}
                </Tabs>
            </Box>
            <TabPanel value={valueMain} index={0}>
                <InvoicesConverter
                    setIsLoading={setIsLoading}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    setShowSkeleton={setShowSkeleton}
                    convertDoneNew={convertDoneNew}
                    setCorrelative={setCorrelative}
                    correlative={correlative}
                    setConversionId={setConversionId}
                    conversionId={conversionId}
                    setFileNameSaved={setFileNameSaved}
                    fileNameSaved={fileNameSaved}
                    setConvertDoneNew={setConvertDoneNew}
                    setShowPrev={setShowPrev}
                    showModalCredits={showModalCredits}
                    setShowModalCredits={setShowModalCredits}
                    resetConversionMain={resetConversionMain}
                    setResetConversionMain={setResetConversionMain}
                    handleClickHelp={handleClickHelp}
                    setFileData={setFileData}
                    fileData={fileData}
                    setConversionData={setConversionData}
                    setDisabledInput={setDisabledInput}
                    disabledInput={disabledInput}
                    formats={invoiceFormats}
                    setConvertDone={setConvertDone}
                    convertDone={convertDone}
                    setConvertRev={setConvertRev}
                    convertRev={convertRev}
                    setConvertInvalid={setConvertInvalid}
                    convertInvalid={convertInvalid}
                    setStatus={setStatus}
                    status={status}
                    setConvFormat={setConvFormat}
                    convFormat={convFormat}
                    connectSocketConv={connectSocketConv}
                    setFileNameFull={setFileNameFull}
                    groupedData={groupedData}
                    setGroupedData={setGroupedData}
                    setGroupedPeriods={setGroupedPeriods}
                    groupedPeriods={groupedPeriods}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setAcceptedFiles={setAcceptedFiles}
                    acceptedFiles={acceptedFiles}
                    setS3FileName={setS3FileName}
                    s3FileName={s3FileName}
                    progress={progress}
                    setProgress={setProgress}
                    setEndConversion={setEndConversion}
                    setRows={setRows}
                    rows={rows}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setCustomCredits={setCustomCredits}
                    customCredits={customCredits}
                    setModalType={setModalType}
                    setRowsModal={setRowsModal}
                    rowsModal={rowsModal}
                    valueMain={valueMain}
                    conversion_type={conversion_type}
                    reloadInvoices={reloadInvoices}
                    showRepeatModal={showRepeatModal}
                    setShowRepeatModal={setShowRepeatModal}
                    showRepeatVal={showRepeatVal}
                    setShowRepeatVal={setShowRepeatVal}
                    setBasePreviewData={setBasePreviewData}
                    setOpenPreview={setOpenPreview}
                    setSelectedInv={setSelectedInv}
                    companyDetails={companyDetails}
                    activePlan={activePlan}
                    setActivePlan={setActivePlan}
                    allCompanies={allCompanies}
                    paymentType={paymentType}
                    setConversionContacts={setConversionContacts}
                    setShowNewInv={setShowNewInv}
                    resetConversionInvoices={resetConversionInvoices}
                />
                <MainInvTabs
                    conversions={conversions}
                    usersFilters={usersFilters}
                    company_id={todos?.companyID}
                    setConversions={setConversions}
                    setIsLoading={setIsLoading}
                    franchise_id={todos?.franchiseID}
                    grupal={todos?.isGrouped}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    setFilters={setFilters}
                    filters={filters}
                    setTutorial={setTutorial}
                    tutorial={tutorial}
                    setConvertDoneNew={setConvertDoneNew}
                    setCorrelative={setCorrelative}
                    setConversionId={setConversionId}
                    setFileNameSaved={setFileNameSaved}
                    setShowPrev={setShowPrev}
                    setFileData={setFileData}
                    setFileNameFull={setFileNameFull}
                    setConversionData={setConversionData}
                    handleGetConversions={handleGetConversions}
                    finalQBOBanks={finalQBOBanks}
                    download={download}
                    setDownload={setDownload}
                    setConvFormat={setConvFormat}
                    setGroupConversions={setGroupConversions}
                    groupConversions={groupConversions}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setGroupedData={setGroupedData}
                    setGroupedPeriods={setGroupedPeriods}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setCorrelativeModalGrouped={setCorrelativeModalGrouped}
                    setValue={setValue}
                    value={value}
                    setModalType={setModalType}
                    columns={columns}
                    setColumns={setColumns}
                    ogColumns={columns1}
                    columns2={columns2}
                    setRowsModal={setRowsModal}
                    setOpenPreview={setOpenPreview}
                    conversion_type={conversion_type}
                    setBasePreviewData={setBasePreviewData}
                    integration={integration}
                    setShowSync={setShowSync}
                    setShowNewInv={setShowNewInv}
                    setAllConversions={setAllConversions}
                    allConversions={allConversions}
                    setCurrentInv={setCurrentInv}
                    setSelectedInv={setSelectedInv}
                    setConversionContacts={setConversionContacts}
                />
            </TabPanel>
            <TabPanel value={valueMain} index={1}>
                <ConsolidatedInvoicesConverter
                    setIsLoading={setIsLoading}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    convertDoneNew={convertDoneNew}
                    correlative={correlative}
                    setCorrelative={setCorrelative}
                    setConversionId={setConversionId}
                    setFileNameSaved={setFileNameSaved}
                    setConvertDoneNew={setConvertDoneNew}
                    setShowPrevConsolidated={setShowPrevConsolidated}
                    showModalCredits={showModalCredits}
                    setShowModalCredits={setShowModalCredits}
                    resetConversionMain={resetConversionMain}
                    setResetConversionMain={setResetConversionMain}
                    handleClickHelp={handleClickHelp}
                    setFileData={setFileData}
                    fileData={fileData}
                    setConversionData={setConversionData}
                    setDisabledInput={setDisabledInput}
                    disabledInput={disabledInput}
                    formats={consolidatedFormats}
                    setConvertDone={setConvertDone}
                    convertDone={convertDone}
                    setConvertRev={setConvertRev}
                    convertRev={convertRev}
                    setConvertInvalid={setConvertInvalid}
                    convertInvalid={convertInvalid}
                    setStatus={setStatus}
                    status={status}
                    setConvFormat={setConvFormat}
                    setFileNameFull={setFileNameFull}
                    acceptedFiles={acceptedFiles}
                    setAcceptedFiles={setAcceptedFiles}
                    setS3FileName={setS3FileName}
                    s3FileName={s3FileName}
                    progress={progress}
                    setProgress={setProgress}
                    setEndConversion={setEndConversion}
                    setRows={setRows}
                    rows={rows}
                    setCustomCredits={setCustomCredits}
                    customCredits={customCredits}
                    setRowsModal={setRowsModal}
                    rowsModal={rowsModal}
                    conversion_type={conversion_type}
                    endConversion={endConversion}
                    showRepeatModal={showRepeatModal}
                    setShowRepeatModal={setShowRepeatModal}
                    showRepeatVal={showRepeatVal}
                    setShowRepeatVal={setShowRepeatVal}
                    setOpenPreview={setOpenPreview}
                    setHTML={setHTML}
                    setBasePreviewData={setBasePreviewData}
                    companyDetails={companyDetails}
                    activePlan={activePlan}
                    setActivePlan={setActivePlan}
                    allCompanies={allCompanies}
                    paymentType={paymentType}
                />
                <ConsolidatedInvoicesTable
                    consolidatedConversions={consolidatedConversions}
                    usersFilters={usersFilters}
                    company_id={todos?.companyID}
                    setConsolidatedConversions={setConsolidatedConversions}
                    setIsLoading={setIsLoading}
                    franchise_id={todos?.franchiseID}
                    grupal={todos?.isGrouped}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    setFilters={setFilters}
                    filters={filters}
                    setTutorial={setTutorial}
                    tutorial={tutorial}
                    setConvertDoneNew={setConvertDoneNew}
                    setCorrelative={setCorrelative}
                    setConversionId={setConversionId}
                    setFileNameSaved={setFileNameSaved}
                    setShowPrevConsolidated={setShowPrevConsolidated}
                    setFileData={setFileData}
                    setFileNameFull={setFileNameFull}
                    setConversionData={setConversionData}
                    handleGetConversions={handleGetConversions}
                    download={download}
                    setDownload={setDownload}
                    setConvFormat={setConvFormat}
                    setModalType={setModalType}
                    reloadInvoices={reloadInvoices}
                    setRowsModal={setRowsModal}
                    setOpenPreview={setOpenPreview}
                    setHTML={setHTML}
                    conversion_type={conversion_type}
                    setBasePreviewData={setBasePreviewData}
                    columns={columns3}
                />
            </TabPanel>
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            {open && (
                <ChatModal
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type={1}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    setCaseInfo={setCaseInfo}
                    caseInfo={caseInfo}
                />
            )}
            <FormDialog
                open={openModalGrouped}
                handleClose={handleCloseGroupedDiag}
                maxWidth="md"
                title={
                    <Typography variant="h3" component="span">
                        {t("converter.convDetailsInvoices") +
                            (conversionData ? " | GROUP-" + zeroPad(correlativeModalGrouped ?? 0, 5) : "")}
                    </Typography>
                }
                content={
                    <GroupedContent
                        groupedData={groupedData}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        setShow={setShow}
                        setConvFormat={setConvFormat}
                        acceptedFiles={acceptedFiles}
                        setAcceptedFiles={setAcceptedFiles}
                        setFileData={setFileData}
                        setGroupedData={setGroupedData}
                        setConvertDone={setConvertDone}
                        setS3FileName={setS3FileName}
                        s3FileName={s3FileName}
                        formats={invoiceFormats}
                        setRows={setRows}
                        rows={rows}
                        setOpenPreview={setOpenPreview}
                        setIsLoading={setIsLoading}
                        isHist={openModalGroupedHist}
                        setFileNameFull={setFileNameFull}
                        accountId={""}
                        setProgress={setProgress}
                        progress={progress}
                        modalType={modalType}
                        setRowsModal={setRowsModal}
                        rowsModal={rowsModal}
                        setBasePreviewData={setBasePreviewData}
                        setShowRepeatModal={setShowRepeatModal}
                        setShowRepeatVal={setShowRepeatVal}
                        setOpenModalGrouped={setOpenModalGrouped}
                        replaceInvBill={replaceInvBill}
                    />
                }
                actions={
                    <GroupedButtons
                        convertAll={handleConvertNewFiles}
                        handleCloseDialog={handleCloseGroupedDiag}
                        isHist={openModalGroupedHist}
                    />
                }
            />
            {openPreview && (
                <SimpleDialog
                    open={openPreview}
                    handleClose={handleClosePreview}
                    maxWidth="md"
                    preview={true}
                    content={
                        <BasePreviewFile
                            filename={fileNameFull ? fileNameFull?.toLowerCase() : fileData?.path?.toLowerCase()}
                            filedata={fileData}
                            __html={__html}
                        />
                    }
                    actions={fileData ? <BaseActionsPreviewFile basePreviewData={basePreviewData} /> : null}
                />
            )}
            <SimpleDialog open={openHeavy} handleClose={handleClose} maxWidth="sm" content={actionsContent} />
        </BackGeneral>
    );
};

export default ConverterInvoices;
