import {
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const NewInvoiceTableSummary = (props) => {
    const {
        totalsInfo
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <StyledTableRow
                        sx={{
                            "& th": { border: "none" },
                            backgroundColor: "#f8f8f9",
                        }}
                    >
                        <StyledTableCell2
                            align={"right"}
                        >
                            <Typography variant="h3">Subtotal</Typography>
                        </StyledTableCell2>
                        <StyledTableCell2
                            key={"subtotalInv"}
                            align={"center"}
                            style={{
                                width: "50%",
                            }}
                        >
                            <Typography variant="h3" fontWeight={500}>{parseFloat(totalsInfo.subtotal).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })}</Typography>
                        </StyledTableCell2>
                    </StyledTableRow>
                    <StyledTableRow
                        sx={{
                            "& th": { border: "none" },
                        }}
                    >
                        <StyledTableCell2
                            align={"right"}
                        >
                            <Typography variant="h3">{t("converter.discount")}</Typography>
                        </StyledTableCell2>
                        <StyledTableCell2
                            key={"discountInv"}
                            align={"center"}
                            style={{
                                width: "50%",
                            }}
                        >
                            <Typography variant="h3" fontWeight={500}>{parseFloat(totalsInfo.discount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })}</Typography>
                        </StyledTableCell2>
                    </StyledTableRow>
                    <StyledTableRow
                        sx={{
                            "& th": { border: "none" },
                        }}
                    >
                        <StyledTableCell2
                            align={"right"}
                        >
                            <Typography variant="h3">{t("converter.totalTax")}</Typography>
                        </StyledTableCell2>
                        <StyledTableCell2
                            key={"taxInv"}
                            align={"center"}
                            style={{
                                width: "50%",
                            }}
                        >
                            <Typography variant="h3" fontWeight={500}>{parseFloat(totalsInfo.tax).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })}</Typography>
                        </StyledTableCell2>
                    </StyledTableRow>
                    <StyledTableRow
                        sx={{
                            "& th": { border: "none" },
                            backgroundColor: "#f8f8f9",
                        }}
                    >
                        <StyledTableCell2
                            align={"right"}
                        >
                            <Typography variant="h3">Total</Typography>
                        </StyledTableCell2>
                        <StyledTableCell2
                            key={"totalInv"}
                            align={"center"}
                            style={{
                                width: "50%",
                            }}
                        >
                            <Typography variant="h3" fontWeight={500}>{parseFloat(totalsInfo.total).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })}</Typography>
                        </StyledTableCell2>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NewInvoiceTableSummary;
