import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled, Typography } from "@mui/material";
import LightTooltip from "./LightTooltip";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: theme.palette.V080,     // Color por defecto
    fontWeight: 600,               // Peso de la fuente por defecto

    "&.Mui-focused": {
        color: theme.palette.V090, // Color cuando el label está enfocado
    },
}));

// Estilo personalizado para el Select
const StyledSelect = styled(Select)(({ theme }) => ({
    // Fondo blanco para el contenedor principal
    backgroundColor: "white",

    // Aplica el color del borde por defecto
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.V050,
    },

    // Aplica el color del borde en hover
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.V090,
    },

    // Aplica el color del borde en focus
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.V090,
    },

}));


export default function BaseSelect(props) {
    const { id, value, onChange, values, label, error, type, disabled, hasTooltip, language, name, ...rest } = props;

    if (hasTooltip) {
        return (
            <LightTooltip title={values.find((item) => item.id === value)?.name} position="top">
                <FormControl fullWidth error={error} disabled={disabled}>
                    <StyledInputLabel id={"customized-base-select-label" + id}>
                        {label}
                    </StyledInputLabel>
                    <StyledSelect
                        labelId={"customized-base-select-label" + id}
                        id={"customized-base-select" + id}
                        value={value}
                        onChange={onChange}
                        name={name}
                        MenuProps={MenuProps}
                        label={label}
                        sx={{ height: 48 }}  // Establecer la altura del Select
                    >
                        {values.map((val) => (
                            <MenuItem key={val.id} value={val.id}>
                                {type === "bank" ? (
                                    <>
                                        <Typography fontSize={16} fontWeight={600} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                            {val.name}&nbsp;
                                        </Typography>
                                        <Typography>{val.bankName}</Typography>
                                    </>
                                ) : (
                                    val.name
                                )}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
            </LightTooltip>
        );
    }

    return (
        <FormControl fullWidth error={error} disabled={disabled} sx={{ height: 48 }}>
            <StyledInputLabel id={"customized-base-select-label" + id}>
                {label}
            </StyledInputLabel>
            <StyledSelect
                {...rest}
                labelId={"customized-base-select-label" + id}
                id={"customized-base-select" + id}
                value={value}
                onChange={onChange}
                name={name}
                MenuProps={MenuProps}
                label={label}
                sx={{ height: 48 }}  // Establecer la altura del Select
                variant="outlined" // Asegúrate de usar la variante "outlined"
            >
                {values.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                        {type === "bank" ? (
                            <>
                                <Typography variant="h3" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                    {val.name}&nbsp;
                                </Typography>
                                <Typography>{val.bankName}</Typography>
                            </>
                        ) : type === "conversionFormats" ? (
                            language === "es" ? (
                                val.name
                            ) : (
                                val.name_en
                            )
                        ) : (
                            val.name
                        )}
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    );
}
