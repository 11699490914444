/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import conversionService from "../../../services/conversion";
import { useNavigate } from "react-router-dom";

/** MUI Icons */
// import ConverterIcon from "../../assets/converter-icon.svg";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckIcon from "@mui/icons-material/Check";
import CachedIcon from "@mui/icons-material/Cached";
import PreviewIcon from "@mui/icons-material/Preview";

/** MUI imports */
import { Box, Grid, IconButton, Link, Stack, Typography } from "@mui/material";

/** Component imports */
import classes from "../../ui/UploadFile.module.css";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import LightTooltip from "../../ui/LightTooltip";

//const formats = [".xlsx", ".pdf", ".csv"];

const UploadFileInc = (props) => {
    const {
        acceptedFiles,
        setAcceptedFiles,
        formats,
        disabled,
        setTexto,
        setAlertType,
        setShow,
        accountId,
        progress,
        setProgress,
        setShowRepeatModal,
        setShowRepeatVal,
        setStatus,
        reload,
        setConvInfo,
        accountDet,
        isLoadingConv,
        previewDoc,
        type,
        setFileUpInfo,
        fileUpInfo,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const navigate = useNavigate();

    const todos = useSelector((state) => state.value);

    /** Component states */
    const [loadingFile, setLoadingFile] = useState(true);

    let acceptFormat = {};
    if (formats && formats.map((item) => item.name).includes("pdf")) {
        acceptFormat["application/pdf"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("png")) {
        acceptFormat["image/png"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("jpg")) {
        acceptFormat["image/jpg"] = [];
        acceptFormat["image/jpeg"] = [];
    }

    /** Component functions */
    const onDrop = useCallback(
        async (newAcceptedFiles) => {
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded / total) * 100);

                    if (percent < 100) {
                        setProgress(percent);
                    }
                },
            };

            setLoadingFile(true);

            let isHeavy = false;
            const data = new FormData();
            data.append("accountId", accountId);
            data.append("type", type);
            data.append("company_id", todos.companyID);

            if (newAcceptedFiles.length === 1) {
                if (newAcceptedFiles[0].size >= 50000000) {
                    isHeavy = true;
                } else {
                    data.append("file", newAcceptedFiles[0]);

                    setAcceptedFiles([...acceptedFiles, newAcceptedFiles[0]]);

                    await conversionService
                        .uploadFileS3(data, options)
                        .then((response) => {
                            setStatus(response.statusByFile[0].status === 200 ? 1 : 2);
                            setFileUpInfo({
                                correlative: response.correlative,
                                filenameS3: response.statusByFile[0].filename,
                                filenameOG: response.statusByFile[0].original_filename,
                                price: response.statusByFile[0].price,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            setStatus(3);
                            if (err?.response?.data?.correlative) {
                                setFileUpInfo({
                                    correlative: err?.response?.data?.correlative,
                                    filenameS3:  err?.response?.data.statusByFile[0].filename,
                                    filenameOG:  err?.response?.data.statusByFile[0].original_filename,
                                    price:  err?.response?.data.statusByFile[0].price,
                                });
                                setTexto(t("converter.errorUpload"));
                                setAlertType("error");
                                setShow(true);
                            } else {
                                setTexto(t("converter.errorUpload"));
                                setAlertType("error");
                                setShow(true);
                            }

                            reload();
                        });
                }
            }

            if (newAcceptedFiles.length === 0) {
                setTexto(t("converter.warningWrongQty"));
                setAlertType("warning");
                setShow(true);
            }
            if (isHeavy) {
                setTexto(t("converter.warningMaxSize"));
                setAlertType("warning");
                setShow(true);

                const params = {
                    user_name: todos.userInfo.user_name,
                    franchise_name: todos.allComData.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    ).franchise_name,
                    company_name: todos.allComData.find(
                        (company) => !company.franchise_name && company._id === todos.companyID
                    ).company_name,
                };

                //Send email
                await conversionService.notifySupport(params).catch((err) => {
                    console.log(err);
                });
            }

            setProgress(0);
            setLoadingFile(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            accountDet,
            accountId,
            reload,
            setAcceptedFiles,
            setAlertType,
            setConvInfo,
            setProgress,
            setShow,
            setShowRepeatModal,
            setShowRepeatVal,
            setStatus,
            setTexto,
            t,
            todos.allComData,
            todos.companyID,
            todos.franchiseID,
            todos.userInfo.user_name,
            type,
        ]
    );

    const handleClick = (e, type) => {
        e.stopPropagation();
        if (type) {
            navigate("/inbox");
        }
    };

    const deleteFile = async (e) => {
        e.stopPropagation();
        const params = {
            uploaded_file: fileUpInfo.filenameS3,
            type: type,
            company_id: todos.companyID,
        };

        await conversionService
            .deleteFileS3(params)
            .then(() => {
                setStatus(0);
                setAcceptedFiles([]);
                setFileUpInfo(null);
            })
            .catch((err) => {
                console.log(err);
                setTexto(t("converter.errorUpload"));
                setAlertType("error");
                setShow(true);
            });
    };

    const { getRootProps, getInputProps } = useDropzone({
        disabled: formats.length === 0 || disabled || progress > 0,
        accept: acceptFormat,
        maxFiles: 1,
        onDrop,
    });

    const formatBytes = (bytes) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
    };

    return (
        <Box
            {...getRootProps({
                className: formats.length === 0 || disabled || progress > 0 ? classes.dropzone : classes.dropzoneActive,
            })}
        >
            <input {...getInputProps()} />
            <Grid container spacing={2} justifyContent="center">
                <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{
                        bgcolor: "#fff",
                        padding: "6px 10px 12px 30px !important",
                        mt: 2,
                        border: "1px solid #E0E0E0",
                    }}
                >
                    <Stack direction="row" spacing={0} mt={1} alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={window.innerWidth > 1360 ? 15 : 12}
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        formats.length === 0 ? "converter.dragDropDisabled" : "converter.dragDrop"
                                    ),
                                }}
                            ></Typography>
                            <Stack direction="row" spacing={1} mt={2} alignItems="center">
                                <Typography variant="h2" fontWeight={500} fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                    {type === "invoice" || type === "consolidated"
                                        ? t("converter.formatInv")
                                        : t("converter.formatRevenue")}
                                    :
                                </Typography>
                                {formats.map((item, index) => (
                                    <Box component="span" className={classes.boxFormat} key={"format_" + index}>
                                        <Typography
                                            fontWeight={600}
                                            fontSize={window.innerWidth > 1360 ? "11px !important" : "9px !important"}
                                        >
                                            .{item.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                        <UploadFileIcon
                            fontSize={window.innerWidth > 1360 ? "large" : "medium"}
                            sx={{ color: formats.length === 0 ? "disabled" : "#361A52", mr: 1.5 }}
                        />
                    </Stack>
                </Grid>
                {((acceptedFiles.length > 0 && progress !== 0 && loadingFile) || isLoadingConv) && (
                    <Grid item xs={12} sm={11} sx={{ pt: "20px !important" }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="h2" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                {t("converter.uploading")}
                            </Typography>
                            <CachedIcon color="primary" fontSize="small" />
                        </Stack>
                    </Grid>
                )}
                {((acceptedFiles.length > 0 && progress === 0 && !loadingFile) || isLoadingConv) && (
                    <Grid item xs={12} sm={11} sx={{ paddingTop: isLoadingConv ? "0 !important" : "16px !important" }}>
                        {!isLoadingConv && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="h4" fontSize={window.innerWidth > 1360 ? 13 : 11} gutterBottom>
                                    {t("converter.fileLoaded")}
                                </Typography>
                                <CheckIcon sx={{ color: "#0BEF57" }} fontSize="small" />
                            </Stack>
                        )}
                        {acceptedFiles.length === 1 && (
                            <Stack direction="row" spacing={0.7} alignItems="center">
                                <Typography color="primary" variant="h4" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                    {acceptedFiles[0].path} ({formatBytes(acceptedFiles[0].size)})
                                </Typography>
                                <LightTooltip title={t("converter.previewFile")}>
                                    <IconButton size="small" color="primary" onClick={previewDoc}>
                                        <PreviewIcon fontSize="small" />
                                    </IconButton>
                                </LightTooltip>
                                <LightTooltip title={t("miscellaneous.clear")}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        disabled={isLoadingConv}
                                        onClick={(e) => !isLoadingConv && deleteFile(e)}
                                        sx={{
                                            color: isLoadingConv ? "grey" : "#361A52",
                                            transition: "all .2s ease-in",
                                            "&:hover": {
                                                color: isLoadingConv ? "grey" : "red",
                                            },
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </LightTooltip>
                            </Stack>
                        )}
                        {acceptedFiles.length > 1 && (
                            <Stack direction="row" spacing={0.7} alignItems="center">
                                <Typography color="primary" variant="h4" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                    {acceptedFiles.length}
                                    {t("converter.uploadedFiles")}
                                </Typography>
                                <LightTooltip title={t("miscellaneous.clear")} position="right">
                                    <DeleteIcon
                                        sx={{
                                            width: "18px",
                                            color: isLoadingConv ? "grey" : "#4A22D4",
                                            transition: "all .2s ease-in",
                                            "&:hover": {
                                                color: isLoadingConv ? "grey" : "red",
                                            },
                                            cursor: "pointer",
                                        }}
                                        disabled={isLoadingConv}
                                        onClick={(e) => !isLoadingConv && deleteFile(e)} // Evita el click si está deshabilitado
                                    />
                                </LightTooltip>
                            </Stack>
                        )}
                    </Grid>
                )}
                {acceptedFiles.length === 0 && (
                    <>
                        <Grid
                            item
                            xs={12}
                            sm={11}
                            sx={{
                                paddingTop: acceptedFiles.length === 0 ? "16px !important" : "0 !important",
                            }}
                        >
                            <Typography fontSize={window.innerWidth > 1360 ? 13 : 11} fontWeight={500}>
                                {t("incomes.needHelp")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={11} sx={{ paddingTop: "0 !important" }}>
                            <Link
                                {...getRootProps({ onClick: (e) => handleClick(e, 1) })}
                                sx={{
                                    fontWeight: 600,
                                    color: formats.length === 0 ? "#bcbcbc" : "#361A52",
                                }}
                            >
                                {t("converter.instrucText4")}
                            </Link>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default UploadFileInc;
