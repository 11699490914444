/* Componentes */
import { useState, useEffect } from "react";
import BackGeneral from "../components/layout/BackGeneral";
import computerVision from "../services/computer_vision";
import {
    Container,
    Typography,
    Grid,
    Stack,
    MenuItem,
    FilledInput,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import BaseButton from "../components/ui/BaseButton";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const ComputerVisionStatements = () => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [elapsedTime, setElapsedTime] = useState("");
    const [azureModels, setAzureModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState("");
    const [conversionID, setConversionID] = useState("");

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setData([]);
        setError(false);

        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("model", selectedModel);

        try {
            const response = await computerVision.analyzeBankStatements(formData);
            // console.log(response);

            setData(response);
            setElapsedTime(response[0].elapsed_time);
            setConversionID(response[0].conversion_id);
        } catch (error) {
            setError(true);
            console.error("Error:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await computerVision
                .getCustomModelsStatements()
                .then((data) => {
                    setAzureModels(data);
                    setSelectedModel(data[0]);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, []);

    const handleChangeModel = (event) => {
        setSelectedModel(event.target.value);
    };

    const downloadAIConversion = async () => {
        setIsLoading(true);

        const params = {
            conversion_id: conversionID,
            file_name: `Conv_${file?.name?.replace(/\.[^/.]+$/, "")}.csv`
        };

        await computerVision
            .downloadAIConversion(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 45 * 4.5 + 8,
                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
            },
            sx: {
                borderRadius: 2,
                mt: 0.7,
            },
        },
        variant: "menu",
        getcontentanchorel: null,
    };

    return (
        <>
            <BackGeneral colorBg="#fff">
                <Container maxWidth="lg">
                    <Grid container spacing={6}>
                        <Grid item xs={12} sx={{ marginTop: 4, textAlign: "center" }}>
                            <Typography variant="h5" gutterBottom>
                                Modelo IA - Bank Statements
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Stack direction="column" justifyContent="center" spacing={2} mb={2}>
                                    <div>
                                        <BaseButton
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Cargar documento
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={handleChangeFile}
                                                accept="application/pdf, image/*"
                                                required
                                            />
                                        </BaseButton>
                                    </div>
                                    {file && (
                                        <div>
                                            <Typography variant="h2">{file.name}</Typography>
                                        </div>
                                    )}
                                    <div>
                                        <FormControl
                                            variant="filled"
                                            sx={{ width: { xs: "100%", md: "30%" } }}
                                            required
                                        >
                                            <InputLabel
                                                id="demo-multiple-checkbox-label"
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            >
                                                Modelo custom
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                value={selectedModel}
                                                displayEmpty
                                                onChange={handleChangeModel}
                                                input={
                                                    <FilledInput
                                                        label={"Modelo custom"}
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    />
                                                }
                                                MenuProps={MenuProps}
                                                defaultValue=""
                                                name="model"
                                            >
                                                {azureModels.map((item) => (
                                                    <MenuItem value={item} key={item}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Stack>
                                <BaseButton sizebutton="m" type="submit">
                                    Enviar
                                </BaseButton>
                            </form>

                            {data.length > 0 && <>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Typography>
                                        <b>Tiempo:</b> {elapsedTime}
                                    </Typography>
                                </Grid>
                                <BaseButton sizebutton="l" disableElevation onClick={downloadAIConversion} sx={{ mt: 3, mb: 3 }}>
                                    Descargar transacciones
                                </BaseButton>
                            </>}
                            <Grid container spacing={2} sx={{ textAlign: "left" }}>
                                <Grid item xs={6} md={6}>
                                    {data.map((item, index) => (
                                        <>
                                            <Typography key={"title" + index} sx={{ mt: 1 }} fontWeight={600}>
                                                Información obtenida del archivo {file?.name}:
                                            </Typography>
                                            <Typography key={"body" + index} sx={{ ml: 1 }}>
                                                <pre>{JSON.stringify(item, null, 2)}</pre>
                                            </Typography>
                                        </>
                                    ))}
                                </Grid>
                                <Grid item xs={2} md={4}>
                                    {data.map((item, index) => (
                                        <>
                                            <Typography key={"title2" + index} sx={{ mt: 1 }} fontWeight={600}>
                                                Resultados de validaciones:
                                            </Typography>
                                            <Typography key={"body2" + index} sx={{ ml: 1 }}>
                                                <br/>
                                                <Typography fontWeight={600}>Validación de saldos: </Typography>{item.balance_validation_status === 0 ? "Exitosa" : "Fallida"}
                                                <br/><br/>
                                                <Typography fontWeight={600}>Validación de fechas: </Typography>{item.date_validation_status === 0 ? "Exitosa" : "Fallida"}
                                            </Typography>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {error && (
                        <>
                            <Typography fontWeight={600} sx={{ mt: 4 }} color="red" textAlign="center">
                                Ha ocurrido un error al exraer datos del estado de cuenta.
                            </Typography>
                        </>
                    )}
                </Container>
            </BackGeneral>
            <SimpleBackdrop open={isLoading} />
        </>
    );
};

export default ComputerVisionStatements;
