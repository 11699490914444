import { Box, Button, Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseSelect from "../ui/BaseSelect";
import { useEffect, useState } from "react";
import classes from "../converter/MainConverter.module.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import InfoIcon from "@mui/icons-material/Info";

import { fetchFileFromS3, getFileExtension } from "../../utils/utils";
import documentClassService from "../../services/document_classi";

import { Document, Page } from "react-pdf";

import BaseButton from "../ui/BaseButton";
import moment from "moment";
import { useSelector } from "react-redux";

const ClasiDoc = (props) => {
    const { setIsLoading } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [suscription, setSuscription] = useState({
        value: "",
        count: 0,
    });
    const [conversionFile, setConversionFile] = useState(null);
    const [conversionFileName, setConversionFileName] = useState("");
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [multiDoc, setMultiDoc] = useState([]);
    const [actualDocIndex, setActualDocIndex] = useState(0);
    const [company, setCompany] = useState("");
    const [suscriptions, setSuscriptions] = useState([]);
    const [suscriptionsData, setSuscriptionsData] = useState([]);
    const [selectedInv, setSelectedInv] = useState({});
    const [companies, setCompanies] = useState([]);

    const getFile = async (file_name) => {
        const data = await fetchFileFromS3(file_name);
        if (data) {
            setConversionFile(data);
        }
    };

    const handleChange = async (event) => {
        setIsLoading(true);
        const foundSus = suscriptionsData.find((item) => item._id === event.target.value);

        setSuscription({
            ...suscription,
            value: event.target.value,
            count: foundSus.count,
        });
        setMultiDoc(foundSus.pendingInvoices);
        setConversionFileName(foundSus.pendingInvoices[0].uploaded_file);
        await getFile(foundSus.pendingInvoices[0].file_name);
        setSelectedInv(foundSus.pendingInvoices[0]);

        setIsLoading(false);
    };

    const handleChangeCom = (event) => {
        setCompany(event.target.value);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const nextPage = () => {
        setPageNumber((prevNum) => prevNum + 1);
    };

    const lastPage = () => {
        setPageNumber(numPages);
    };

    const prevPage = () => {
        setPageNumber((prevNum) => prevNum - 1);
    };

    const firstPage = () => {
        setPageNumber(1);
    };

    const handleNextDoc = async (direction) => {
        setIsLoading(true);
        let newData = {};

        if (actualDocIndex + direction === -1) {
            newData = multiDoc[0];
        } else {
            newData = multiDoc[actualDocIndex + direction];
        }

        setActualDocIndex(actualDocIndex + direction);

        setConversionFileName(newData.file_name);
        setPageNumber(1);
        await getFile(newData.file_name);

        setSelectedInv(newData);

        setIsLoading(false);
    };

    const saveInv = async () => {
        setIsLoading(true);
        await documentClassService
            .reassignInvoice({
                company_id: company,
                conversion_id: selectedInv._id,
            })
            .then((data) => {
                console.log(data);
            });

        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            let franchiseID = "";
            await documentClassService
                .getInvoicesPending()
                .then(async (data) => {
                    setSuscriptions(
                        data.map((item) => ({
                            id: item._id,
                            name: `${item.franchiseDetails.franchise_name} (${item.count})`,
                        }))
                    );
                    franchiseID = data[0]._id;
                    setSuscriptionsData(data);
                    setSuscription({
                        value: data[0]._id,
                        count: data[0].count,
                    });
                    setMultiDoc(data[0].pendingInvoices);
                    setConversionFileName(data[0].pendingInvoices[0].uploaded_file);
                    await getFile(data[0].pendingInvoices[0].file_name);

                    setSelectedInv(data[0].pendingInvoices[0]);
                })
                .catch((err) => {
                    console.log(err);
                });

            await documentClassService
                .getCompaniesByFranchise({ franchise_id: franchiseID })
                .then((data) => {
                    setCompanies(
                        data.map((item) => ({
                            id: item._id,
                            name: item.company_name,
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
    }, [setIsLoading]);

    return (
        <Container maxWidth="xl" sx={{ pt: 3 }}>
            <Grid container spacing={4} sx={{ py: 2, px: { xs: 1, md: 5 } }}>
                <Grid item xs={12}>
                    <Typography color="primary" variant="h1">
                        {t("inbox.classiDoc")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    backgroundColor: "white",
                                    p: 2,
                                    borderRadius: 4,
                                    boxShadow: "4px 4px 10px rgba(74, 34, 212, 0.1)",
                                }}
                            >
                                <Box sx={{ width: 250 }}>
                                    <BaseSelect
                                        value={suscription.value}
                                        values={suscriptions}
                                        onChange={handleChange}
                                        label="Suscripción"
                                    ></BaseSelect>
                                </Box>
                                <Stack>
                                    <Typography variant="h2" textAlign="end">
                                        {suscription.count} documentos por clasificar
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            {conversionFile ? (
                                <>
                                    {getFileExtension(
                                        conversionFileName
                                            ? conversionFileName?.toLowerCase()
                                            : conversionFile.path?.toLowerCase()
                                    ) === "pdf" ? (
                                        <>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{ bgcolor: "#FFF", borderRadius: 2 }}
                                            >
                                                <IconButton disabled={pageNumber === 1} onClick={firstPage}>
                                                    <FirstPageIcon
                                                        sx={{
                                                            color: pageNumber === 1 ? "rgba(0, 0, 0, 0.26)" : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton disabled={pageNumber === 1} onClick={prevPage}>
                                                    <ArrowBackIcon
                                                        sx={{
                                                            color: pageNumber === 1 ? "rgba(0, 0, 0, 0.26)" : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography>
                                                    {pageNumber} - {numPages}
                                                </Typography>
                                                <IconButton disabled={pageNumber === numPages} onClick={nextPage}>
                                                    <ArrowForwardIcon
                                                        sx={{
                                                            color:
                                                                pageNumber === numPages
                                                                    ? "rgba(0, 0, 0, 0.26)"
                                                                    : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton disabled={pageNumber === numPages} onClick={lastPage}>
                                                    <LastPageIcon
                                                        sx={{
                                                            color:
                                                                pageNumber === numPages
                                                                    ? "rgba(0, 0, 0, 0.26)"
                                                                    : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                            </Stack>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <Box
                                        sx={{
                                            height: 650,
                                            overflow: "auto",
                                            "&::-webkit-scrollbar": {
                                                width: "10px",
                                                height: "10px",
                                            },
                                            "&::-webkit-scrollbar-track": {
                                                boxShadow: "inset 10px 10px 15px #c7c9d1",
                                                borderRadius: "10px",
                                            },
                                            "&::-webkit-scrollbar-thumb": {
                                                background: "#404963",
                                                borderRadius: "10px",
                                            },
                                            "&::-webkit-scrollbar-thumb:hover": {
                                                background: "#031851",
                                            },
                                        }}
                                    >
                                        {getFileExtension(
                                            conversionFileName
                                                ? conversionFileName?.toLowerCase()
                                                : conversionFile.path?.toLowerCase()
                                        ) === "pdf" ? (
                                            <>
                                                <Document file={conversionFile} onLoadSuccess={onDocumentLoadSuccess}>
                                                    <Page pageNumber={pageNumber} />
                                                </Document>
                                            </>
                                        ) : (
                                            <Box
                                                className={classes.workbookContainer}
                                                sx={{ bgcolor: "#CCC", maxHeight: 1000 }}
                                                justifyContent="center"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    sx={{ width: "100%", height: "auto" }}
                                                    component="img"
                                                    src={conversionFile}
                                                ></Box>
                                            </Box>
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    className={classes.workbookContainer}
                                    sx={{ bgcolor: "#CCC", height: 600 }}
                                    justifyContent="center"
                                    display="flex"
                                    alignItems="center"
                                >
                                    No tienes ningún documento pendiente por clasificar en tus empresas
                                </Box>
                            )}
                            {multiDoc.length > 1 && (
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    <Button
                                        endIcon={<ArrowBackIcon />}
                                        sx={{ color: actualDocIndex === 0 ? "rgba(0, 0, 0, 0.26)" : "#131f3e" }}
                                        disabled={actualDocIndex === 0}
                                        onClick={() => handleNextDoc(-1)}
                                    >
                                        {t("converter.previous")}
                                    </Button>
                                    <Button
                                        startIcon={<ArrowForwardIcon />}
                                        sx={{
                                            color:
                                                actualDocIndex === multiDoc.length - 1
                                                    ? "rgba(0, 0, 0, 0.26)"
                                                    : "#131f3e",
                                        }}
                                        disabled={actualDocIndex === multiDoc.length - 1}
                                        onClick={() => handleNextDoc(1)}
                                    >
                                        {t("miscellaneous.next")}
                                    </Button>
                                </Stack>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h1" color="primary" my={2}>
                        Resumen de conversión de factura
                    </Typography>
                    <Typography gutterBottom>
                        <b>Archivo:</b> {selectedInv.uploaded_file}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Factura:</b> {selectedInv.formatted_control_number}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Contacto:</b>{" "}
                        {selectedInv.conversion_type === 1
                            ? selectedInv.formatted_customer_name
                            : selectedInv.formatted_vendor_name}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Fecha:</b> {moment.utc(selectedInv.formatted_invoice_date).format(todos.dateFormat)}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Monto:</b>{" "}
                        {parseFloat(selectedInv.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })}
                    </Typography>
                    <Typography variant="h1" color="primary" gutterBottom my={2}>
                        Selecciona una empresa
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Box sx={{ width: 250 }}>
                            <BaseSelect
                                value={company}
                                values={companies}
                                onChange={handleChangeCom}
                                label="Empresa"
                            ></BaseSelect>
                        </Box>
                        <BaseButton onClick={saveInv}>Guardar</BaseButton>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2} mt={2} width={400}>
                        <InfoIcon color="primary" />
                        <Typography>
                            Al presionar <b>Guardar</b> se registrará el documento en la empresa seleccionada. Esta
                            acción no es reversible
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ClasiDoc;
