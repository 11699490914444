import { MenuItem } from "@mui/material";
import Countries from "../Js/countries";
import BaseSelect2 from "./BaseSelect2";

export default function CustomFilledSelectCountries(props) {
    const { id, label, value, onChange, error, disabled, required, name, ...rest } = props;

    const allCountries = [...Countries];
    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    return (
        <BaseSelect2
            fullWidth
            required={required}
            error={error}
            disabled={disabled ?? false}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            label={label}
            MenuProps={{
                PaperProps: {
                    sx: {
                        maxHeight: 48 * 4.5 + 8,
                        mt: 0.7,
                        borderRadius: 2,
                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                    },
                },
            }}
            {...rest}
        >
            {allCountries.map((option) => (
                <MenuItem key={"country_" + option.code} value={option.code}>
                    {sessionStorage.getItem("lng") === "en" ? option.name_en : option.name_es}
                </MenuItem>
            ))}
        </BaseSelect2>
    );
}
