/** React imports */
import { useTranslation } from "react-i18next";

/** MUI imports */
import { Box } from "@mui/material";
import BaseButton from "../ui/BaseButton";

const AddCompanyButtons = (props) => {
    const [t] = useTranslation("global");

    const { activeStep, steps, handleNext, createCompany, onClose, isErrorName, smbInfo, hiddenSkip = false, showButtonInt = false, hideButtonBack = false } = props;
    
    return (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
            {!hideButtonBack && <BaseButton
                variantType="primaryText"
                //disabled={activeStep === 0}
                onClick={onClose}
                sizebutton="s"
            >
                {activeStep === 0 && !hiddenSkip ? t("dialog.skip2") : t("dialog.skip")}
            </BaseButton>}
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep < steps.length - 1 && (
                <BaseButton
                    onClick={handleNext}
                    disabled={isErrorName || !smbInfo.companyName.trim() || !smbInfo.country || smbInfo.systems.length === 0 || !smbInfo.ecActivity}
                    disableElevation
                    sizebutton="s"
                >
                    {t("dialog.continue")}
                </BaseButton>
            )}
            {activeStep === steps.length - 1 && (
                <BaseButton onClick={createCompany} disableElevation
                    sizebutton={showButtonInt ? "l" : "s"}
                >
                    {showButtonInt ? t("register.continueWithoutInteg") : t("dialog.continue")}
                </BaseButton>
            )}
        </Box>
    );
};

export default AddCompanyButtons;
