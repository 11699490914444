const allCurrencies = [
    {
      "Code": "VES",
      "Description": "Venezuelan Bolívar Soberano",
      "setup": true
    },
    {
      "Code": "AFA",
      "Description": "Afghan Afghani"
    },
    {
      "Code": "ALL",
      "Description": "Albanian Lek"
    },
    {
      "Code": "DZD",
      "Description": "Algerian Dinar"
    },
    {
      "Code": "AOA",
      "Description": "Angolan Kwanza"
    },
    {
      "Code": "ARS",
      "Description": "Argentine Peso"
    },
    {
      "Code": "AMD",
      "Description": "Armenian Dram"
    },
    {
      "Code": "AWG",
      "Description": "Aruban Florin"
    },
    {
      "Code": "AUD",
      "Description": "Australian Dollar"
    },
    {
      "Code": "AZN",
      "Description": "Azerbaijani Manat"
    },
    {
      "Code": "BSD",
      "Description": "Bahamian Dollar"
    },
    {
      "Code": "BHD",
      "Description": "Bahraini Dinar"
    },
    {
      "Code": "BDT",
      "Description": "Bangladeshi Taka"
    },
    {
      "Code": "BBD",
      "Description": "Barbadian Dollar"
    },
    {
      "Code": "BYR",
      "Description": "Belarusian Ruble"
    },
    {
      "Code": "BEF",
      "Description": "Belgian Franc"
    },
    {
      "Code": "BZD",
      "Description": "Belize Dollar"
    },
    {
      "Code": "BMD",
      "Description": "Bermudan Dollar"
    },
    {
      "Code": "BTN",
      "Description": "Bhutanese Ngultrum"
    },
    {
      "Code": "BTC",
      "Description": "Bitcoin"
    },
    {
      "Code": "BOB",
      "Description": "Bolivian Boliviano"
    },
    {
      "Code": "BAM",
      "Description": "Bosnia-Herzegovina Convertible Mark"
    },
    {
      "Code": "BWP",
      "Description": "Botswanan Pula"
    },
    {
      "Code": "BRL",
      "Description": "Brazilian Real"
    },
    {
      "Code": "GBP",
      "Description": "British Pound Sterling"
    },
    {
      "Code": "BND",
      "Description": "Brunei Dollar"
    },
    {
      "Code": "BGN",
      "Description": "Bulgarian Lev"
    },
    {
      "Code": "BIF",
      "Description": "Burundian Franc"
    },
    {
      "Code": "KHR",
      "Description": "Cambodian Riel"
    },
    {
      "Code": "CAD",
      "Description": "Canadian Dollar"
    },
    {
      "Code": "CVE",
      "Description": "Cape Verdean Escudo"
    },
    {
      "Code": "KYD",
      "Description": "Cayman Islands Dollar"
    },
    {
      "Code": "XOF",
      "Description": "CFA Franc BCEAO"
    },
    {
      "Code": "XAF",
      "Description": "CFA Franc BEAC"
    },
    {
      "Code": "XPF",
      "Description": "CFP Franc"
    },
    {
      "Code": "CLP",
      "Description": "Chilean Peso"
    },
    {
      "Code": "CLF",
      "Description": "Chilean Unit of Account"
    },
    {
      "Code": "CNY",
      "Description": "Chinese Yuan"
    },
    {
      "Code": "COP",
      "Description": "Colombian Peso"
    },
    {
      "Code": "KMF",
      "Description": "Comorian Franc"
    },
    {
      "Code": "CDF",
      "Description": "Congolese Franc"
    },
    {
      "Code": "CRC",
      "Description": "Costa Rican Colón"
    },
    {
      "Code": "HRK",
      "Description": "Croatian Kuna"
    },
    {
      "Code": "CUC",
      "Description": "Cuban Convertible Peso"
    },
    {
      "Code": "CZK",
      "Description": "Czech Republic Koruna"
    },
    {
      "Code": "DKK",
      "Description": "Danish Krone"
    },
    {
      "Code": "DJF",
      "Description": "Djiboutian Franc"
    },
    {
      "Code": "DOP",
      "Description": "Dominican Peso"
    },
    {
      "Code": "XCD",
      "Description": "East Caribbean Dollar"
    },
    {
      "Code": "EGP",
      "Description": "Egyptian Pound"
    },
    {
      "Code": "ERN",
      "Description": "Eritrean Nakfa"
    },
    {
      "Code": "EEK",
      "Description": "Estonian Kroon"
    },
    {
      "Code": "ETB",
      "Description": "Ethiopian Birr"
    },
    {
      "Code": "EUR",
      "Description": "Euro"
    },
    {
      "Code": "FKP",
      "Description": "Falkland Islands Pound"
    },
    {
      "Code": "FJD",
      "Description": "Fijian Dollar"
    },
    {
      "Code": "GMD",
      "Description": "Gambian Dalasi"
    },
    {
      "Code": "GEL",
      "Description": "Georgian Lari"
    },
    {
      "Code": "DEM",
      "Description": "German Mark"
    },
    {
      "Code": "GHS",
      "Description": "Ghanaian Cedi"
    },
    {
      "Code": "GIP",
      "Description": "Gibraltar Pound"
    },
    {
      "Code": "GRD",
      "Description": "Greek Drachma"
    },
    {
      "Code": "GTQ",
      "Description": "Guatemalan Quetzal"
    },
    {
      "Code": "GNF",
      "Description": "Guinean Franc"
    },
    {
      "Code": "GYD",
      "Description": "Guyanaese Dollar"
    },
    {
      "Code": "HTG",
      "Description": "Haitian Gourde"
    },
    {
      "Code": "HNL",
      "Description": "Honduran Lempira"
    },
    {
      "Code": "HKD",
      "Description": "Hong Kong Dollar"
    },
    {
      "Code": "HUF",
      "Description": "Hungarian Forint"
    },
    {
      "Code": "ISK",
      "Description": "Icelandic Króna"
    },
    {
      "Code": "INR",
      "Description": "Indian Rupee"
    },
    {
      "Code": "IDR",
      "Description": "Indonesian Rupiah"
    },
    {
      "Code": "IRR",
      "Description": "Iranian Rial"
    },
    {
      "Code": "IQD",
      "Description": "Iraqi Dinar"
    },
    {
      "Code": "ILS",
      "Description": "Israeli New Sheqel"
    },
    {
      "Code": "ITL",
      "Description": "Italian Lira"
    },
    {
      "Code": "JMD",
      "Description": "Jamaican Dollar"
    },
    {
      "Code": "JPY",
      "Description": "Japanese Yen"
    },
    {
      "Code": "JOD",
      "Description": "Jordanian Dinar"
    },
    {
      "Code": "KZT",
      "Description": "Kazakhstani Tenge"
    },
    {
      "Code": "KES",
      "Description": "Kenyan Shilling"
    },
    {
      "Code": "KWD",
      "Description": "Kuwaiti Dinar"
    },
    {
      "Code": "KGS",
      "Description": "Kyrgystani Som"
    },
    {
      "Code": "LAK",
      "Description": "Laotian Kip"
    },
    {
      "Code": "LVL",
      "Description": "Latvian Lats"
    },
    {
      "Code": "LBP",
      "Description": "Lebanese Pound"
    },
    {
      "Code": "LSL",
      "Description": "Lesotho Loti"
    },
    {
      "Code": "LRD",
      "Description": "Liberian Dollar"
    },
    {
      "Code": "LYD",
      "Description": "Libyan Dinar"
    },
    {
      "Code": "LTC",
      "Description": "Litecoin"
    },
    {
      "Code": "LTL",
      "Description": "Lithuanian Litas"
    },
    {
      "Code": "MOP",
      "Description": "Macanese Pataca"
    },
    {
      "Code": "MKD",
      "Description": "Macedonian Denar"
    },
    {
      "Code": "MGA",
      "Description": "Malagasy Ariary"
    },
    {
      "Code": "MWK",
      "Description": "Malawian Kwacha"
    },
    {
      "Code": "MYR",
      "Description": "Malaysian Ringgit"
    },
    {
      "Code": "MVR",
      "Description": "Maldivian Rufiyaa"
    },
    {
      "Code": "MRO",
      "Description": "Mauritanian Ouguiya"
    },
    {
      "Code": "MUR",
      "Description": "Mauritian Rupee"
    },
    {
      "Code": "MXN",
      "Description": "Mexican Peso"
    },
    {
      "Code": "MDL",
      "Description": "Moldovan Leu"
    },
    {
      "Code": "MNT",
      "Description": "Mongolian Tugrik"
    },
    {
      "Code": "MAD",
      "Description": "Moroccan Dirham"
    },
    {
      "Code": "MZM",
      "Description": "Mozambican Metical"
    },
    {
      "Code": "MMK",
      "Description": "Myanmar Kyat"
    },
    {
      "Code": "NAD",
      "Description": "Namibian Dollar"
    },
    {
      "Code": "NPR",
      "Description": "Nepalese Rupee"
    },
    {
      "Code": "ANG",
      "Description": "Netherlands Antillean Guilder"
    },
    {
      "Code": "TWD",
      "Description": "New Taiwan Dollar"
    },
    {
      "Code": "NZD",
      "Description": "New Zealand Dollar"
    },
    {
      "Code": "NIO",
      "Description": "Nicaraguan Córdoba"
    },
    {
      "Code": "NGN",
      "Description": "Nigerian Naira"
    },
    {
      "Code": "KPW",
      "Description": "North Korean Won"
    },
    {
      "Code": "NOK",
      "Description": "Norwegian Krone"
    },
    {
      "Code": "OMR",
      "Description": "Omani Rial"
    },
    {
      "Code": "PKR",
      "Description": "Pakistani Rupee"
    },
    {
      "Code": "PAB",
      "Description": "Panamanian Balboa"
    },
    {
      "Code": "PGK",
      "Description": "Papua New Guinean Kina"
    },
    {
      "Code": "PYG",
      "Description": "Paraguayan Guarani"
    },
    {
      "Code": "PEN",
      "Description": "Peruvian Nuevo Sol"
    },
    {
      "Code": "PHP",
      "Description": "Philippine Peso"
    },
    {
      "Code": "PLN",
      "Description": "Polish Zloty"
    },
    {
      "Code": "QAR",
      "Description": "Qatari Rial"
    },
    {
      "Code": "RON",
      "Description": "Romanian Leu"
    },
    {
      "Code": "RUB",
      "Description": "Russian Ruble"
    },
    {
      "Code": "RWF",
      "Description": "Rwandan Franc"
    },
    {
      "Code": "SVC",
      "Description": "Salvadoran Colón"
    },
    {
      "Code": "WST",
      "Description": "Samoan Tala"
    },
    {
      "Code": "STD",
      "Description": "São Tomé and Príncipe Dobra"
    },
    {
      "Code": "SAR",
      "Description": "Saudi Riyal"
    },
    {
      "Code": "RSD",
      "Description": "Serbian Dinar"
    },
    {
      "Code": "SCR",
      "Description": "Seychellois Rupee"
    },
    {
      "Code": "SLL",
      "Description": "Sierra Leonean Leone"
    },
    {
      "Code": "SGD",
      "Description": "Singapore Dollar"
    },
    {
      "Code": "SKK",
      "Description": "Slovak Koruna"
    },
    {
      "Code": "SBD",
      "Description": "Solomon Islands Dollar"
    },
    {
      "Code": "SOS",
      "Description": "Somali Shilling"
    },
    {
      "Code": "ZAR",
      "Description": "South African Rand"
    },
    {
      "Code": "KRW",
      "Description": "South Korean Won"
    },
    {
      "Code": "SSP",
      "Description": "South Sudanese Pound"
    },
    {
      "Code": "XDR",
      "Description": "Special Drawing Rights"
    },
    {
      "Code": "LKR",
      "Description": "Sri Lankan Rupee"
    },
    {
      "Code": "SHP",
      "Description": "St. Helena Pound"
    },
    {
      "Code": "SDG",
      "Description": "Sudanese Pound"
    },
    {
      "Code": "SRD",
      "Description": "Surinamese Dollar"
    },
    {
      "Code": "SZL",
      "Description": "Swazi Lilangeni"
    },
    {
      "Code": "SEK",
      "Description": "Swedish Krona"
    },
    {
      "Code": "CHF",
      "Description": "Swiss Franc"
    },
    {
      "Code": "SYP",
      "Description": "Syrian Pound"
    },
    {
      "Code": "TJS",
      "Description": "Tajikistani Somoni"
    },
    {
      "Code": "TZS",
      "Description": "Tanzanian Shilling"
    },
    {
      "Code": "THB",
      "Description": "Thai Baht"
    },
    {
      "Code": "TOP",
      "Description": "Tongan Pa'anga"
    },
    {
      "Code": "TTD",
      "Description": "Trinidad & Tobago Dollar"
    },
    {
      "Code": "TND",
      "Description": "Tunisian Dinar"
    },
    {
      "Code": "TRY",
      "Description": "Turkish Lira"
    },
    {
      "Code": "TMT",
      "Description": "Turkmenistani Manat"
    },
    {
      "Code": "UGX",
      "Description": "Ugandan Shilling"
    },
    {
      "Code": "UAH",
      "Description": "Ukrainian Hryvnia"
    },
    {
      "Code": "AED",
      "Description": "United Arab Emirates Dirham"
    },
    {
      "Code": "UYU",
      "Description": "Uruguayan Peso"
    },
    {
      "Code": "USD",
      "Description": "United States Dollar"
    },
    {
      "Code": "UZS",
      "Description": "Uzbekistan Som"
    },
    {
      "Code": "VUV",
      "Description": "Vanuatu Vatu"
    },
    {
      "Code": "VND",
      "Description": "Vietnamese Dong"
    },
    {
      "Code": "YER",
      "Description": "Yemeni Rial"
    },
    {
      "Code": "ZMK",
      "Description": "Zambian Kwacha"
    },
    {
      "Code": "ZWL",
      "Description": "Zimbabwean Dollar"
    }
];
export default allCurrencies;
