/* Componentes */
import { useDispatch, useSelector } from "react-redux";
import BackGeneral from "../components/layout/BackGeneral";
import MainSubscription from "../components/subscription/MainSubscription";
import { useEffect, useState } from "react";

import subscriptionService from "../services/subscription";

import { useTranslation } from "react-i18next";

import { addCredits, addIsGrouped, addSubscription } from "../redux/userSlice";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import { useNavigate } from "react-router-dom";
import plansInfoNew from "../components/Js/plans";
import BaseSnackbar from "../components/ui/BaseSnackbar";
import AlertDialog from "../components/ui/AlertDialog";
import authService from "../services/auth"
import casesServices from "../services/case";
import userCompanyService from "../services/user_company"
import { getCompanyCredits, getCustomPlanId, updateCartPlan } from "../utils/utils";
import CustomForm from "../components/subscription/CustomForm";
import AddCreditPacksDialog from "../components/credits/AddCreditPacksDialog";
import paymentMethodsService from "../services/payment_methods";
import AddPaymentDialog from "../components/payments/AddPaymentDialog";
import ChangePayDialog from "../components/subscription/shoppingCart/ChangePayDialog";
import ChatModal from "../components/ui/Chat";
import { initiateSocket, newUserResponse, subscribeToChat } from "../services/Socket";

const Subscription = () => {
    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const [currentPlan, setCurrentPlan] = useState({
        value: 0,
        custom: false,
    });
    const [currentPlanInfo, setCurrentPlanInfo] = useState(null); //Información completa del plan actual
    const [activePlan, setActivePlan] = useState(null); //Plan seleccionado
    const [isLoading, setIsLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [paymentType, setPaymentType] = useState("monthly");
    const [currentLevel, setCurrentLevel] = useState("");
    const [priceMonthly, setPriceMonthly] = useState("");
    const [priceMonthlyOG, setPriceMonthlyOG] = useState("");
    const [titleModal, setTitleModal] = useState("");
    const [bodyMess, setBodyMess] = useState("");
    const [agreeBtnMsg, setAgreeBtnMsg] = useState(t("dialog.continue"));
    const [disagreeBtnMsg, setDisagreeBtnMsg] = useState(t("dialog.cancel"));
    const [questionCustom, setQuestionCustom] = useState({
        question1: "",
        question2: "",
        question3: "",
    });
    const [activeStep, setActiveStep] = useState(0);
    const [showCart, setShowCart] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({});
    const [allCompanies, setAllCompanies] = useState([]);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openAlert, setOpenAlert] = useState(false);
    const [errorCustomPlan, setErrorCustomPlan] = useState(false);
    const [integ, setInteg] = useState(3);
    const [showModalCredits, setShowModalCredits] = useState(false);
    const [showAddPay, setShowAddPay] = useState(false);
    const [showChangePay, setShowChangePay] = useState(false); // Modal para cambiar tarjeta
    const [defaultPayMethod, setDefaultPayMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [caseInfo, setCaseInfo] = useState({});

    window.history.replaceState(null, "");
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCloseDialog = () => {
        setQuestionCustom({
            question1: "",
            question2: "",
            question3: "",
        });
        setOpenAlert(false);
    };

    const handleSubmitCustomPlan = async () => {
        if (questionCustom.question1 === "" || questionCustom.question2 === "" || questionCustom.question3 === "") {
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
            setErrorCustomPlan(true)
        } else {
            setErrorCustomPlan(false)
            handleCloseDialog()
            setIsLoading(true)

            const paramsC = {
                company_id: companyDetails._id,
                planId: getCustomPlanId(),
                origin_form: "subscription",
                origin: 4,
                question1: questionCustom.question1,
                question2: questionCustom.question2,
                question3: questionCustom.question3,
                user_email: todos?.userInfo?.email
            }

            // Se agrega free trial del plan 11
            await authService
                .addFreeTrialPlan(paramsC)
                .then(async () => {
                    await updateCompany()
                })
                .catch((error) => console.log(error.response));

            setIsLoading(false)
        }
    };

    const updateCompany = async () => {
        
        setIsLoading(true);
        await userCompanyService
            .getBusinessDirectoryDetails({ company_id: companyDetails._id })
            .then(async (data) => {
                setShowCart(false)
                setActiveStep(0)
                setCompanyDetails(data);
                dispatch(addIsGrouped(false));
                dispatch(addSubscription({
                    value: data.id_status,
                    last_value: data.id_last_status,
                    plan: data.subscription.subscription_plan,
                }))
    
                const credits = await getCompanyCredits(companyDetails._id);
                dispatch(addCredits(credits));

                if (todos.selected === "ddlSalesInv") {
                    navigate(`/convert/invoices`);
                } else if (todos.selected === "ddlPurchaseInv") {
                    navigate(`/convert/bills`);
                } else {
                    navigate("/dashboard");
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            if (todos.companyID !== null) {
                setIsLoading(true)
                await userCompanyService
                    .getBusinessDirectoryDetails({ company_id: todos.companyID })
                    .then(async (data) => {
                        setCompanyDetails(data);
                        setInteg(data.system_integration)
                        setActivePlan(data.subscription.subscription_plan)
                        setPaymentType(data?.last_invoice?.line_items[0]?.line_qty === 365 ? "annual" : "monthly")
                        await userCompanyService
                            .getBusinessDirectory({franchise_id: data.franchise_id})
                            .then((data) => {
                                const companies = data.companies.map((item) => ({
                                    value: item._id,
                                    name: item.company_name,
                                }));

                                setAllCompanies(companies);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                setIsLoading(false)
            }
        })();
    }, [todos.companyID]);
    
    const handleShowCart = async (e, planId = null) => {
        handleCloseDialog(false);
        const finalPlan = planId !== null ? planId : currentLevel
        let activePlan = companyDetails?.subscription?.subscription_plan ?? null;
        let isCancelled = "subscription" in companyDetails && companyDetails.subscription !== null && "last_cancel" in companyDetails.subscription && companyDetails.subscription.last_cancel !== null;
        if (activePlan && activePlan >= 0 && !(companyDetails.id_status === 1 || (companyDetails.id_status === 4 && companyDetails.id_last_status === 1)) && !isCancelled) {
            await updateCartPlan(activePlan > finalPlan ? 3 : 4, finalPlan, paymentType, companyDetails, setIsLoading, allCompanies.length, "month")
        } else {
            await updateCartPlan(1, finalPlan, paymentType, companyDetails, setIsLoading, allCompanies.length, "month")
        }
        setShowCart(true);
    };

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const getPaymentMethods = async (reload) => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            companyID: todos.companyID,
        };

        try {
            if (todos.companyID || todos.franchiseID) {
                const result = await paymentMethodsService.getPaymentMethods(params);
                const defaultPaymentMethod = result.find((card) => card.default_method);
                setDefaultPayMethod(defaultPaymentMethod);
                setPaymentMethods(result);
            }
        } catch (error) {
            console.log(error);
        }

        if (!reload) {
            await subscriptionService
                .getSubscription({
                    company_id: todos.companyID,
                })
                .then((response) => {
                    if (response) {
                        setActivePlan(response.subscription_plan);
                    } else {
                        setActivePlan(null);
                    }
                })
                .catch((err) => console.log(err));
        }

        setIsLoading(false);
    };

    const handleCloseModalCredits = () => {
        setShowModalCredits(false);
    };
    
    const handleCloseModal2 = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(false);
            getPaymentMethods(true);
            setShowModalCredits(true);
        }
    };

    
    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(true);
            getPaymentMethods(true);
        }
    };

    const handleCloseChangePay = () => {
        setShowChangePay(false);
        setShowModalCredits(true);
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePay(false);
    };

    const updateMicroPlan = async () => {
        handleCloseDialog();
        setIsLoading(true);
        await authService
            .addMicroPlan({ company_id: companyDetails._id, origin: "subscription", user_email: todos?.userInfo?.email })
            .then(async () => {
                await updateCompany();
            })
            .catch((error) => console.log(error.response));
        setIsLoading(false);
    };

    const connectSocketGen = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo)

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClickHelp = async () => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "general",
        };

        await casesServices
            .createNewCase(params)
            .then(async (response) => {
                setCaseInfo(response);
                await connectSocketGen(response._id);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    return (
        <BackGeneral>
            <MainSubscription
                franchise_id={todos.franchiseID}
                company_id={todos.companyID}
                currentPlan={currentPlan}
                activePlan={activePlan}
                setIsLoading={setIsLoading}
                currentPlanInfo={currentPlanInfo}
                setCurrentPlanInfo={setCurrentPlanInfo}
                payments={payments}
                setPayments={setPayments}
                showSkeleton={showSkeleton}
                setShowSkeleton={setShowSkeleton}
                plansInfoNew={plansInfoNew}
                setCurrentPlan={setCurrentPlan}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                currentLevel={currentLevel}
                priceMonthly={priceMonthly}
                priceMonthlyOG={priceMonthlyOG}
                setPriceMonthly={setPriceMonthly}
                setPriceMonthlyOG={setPriceMonthlyOG}
                setCurrentLevel={setCurrentLevel}
                setTitleModal={setTitleModal}
                setAgreeBtnMsg={setAgreeBtnMsg}
                setDisagreeBtnMsg={setDisagreeBtnMsg}
                setBodyMess={setBodyMess}
                setActiveStep={setActiveStep}
                setShowCart={setShowCart}
                showCart={showCart}
                setCompanyDetails={setCompanyDetails}
                companyDetails={companyDetails}
                setAllCompanies={setAllCompanies}
                allCompanies={allCompanies}
                setOpenAlert={setOpenAlert}
                activeStep={activeStep}
                setTexto={setTexto}
                setShow={setShow}
                setAlertType={setAlertType}
                setInteg={setInteg}
                integ={integ}
                updateCompany={updateCompany}
                handleShowCart={handleShowCart}
                setShowModalCredits={setShowModalCredits}
                handleClickHelp={handleClickHelp}
            />
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={agreeBtnMsg}
                disagreeBtnLabel={disagreeBtnMsg}
                type="info2"
                title={titleModal}
                message={currentPlan.value === 3 && currentPlan.custom === true ? 
                <CustomForm
                    setQuestionCustom={setQuestionCustom}
                    questionCustom={questionCustom}
                    errorCustomPlan={errorCustomPlan}
                /> : bodyMess}
                agreeAction={
                    !currentPlan.custom
                        ? currentPlan.value === 0
                            ? companyDetails?.subscription && companyDetails?.subscription?.subscription_plan >= 0 // Downgrade
                                ? handleShowCart
                                : updateMicroPlan
                            : handleShowCart
                        : handleSubmitCustomPlan
                }
                maxWidth="sm"
                origin="converter"
                onlyAction={true}
                oneAction={handleCloseDialog}
            />
            {showModalCredits && (
                <AddCreditPacksDialog
                    open={showModalCredits}
                    onClose={handleCloseModalCredits}
                    setShowAddPay={setShowAddPay}
                    setShowChangePay={setShowChangePay}
                    getPaymentMethods={() => getPaymentMethods(false)}
                    activePlan={activePlan}
                    paymentMethod={defaultPayMethod}
                    setIsLoading={setIsLoading}
                    currentPlan={currentPlan}
                    companyDetails={companyDetails}
                    allCompanies={allCompanies}
                    paymentType={paymentType}
                />
            )}
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    handleClose2={handleCloseModal2}
                    converter={true}
                />
            )}
            {showChangePay && (
                <ChangePayDialog
                    open={showChangePay}
                    handleClose={handleCloseChangePay}
                    payments={paymentMethods}
                    setIsLoading={setIsLoading}
                    reloadData={() => getPaymentMethods(true)}
                    defaultCard={defaultPayMethod}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                users={users}
                type={4}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                chatDisabled={false}
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
                createCase={true}
            />}
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
        </BackGeneral>
    );
};

export default Subscription;
