import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

// Componente CustomDatePicker
const CustomDatePicker = ({ label, value, onChange, readOnly = true, isSearch, isError, ...rest }) => {
    const [t] = useTranslation("global");
    const theme = useTheme(); // Obtén el tema actual

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={t("language.locale")}
            localeText={{
                clearButtonLabel: t("miscellaneous.clear"),
            }}
        >
            <DatePicker
                {...rest}
                label={label ? <Typography
                    sx={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        color: "#131F3E",
                    }}
                >
                    {label}
                </Typography> : ""}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        readOnly: readOnly,
                        error: isError,
                        sx: {
                            height: isSearch ? 42 : 48, // Ajusta la altura aquí
                            "& .MuiOutlinedInput-root": {
                                    height: isSearch ? 42 : 48, // Asegura que el contenedor del input tenga la altura correcta
                                    backgroundColor: "#fff",
                                "& fieldset": {
                                    borderColor: theme.palette.V050, // Color del borde
                                },
                                "&:hover fieldset": {
                                    borderColor: theme.palette.V090, // Color del borde en hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: theme.palette.V090, // Color del borde en focus
                                },
                                "&.Mui-error fieldset": {
                                    borderColor: theme.palette.secondary.main, // Color del borde cuando hay un error
                                },
                            },
                        },
                    },
                    actionBar: { actions: ["clear"] },
                }}
                value={value !== "" ? value : null}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;