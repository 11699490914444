import React, { useState, useEffect } from "react";
import introJs from "intro.js";
// import { usePlaidLink } from "react-plaid-link";

/* MUI Imports */
import {
    Card,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Grid,
    Stack,
    IconButton,
    ListItemButton,
    Skeleton,
    FormControlLabel,
    Switch,
} from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import { useSearchParams } from "react-router-dom";

/** Styles */
import classes from "./PendingsAccounts.module.css";
import { styled } from "@mui/material/styles";

/** MUI Icons */
import CachedIcon from "@mui/icons-material/Cached";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import PlaidIcon from "../../assets/Plaid_logo.svg";

/** Services */
import accountsService from "../../services/accounts";
import companyService from "../../services/company";

/** Components */
import FormDialog from "../ui/FormDialog";
import AccountDetails from "./AccountDetails";
import AlertDialog from "../ui/AlertDialog";
import SimpleBackdrop from "../ui/SimpleBackdrop";
import { useDispatch } from "react-redux";
import { addTutorial } from "../../redux/userSlice";
import BaseButton from "../ui/BaseButton";
import { useGlobalContext } from "../../context/GlobalContext";

const StyledListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: "#FFF",
    borderRadius: "10px",
    marginTop: 7,
    "& .MuiListItemButton-root:hover": {
        borderRadius: "10px",
        backgroundColor: "#F0F0F0",
    },
}));

const defaultAcc = {
    account_name: "",
    acc_id: null,
    account_id: null,
    company_id: null,
    country_id: "",
    currency_id: "",
    bank_id: "",
    frequency: 0,
};

let rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        name: <Skeleton variant="text" animation="wave" width="100%" />,
    });
}

const PendingAccounts = (props) => {
    const {
        pendingAccounts,
        archivedAccounts,
        setIsLoading,
        setReload,
        countriesFilters,
        currenciesFilters,
        banksFilters,
        setTexto,
        setAlertType,
        setShow,
        companyID,
        accounts,
        isLoading,
        showSkeleton,
        handleClickHelp,
    } = props;

    /** Internationalization i18n */
    const { t, todos } = useGlobalContext();

    const dispatch = useDispatch();

    /** Component states */
    const [isArchivedOpen, setIsArchivedOpen] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [selectedAcc, setSelectedAcc] = useState(defaultAcc);
    const [editMode, setEditMode] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [currentAccount, setCurrentAccount] = useState({});
    const [showAcc, setShowAcc] = useState(false);
    const [isError, setIsError] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [, setSearchParams] = useSearchParams();

    // const [token, setToken] = useState(null);

    // const PlaidLink = ({ token }) => {
    //     const onSuccess = useCallback((public_token) => {
    //         accountsService
    //             .setAccessToken({ public_token })
    //             .then((response) => {
    //                 console.log(response);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }, []);

    //     const config = {
    //         token,
    //         onSuccess,
    //     };

    //     const { open } = usePlaidLink(config);

    //     return (
    //         <IconButton onClick={open}>
    //             <Box
    //                 component="img"
    //                 sx={{
    //                     width: 50,
    //                 }}
    //                 src={PlaidIcon}
    //             />
    //         </IconButton>
    //     );
    // };

    // const createLinkToken = async () => {
    //     await accountsService
    //         .createLinkToken()
    //         .then((response) => {
    //             console.log(response.link_token);
    //             setToken(response.link_token);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    const archiveAccount = async (accountID) => {
        setIsLoading(true);

        let params = {
            account_id: accountID,
        };

        await accountsService
            .archiveAccount(params)
            .then(() => {
                setReload();
                setAlertType("success");
                setTexto(t("accounts.successArchived"));
                setShow(true);
            })
            .catch((err) => {
                setTexto(t("accounts.errorArchived"));
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const restoreAccount = async (accountID) => {
        setIsLoading(true);

        let params = {
            account_id: accountID,
        };

        await accountsService
            .restoreAccount(params)
            .then(() => {
                setReload();
                setAlertType("success");
                setTexto(t("accounts.successUnarchived"));
                setShow(true);
            })
            .catch((err) => {
                setTexto(t("accounts.errorUnarchived"));
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const handleClickReload = async (event) => {
        let params = {
            company_id: companyID,
        };

        await accountsService.updateSystemAccounts(params);
        setReload();
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
    };

    const handleClickNewAccount = () => {
        setEditMode(false);
        setOpenNew(true);
    };

    const handleOpenEdit = (item) => {
        if (!("frequency" in item)) {
            item = {
                ...item,
                frequency: "",
            };
        }

        setCurrentAccount(item);
        setEditMode(true);
        setSelectedAcc(item);
        setOpenNew(true);
    };

    const handleCloseModal = () => {
        setSelectedAcc(defaultAcc);
        setOpenNew(false);
        setEditMode(false);
        setShowAcc(false);
        setOpenAlert(false);
        setIsError(false);
    };

    const handleNew = async () => {
        if (
            !selectedAcc.account_name ||
            !selectedAcc.country_id ||
            !selectedAcc.currency_id ||
            !selectedAcc.bank_id ||
            (selectedAcc.system_integration === 1 &&
                selectedAcc.account_id_system !== null &&
                selectedAcc.account_id_system !== undefined &&
                selectedAcc.account_id_system !== "" &&
                !selectedAcc.frequency)
        ) {
            setIsError(true);
            setTexto(t("accounts.requireFields"));
            setAlertType("warning");
            setShow(true);
            return;
        }

        setIsLoading(true);

        let bank_id = selectedAcc.bank_id;

        if (selectedAcc.bank_id.includes(",")) {
            let banksId = selectedAcc.bank_id.split(",");
            let finalBank = banksFilters.find(
                (bank) =>
                    banksId.includes(bank._id) &&
                    selectedAcc.country_id === bank.country_id &&
                    selectedAcc.currency_id === bank.currency_id
            );
            bank_id = finalBank._id;
        }

        const params = {
            account_name: selectedAcc.account_name,
            company_id: companyID,
            country_id: selectedAcc.country_id,
            currency_id: selectedAcc.currency_id,
            bank_id: bank_id,
            frequency: selectedAcc.frequency,
        };

        await accountsService
            .addAccount(params)
            .then(() => {
                setTexto(t("accounts.successNew"));
                setAlertType("success");
                setShow(true);
                handleCloseModal();
                setReload();
                if (todos.steps.step4) {
                    dispatch(addTutorial({ ...todos.steps, step4: false, step5: true }));
                }
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    useEffect(() => {
        // createLinkToken();

        if (companyID) {
            async function getCompanyDetails() {
                try {
                    const result = await companyService.getCompanyDetails(companyID);

                    setCompanyData(result);
                } catch (error) {
                    console.log(error);
                }
            }
            getCompanyDetails();
        }
        // eslint-disable-next-line
    }, [companyID]);

    useEffect(() => {
        if (todos.steps.step3 && !todos.dontShowAgain) {
            let isDone = false;

            introJs()
                .setOptions({
                    disableInteraction: true,
                    exitOnOverlayClick: false,
                    keyboardNavigation: false,
                    nextLabel: t("dialog.continue"),
                    prevLabel: t("miscellaneous.goBack"),
                    doneLabel: t("miscellaneous.accept"),
                    steps: [
                        {
                            title: `${t("miscellaneous.step")} 3`,
                            element: "#account-tutorial",
                            intro: t("miscellaneous.tutorialAcc1"),
                        },
                    ],
                })
                .oncomplete(() => {
                    isDone = true;
                    dispatch(addTutorial({ ...todos.steps, step3: false, step4: true }));
                })
                .onexit(() => {
                    if (!isDone) {
                        dispatch(addTutorial({ ...todos.steps, step3: false }));
                    }
                })
                .start();
        }
    }, [dispatch, t, todos.dontShowAgain, todos.steps]);

    const handleUpdate = async () => {
        if (
            !selectedAcc.account_name ||
            !selectedAcc.country_id ||
            !selectedAcc.currency_id ||
            !selectedAcc.bank_id ||
            (selectedAcc.system_integration === 1 &&
                selectedAcc.account_id_system !== null &&
                selectedAcc.account_id_system !== undefined &&
                selectedAcc.account_id_system !== "" &&
                !selectedAcc.frequency)
        ) {
            setIsError(true);
            setTexto(t("accounts.requireFields"));
            setAlertType("warning");
            setShow(true);
            return;
        }

        setIsLoading(true);

        let bank_id = selectedAcc.bank_id;

        if (selectedAcc.bank_id.includes(",")) {
            let banksId = selectedAcc.bank_id.split(",");
            let finalBank = banksFilters.find(
                (bank) =>
                    banksId.includes(bank._id) &&
                    selectedAcc.country_id === bank.country_id &&
                    selectedAcc.currency_id === bank.currency_id
            );
            bank_id = finalBank._id;
        }

        const params = {
            account_id: selectedAcc._id,
            account_name: selectedAcc.account_name,
            company_id: selectedAcc.company_id,
            country_id: selectedAcc.country_id,
            currency_id: selectedAcc.currency_id,
            bank_id: bank_id,
            frequency: selectedAcc.frequency,
        };

        await accountsService
            .updateAccount(params)
            .then(() => {
                setTexto(t("accounts.successUpdate"));
                setAlertType("success");
                setShow(true);
                handleCloseModal();
                setReload();
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleCombine = () => {
        setOpenAlert(true);
    };

    const combineAcc = async () => {
        if (selectedAcc.frequency >= 1 && selectedAcc.frequency <= 31) {
            setIsLoading(true);
            const params = {
                account_id: selectedAcc.acc_id,
                pending_account_id: selectedAcc._id,
                account_name: selectedAcc.account_name,
                company_id: selectedAcc.company_id,
                country_id: selectedAcc.country_id,
                currency_id: selectedAcc.currency_id,
                bank_id: selectedAcc.bank_id,
                frequency: selectedAcc.frequency,
            };

            await accountsService
                .combineAccounts(params)
                .then(() => {
                    setTexto(t("accounts.successUpdate"));
                    setAlertType("success");
                    setShow(true);
                    handleCloseModal();
                    setReload();
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        } else {
            setOpenAlert(false);
            setIsError(true);
        }
    };

    const bodyMess = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mt: 2,
                }}
                textAlign="justify"
            >
                {t("accounts.haveInMind")} <b>{selectedAcc.account_name}</b> {`${t("accounts.with")} `}
                <b>{accounts && accounts.find((element) => element._id === selectedAcc.acc_id)?.account_name}</b>,
                {` ${t("accounts.pendingAcc")}`}
            </Typography>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mt: 2,
                    mb: 3,
                }}
                textAlign="justify"
            >
                {t("accounts.desCannotUndo")}
            </Typography>
        </>
    );

    const handleChangeSwitch = async (event) => {
        setShowAcc((prevState) => !prevState);

        if (showAcc) {
            setSelectedAcc({
                ...selectedAcc,
                country_id: currentAccount.country_id,
                currency_id: currentAccount.currency_id,
                bank_id: currentAccount.bank_id,
                frequency: currentAccount.frequency,
                acc_id: null,
            });
        }
    };

    const editAccButtons = (
        <Grid container justifyContent="space-between">
            <Grid item>
                <BaseButton onClick={handleCloseModal} variantType="primaryTextButton" sizebutton="m">{t("dialog.skip")}</BaseButton>
            </Grid>
            <Grid item>
                {editMode && (
                    <FormControlLabel
                        sx={{ alignItems: "center" }}
                        control={
                            <Switch
                                id="disabledAccounts"
                                checked={showAcc}
                                onChange={handleChangeSwitch}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label={
                            <Typography fontWeight={600} fontSize={14} noWrap>
                                {t("accounts.combine")}
                            </Typography>
                        }
                    />
                )}
                <BaseButton
                    disabled={showAcc && !selectedAcc.acc_id}
                    onClick={showAcc ? handleCombine : editMode ? handleUpdate : handleNew}
                    sizebutton="m"
                >
                    {editMode ? t("dialog.saveChanges") : t("dialog.createAccount")}
                </BaseButton>
            </Grid>
        </Grid>
    );

    const handleParams = () => {
        setSearchParams({ connected: false, connect: "", company_id: companyID, type: 2, open_m: true });
    };

    return (
        <>
            <Card className={classes.cardContent}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={16} fontWeight={600}>
                        {t("accounts.pendingAccounts")}
                    </Typography>
                    {companyData?._id &&
                        (companyData.system_integration === 1 || companyData.system_integration === 2) && (
                            <CachedIcon
                                sx={{ cursor: "pointer" }}
                                fontSize="small"
                                onClick={handleClickReload}
                            />
                        )}
                </Stack>
                <BaseButton
                    sx={{ mt: 2, justifyContent: "left" }}
                    disableElevation
                    onClick={handleClickNewAccount}
                    fullWidth
                    id="account-tutorial"
                >
                    + {t("accounts.createAccount")}
                </BaseButton>
                <List sx={{ maxHeight: 600, overflowY: "auto" }}>
                    {showSkeleton
                        ? rowsSkeleton.map((row) => <StyledListItem key={row.id}>{row.name}</StyledListItem>)
                        : pendingAccounts &&
                          pendingAccounts.map((account) => (
                              <StyledListItem
                                  key={account._id}
                                  secondaryAction={
                                      <LightTooltip title={t("accounts.archive")}>
                                          <>
                                              <IconButton
                                                  edge="end"
                                                  aria-label="archive"
                                                  onClick={() => archiveAccount(account._id)}
                                              >
                                                  <ArchiveIcon className="archive-icon" />
                                              </IconButton>
                                          </>
                                      </LightTooltip>
                                  }
                                  disablePadding
                              >
                                  <ListItemButton onClick={() => handleOpenEdit(account)}>
                                      <ListItemText sx={{ fontWeight: "bold" }}>{account.account_name}</ListItemText>
                                  </ListItemButton>
                              </StyledListItem>
                          ))}
                </List>
                {archivedAccounts && archivedAccounts.length > 0 && (
                    <>
                        <Box
                            onClick={() => setIsArchivedOpen(!isArchivedOpen)}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                                height: "45px",
                            }}
                        >
                            <ArchiveIcon sx={{ mt: 1, ml: 1.5 }} />
                            <Typography sx={{ mt: 1.5, ml: 2 }}>{t("accounts.reviewArchived")}</Typography>
                            {isArchivedOpen ? (
                                <ExpandLessIcon sx={{ mt: 1, ml: "auto", mr: 1.5 }} />
                            ) : (
                                <ExpandMoreIcon sx={{ mt: 1, ml: "auto", mr: 1.5 }} />
                            )}
                        </Box>
                        <Collapse in={isArchivedOpen}>
                            <List sx={{ maxHeight: 600, overflowY: "auto" }}>
                                {showSkeleton
                                    ? rowsSkeleton.map((row) => <StyledListItem>{row.name}</StyledListItem>)
                                    : archivedAccounts &&
                                      archivedAccounts.map((account) => (
                                          <StyledListItem
                                              key={account._id}
                                              secondaryAction={
                                                  <LightTooltip title={t("accounts.unarchive")}>
                                                      <>
                                                          <IconButton
                                                              edge="end"
                                                              aria-label="archive"
                                                              onClick={() => restoreAccount(account._id)}
                                                          >
                                                              <UnarchiveIcon className="unarchive-icon" />
                                                          </IconButton>
                                                      </>
                                                  </LightTooltip>
                                              }
                                          >
                                              <ListItemText sx={{ fontWeight: "bold" }}>
                                                  {account.account_name}
                                              </ListItemText>
                                          </StyledListItem>
                                      ))}
                            </List>
                        </Collapse>
                    </>
                )}
                {pendingAccounts && pendingAccounts.length === 0 && archivedAccounts.length === 0 && (
                    <Box sx={{ justifyContent: "center" }}>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                mr: 1,
                            }}
                        >
                            {`${t("accounts.noPendingAccounts")} `}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <BaseButton onClick={handleParams} variantType="errorText">
                                {`${t("accounts.seeIntegrations")}`}
                            </BaseButton>
                            {/* <LightTooltip title={t("dialog.youWishPlaid")}>
                                <Grid item>{token && <PlaidLink token={token} />}</Grid>
                            </LightTooltip> */}
                        </Stack>
                    </Box>
                )}
            </Card>
            <FormDialog
                open={openNew}
                handleClose={handleCloseModal}
                maxWidth="md"
                title={
                    editMode ? (
                        <Stack direction="row" justifyContent="space-between">
                            {t("accounts.config")} {currentAccount.account_name}
                            <BaseButton variantType="errorText" onClick={handleClickHelp}>{t("accounts.needHelp")}</BaseButton>
                        </Stack>
                    ) : (
                        <Stack direction="row" justifyContent="space-between">
                            {t("accounts.createAccount1")}
                            <BaseButton variantType="errorText" onClick={handleClickHelp}>{t("accounts.needHelp")}</BaseButton>
                        </Stack>
                    )
                }
                content={
                    <AccountDetails
                        data={selectedAcc}
                        setSelectedAcc={setSelectedAcc}
                        countries={countriesFilters}
                        currencies={currenciesFilters}
                        banks={banksFilters}
                        showAcc={showAcc}
                        accounts={accounts}
                        isError={isError}
                        setIsLoading={setIsLoading}
                        editMode={editMode}
                        handleClickHelp={handleClickHelp}
                    />
                }
                actions={editAccButtons}
            />
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={combineAcc}
                maxWidth="sm"
            />
            <SimpleBackdrop open={isLoading} />
        </>
    );
};

export default PendingAccounts;
