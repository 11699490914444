import {
    Box,
    Checkbox,
    IconButton,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Grid,
    Autocomplete,
    Popper,
    Divider,
    InputAdornment,
} from "@mui/material";
import FormDialog from "../../ui/FormDialog";
import InfoIcon2 from "@mui/icons-material/Info";

import { useTranslation } from "react-i18next";
import SyncIcon from "@mui/icons-material/Sync";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";

import XeroLogo from "../../../assets/xero.svg";
import QuickBooksLogo from "../../../assets/quickbooks.svg";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import LightTooltip from "../../ui/LightTooltip";

import moment from "moment";

import conversionService from "../../../services/conversion";
import { useSelector } from "react-redux";
import EditContactChecks from "./EditContactChecks";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { getContactIcon, similarity } from "../../../utils/utils";
import BaseButton from "../../ui/BaseButton";
import CustTextField from "../../ui/CustTextField";
import CustomDatePicker from "../../ui/CustomDatePicker";
import BaseSelect2 from "../../ui/BaseSelect2";
import PreviewIcon from "@mui/icons-material/Preview";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const ChecksSync = (props) => {
    const {
        setIsLoading,
        checksData,
        downloadChecks,
        handleGoBack,
        setChecksStatus,
        setDetailsHist,
        setShowSyncDetails,
        setShowSync,
        previewCheck,
        setOpenHeavy,
    } = props;
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    let filteredCurrentCompany = todos?.allComData.find((item) => item._id.toString() === todos?.companyID);

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);
    const [rows, setRows] = useState([]);
    const [rowsSize, setRowsSize] = useState(0);
    const [selectedAllAcc, setSelectedAllAcc] = useState("");
    const [accountsXero, setAccountsXero] = useState([]);
    const [contactsXero, setContactsXero] = useState([]);
    const [status, setStatus] = useState(0);
    const [hasErrors, setHasErrors] = useState(0);
    const [openModalEditContact, setOpenModalEditContact] = useState(false);
    const [contactXeroUpdated, setContactXeroUpdated] = useState("");
    const [extContactName, setExtContactName] = useState("");
    const [rowEditContact, setRowEditContact] = useState("");
    const [currentCheckData, setCurrentCheckData] = useState(checksData);

    const columns = [
        { name: "checks", title: t("converter.checks"), width: "10%" },
        { name: "contactExt", title: t("converter.contactExt") },
        { name: "contactXeroName", title: t("converter.contactXero"), width: "20%" },
        { name: "date", title: t("payment.date"), align: "center", width: "10%" },
        { name: "concept", title: t("payment.concept") },
        { name: "amount", title: t("credits.amount"), align: "right", width: "10%" },
        { name: "account", title: t("accounts.accountNum"), width: "15%" },
        { name: "actions", title: <></>, width: "2%" },
    ];

    useEffect(() => {
        if ("checks" in currentCheckData) {
            const newRowsSync = currentCheckData.checks.filter((item) => item.id_status === 3 || item.id_status === 4);
            const newRows = newRowsSync.map((itemAux) => {
                const findContactSim = contactsXero.find((item) => item.name === itemAux.check_pay_to);

                // Si no se consigue el contacto exacto, se busca una coincidencia aproximada
                var similarContact = null;
                if (!findContactSim) {
                    similarContact = contactsXero.find((item) => similarity(item.name, itemAux.check_pay_to) >= 0.85);
                }

                if (itemAux.contact_account_code?.length === 1 || itemAux.contact_tax_type?.length === 1) {
                    if (
                        !itemAux.account_code_xero &&
                        !itemAux.account_code &&
                        itemAux.contact_account_code.length === 1
                    ) {
                        itemAux.account_code_xero = itemAux.contact_account_code[0].AccountCode;
                    }
                }

                return {
                    id: itemAux._id,
                    checks: itemAux.check_number,
                    contactExt: itemAux.check_pay_to,
                    contactXeroID: findContactSim?.value ?? similarContact?.value ?? "first",
                    contactXeroName: findContactSim?.name ?? similarContact?.name ?? itemAux.check_pay_to,
                    date: moment.utc(itemAux.formatted_date),
                    concept: itemAux.check_description,
                    amount: itemAux.formatted_amount,
                    account: itemAux.account_code_xero ?? "",
                    uploaded_file_s3: itemAux.uploaded_file_s3,
                    contacts: [
                        { value: "first", name: findContactSim?.name ?? similarContact?.name ?? itemAux.check_pay_to },
                    ],
                };
            });
            setRows(newRows);
            setRowsSize(newRows.length);
        } else {
            const findContactSim = contactsXero.find((item) => item.name === currentCheckData.check_pay_to);

            // Si no se consigue el contacto exacto, se busca una coincidencia aproximada
            var similarContact = null;
            if (!findContactSim) {
                similarContact = contactsXero.find(
                    (item) => similarity(item.name, currentCheckData.check_pay_to) >= 0.85
                );
            }

            if (
                currentCheckData.contact_account_code?.length === 1 ||
                currentCheckData.contact_tax_type?.length === 1
            ) {
                if (
                    !currentCheckData.account_code_xero &&
                    !currentCheckData.account_code &&
                    currentCheckData.contact_account_code.length === 1
                ) {
                    currentCheckData.account_code_xero = currentCheckData.contact_account_code[0].AccountCode;
                }
            }

            const newRow = [
                {
                    id: currentCheckData._id,
                    checks: currentCheckData.check_number,
                    contactExt: currentCheckData.check_pay_to,
                    contactXeroID: findContactSim?.value ?? similarContact?.value ?? "first",
                    contactXeroName: findContactSim?.name ?? similarContact?.name ?? currentCheckData.check_pay_to,
                    date: moment.utc(currentCheckData.formatted_date),
                    concept: currentCheckData.check_description,
                    amount: currentCheckData.formatted_amount,
                    account: currentCheckData.account_code_xero ?? "",
                    uploaded_file_s3: currentCheckData.uploaded_file_s3,
                    contacts: [
                        {
                            value: "first",
                            name: findContactSim?.name ?? similarContact?.name ?? currentCheckData.check_pay_to,
                        },
                    ],
                },
            ];

            setRows(newRow);
            setRowsSize(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsXero, todos.dateFormat]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    setAccountsXero(data.map((item) => ({ value: item.Code, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getContacts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setContactsXero(
                        data.map((item) => ({ ...item, value: item.ContactID, name: item.Name, idSys: item.contact_id_system, }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
    }, [setIsLoading, todos.companyID]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangeAmount = (event, id) => {
        if (event.target.value > 0 || event.target.value === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux.id) {
                        return { ...aux, [event.target.name]: event.target.value };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeTexts = (event, id) => {
        setRows(
            rows.map((aux) => {
                if (id === aux.id) {
                    return { ...aux, [event.target.name]: event.target.value };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleChangeSelect = (event, id) => {
        setRows(
            rows.map((aux) => {
                if (id === aux.id) {
                    return { ...aux, [event.target.name]: event.target.value };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleChangeSelectContact = async (newValue, id) => {
        if (newValue !== null) {
            setIsLoading(true);

            if (newValue.value !== "first") {
                const newContactID = contactsXero.find((item) => item.value === newValue.value).idSys;
                const params = {
                    company_id: todos.companyID,
                    contact_id_system: newContactID,
                };
                await conversionService.getLastSPENDsFromContactArrays(params).then((response) => {
                    if (response) {
                        const newData = { ...currentCheckData };
    
                        if (newData.checks) {
                            newData.checks = currentCheckData.checks.map((item) => {
                                if (item._id === id) {
                                    item.contact_account_code = response.contact_account_code;
                                    item.contact_tax_type = response.contact_tax_type;
                                }
                                return item;
                            });
                        } else {
                            newData.contact_account_code = response.contact_account_code;
                            newData.contact_tax_type = response.contact_tax_type;
                        }
                        setCurrentCheckData(newData);
                    }
                });
            }

            let finalRows = rows.map((aux) => {
                if (id === aux.id) {
                    return {
                        ...aux,
                        contactXeroID: newValue.value,
                        contactXeroName: newValue.name,
                    };
                } else {
                    return aux;
                }
            });

            setRows(finalRows);
            setIsLoading(false);
        }
    };

    const handleChangeDate = (val, id) => {
        setRows(
            rows.map((aux) => {
                if (id === aux.id) {
                    return { ...aux, date: val };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleChangeSelectAcc = (event) => {
        setSelectedAllAcc(event.target.value);
        setRows(
            rows.map((aux) => {
                if (selected.find((item) => item === aux.id)) {
                    return { ...aux, account: event.target.value };
                } else {
                    return aux;
                }
            })
        );
    };

    const showDetails = async (data) => {
        const newRows = data
            .filter((item) => item.id_status === 3 || item.id_status === 4)
            .map((itemAux) => {
                return {
                    id: itemAux.check_id,
                    check: itemAux.check_number,
                    contact: itemAux.Name,
                    error: itemAux.error_type,
                    uploaded_file_s3: itemAux.uploaded_file_s3,
                };
            });
        setChecksStatus({
            total: data.length,
            failed: data.filter((item) => item.id_status === 3 || item.id_status === 4)?.length,
            success: data.filter((item) => item.id_status !== 3 && item.id_status !== 4)?.length,
        });
        setDetailsHist(newRows);
        setShowSync(false);
        setShowSyncDetails(true);
    };

    //Funcion de sincronizacion
    const synChecks = async () => {
        setIsLoading(true);

        const newChecksArr = rows.map((item) => ({
            check_id: item.id,
            ContactID: item.contactXeroID === "first" ? null : item.contactXeroID,
            Name: item.contactXeroName,
            AccountCode: item.account,
            Description: item.concept,
            Date: item.date.format("YYYY-MM-DD"),
            Amount: item.amount,
            CheckNumber: item.checks,
        }));

        const params = {
            arrayChecks: newChecksArr,
        };

        console.log(newChecksArr)

        // console.log("params", params);

        await conversionService
            .syncChecks(params)
            .then((response) => {
                if ((response.status && response.status === 203) || (response.data.status && response.data.status === 203)) {
                    setIsLoading(false);
                    endSync();
                    setOpenHeavy(true);
                } else {
                    // console.log("response", response);
                    let hasErrors = 0;
                    const errorIDs = [];
                    showDetails(response.data);
                    for (const item of response.data) {
                        if (item.id_status === 10) {
                            hasErrors++;
                            errorIDs.push(item.check_id);
                        }
                    }
                    // 1: Todo correcto, 2: Algunos cheques no sincronizaron, 3: Ningun cheque se sincronizo
                    if (hasErrors === response.data.length) {
                        setStatus(3);
                    } else if (hasErrors > 0) {
                        setStatus(2);
                    } else {
                        setStatus(1);
                    }
                    setRows(rows.filter((item) => errorIDs.includes(item.id)));
                    setHasErrors(hasErrors);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const endSync = () => {
        setStatus(0);
        handleGoBack();
    };

    const handleClickEditContact = (event, name, pay_to, row_id) => {
        event.stopPropagation();
        setContactXeroUpdated(name);
        setExtContactName(pay_to);
        setRowEditContact(row_id);
        setOpenModalEditContact(true);
    };

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setOpenModalEditContact(false);
        setContactXeroUpdated("");
    };

    const handleConfirmUpdateXeroContact = () => {
        const finalRows = rows.map((aux) => {
            if (rowEditContact === aux.id) {
                const contactsCopy = aux.contacts;
                const indexContact = contactsCopy.findIndex((item) => item.value === "first");
                contactsCopy[indexContact].name = contactXeroUpdated;
                contactsCopy[indexContact].newContact = true;
                return {
                    ...aux,
                    contactXeroName: contactXeroUpdated,
                    contacts: contactsCopy,
                };
            } else {
                return aux;
            }
        });
        setRows(finalRows);
        setOpenModalEditContact(false);
    };

    const dialogContactButtons = (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <BaseButton
                    disableElevation
                    variantType="primaryTextButton"
                    onClick={handleCloseDialog}
                    sizebutton="s"
                >
                    {t("team.cancel")}
                </BaseButton>
            </Grid>
            <Grid item>
                <BaseButton
                    disableElevation
                    sizebutton="s"
                    onClick={handleConfirmUpdateXeroContact}
                    disabled={!contactXeroUpdated || contactXeroUpdated === ""}
                >
                    {t("subscription.confirm")}
                </BaseButton>
            </Grid>
        </Grid>
    );

    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            boxSizing: "border-box",
            "& ul": {
                padding: 0,
                margin: 0,
            },
        },
        [`& .${autocompleteClasses.paper}`]: {
            borderRadius: "15px",
        },
    });

    console.log(rows)

    return (
        <>
            <Box
                sx={{
                    backgroundColor: status === 0 ? "#F6F4FD" : status === 1 || status === 2 ? "#DBF4E5" : "#FFD8D5",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    {status === 0 ? (
                        <>
                            <Box>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography variant="h3">{t(
                                            filteredCurrentCompany?.system_integration === 1
                                                ? "converter.syncXeroChecks"
                                                : "converter.syncQBChecks"
                                        )}</Typography>
                                    <Box
                                    component="img"
                                    sx={{
                                        height: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                        width: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                    }}
                                    src={filteredCurrentCompany?.system_integration === 1 ? XeroLogo : QuickBooksLogo}
                                />
                                </Stack>
                                <Typography>
                                    <b>{rows.length}</b>{" "}
                                    {rows.length === 1 ? t("converter.totalCheck") : t("converter.totalCheck2")}
                                </Typography>
                            </Box>
                            <Stack direction="row" spacing={2}>
                                <BaseButton variantType="primaryOutlined" sizebutton="s" endIcon={<DownloadIcon />} onClick={downloadChecks}>
                                    {t("payment.download")}
                                </BaseButton>
                                <BaseButton
                                    sizebutton="s"
                                    disableElevation
                                    endIcon={<SyncIcon />}
                                    onClick={synChecks}
                                    disabled={rows.find((item) => item.account === "" || item.checks === "" || !item.checks || item.amount === "" || item.contactXeroID === "")}
                                >
                                    {t("converter.sync")}
                                </BaseButton>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {status !== 3 && (
                                    <Typography variant="h3" sx={{ color: status === 1 ? "#34BD6A" : "#FFB23F" }}>
                                        {rowsSize - hasErrors}/{rowsSize}
                                    </Typography>
                                )}
                                <Typography variant="h3">
                                    {status === 1 || status === 2
                                        ? t("converter.checksSyncSucc")
                                        : t("converter.bannerErr")}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 20,
                                        width: 20,
                                    }}
                                    src={XeroLogo}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={4}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        backgroundColor: status !== 3 ? "#E4F7EB" : "#FFE1E6",
                                        borderRadius: 10,
                                        px: 2,
                                        py: 1,
                                    }}
                                >
                                    {status === 1 ? (
                                        <CheckIcon color="success" />
                                    ) : status === 2 ? (
                                        <ErrorIcon color="warning" />
                                    ) : (
                                        <CloseIcon color="error" />
                                    )}
                                    <Typography>
                                        {status === 1
                                            ? t("converter.bannerCheck")
                                            : status === 2
                                            ? t("converter.bannerWarning")
                                            : t("converter.bannerError")}
                                    </Typography>
                                </Stack>
                                <BaseButton sizebutton="s" disableElevation onClick={endSync}>
                                    {t("dialog.finally")}
                                </BaseButton>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Box>
            <Box sx={{ backgroundColor: "#F8F8F9", px: 3, py: 2, borderRadius: 4, mt: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">{t("converter.syncChecks")}</Typography>
                    {/* <Button
                        variant="outlined"
                        sx={{ backgroundColor: "white", px: 4, py: 1 }}
                        startIcon={<FilterAltIcon />}
                    >
                        {t("dashboard.filters")}
                    </Button> */}
                </Stack>
                {rows.length > 1 && (
                    <Stack direction="row" spacing={1} alignItems="center" my={2}>
                        <div>
                            <BaseSelect2
                                labelId="simple-select-label-all"
                                name="accXero"
                                value={selectedAllAcc}
                                onChange={handleChangeSelectAcc}
                                size="small"
                                MenuProps={MenuProps}
                                label={t("accounts.accountNum")}
                                disabled={selected.length === 0}
                                sx={{ width: 250 }}
                            >
                                {accountsXero.map((item) => (
                                    <MenuItem value={item.value} key={item.value}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </BaseSelect2>
                        </div>
                        <LightTooltip title={t("converter.assignAllAcc")}>
                            <InfoIcon color="primary" fontSize="small" />
                        </LightTooltip>
                    </Stack>
                )}
                <TableContainer>
                    <Table sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1300 }}>
                        <TableHead>
                            <TableRow sx={{ "& th": { border: "none" } }}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selected.length > 0 && selected.length < rows.length}
                                        checked={rows.length > 0 && selected.length === rows.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableCell>
                                {columns.map((headCell) => (
                                    <StyledTableCell
                                        key={headCell.name}
                                        align={headCell.align ?? "left"}
                                        style={{ width: headCell.width }}
                                    >
                                        {headCell.title}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                const customContacts = row.contacts.concat(contactsXero);

                                return (
                                    <StyledTableRow
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                                onClick={() => handleClick(row.id)}
                                            />
                                        </TableCell>
                                        <StyledTableCell>
                                            <CustTextField
                                                id="input-with-icon-textfield"
                                                hiddenLabel
                                                name="checks"
                                                value={row.checks}
                                                onChange={(event) => handleChangeTexts(event, row.id)}
                                                isSearch={true}
                                                fullWidth
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>{row.contactExt}</StyledTableCell>
                                        <StyledTableCell>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                            <Autocomplete
                                                disableClearable
                                                fullWidth
                                                sx={{ height: 42 }}
                                                PopperComponent={StyledPopper}
                                                options={customContacts}
                                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                                value={
                                                    customContacts.find(
                                                    (item) => item.value === row.contactXeroID
                                                    ) ?? null
                                                }
                                                onChange={(event, newValue) =>
                                                    handleChangeSelectContact(newValue, row.id)
                                                }
                                                renderOption={(props, option, { index }) => {
                                                    const { key, ...optionProps } = props;
                                                    if (option.value === "first") {
                                                        return (
                                                            <Box
                                                                key={key + index}
                                                                component="li"
                                                                {...optionProps}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    width="100%"
                                                                >
                                                                    <Stack direction="column">
                                                                        <Typography
                                                                                fontWeight={600}
                                                                                color="primary"
                                                                            >
                                                                            {t("converter.edit")} {option.name}
                                                                        </Typography>
                                                                        <Typography
                                                                            fontSize={11}
                                                                            lineHeight={1}
                                                                            color="#131f3e"
                                                                        >
                                                                            {t("converter.editContactMsg")}
                                                                        </Typography>
                                                                    </Stack>
                                                                    <IconButton
                                                                        onClick={(event) =>
                                                                            handleClickEditContact(
                                                                                event,
                                                                                option.name,
                                                                                row.contactExt,
                                                                                row.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Stack>
                                                            </Box>
                                                        );
                                                    } else {
                                                        return (
                                                            <Box
                                                                key={key + index}
                                                                component="li"
                                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                {...optionProps}
                                                            >
                                                                <Typography color="#131f3e">
                                                                    {option.name}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <CustTextField
                                                        {...params}
                                                        label={
                                                            <Typography fontWeight={600} color="#361A52">
                                                                {row.contactXeroID === "first"
                                                                    ? t("converter.createContact")
                                                                    : t("converter.chooseContact")}
                                                            </Typography>
                                                        }
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {params.InputProps.endAdornment}
                                                                    <Box sx={{ right: "44px", position: "absolute", top: "50%" }}>
                                                                        <InputAdornment position="end">
                                                                        {getContactIcon(row.contactExt, contactsXero, customContacts, customContacts.find((item) => item.value === row.contactXeroID) ?? null, t, "newContact")}
                                                                        </InputAdornment>
                                                                    </Box>
                                                                </>
                                                            ),
                                                        }}
                                                        isSearch={true}
                                                        size="small"
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => {
                                                    if (!value || Object.keys(value).length === 0) return false;
                                                    return option.value === value.value;
                                                }}
                                                noOptionsText={
                                                    <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                                        <Typography
                                                            fontWeight={600}
                                                            fontSize={13}
                                                            mt={0.5}
                                                            color="#131f3e"
                                                        >
                                                            {t("converter.noContact")}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <CustomDatePicker
                                                value={row.date}
                                                onChange={(e) => handleChangeDate(e, row.id)}
                                                isSearch={true}
                                                fullWidth
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <CustTextField
                                                id="input-with-icon-textfield"
                                                hiddenLabel
                                                name="concept"
                                                value={row.concept}
                                                onChange={(event) => handleChangeTexts(event, row.id)}
                                                isSearch={true}
                                                fullWidth
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <CustTextField
                                                id="input-with-icon-textfield"
                                                hiddenLabel
                                                value={row.amount}
                                                name="amount"
                                                onChange={(event) => handleChangeAmount(event, row.id)}
                                                type="number"
                                                sx={{
                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            display: "none",
                                                        },
                                                }}
                                                isSearch={true}
                                                fullWidth
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <BaseSelect2
                                                fullWidth
                                                hiddenLabel
                                                id="demo-simple-select-standard"
                                                name="account"
                                                value={row.account}
                                                onChange={(event) => handleChangeSelect(event, row.id)}
                                                // label={t("converter.chooseAcc")}
                                                isSearch={true}
                                                size="small"
                                            >
                                                <Typography variant="h3" pl={1} gutterBottom>
                                                    {t("converter.kiiperSug")}
                                                </Typography>
                                                {accountsXero?.filter((item) => {
                                                    const accountCode = "checks" in currentCheckData
                                                        ? currentCheckData?.checks[index]?.contact_account_code
                                                        : currentCheckData?.contact_account_code;

                                                    return accountCode && Array.isArray(accountCode) && accountCode
                                                        ?.map((val) => val.AccountCode)
                                                        ?.includes(item.value);
                                                })?.length === 0 ? (
                                                    <MenuItem disabled value="">
                                                        <em>{t("converter.noHist")}</em>
                                                    </MenuItem>
                                                ) : (
                                                    accountsXero
                                                        ?.filter((item) => {
                                                            const accountCode = "checks" in currentCheckData
                                                                ? currentCheckData?.checks[index]?.contact_account_code
                                                                : currentCheckData?.contact_account_code;
                                                
                                                            // Verificar si `accountCode` es válido antes de mapear y comparar
                                                            return accountCode && Array.isArray(accountCode) && accountCode
                                                                ?.map((val) => val.AccountCode)
                                                                ?.includes(item.value);
                                                        })
                                                        ?.map((val) => (
                                                            <MenuItem key={val.value} value={val.value}>
                                                                {val.name}
                                                            </MenuItem>
                                                        ))
                                                )}
                                                <Divider />
                                                <Typography variant="h3" pl={1} gutterBottom>
                                                    {t("converter.otherAcc")}
                                                </Typography>
                                                    {accountsXero?.filter((item) => {
                                                        const accountCode = "checks" in currentCheckData
                                                            ? currentCheckData?.checks[index]?.contact_account_code
                                                            : currentCheckData?.contact_account_code;
                                                
                                                        // Verificar si `accountCode` es válido antes de mapear y comparar
                                                        return !(accountCode && Array.isArray(accountCode) && accountCode.map((val) => val.AccountCode).includes(item.value));
                                                    })
                                                    .map((item) => (
                                                        <MenuItem value={item.value} key={item.value}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </BaseSelect2>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <LightTooltip title={t("converter.previewFile")}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => previewCheck(row?.retry ? row.original_uploaded_file_s3 : row.uploaded_file_s3, row.id)}
                                                >
                                                    <PreviewIcon fontSize="small" />
                                                </IconButton>
                                            </LightTooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <StyledTableRow>
                                    <TableCell colSpan={6} />
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, { label: t("miscellaneous.all"), value: -1 }]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("dashboard.rowsPerPage")}
                    labelDisplayedRows={(page) =>
                        `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${page.count}`
                    }
                />
            </Box>
            <FormDialog
                open={openModalEditContact}
                handleClose={handleCloseDialog}
                maxWidth="sm"
                title={
                    <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                        <InfoIcon2 fontSize="small" />
                        <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                            {t("converter.dialogContactXero")}
                        </Typography>
                    </Stack>
                }
                content={
                    <EditContactChecks
                        extContactName={extContactName}
                        contactXeroUpdated={contactXeroUpdated}
                        setContactXeroUpdated={setContactXeroUpdated}
                    />
                }
                actions={dialogContactButtons}
                align="center"
            />
        </>
    );
};

export default ChecksSync;
