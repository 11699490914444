/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/* Styles */
import classes from "./UserRegister.module.css";

/* MUI Imports */
import {
    Typography,
    Grid,
    InputLabel,
    MenuItem,
    InputAdornment,
    Stack,
    Divider,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

/* MUI Icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/* Services */
import authService from "../../../services/auth";
import userService from "../../../services/user";

/** Country flags */
import Flag from "react-world-flags";
import { useSelector } from "react-redux";
import CustTextField from "../../ui/CustTextField";
import BaseButton from "../../ui/BaseButton";
import BaseSelect2 from "../../ui/BaseSelect2";

const UserRegisterTab1 = (props) => {
    const {
        isError,
        setIsError,
        errorEditMode,
        userData,
        handleChangeUserData,
        editMode,
        originList,
        phoneCode,
        setPhoneCode,
        countryCode,
        setCountryCode,
        allCountries,
        language,
        phoneNumber,
        setPhoneNumber,
        setUserData,
        compareUserData,
        setAlertType,
        existingEmail,
        setExistingEmail,
        errorEmail,
        setErrorEmail,
        setShow,
        setTexto,
        password,
        setPassword,
        updatePassword,
        setUpdatePassword,
        colorSecurityLevel,
        validateSecurityLevel,
        showChangePassword,
        setShowChangePassword,
        errorPassword,
        setErrorPassword,
        setOpenBackdrop,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const roleList = [
        { id: 1, data: t("register.accountant") },
        { id: 2, data: t("register.businessOwner") },
        { id: 3, data: t("register.manager") },
        { id: 4, data: t("register.administrator") },
        { id: 5, data: t("register.businessman") },
        { id: 6, data: t("register.other") },
    ];

    const countryAndPhoneInput = (
        <>
            <Grid item xs={12} md={6}>
                <BaseSelect2
                    fullWidth
                    error={(isError || errorEditMode) && (userData.role === "" || !userData.role)}
                    label={t("register.role")}
                    name="role"
                    value={userData.role ?? ""}
                    onChange={(event) => handleChangeUserData(event)}
                    renderValue={() => (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography>{roleList.find((rol) => rol.id === userData.role)?.data}</Typography>
                        </Stack>
                    )}
                >
                    {roleList?.map(({ id, data }) => (
                        <MenuItem value={id} key={`${id}${data}`}>
                            {data}
                        </MenuItem>
                    ))}
                </BaseSelect2>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <BaseSelect2
                            label={t("register.code")}
                            error={(isError || errorEditMode) && (phoneCode === "" || !phoneCode) ? true : false}
                            name="phoneCode"
                            value={countryCode ?? ""}
                            onChange={(event) => handleChangePhoneCode(event)}
                            fullWidth
                            renderValue={() => (
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Flag code={countryCode} height="12" width="20" />
                                    <Typography>{phoneCode}</Typography>
                                </Stack>
                            )}
                        >
                            {allCountries.map((option) => (
                                <MenuItem value={option.country_code} key={option.country_code}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Flag code={option.country_code} height="14" width="22" />
                                        <Typography>
                                            {sessionStorage.getItem("lng") === "en" || language === "en"
                                                ? option.name_en
                                                : option.name_es}
                                            {" ("}
                                            {option.phone_code + ")"}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </BaseSelect2>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustTextField
                            fullWidth
                            label={t("register.phone")}
                            name="phoneNumber"
                            error={(isError || errorEditMode) && (phoneNumber === "" || !phoneNumber)}
                            onChange={(event) => handleChangePhoneNumber(event)}
                            value={phoneNumber}
                            autoComplete="one-time-code"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setCountryCode(event.target.value);
            setPhoneCode(codeFilter.phone_code);
        }
    };

    const handleChangePhoneNumber = (event) => {
        if (!isNaN(event.target.value)) {
            setPhoneNumber(event.target.value);
        }
    };

    const handleChangeCurrentEmail = async (event) => {
        setUserData({
            ...userData,
            email: event.target.value,
        });
        verifyEmail(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email)
                .then((response) => {
                    if (response.status === 200 && email !== compareUserData.email) {
                        setAlertType("error");
                        setExistingEmail(true);
                        setErrorEmail(true);
                        setShow(true);
                        setTexto(t("register.existingEmail"));
                    } else {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                });
        } else {
            setIsError(true);
        }
    };

    const renderPasswordInput = (
        label,
        type,
        name,
        onChange,
        value,
        error,
        onClick,
        onMouseDown,
        iconName,
        showVisibility,
        mt,
        onBlur
    ) => {
        return (
            <Grid item xs={12} mt={mt ? mt : null}>
                <CustTextField
                    fullWidth
                    error={error}
                    label={label}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    type={type}
                    autoComplete="one-time-code"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={onClick} onMouseDown={onMouseDown} edge="end" name={iconName}>
                                {showVisibility ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
        );
    };

    const handleChangePassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                [event.target.name]: event.target.value,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleClickShowPassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                showPassword: !password.showPassword,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                showCurrentPassword: !updatePassword.showCurrentPassword,
            });
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowRepeatPassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                showRepeatPassword: !password.showRepeatPassword,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                showNewPassword: !updatePassword.showNewPassword,
            });
        }
    };

    const boxSecurityLevel = (color) => (
        <Box sx={{ backgroundColor: color, width: "40px", height: "15px", borderRadius: "4px", ml: 0.6 }}></Box>
    );

    const securityLevelContent = (
        <Grid item xs={6}>
            <InputLabel className={classes.labels}>{t("register.securityLevel")}</InputLabel>
            <Box sx={{ mt: 1.5, mb: 2, display: "flex", flexDirection: "row" }}>
                {boxSecurityLevel(colorSecurityLevel.firstLevel)}
                {boxSecurityLevel(colorSecurityLevel.secondLevel)}
                {boxSecurityLevel(colorSecurityLevel.thirdLevel)}
                {boxSecurityLevel(colorSecurityLevel.fourthLevel)}
                {boxSecurityLevel(colorSecurityLevel.fifthLevel)}
            </Box>
            <List sx={{ mt: -2 }}>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.uppercaseLetter ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.uppercaseLetter")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.lowercaseLetter ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.lowercaseLetter")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.requiredNumber ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.requiredNumber")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            color={validateSecurityLevel.requiredSymbol ? "success" : "disabled"}
                            sx={{ fontSize: "20px" }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.requiredSymbol")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.eightCharacters ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.eightCharacters")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
            </List>
        </Grid>
    );

    const handleShowChangePassword = () => {
        setShowChangePassword((show) => !show);
        setUpdatePassword({
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showRepeatNewPassword: false,
        });
    };

    const handleChangeCurrentPassword = async (event) => {
        setUpdatePassword({
            ...updatePassword,
            currentPassword: event.target.value,
        });
    };

    const verifyPass = async () => {
        setOpenBackdrop(true);
        await userService
            .verifyPassword({
                user_id: todos?.userInfo._id,
                password: updatePassword.currentPassword,
            })
            .then((response) => {
                if (response.status === 200) {
                    setErrorPassword(false);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setErrorPassword(true);
                }
            });

        setOpenBackdrop(false);
    };

    const handleClickShowRepeatNewPassword = (event) => {
        setUpdatePassword({
            ...updatePassword,
            showRepeatNewPassword: !updatePassword.showRepeatNewPassword,
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CustTextField
                        fullWidth
                        error={(isError || errorEditMode) && (userData.fullName === "" || !userData.fullName)}
                        label={t("register.fullName")}
                        name="fullName"
                        onChange={(event) => handleChangeUserData(event)}
                        value={userData.fullName}
                    />
                </Grid>
                {!editMode ? (
                    <>
                        <Grid item xs={6}>
                            <BaseSelect2
                                error={
                                    (isError || errorEditMode) && (userData.role === "" || !userData.role)
                                        ? true
                                        : false
                                }
                                label={t("register.role")}
                                name="role"
                                displayEmpty
                                value={userData.role ?? ""}
                                onChange={(event) => handleChangeUserData(event)}
                                fullWidth
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return t("register.select");
                                    }
                                    return roleList.find((rol) => rol.id === selected)?.data;
                                }}
                                shrink
                            >
                                <MenuItem disabled value="">
                                    <em>{t("register.select")}</em>
                                </MenuItem>
                                {roleList?.map(({ id, data }) => (
                                    <MenuItem value={id} key={`${id}${data}`}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </BaseSelect2>
                        </Grid>
                        <Grid item xs={6}>
                            <BaseSelect2
                                error={isError && userData.origin.length === 0 ? true : false}
                                shrink
                                label={t("register.origin")}
                                displayEmpty
                                name="origin"
                                value={userData.origin}
                                onChange={(event) => handleChangeUserData(event)}
                                fullWidth
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return t("register.select");
                                    }

                                    return originList.find((rol) => rol.id === selected)?.data;
                                }}
                            >
                                <MenuItem disabled value="">
                                    <em>{t("register.select")}</em>
                                </MenuItem>
                                {originList?.map(({ id, data }) => (
                                    <MenuItem value={id} key={`${id}${data}`}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </BaseSelect2>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <BaseSelect2
                                        label={t("register.code")}
                                        error={
                                            (isError || errorEditMode) && (phoneCode === "" || !phoneCode)
                                                ? true
                                                : false
                                        }
                                        name="phoneCode"
                                        value={countryCode ?? ""}
                                        onChange={(event) => handleChangePhoneCode(event)}
                                        fullWidth
                                        renderValue={() => (
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Flag code={countryCode} height="12" width="20" />
                                                <Typography>{phoneCode}</Typography>
                                            </Stack>
                                        )}
                                    >
                                        {allCountries.map((option) => (
                                            <MenuItem value={option.country_code} key={option.country_code}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Flag code={option.country_code} height="14" width="22" />
                                                    <Typography>
                                                        {sessionStorage.getItem("lng") === "en" || language === "en"
                                                            ? option.name_en
                                                            : option.name_es}
                                                        {" ("}
                                                        {option.phone_code + ")"}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}
                                    </BaseSelect2>
                                </Grid>
                                <Grid item xs={8}>
                                    <CustTextField
                                        fullWidth
                                        label={t("register.phone")}
                                        name="phoneNumber"
                                        error={(isError || errorEditMode) && (phoneNumber === "" || !phoneNumber)}
                                        onChange={(event) => handleChangePhoneNumber(event)}
                                        value={phoneNumber}
                                        autoComplete="one-time-code"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} md={6}>
                            <CustTextField
                                fullWidth
                                label={t("register.email")}
                                name="email"
                                onChange={(event) => handleChangeCurrentEmail(event)}
                                error={
                                    ((isError || errorEditMode || errorEmail) && !/.+@.+\..+/.test(userData.email)) ||
                                    existingEmail
                                }
                                value={userData.email}
                            />
                        </Grid>
                        {countryAndPhoneInput}
                    </>
                )}
            </Grid>
            {!editMode ? (
                <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Divider sx={{ mt: 2, mb: 2, width: "50%" }} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {renderPasswordInput(
                                t("register.password"),
                                password.showPassword ? "text" : "password",
                                "password",
                                handleChangePassword,
                                password.password,
                                isError && (password.password === "" || password.password !== password.repeatPassword)
                                    ? true
                                    : false,
                                handleClickShowPassword,
                                handleMouseDownPassword,
                                "showPassword",
                                password.showPassword
                            )}
                            {renderPasswordInput(
                                t("register.repeatPassword"),
                                password.showRepeatPassword ? "text" : "password",
                                "repeatPassword",
                                handleChangePassword,
                                password.repeatPassword,
                                isError &&
                                    (password.repeatPassword === "" || password.password !== password.repeatPassword)
                                    ? true
                                    : false,
                                handleClickShowRepeatPassword,
                                handleMouseDownPassword,
                                "showRepeatPassword",
                                password.showRepeatPassword,
                                3
                            )}
                            {password.password &&
                                password.repeatPassword &&
                                password.password !== password.repeatPassword && (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        {
                                            <Typography sx={{ fontSize: "12px" }} color="error" mt={1}>
                                                {t("register.passwordError")}
                                            </Typography>
                                        }
                                    </Box>
                                )}
                        </Grid>
                        {securityLevelContent}
                    </Grid>
                </>
            ) : !showChangePassword ? (
                <BaseButton variantType="primaryText" sx={{ my: 1 }} onClick={handleShowChangePassword}>
                    {t("register.changePassword")}
                </BaseButton>
            ) : null}
            {showChangePassword ? (
                <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Divider sx={{ mt: 2, mb: 2, width: "50%" }} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {renderPasswordInput(
                                t("register.currentPassword"),
                                updatePassword.showCurrentPassword ? "text" : "password",
                                "currentPassword",
                                handleChangeCurrentPassword,
                                updatePassword.currentPassword,
                                errorPassword ? true : false,
                                handleClickShowPassword,
                                handleMouseDownPassword,
                                "showCurrentPassword",
                                updatePassword.showCurrentPassword,
                                3,
                                verifyPass
                            )}
                            {renderPasswordInput(
                                t("register.newPassword"),
                                updatePassword.showNewPassword ? "text" : "password",
                                "newPassword",
                                handleChangePassword,
                                updatePassword.newPassword,
                                isError &&
                                    (updatePassword.newPassword === "" ||
                                        updatePassword.newPassword !== updatePassword.repeatPassword)
                                    ? true
                                    : false,
                                handleClickShowRepeatPassword,
                                handleMouseDownPassword,
                                "showNewPassword",
                                updatePassword.showNewPassword,
                                3
                            )}
                            {renderPasswordInput(
                                t("register.repeatPassword"),
                                updatePassword.showRepeatNewPassword ? "text" : "password",
                                "repeatPassword",
                                handleChangePassword,
                                updatePassword.repeatPassword,
                                isError &&
                                    (updatePassword.repeatPassword === "" ||
                                        updatePassword.repeatPassword !== updatePassword.newPassword)
                                    ? true
                                    : false,
                                handleClickShowRepeatNewPassword,
                                handleMouseDownPassword,
                                "showRepeatPassword",
                                updatePassword.showRepeatNewPassword,
                                3
                            )}
                            {updatePassword.newPassword &&
                                updatePassword.repeatPassword &&
                                updatePassword.newPassword !== updatePassword.repeatPassword && (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        {
                                            <Typography sx={{ fontSize: "12px" }} color="error" mt={1}>
                                                {t("register.passwordError")}
                                            </Typography>
                                        }
                                    </Box>
                                )}
                        </Grid>
                        {securityLevelContent}
                    </Grid>
                </>
            ) : null}
            <Grid item sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                {editMode && showChangePassword && (
                    <BaseButton variantType="primaryText" sx={{ mr: "auto" }} onClick={handleShowChangePassword}>
                        {t("register.cancelChangePass")}
                    </BaseButton>
                )}
            </Grid>
        </>
    );
};

export default UserRegisterTab1;
