import {
    Box,
    Button,
    Grid,
    IconButton,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import LightTooltip from "../../ui/LightTooltip";
import classes from "../../ui/UploadFile.module.css";
import { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import CheckIcon from "@mui/icons-material/Check";
import CachedIcon from "@mui/icons-material/Cached";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import BaseButton from "../../ui/BaseButton";

import conversionService from "../../../services/conversion";
import { fetchFileFromS3 } from "../../../utils/utils";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "& td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "2px 15px",
    },
}));

const UploadFilePopover = (props) => {
    const {
        setTexto,
        setAlertType,
        setShow,
        setIsLoading,
        setPreviewFile,
        setOpenPreview,
        type,
        rowsModal,
        setRowsModal,
        currentInv,
        setAccountPrev,
        integration,
        previewFile
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isLoadingConv] = useState(false);
    const [loadingFile, setLoadingFile] = useState(true);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? "simple-popover" : undefined;

    const [t] = useTranslation("global");

    const handleClose = () => {
        setAnchorEl(null);
    };

    const previewDoc = async (e) => {
        e.stopPropagation();

        // setIsLoading(true)
        // let dataFile = await fetchFileFromS3(rows[0].file_name_s3);
        // setIsLoading(false)

        // if (!dataFile) {
        //     setTexto(t("converter.dnldError"));
        //     setAlertType("error");
        //     setShow(true);
        // } else {
        //     setFileData(dataFile);
        // }

        // setFileNameFull(rows[0].file_name_s3);

        // setBasePreviewData({
        //     type: "invoice",
        //     file_s3: rows[0].file_name_s3,
        //     file: rows[0].file_name,
        // });
        // // Open modal
        // setOpenPreview(true);
    };

    function generateUniqueId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    }

    const isModal = true;

    const formats = [
        { id: 0, name: "pdf" },
        { id: 1, name: "jpg" },
        { id: 2, name: "png" },
    ];

    let acceptFormat = {
        "application/pdf": [],
        "image/png": [],
        "image/jpg": [],
        "image/jpeg": [],
    };

    /** Component functions */
    const onDrop = useCallback(
        async (newAcceptedFiles) => {
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded / total) * 100);

                    if (percent < 100) {
                        setProgress(percent);
                    }
                },
            };

            setLoadingFile(true);

            const data = new FormData();
            data.append("invoice_id", currentInv._id);
            data.append("file", newAcceptedFiles[0]);

            setAcceptedFiles([...acceptedFiles, newAcceptedFiles[0]]);

            await conversionService
                .uploadAddFileS3(data, options)
                .then((response) => {
                    let newRows = [
                        {
                            id: generateUniqueId(),
                            file_name: newAcceptedFiles[0].name,
                            file_name_s3: response,
                        },
                    ];

                    setRowsModal([...rowsModal, ...newRows]);
                })
                .catch((err) => {
                    console.log(err);
                });

            if (newAcceptedFiles.length === 0) {
                setTexto(t("converter.warningWrongFormat"));
                setAlertType("warning");
                setShow(true);
            }
            setProgress(0);
            setLoadingFile(false);
        },
        [acceptedFiles, currentInv._id, rowsModal, setAlertType, setRowsModal, setShow, setTexto, t]
    );

    const { getRootProps, getInputProps } = useDropzone({
        disabled: formats.length === 0 || progress > 0,
        accept: acceptFormat,
        onDrop,
    });

    const formatBytes = (bytes) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
    };

    const preview = async (file) => {
        setIsLoading(true);

        const data = await fetchFileFromS3(file.file_name_s3);

        setPreviewFile({
            fileName: file.file_name,
            fileData: data,
        });
        setAccountPrev(file.id);
        handleClose();

        if (type !== "view") {
            setOpenPreview(true);
        }

        setIsLoading(false);
    };

    const deleteFile = async (e) => {
        e.stopPropagation();
    };

    const deleteFiles = async (id, filename, filenames3) => {
        const params = {
            uploaded_file_s3: filenames3,
            invoice_id: currentInv._id,
        };

        setIsLoading(true);

        let finalRowsModal = rowsModal.filter((item) => item.id !== id);

        await conversionService
            .deleteAddFileS3(params)
            .then(async () => {
                setRowsModal(finalRowsModal);
                setAcceptedFiles(acceptedFiles.filter((item) => item.name !== filename));

                if(previewFile.fileName === filename){
                    const data = await fetchFileFromS3(finalRowsModal[0].file_name_s3);

                    setPreviewFile({
                        fileName: finalRowsModal[0].file_name,
                        fileData: data,
                    });
                    setAccountPrev(finalRowsModal[0].id);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (finalRowsModal.length === 0) {
            setAcceptedFiles([]);
        }

        setIsLoading(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                aria-describedby={idPopover}
                onClick={handleClick}
                sx={{
                    minWidth: 0,
                    py: 1.5,
                    px: 1.8,
                }}
            >
                {rowsModal.length > 0 ? (
                    <Box component="span" sx={{ mr: 0.8, ml: 0.5 }}>
                        {rowsModal.length}
                    </Box>
                ) : (
                    ""
                )}{" "}
                <AttachFileIcon color="primary" fontSize="small" />
            </Button>
            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    textAlign: "left",
                }}
                PaperProps={{
                    style: { width: "30%", padding: 5, borderRadius: 14 },
                }}
            >
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12}>
                        <TableContainer
                            sx={{
                                maxHeight: 214,
                                overflow: "auto",
                                "&::-webkit-scrollbar": {
                                    width: "10px",
                                    height: "10px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    boxShadow: "inset 10px 10px 15px #c7c9d1",
                                    borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#404963",
                                    borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#031851",
                                },
                            }}
                        >
                            <Table aria-label="simple table" sx={{ borderCollapse: "separate" }}>
                                <TableBody>
                                    {rowsModal.map((row, index) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <BaseButton onClick={() => preview(row)} variantType="primaryText">
                                                        {row.file_name}
                                                    </BaseButton>
                                                    <LightTooltip
                                                        title={
                                                            integration === 1 && currentInv.id_status === 4
                                                                ? t("miscellaneous.cantdelXero")
                                                                : ""
                                                        }
                                                    >
                                                        <span>
                                                            <IconButton
                                                                color="secondary"
                                                                size="small"
                                                                onClick={() =>
                                                                    deleteFiles(row.id, row.file_name, row.file_name_s3)
                                                                }
                                                                disabled={
                                                                    index === 0 ||
                                                                    (integration === 1 && currentInv.id_status === 4)
                                                                }
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </span>
                                                    </LightTooltip>
                                                </Stack>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Box
                    {...getRootProps({
                        className: formats.length === 0 || progress > 0 ? classes.dropzone : classes.dropzoneActive,
                    })}
                >
                    <input {...getInputProps()} />
                    <Grid container spacing={2} justifyContent="center">
                        <Grid
                            item
                            xs={12}
                            sm={10}
                            sx={{
                                bgcolor: "#fff",
                                padding: "6px 10px 12px 30px !important",
                                mt: 2,
                                border: "1px solid #E0E0E0",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                mt={1}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography
                                        variant="h2"
                                        fontWeight={500}
                                        fontSize={window.innerWidth > 1360 ? 15 : 12}
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                formats.length === 0
                                                    ? "converter.dragDropDisabled"
                                                    : "converter.dragDrop"
                                            ),
                                        }}
                                    ></Typography>
                                    <Stack direction="row" spacing={1} mt={2} alignItems="center">
                                        <Typography
                                            variant="h2"
                                            fontWeight={500}
                                            fontSize={window.innerWidth > 1360 ? 13 : 11}
                                        >
                                            {t("converter.formatInv")}
                                        </Typography>
                                        {formats.map((item, index) => (
                                            <Box component="span" className={classes.boxFormat} key={"format_" + index}>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={
                                                        window.innerWidth > 1360 ? "11px !important" : "9px !important"
                                                    }
                                                >
                                                    .{item.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                                <UploadFileIcon
                                    fontSize={window.innerWidth > 1360 ? "large" : "medium"}
                                    sx={{ color: formats.length === 0 ? "disabled" : "#361A52", mr: 1.5 }}
                                />
                            </Stack>
                        </Grid>
                        {((acceptedFiles.length > 0 && progress !== 0 && loadingFile) || isLoadingConv) && (
                            <Grid item xs={12} sm={11} sx={{ pt: "20px !important" }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h2" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                        {t("converter.uploading")}
                                    </Typography>
                                    <CachedIcon color="primary" fontSize="small" />
                                </Stack>
                            </Grid>
                        )}
                        {((acceptedFiles.length > 0 && progress === 0 && !loadingFile && !isModal) ||
                            isLoadingConv) && (
                            <Grid
                                item
                                xs={12}
                                sm={11}
                                sx={{
                                    paddingTop: isLoadingConv ? "0 !important" : "16px !important",
                                }}
                            >
                                {!isLoadingConv && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography
                                            variant="h4"
                                            fontSize={window.innerWidth > 1360 ? 13 : 11}
                                            gutterBottom
                                        >
                                            {t("converter.fileLoaded")}
                                        </Typography>
                                        <CheckIcon sx={{ color: "#0BEF57" }} fontSize="small" />
                                    </Stack>
                                )}
                                {acceptedFiles.length === 1 && (
                                    <Stack direction="row" spacing={0.7} alignItems="center">
                                        <Typography
                                            color="primary"
                                            variant="h4"
                                            fontSize={window.innerWidth > 1360 ? 13 : 11}
                                        >
                                            {acceptedFiles[0].path} ({formatBytes(acceptedFiles[0].size)})
                                        </Typography>
                                        <LightTooltip title={t("converter.previewFile")}>
                                            <IconButton size="small" color="primary" onClick={previewDoc}>
                                                <PreviewIcon fontSize="small" />
                                            </IconButton>
                                        </LightTooltip>
                                        <LightTooltip title={t("miscellaneous.clear")}>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                disabled={isLoadingConv}
                                                onClick={(e) => !isLoadingConv && deleteFile(e)}
                                                sx={{
                                                    color: isLoadingConv ? "grey" : "#4A22D4",
                                                    transition: "all .2s ease-in",
                                                    "&:hover": {
                                                        color: isLoadingConv ? "grey" : "red",
                                                    },
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </LightTooltip>
                                    </Stack>
                                )}
                                {acceptedFiles.length > 1 && (
                                    <Stack direction="row" spacing={0.7} alignItems="center">
                                        <Typography
                                            color="primary"
                                            variant="h4"
                                            fontSize={window.innerWidth > 1360 ? 13 : 11}
                                        >
                                            {acceptedFiles.length}
                                            {t("converter.uploadedFiles")}
                                        </Typography>
                                        <LightTooltip title={t("miscellaneous.clear")} position="right">
                                            <DeleteIcon
                                                sx={{
                                                    width: "18px",
                                                    color: isLoadingConv ? "grey" : "#4A22D4",
                                                    transition: "all .2s ease-in",
                                                    "&:hover": {
                                                        color: isLoadingConv ? "grey" : "red",
                                                    },
                                                    cursor: "pointer",
                                                }}
                                                disabled={isLoadingConv}
                                                onClick={(e) => !isLoadingConv && deleteFile(e)} // Evita el click si está deshabilitado
                                            />
                                        </LightTooltip>
                                    </Stack>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default UploadFilePopover;
