import { useTheme } from '@mui/material';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [t, i18n] = useTranslation('global');
  const todos = useSelector((state) => state.value);
  const theme = useTheme();

  return (
    <GlobalContext.Provider value={{ t, i18n, todos, theme }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Hook personalizado para consumir el contexto
export const useGlobalContext = () => {
  return useContext(GlobalContext);
};