/** React imports */
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** React-Redux */
import { useSelector, useDispatch } from "react-redux";
import {
    addUserInfo,
    addAuth,
    addPermissions,
    addDateFormat,
    addAmountFormat,
    addTutorial,
    addCompanyID,
    addFranchiseID,
} from "../../../redux/userSlice";
import { useTheme } from '@mui/material/styles';

/* Styles */
import classes from "./UserRegister.module.css";

/* MUI Imports */
import {
    Typography,
    Button,
    Paper,
    Box,
    Link,
    Stepper,
    Step,
    StepLabel,
    Stack,
    Container,
} from "@mui/material";

/** Components imports */
import countriesProject from "../../Js/countries";
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import DialogChangeEmail from "../../user/DialogChangeEmail";
import DialogSaveNewEmail from "../../user/DialogSaveNewEmail";
import AlertDialog from "../../ui/AlertDialog";

/* Services */
import authService from "../../../services/auth";
import userService from "../../../services/user";
import casesServices from "../../../services/case";
import userConfigService from "../../../services/user_config";
import ITTeam from "../../Js/ITTeam";
import Integrations from "../../subscription/Integrations";
import userCompanyService from "../../../services/user_company";
import PlanSubs from "../../subscription/plans/PlansMain";
import ShoppingCartReg from "../../subscription/shoppingCart/ShoppingCartReg";
import { updateCartPlan } from "../../../utils/utils";
import CustomForm from "../../subscription/CustomForm";
import UserRegisterTab2 from "./UserRegisterTab2";
import UserRegisterTab1 from "./UserRegisterTab1";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import ChatModal from "../../ui/Chat";
import BaseButton from "../../ui/BaseButton";

const UserRegister = (props) => {
    const {
        editMode,
        userEmail,
        handleShowRegister,
        independentUser,
        invitation,
        language,
        invitationId,
        isLoginRedirect,
    } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const theme = useTheme(); // Obtener el tema actual

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /* RegExp */
    let uppercaseLetter = /[A-Z]/;
    let lowercaseLetter = /[a-z]/;
    let requiredNumber = /\d/;
    let requiredSymbol = /[,.#\-@$!?*/+]/;

    /** Countries */
    const allCountries = [...countriesProject];
    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en" || language === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    /* Component states */
    const [isError, setIsError] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [counter, setCounter] = useState(120);
    const [checkTandC, setCheckTandC] = useState(false);
    const [termsLink, setTermsLink] = useState(
        sessionStorage.getItem("lng") === "es" || language === "es"
            ? "https://kiiper.app/es/terms-and-conditions"
            : "https://kiiper.app/terms-and-conditions/"
    );

    const [userData, setUserData] = useState({
        fullName: "",
        role: "",
        tools: [],
        origin: "",
        country: "",
        email: "",
        practice_name: "",
        practice_size: "",
        company_type: "",
        business_activity: "",
        company_name: "",
        company_country_name: "",
        subscription_type: "",
    });

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [countryCode, setCountryCode] = useState("");

    const [password, setPassword] = useState({
        password: "",
        repeatPassword: "",
        showPassword: false,
        showRepeatPassword: false,
    });
    const [securityLevel, setSecurityLevel] = useState(0);
    const [validateSecurityLevel, setValidateSecurityLevel] = useState({
        uppercaseLetter: false,
        lowercaseLetter: false,
        requiredNumber: false,
        requiredSymbol: false,
        eightCharacters: false,
    });
    const [colorSecurityLevel, setColorSecurityLevel] = useState({
        firstLevel: "rgba(19, 31, 62, 0.05)",
        secondLevel: "rgba(19, 31, 62, 0.05)",
        thirdLevel: "rgba(19, 31, 62, 0.05)",
        fourthLevel: "rgba(19, 31, 62, 0.05)",
        fifthLevel: "rgba(19, 31, 62, 0.05)",
    });

    /* Estados para edición de perfil de usuario */
    const [errorEditMode, setErrorEditMode] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
    const [confirChangeEmail, setConfirmChangeEmail] = useState(false);
    const [updatePassword, setUpdatePassword] = useState({
        currentPassword: "",
        newPassword: "",
        repeatPassword: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showRepeatNewPassword: false,
    });
    const [confirmPassword, setConfirmPassword] = useState({
        password: "",
        repeatPassword: "",
        showPassword: false,
        showRepeatPassword: false,
    });
    const [errorPassword, setErrorPassword] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [paramsChangeMail, setParamsChangeMail] = useState({});
    const [compareUserData, setCompareUserData] = useState({
        fullName: "",
        country: "",
        email: "",
        phoneCode: "",
        phoneNumber: "",
    });
    const [activeStep, setActiveStep] = useState(isLoginRedirect ? 2 : 0);
    const [inheritData, setInheritData] = useState(false);
    const [smbInfo, setSmbInfo] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [integ, setInteg] = useState(3);
    const [openAlert, setOpenAlert] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [bodyMess, setBodyMess] = useState("");
    const [agreeBtnMsg, setAgreeBtnMsg] = useState(t("dialog.continue"));
    const [disagreeBtnMsg, setDisagreeBtnMsg] = useState(t("dialog.cancel"));
    const [questionCustom, setQuestionCustom] = useState({
        question1: "",
        question2: "",
        question3: "",
    });
    const [currentPlan, setCurrentPlan] = useState({
        value: 1,
        custom: false,
    });
    const [paymentType, setPaymentType] = useState("monthly");
    const [currentLevel, setCurrentLevel] = useState("");
    const [priceMonthly, setPriceMonthly] = useState("");
    const [priceMonthlyOG, setPriceMonthlyOG] = useState("");
    const [errorCustomPlan, setErrorCustomPlan] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [caseInfo, setCaseInfo] = useState({});
    const [afterRedirect, setAfterRedirect] = useState(false);

    const subscriptionType = [
        { id: 1, name: t("register.accountingFirm") },
        { id: 2, name: t("register.companiesGroup") },
        { id: 3, name: t("register.indivCompany") },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const originList = [
        { id: 0, data: t("register.recommendation") },
        { id: 1, data: t("register.socialNetworks") },
        { id: 2, data: t("register.webPage") },
        { id: 3, data: "Xero app store" },
        { id: 4, data: t("register.forums") },
        { id: 5, data: t("register.myBusiness") },
        { id: 6, data: t("register.other") },
    ];


    // useEffect(() => {
    //     setUserData((prevState) =>({
    //         ...prevState,
    //         role: roleList.find(item => item.id === prevState.role)?.id ?? "",
    //         origin: originList.find(item => item.id === prevState.origin)?.id ?? ""
    //     }));
    // }, [originList, roleList])

    /* Component functions */
    const handleChangeUserData = (event) => {
        event.preventDefault();

        setUserData({
            ...userData,
            [event.target.name]: event.target.value,
        });
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    useEffect(() => {
        if ((todos?.userInfo?.email || userEmail) && !isLoginRedirect) {
            setOpenBackdrop(true);
            async function getUserData() {
                try {
                    let result = await userService.getUserData(todos?.userInfo?.email ?? userEmail);
                    dispatch(addUserInfo(result));

                    if (result) {
                        setUserData({
                            fullName: result.user_name,
                            country: result.country_name,
                            email: result.email,
                            tools: result.software,
                            origin: result.kiiper_discovery
                                ? result.kiiper_discovery
                                : invitation
                                    ? t("register.myBusiness")
                                    : "",
                            role: result.role,
                        });

                        await userConfigService
                            .getUserConfig(result._id)
                            .then((response) => {
                                if (response.status === 204) {
                                    sessionStorage.setItem("lng", "en");
                                    i18n.changeLanguage("en");

                                    dispatch(addDateFormat("MM/DD/YYYY"));
                                    dispatch(addAmountFormat("en-US"));
                                } else {
                                    sessionStorage.setItem("lng", response.language);
                                    i18n.changeLanguage(response.language);

                                    dispatch(addDateFormat(response.date_format));
                                    dispatch(addAmountFormat(response.amount_format));
                                }
                            })
                            .catch((error) => {
                                console.log(error.response);
                            });

                        setPhoneCode(result.phone_code);
                        setPhoneNumber(result.phone_number);

                        let codeFilter = allCountries.find((country) => country.phone_code === result.phone_code);
                        setCountryCode(codeFilter?.country_code);

                        setCompareUserData({
                            fullName: result.user_name,
                            country: result.country_name,
                            email: result.email,
                            phoneCode: result.phone_code,
                            phoneNumber: result.phone_number,
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            getUserData();
            setOpenBackdrop(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const newPassword = editMode ? updatePassword.newPassword : password.password;
        const hasUppercase = uppercaseLetter.test(newPassword);
        const hasLowercase = lowercaseLetter.test(newPassword);
        const hasNumber = requiredNumber.test(newPassword);
        const hasSymbol = requiredSymbol.test(newPassword);
        const hasMinLength = newPassword.length >= 8;
        const trueCount = [hasUppercase, hasLowercase, hasNumber, hasSymbol, hasMinLength].filter(Boolean).length;

        setValidateSecurityLevel({
            uppercaseLetter: hasUppercase,
            lowercaseLetter: hasLowercase,
            requiredNumber: hasNumber,
            requiredSymbol: hasSymbol,
            eightCharacters: hasMinLength,
        });

        setSecurityLevel(trueCount);
        // eslint-disable-next-line
    }, [password.password, updatePassword.newPassword, editMode]);

    useEffect(() => {
        if (securityLevel === 0) {
            setColorSecurityLevel({
                firstLevel: "rgba(19, 31, 62, 0.05)",
                secondLevel: "rgba(19, 31, 62, 0.05)",
                thirdLevel: "rgba(19, 31, 62, 0.05)",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel === 1) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#DD1717",
                secondLevel: "rgba(19, 31, 62, 0.05)",
                thirdLevel: "rgba(19, 31, 62, 0.05)",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel >= 2) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#DD1717",
                secondLevel: "#DD1717",
                thirdLevel: "rgba(19, 31, 62, 0.05)",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel >= 3 && securityLevel < 4) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#FFC266",
                secondLevel: "#FFC266",
                thirdLevel: "#FFC266",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel >= 4 && securityLevel < 5) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#00B147",
                secondLevel: "#00B147",
                thirdLevel: "#00B147",
                fourthLevel: "#00B147",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel === 5) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#00B147",
                secondLevel: "#00B147",
                thirdLevel: "#00B147",
                fourthLevel: "#00B147",
                fifthLevel: "#00B147",
            });
        }
        // eslint-disable-next-line
    }, [securityLevel]);

    useEffect(() => {
        if (!editMode) {
            if (
                password.password &&
                password.repeatPassword &&
                userData.fullName &&
                userData.role &&
                userData.origin.length > 0 &&
                securityLevel === 5 &&
                phoneCode &&
                phoneNumber &&
                activeStep === 0
            ) {
                setDisabledButton(false);
            } else if (
                password.password &&
                password.repeatPassword &&
                userData.fullName &&
                userData.role &&
                userData.origin.length > 0 &&
                securityLevel === 5 &&
                phoneCode &&
                phoneNumber &&
                userData.country &&
                userData.practice_name &&
                userData.subscription_type &&
                userData.company_name &&
                userData.company_country_name &&
                userData.tools.length > 0 &&
                userData.business_activity &&
                (activeStep === 1 || activeStep === 2)
            ) {
                setDisabledButton(false);
            } else if (
                userData.fullName &&
                userData.role &&
                userData.origin.length > 0 &&
                userData.country &&
                userData.practice_name &&
                userData.subscription_type &&
                userData.company_name &&
                userData.company_country_name &&
                userData.tools.length > 0 &&
                userData.business_activity &&
                activeStep === 3
            ) {
                setDisabledButton(false);
            } else {
                setDisabledButton(false);
            }
        } else {
            if (userData.fullName && userData.role && userData.email && phoneCode && phoneNumber) {
                if (showChangePassword) {
                    if (
                        updatePassword.currentPassword &&
                        updatePassword.newPassword &&
                        updatePassword.repeatPassword &&
                        securityLevel === 5 &&
                        !errorPassword
                    ) {
                        setDisabledButton(false);
                    } else {
                        setDisabledButton(true);
                    }
                } else {
                    setDisabledButton(false);

                    if (existingEmail) {
                        setDisabledButton(true);
                    }

                    if (
                        userData.fullName === compareUserData.fullName &&
                        userData.role === compareUserData.role &&
                        userData.email === compareUserData.email &&
                        phoneCode === compareUserData.phoneCode &&
                        phoneNumber === compareUserData.phoneNumber &&
                        !showChangePassword
                    ) {
                        setDisabledButton(true);
                    }
                }
            } else {
                setDisabledButton(true);
            }
        }
    }, [
        showChangePassword,
        password.password,
        password.repeatPassword,
        userData.fullName,
        userData.role,
        userData.origin,
        userData.country,
        securityLevel,
        editMode,
        updatePassword.currentPassword,
        updatePassword.newPassword,
        updatePassword.repeatPassword,
        userData.email,
        phoneCode,
        phoneNumber,
        compareUserData.fullName,
        compareUserData.role,
        compareUserData.email,
        compareUserData.phoneCode,
        compareUserData.phoneNumber,
        errorPassword,
        existingEmail,
        userData.practice_name,
        userData.subscription_type,
        userData.company_name,
        userData.company_country_name,
        userData.tools?.length,
        userData.business_activity,
        activeStep,
    ]);

    useEffect(() => {
        if (editMode) {
            if (!phoneCode || !phoneNumber || !userData.country || !userData.fullName || !userData.email) {
                setErrorEditMode(true);
                setDisabledButton(true);
            } else {
                setErrorEditMode(false);
            }
        }
    }, [editMode, userData.email, phoneCode, phoneNumber, userData.country, userData.fullName]);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
            setTermsLink(
                language === "es" || i18n.language === "es"
                    ? "https://kiiper.app/es/terms-and-conditions"
                    : "https://kiiper.app/terms-and-conditions/"
            );
        }
    }, [language, i18n]);

    useEffect(() => {
        (async () => {
            const company_param = searchParams.get("company_id");
            const connected_param = searchParams.get("connected");
            const connect_param = searchParams.get("connect");
            const type_param = searchParams.get("redirectType");
            const alert_param = searchParams.get("alert");
            if (company_param && type_param === "3") {
                const allCountriesF = [...countriesProject];
                // Redirección de Xero o Quickbooks al conectarse
                if (connected_param && connected_param === "true" && connect_param) {
                    await userCompanyService
                        .getBusinessDirectoryDetails({ company_id: company_param })
                        .then((data) => {
                            setSmbInfo(data);

                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                            setSearchParams(searchParams);

                            const discover = originList.find((item) => item.data === data?.user_info?.kiiper_discovery)
                            setUserData({
                                ...userData,
                                fullName: data.user_info.user_name,
                                role: data.user_info.role,
                                tools: data.franchise_id.file_formats,
                                country: data.franchise_id.country_name,
                                email: data.user_info.email,
                                practice_name: data.franchise_id.franchise_name,
                                business_activity: data.franchise_id.business_activity,
                                company_name: data.company_name,
                                company_country_name: data.country_name,
                                subscription_type: data.franchise_id.type,
                                origin: discover?.id ?? "",
                            });

                            setPhoneCode(data?.user_info?.phone_code ?? "");
                            setPhoneNumber(data?.user_info?.phone_number ?? "");

                            let codeFilter = allCountriesF.find((country) => country.phone_code === data?.user_info?.phone_code);
                            setCountryCode(codeFilter?.country_code ?? "");

                            setActiveStep(3);
                            setAlertType("success");
                            setTexto(t("dialog.connectionEstablished"));
                            setShow(true);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    // Botón de regresar
                    await userCompanyService
                        .getBusinessDirectoryDetails({ company_id: company_param })
                        .then((data) => {
                            setSmbInfo(data);

                            const discover = originList.find((item) => item.data === data?.user_info?.kiiper_discovery)
                            setUserData({
                                ...userData,
                                fullName: data.user_info.user_name,
                                role: data.user_info.role,
                                tools: data.franchise_id.file_formats,
                                country: data.franchise_id.country_name,
                                email: data.user_info.email,
                                practice_name: data.franchise_id.franchise_name,
                                business_activity: data.franchise_id.business_activity,
                                company_name: data.company_name,
                                company_country_name: data.country_name,
                                subscription_type: data.franchise_id.type,
                                origin: discover?.id ?? "",
                            });

                            setPhoneCode(data?.user_info?.phone_code ?? "");
                            setPhoneNumber(data?.user_info?.phone_number ?? "");

                            let codeFilter = allCountriesF.find((country) => country.phone_code === data?.user_info?.phone_code);
                            setCountryCode(codeFilter?.country_code ?? "");

                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                            setSearchParams(searchParams);

                            setActiveStep(3);
                            if (alert_param && alert_param === "1") {
                                setAlertType("error");
                                setTexto(t("dialog.alreadyConnected"));
                                setShow(true);
                            } else if (alert_param && alert_param === "2") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError1"));
                                setShow(true);
                            } else if (alert_param && alert_param === "3") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError2"));
                                setShow(true);
                            } else if (alert_param && alert_param === "4") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError3"));
                                setShow(true);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                setAfterRedirect(true)
            }
        })();
    }, [searchParams, t, userData, setSearchParams, originList]);

    useEffect(() => {
        (async () => {
            const redirectLogin = searchParams.get("redirectLogin");
            const redirectCompany = searchParams.get("company_id");

            const allCountriesF = [...countriesProject];
            if (redirectLogin && redirectLogin === "true" && redirectCompany) {
                // Redirección desde login
                await userCompanyService
                    .getBusinessDirectoryDetails({ company_id: redirectCompany })
                    .then((data) => {
                        setSmbInfo(data);

                        const discover = originList.find((item) => item.data === data?.user_info?.kiiper_discovery)
                        setUserData({
                            ...userData,
                            fullName: data.user_info.user_name,
                            role: data.user_info.role,
                            tools: data.franchise_id.file_formats,
                            country: data.franchise_id.country_name,
                            email: data.user_info.email,
                            practice_name: data.franchise_id.franchise_name,
                            business_activity: data.franchise_id.business_activity,
                            company_name: data.company_name,
                            company_country_name: data.country_name,
                            subscription_type: data.franchise_id.type,
                            origin: discover?.id ?? "",
                        });

                        setPhoneCode(data?.user_info?.phone_code ?? "");
                        setPhoneNumber(data?.user_info?.phone_number ?? "");

                        let codeFilter = allCountriesF.find((country) => country.phone_code === data?.user_info?.phone_code);
                        setCountryCode(codeFilter?.country_code ?? "");

                        searchParams.delete("redirectLogin");
                        searchParams.delete("company_id");
                        setSearchParams(searchParams);

                        setActiveStep(2);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        })();
    }, [searchParams, t, userData, setSearchParams, originList]);

    const resetStates = () => {
        setPhoneNumber("");
        setPhoneCode("");
        setUserData({
            fullName: "",
            role: "",
            tools: [],
            origin: "",
            country: "",
            email: "",
            practice_name: "",
            practice_size: "",
            company_type: "",
            business_activity: "",
            company_name: "",
            company_country_name: "",
            subscription_type: "",
        });
        setPassword({
            password: "",
            repeatPassword: "",
            showPassword: false,
            showRepeatPassword: false,
        });
        setValidateSecurityLevel({
            uppercaseLetter: false,
            lowercaseLetter: false,
            requiredNumber: false,
            requiredSymbol: false,
            eightCharacters: false,
        });
        setColorSecurityLevel({
            firstLevel: "rgba(19, 31, 62, 0.05)",
            secondLevel: "rgba(19, 31, 62, 0.05)",
            thirdLevel: "rgba(19, 31, 62, 0.05)",
            fourthLevel: "rgba(19, 31, 62, 0.05)",
            fifthLevel: "rgba(19, 31, 62, 0.05)",
        });
        setSecurityLevel(0);
        setInheritData(false);
    };

    const handleResend = async () => {
        const params = {
            user_email: userEmail ?? todos?.userInfo?.email,
            user_name: userData.fullName,
            language: language ?? sessionStorage.getItem("lng"),
        };

        setDisabled(true);

        await authService
            .resend(params)
            .then(() => {
                setTexto(t("login.verifyEmail"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => { });
    };

    useEffect(() => {
        if (disabled && counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        if (disabled && counter === 0) {
            setDisabled(false);
            setCounter(120);
        }
    }, [counter, disabled]);

    const updateMicroPlan = async () => {
        handleCloseDialog();
        setOpenBackdrop(true)
        await authService
            .addMicroPlan({ company_id: smbInfo._id, origin: "register", user_email: userEmail ?? todos?.userInfo?.email })
            .then(async () => {
                await redirectAfterSubmit(false)
            })
            .catch((error) => console.log(error.response));
        //setOpenBackdrop(false)
    }

    const updateFreePlan = async () => {
        handleCloseDialog();
        setOpenBackdrop(true);
        await authService
            .addFreeTrialPlan({ company_id: smbInfo._id, planId: currentLevel, origin_form: "register", user_email: userEmail ?? todos?.userInfo?.email })
            .then(async () => {
                await redirectAfterSubmit(false);
            })
            .catch((error) => console.log(error.response));
        //setOpenBackdrop(false);
    };

    const redirectAfterSubmit = async (showBackdrop) => {
        if (showBackdrop)
            setOpenBackdrop(true);

        setAlertType("success");
        setTexto(t("register.success"));
        setShow(true);
        resetStates();

        await authService
            .checkSession()
            .then(async (isActive) => {
                if (isActive) {
                    navigate("/dashboard", {
                        state: {
                            userEmail: userEmail ?? todos?.userInfo?.email,
                            independent_user: independentUser,
                        },
                    });
                    handleShowRegister();
                } else {
                    let paramsSession = { user_email: userEmail ?? todos?.userInfo?.email };
                    if (password.password && password.password !== undefined && password.password !== "") {
                        paramsSession ={
                            ...paramsSession,
                            password: password.password,
                        }
                    } else {
                        paramsSession ={
                            ...paramsSession,
                            autologin: true,
                        }
                    }

                    setTimeout(async () => {
                        await authService
                            .login(paramsSession)
                            .then((responseLogin) => {
                                const defaultTut = {
                                    step1: true,
                                    step2: false,
                                    step4: false,
                                    step5: false,
                                    step6: false,
                                    step7: false,
                                    step8: false,
                                    step9: false,
                                };

                                if (ITTeam.includes(responseLogin.user.email)) {
                                    defaultTut.step1 = false;
                                }

                                dispatch(addUserInfo(responseLogin.user));
                                dispatch(addPermissions(responseLogin.permissions));
                                dispatch(addAuth(true));
                                dispatch(addDateFormat("MM/DD/YYYY"));
                                dispatch(addAmountFormat("en-US"));
                                dispatch(addTutorial(defaultTut));

                                navigate("/dashboard", {
                                    state: {
                                        userEmail: userEmail ?? todos?.userInfo?.email,
                                        independent_user: independentUser,
                                    },
                                });
                                handleShowRegister();
                            })
                            .catch((err) => {
                                switch (err.response.status) {
                                    case 401:
                                        setTexto(t("login.loginError401"));
                                        break;
                                    case 403:
                                        setTexto(
                                            t("login.loginError403") +
                                            (
                                                <Link
                                                    sx={{ fontWeight: 600, p: 0, textDecoration: "none" }}
                                                    component={Button}
                                                    onClick={handleResend}
                                                    disabled={disabled}
                                                >
                                                    {t("team.resend")}{" "}
                                                    {disabled
                                                        ? ` ${t("login.on")} ` + counter + ` ${t("login.sec")} `
                                                        : ""}
                                                </Link>
                                            )
                                        );
                                        break;
                                    case 404:
                                        setTexto(t("login.loginError404"));
                                        break;
                                    default:
                                        setTexto(t("login.loginError500"));
                                        break;
                                }
                                setAlertType("error");
                                setShow(true);
                            });
                        setOpenBackdrop(false);
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setOpenBackdrop(false);
            });
    };

    const handleSubmitCustomPlan = async () => {
        if (questionCustom.question1 === "" || questionCustom.question2 === "" || questionCustom.question3 === "") {
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
            setErrorCustomPlan(true);
        } else {
            setErrorCustomPlan(false);
            handleCloseDialog();
            setOpenBackdrop(true);

            const paramsC = {
                company_id: smbInfo._id,
                planId: 17,
                origin_form: "register",
                origin: 1,
                question1: questionCustom.question1,
                question2: questionCustom.question2,
                question3: questionCustom.question3,
                user_email: userEmail ?? todos?.userInfo?.email
            }

            // Se agrega free trial del plan 11
            await authService
                .addFreeTrialPlan(paramsC)
                .then(async () => {
                    await redirectAfterSubmit(false);
                })
                .catch((error) => console.log(error.response));

            //setOpenBackdrop(false);
        }
    };

    const saveUserBasicData = async () => {
        if (
            !phoneNumber ||
            !phoneCode ||
            !userData.fullName ||
            !userData.role ||
            userData.origin.length === 0 ||
            !password.password ||
            !password.repeatPassword
        ) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
        } else if (password.password && password.repeatPassword && password.password !== password.repeatPassword) {
            setIsError(true);
            setAlertType("warning");
            setShow(true);
            setTexto(t("register.passwordError"));
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsError(false);
        }
    };

    const saveUserData = async () => {
        setOpenBackdrop(true);
        if (
            !phoneNumber ||
            !phoneCode ||
            !userData.fullName ||
            !userData.role ||
            userData.origin.length === 0 ||
            !userData.country ||
            !password.password ||
            !password.repeatPassword ||
            !userData.practice_name ||
            !userData.business_activity ||
            !userData.company_name ||
            !userData.company_country_name ||
            userData.tools.length === 0
        ) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
        } else if (password.password && password.repeatPassword && password.password !== password.repeatPassword) {
            setIsError(true);
            setAlertType("warning");
            setShow(true);
            setTexto(t("register.passwordError"));
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsError(false);
            let params = {
                user_email: userEmail ?? todos?.userInfo?.email,
                user_name: userData.fullName,
                role: userData.role,
                software: userData.tools,
                kiiper_discovery: originList.find((item) => item.id === userData.origin).data,
                country_name: userData.country,
                phone_code: phoneCode,
                phone_number: phoneNumber,
                user_password: password.password,
                practice_name: userData.practice_name,
                practice_size: userData.practice_size,
                company_type: userData.company_type,
                business_activity: userData.business_activity,
                language: language ?? sessionStorage.getItem("lng"),
                invitationId: invitationId && invitationId !== "" ? invitationId : undefined,
                company_name: userData.company_name,
                company_country_name: userData.company_country_name,
                subscription_type: userData.subscription_type,
            };

            await authService
                .updateUser(params)
                .then((data) => {
                    setSmbInfo(data.data);
                    dispatch(addCompanyID(data.data._id));
                    dispatch(addFranchiseID(data.data.franchise_id._id));
                })
                .catch((err) => {
                    switch (err.response.status) {
                        case 403:
                            setTexto(t("register.notVerified"));
                            break;
                        case 404:
                            setTexto(t("register.userError"));
                            break;
                        default:
                            setTexto(t("register.error"));
                            break;
                    }
                    setIsError(true);
                    setAlertType("error");
                    setShow(true);
                });
        }
        setOpenBackdrop(false);
    };

    const updateUserData = async () => {
        setOpenBackdrop(true);
        if (
            !phoneNumber ||
            !phoneCode ||
            !userData.fullName ||
            !userData.role ||
            !userData.email ||
            (showChangePassword &&
                (!updatePassword.currentPassword || !updatePassword.newPassword || !updatePassword.repeatPassword))
        ) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
        } else if (!/.+@.+\..+/.test(userData.email)) {
            setErrorEmail(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.invalidFormat"));
        } else if (showChangePassword && updatePassword.newPassword !== updatePassword.repeatPassword) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.passwordError2"));
        } else {
            setIsError(false);
            setErrorEmail(false);
            setErrorPassword(false);
            let params = {
                user_id: todos?.userInfo?._id,
                user_email: userData.email,
                user_name: userData.fullName,
                role: userData.role,
                phone_code: phoneCode,
                phone_number: phoneNumber,
                language: language ?? sessionStorage.getItem("lng"),
            };

            if (showChangePassword) {
                params.new_password = updatePassword.newPassword;
                params.current_password = updatePassword.currentPassword;
            }

            if (userData.email !== todos?.userInfo?.email) {
                setOpenConfirmPassword(true);
                setParamsChangeMail(params);
            } else {
                await userService
                    .updateUserProfile(params)
                    .then(async () => {
                        setAlertType("success");
                        setTexto(t("register.success"));
                        setShow(true);
                        let result = await userService.getUserData(todos?.userInfo?.email);
                        if (result) {
                            setUserData({
                                fullName: result.user_name,
                                role: userData.role,
                                email: result.email,
                            });

                            setPhoneCode(result.phone_code);
                            setPhoneNumber(result.phone_number);

                            let codeFilter = allCountries.find((country) => country.phone_code === result.phone_code);
                            setCountryCode(codeFilter?.country_code);

                            setCompareUserData({
                                fullName: result.user_name,
                                role: userData.role,
                                email: result.email,
                                phoneCode: result.phone_code,
                                phoneNumber: result.phone_number,
                            });
                            setShowChangePassword(false);
                            setUpdatePassword({
                                currentPassword: "",
                                newPassword: "",
                                repeatPassword: "",
                                showCurrentPassword: false,
                                showNewPassword: false,
                                showRepeatNewPassword: false,
                            });
                        }
                    })
                    .catch((err) => {
                        switch (err.response.status) {
                            case 401:
                                setTexto(t("register.invalidPass"));
                                break;
                            case 404:
                                setTexto(t("register.error"));
                                break;
                            default:
                                setTexto(t("register.error"));
                                break;
                        }
                        setIsError(true);
                        setAlertType("error");
                        setShow(true);
                    });
            }
        }
        setOpenBackdrop(false);
    };

    const handleCloseConfirmPass = () => {
        setOpenConfirmPassword(false);
        setConfirmPassword({
            password: "",
            repeatPassword: "",
            showPassword: false,
            showRepeatPassword: false,
        });
    };

    const handleCloseConfirmEmail = () => {
        setConfirmChangeEmail(false);
    };

    const handleNext = async () => {
        if (activeStep === 0 && editMode) {
            updateUserData();
        } else if (activeStep === 0 && !editMode) {
            saveUserBasicData();
        } else if (activeStep === 1 && !editMode) {
            saveUserData();
        } else if (activeStep === 3 && !editMode) {
            await redirectAfterSubmit(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [t("register.userInfo"), t("register.business"), t("plans.choosePlan"), t("subscription.integ")];

    const handleCloseDialog = () => {
        setQuestionCustom({
            question1: "",
            question2: "",
            question3: "",
        });
        setErrorCustomPlan(false);
        setOpenAlert(false);
    };

    const connectSocketGen = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo)

                initiateSocket(case_id, smbInfo.user_info, "general", "", userEmail);
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClickHelp = async () => {
        setOpenBackdrop(true);

        let params = {
            company_id: smbInfo._id,
            franchise_id: smbInfo.franchise_id._id,
            user_email: smbInfo.user_info?.email ?? userEmail,
            type: "general",
        };

        await casesServices
            .createNewCase(params)
            .then(async (response) => {
                setCaseInfo(response);
                await connectSocketGen(response._id);
            })
            .catch((err) => {
                console.log(err);
            });
        setOpenBackdrop(false);
    };

    const showStep3 = () => {
        setShowCart(false);
    };

    const handleShowCart = async () => {
        handleCloseDialog(false);
        await updateCartPlan(1, currentLevel, paymentType, smbInfo, setOpenBackdrop, 0, "month")

        setShowCart(true);
    };

    const finalF = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        showStep3();
    };

    return (
        <Container maxWidth={editMode ? "md" : "lg"}>
            <Paper elevation={2} className={activeStep === 2 ? classes.content2 : classes.content}>
                <Typography variant="h2" gutterBottom>
                    {editMode ? t("register.editProfile") : t("register.register")}
                </Typography>
                {!editMode && (
                    <Stepper
                        activeStep={activeStep}
                        sx={{ px: { xs: 0, sm: 8, md: 10 }, py: 2, justifyContent: "center" }}
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step
                                    key={label}
                                    {...stepProps}
                                    sx={{
                                        "& .MuiStepLabel-root .Mui-completed": {
                                            color: theme.palette.primary.main, // circle color (COMPLETED)
                                            borderRadius: "50%",
                                        },
                                        "& .MuiStepLabel-root .Mui-active": {
                                            color: theme.palette.primary.main, // circle color (ACTIVE)
                                            borderRadius: "50%",
                                        },
                                        ".MuiSvgIcon-root": {
                                            color: "rgba(0, 0, 0, 0.38)",
                                            borderRadius: "50%",
                                        },
                                    }}
                                >
                                    <StepLabel {...labelProps}>
                                        <Typography
                                            variant="h3"
                                            sx={{ mx: 1 }}
                                        >
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                )}
                {activeStep === 0 && (
                    <UserRegisterTab1
                        isError={isError}
                        setIsError={setIsError}
                        errorEditMode={errorEditMode}
                        userData={userData}
                        handleChangeUserData={handleChangeUserData}
                        editMode={editMode}
                        originList={originList}
                        phoneCode={phoneCode}
                        setPhoneCode={setPhoneCode}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        allCountries={allCountries}
                        language={language}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setUserData={setUserData}
                        compareUserData={compareUserData}
                        setAlertType={setAlertType}
                        existingEmail={existingEmail}
                        setExistingEmail={setExistingEmail}
                        errorEmail={errorEmail}
                        setErrorEmail={setErrorEmail}
                        setShow={setShow}
                        setTexto={setTexto}
                        password={password}
                        setPassword={setPassword}
                        updatePassword={updatePassword}
                        setUpdatePassword={setUpdatePassword}
                        colorSecurityLevel={colorSecurityLevel}
                        validateSecurityLevel={validateSecurityLevel}
                        showChangePassword={showChangePassword}
                        setShowChangePassword={setShowChangePassword}
                        errorPassword={errorPassword}
                        setErrorPassword={setErrorPassword}
                        setOpenBackdrop={setOpenBackdrop}
                    />
                )}
                {activeStep === 1 && (
                    <UserRegisterTab2
                        userData={userData}
                        setUserData={setUserData}
                        handleChangeUserData={handleChangeUserData}
                        isError={isError}
                        errorEditMode={errorEditMode}
                        allCountries={allCountries}
                        language={language}
                        subscriptionType={subscriptionType}
                        inheritData={inheritData}
                        setInheritData={setInheritData}
                        invitation={invitation}
                        checkTandC={checkTandC}
                        setCheckTandC={setCheckTandC}
                        termsLink={termsLink}
                        theme={theme}
                    />
                )}
                {activeStep === 2 && !showCart && (
                    <PlanSubs
                        setTitleModal={setTitleModal}
                        setOpenAlert={setOpenAlert}
                        setAgreeBtnMsg={setAgreeBtnMsg}
                        setDisagreeBtnMsg={setDisagreeBtnMsg}
                        setBodyMess={setBodyMess}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        setCurrentLevel={setCurrentLevel}
                        setPriceMonthly={setPriceMonthly}
                        setPriceMonthlyOG={setPriceMonthlyOG}
                        currentLevel={currentLevel}
                        priceMonthly={priceMonthly}
                        origin="register"
                        currentPlan={currentPlan}
                        handleShowCart={handleShowCart}
                        maxFreeCompanies={1}
                        currentFreeCompanies={0}
                        subsType={userData.subscription_type}
                        setShow={setShow}
                        setAlertType={setAlertType}
                        setMessage={setTexto}
                        isCancelled={false}
                    />
                )}
                {activeStep === 3 && (
                    <Integrations
                        smbInfo={smbInfo}
                        setTexto={setTexto}
                        setShow={setShow}
                        setAlertType={setAlertType}
                        integ={integ}
                        setInteg={setInteg}
                    />
                )}
                {!showCart && activeStep !== 2 && (
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        {!editMode && !afterRedirect && (activeStep === 1 || activeStep === 2 || activeStep === 3) && (
                            <BaseButton variantType="primaryTextButton" sizebutton="s" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                {t("register.back")}
                            </BaseButton>
                        )}
                        <Box sx={{ flex: "1 1 auto" }} />
                        <BaseButton
                            sizebutton={editMode
                                ? "l"
                                : t(
                                    activeStep === 3
                                        ? integ === 3
                                            ? "l"
                                            : "s"
                                        : "s"
                                )}
                            onClick={handleNext}
                            disabled={
                                editMode
                                    ? false
                                    : activeStep === 0
                                        ? disabledButton
                                        : invitation
                                            ? !checkTandC
                                            : disabledButton
                            }
                        >
                            {editMode
                                ? t("register.saveChanges")
                                : t(
                                    activeStep === 3
                                        ? integ === 3
                                            ? "register.continueWithoutInteg"
                                            : "register.continue"
                                        : "register.continue"
                                )}
                        </BaseButton>
                    </Box>
                )}
                {!showCart && activeStep === 2 && (
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        {!editMode && (activeStep === 1 || activeStep === 2 || activeStep === 3) && (
                            <BaseButton variantType="primaryTextButton" sizebutton="s" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                {t("register.back")}
                            </BaseButton>
                        )}
                    </Box>
                )}
                <DialogChangeEmail
                    open={openConfirmPassword}
                    onClose={handleCloseConfirmPass}
                    setConfirmChangeEmail={setConfirmChangeEmail}
                    params={paramsChangeMail}
                    setOpenBackdrop={setOpenBackdrop}
                    isError={isError}
                    setShow={setShow}
                    setTexto={setTexto}
                    setIsError={setIsError}
                    setAlertType={setAlertType}
                    texto={texto}
                    password={confirmPassword}
                    setPassword={setConfirmPassword}
                    existingEmail={existingEmail}
                />
                <DialogSaveNewEmail open={confirChangeEmail} onClose={handleCloseConfirmEmail} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                <SimpleBackdrop open={openBackdrop} />
                <AlertDialog
                    open={openAlert}
                    onClose={handleCloseDialog}
                    agreeBtnLabel={agreeBtnMsg}
                    disagreeBtnLabel={disagreeBtnMsg}
                    type="info2"
                    title={titleModal}
                    message={
                        currentPlan.value === 3 && currentPlan.custom === true ? (
                            <CustomForm
                                setQuestionCustom={setQuestionCustom}
                                questionCustom={questionCustom}
                                errorCustomPlan={errorCustomPlan}
                            />
                        ) : (
                            bodyMess
                        )
                    }
                    agreeAction={
                        currentPlan.value === 0
                            ? updateMicroPlan
                            : currentPlan.value === 1 ||
                                currentPlan.value === 2 ||
                                (currentPlan.value === 3 && !currentPlan.custom)
                                ? handleShowCart
                                : handleSubmitCustomPlan
                    }
                    maxWidth="sm"
                    origin="converter"
                    onlyAction={true}
                    oneAction={
                        currentPlan.value === 1 ||
                            currentPlan.value === 2 ||
                            (currentPlan.value === 3 && !currentPlan.custom)
                            ? updateFreePlan
                            : handleCloseDialog
                    }
                />
            </Paper>
            {showCart && (
                <>
                    <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
                        <BaseButton variantType="primaryOutlined" onClick={showStep3} sizebutton="m">
                            {t("miscellaneous.goBack")}
                        </BaseButton>
                        <BaseButton variantType="primaryTextButton" sizebutton="m" onClick={handleClickHelp}>{t("accounts.needHelp")}</BaseButton>
                    </Stack>
                    <ShoppingCartReg
                        origin="register"
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        currentLevel={currentLevel}
                        priceMonthly={priceMonthly}
                        priceMonthlyOG={priceMonthlyOG}
                        setPriceMonthly={setPriceMonthly}
                        setActiveStep={setActiveStep}
                        userEmail={userEmail}
                        setIsLoading={setOpenBackdrop}
                        allCompanies={[]}
                        smbInfo={smbInfo}
                        finalF={finalF}
                        setShowCart={setShowCart}
                        subsType={userData.subscription_type}
                    />
                </>
            )}
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                users={users}
                type={4}
                franchiseName={userData.practice_name}
                chatDisabled={false}
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
                createCase={true}
            />}
        </Container>
    );
};

export default UserRegister;
