import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)(({ theme, isSearch }) => ({
    "& label": {
        color: theme.palette.V080,
        fontWeight: 600,
    },
    "& label.Mui-focused": {
        color: theme.palette.V080,
    },
    "& .MuiInputBase-root": {
        height: isSearch ? 42 : 48, // Ajustar la altura del input
    },
    "& label.Mui-error": {
        color: theme.palette.secondary.main, // Color de la etiqueta cuando hay un error
    },
    "& .MuiOutlinedInput-root": {
            height: isSearch ? 42 : 48, // Asegura que el contenedor del input tenga la altura correcta
            backgroundColor: "#fff",
        "& fieldset": {
            borderColor: theme.palette.V050, // Color del borde
        },
        "&:hover fieldset": {
            borderColor: theme.palette.V090, // Color del borde en hover
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.V090, // Color del borde en focus
        },
        "&.Mui-error fieldset": {
            borderColor: theme.palette.secondary.main, // Color del borde cuando hay un error
        },
    },
}));
  
export default function CustTextField(props) {
    const {
        id,
        name,
        label,
        type,
        InputProps,
        value,
        onChange,
        error,
        onBlur,
        helperText,
        onKeyDown,
        shrink,
        size,
        variant,
        fullWidth,
        required,
        disabled,
        isSearch,
        ...rest // Captura cualquier otro parámetro adicional
    } = props;

    return (
        <CssTextField
            {...rest} // Pasa el resto de las props no específicamente mapeadas
            InputLabelProps={{ shrink: shrink }}
            id={id}
            name={name}
            label={label}
            size={size}
            type={type}
            fullWidth={fullWidth}
            value={value}
            onChange={onChange}
            error={error}
            onBlur={onBlur}
            InputProps={InputProps}
            helperText={helperText}
            onKeyDown={onKeyDown}
            variant={variant}
            required={required}
            disabled={disabled}
            isSearch={isSearch}
        />
    );
}
