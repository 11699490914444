import { Checkbox, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoddokTableCell from "./NoddokTableCell";
import NoddokTableRowHeader from "./NoddokTableRowHeader";
import NoddokTableRow from "./NoddokTableRow";

export default function NoddokTable({
    children,
    columns,
    rows,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    selected,
    setSelected,
    showCheckbox = true,
    renderRow, // Nueva propiedad para personalizar el renderizado de filas
}) {
    const [t] = useTranslation("global");

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    
    const isItemSelected = (id) => selected.indexOf(id) !== -1;

    const handleClickCheckbox = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    return (
        <TableContainer>
            <Table size="small" sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}>
                <TableHead>
                    <NoddokTableRowHeader>
                        {showCheckbox && ( // Condicional para mostrar el checkbox solo si showCheckbox es true
                            <NoddokTableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selected.length > 0 && selected.length < rows.length}
                                    checked={rows.length > 0 && selected.length === rows.length}
                                    onChange={handleSelectAllClick}
                                />
                            </NoddokTableCell>
                        )}
                        {columns.map((item) => (
                            <NoddokTableCell
                                key={item.name}
                                align={item?.align ?? "left"}
                                style={{ width: item?.width }}
                            >
                                {item.title}
                            </NoddokTableCell>
                        ))}
                    </NoddokTableRowHeader>
                </TableHead>
                <TableBody>
                {rows.length > 0
                    ? rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, indexRow) =>
                            renderRow ? (
                                renderRow(row, isItemSelected(row.id))
                            ) : (
                                <NoddokTableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        cursor: "default",
                                    }}
                                    {...(showCheckbox && {
                                        role: "checkbox",
                                        "aria-checked": isItemSelected(row.id),
                                        tabIndex: -1,
                                        selected: isItemSelected(row.id),
                                    })}
                                >
                                    {showCheckbox && (
                                        <NoddokTableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected(row.id)}
                                                inputProps={{
                                                    "aria-labelledby": `enhanced-table-checkbox-${indexRow}`,
                                                }}
                                                onClick={() => handleClickCheckbox(row.id)}
                                            />
                                        </NoddokTableCell>
                                    )}
                                    {columns.map((column, index) => {
                                        return (
                                            <NoddokTableCell
                                                key={column.name + index}
                                                align={column?.align ?? "left"}
                                                style={{ width: column?.width }}
                                            >
                                                {row[column.name]}
                                            </NoddokTableCell>
                                        )
                                    })}
                                </NoddokTableRow>
                            )
                        )
                    : children}
                    {emptyRows > 0 && (
                        <NoddokTableRowHeader style={{ height: 53 * emptyRows }}>
                            <NoddokTableCell colSpan={columns.length} />
                        </NoddokTableRowHeader>
                    )}
                </TableBody>
                <TableFooter>
                    <NoddokTableRowHeader>
                        <TablePagination
                            showFirstButton
                            showLastButton
                            rowsPerPageOptions={[10, 20, 30, { label: t("miscellaneous.all"), value: -1 }]}
                            colSpan={12}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                            labelDisplayedRows={(page) =>
                                `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${
                                    page.count
                                }`
                            }
                        />
                    </NoddokTableRowHeader>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}