import {
    Box,
    Button,
    Grid,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    TableBody,
    IconButton,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Divider
} from "@mui/material";
import { styled } from "@mui/material/styles";
import invoicesService from "../../../services/invoices";
import conversionService from "../../../services/conversion";

import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import LightTooltip from "../../ui/LightTooltip";
import SimpleDialog from "../../ui/SimpleDialog";

import { fetchFileFromS3 } from "../../../utils/utils";
import BaseButton from "../../ui/BaseButton";

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const MenuProps2 = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 300,
        },
    },
};

const InvoicesSyncTable = (props) => {
    const {
        invoiceData,
        accountsXero,
        isGreaterExt,
        setOpenPreview,
        setIsLoading,
        setFileData,
        xeroTaxRates,
        xeroItems,
        setInvoiceData,
        setInvoices,
        invoices,
        setBasePreviewData,
        rowsAux,
        setRowsAux,
        edited,
        setEdited,
        setNewRowDiff,
        calcTotal,
        replaceInvBill,
        formatAmount,
        calcSubtotal,
        setTexto,
        setAlertType,
        setShow,
        editGlobal,
        setEditGlobal,
        setFileNameFull
    } = props;
    const [t] = useTranslation("global");

    const [rows, setRows] = useState([]);
    // const [showEdit, setShowEdit] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [selectedAllAcc, setSelectedAllAcc] = useState("");
    const [selectedAllTaxes, setSelectedAllTaxes] = useState("");
    //const [genError, setGenError] = useState(false)
    // const [foundTaxRate, setFoundTaxRate] = useState(null);

    useEffect(() => {
        const copyInv = { ...invoiceData };
        let lineItems = copyInv.line_items ?? [];
        lineItems = lineItems.filter((item) => !item.deleted);

        setSelectedAllAcc(copyInv.global_acc_code ?? selectedAllAcc);

        // Considerar aquí la parecida
        // const calculatedRate = (copyInv.formatted_total_tax / copyInv.formatted_sub_total) * 100;

        // if (calculatedRate !== 0) {
        //     for (let tax of xeroTaxRates) {
        //         if (
        //             tax.EffectiveRate >= calculatedRate - calculatedRate * 0.001 &&
        //             tax.EffectiveRate <= calculatedRate + calculatedRate * 0.001
        //         ) {
        //             setFoundTaxRate(tax.value);

        //             let itemTax = tax.value;
        //             let taxRate = tax.taxRate;
        //             lineItems.forEach((e) => {
        //                 if (!e?.tax_type_xero && !e.tax_amount) {
        //                     e.tax_type_xero = itemTax;
        //                     e.tax_amount_xero = taxRate * calcSubtotal(e) / 100;
        //                 }
        //             });
        //         }
        //     }
        // }

        setRows(lineItems);
        setRowsAux(lineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceData, selectedAllAcc, setRowsAux, xeroTaxRates]);

    const columns = [
        { name: "description", title: t("accounts.description"), width: "15%" },
        { name: "item_code", title: t("register.code") },
        { name: "quantity", title: t("converter.qty") },
        { name: "unit_price", title: t("payment.unitPrice") },
        { name: "line_amount", title: t("converter.totalAmount") },
        { name: "account_code", title: t("accounts.accountNum") },
        { name: "tax_type", title: t("accounts.tax") },
        { name: "actions", title: <></>, width: "8%" },
    ];

    const isEdited = (id) => edited.indexOf(id) !== -1;

    const handleEditRow = async (id, isEdited, rowsParam, rowsAuxParam, fromAddRow) => {
        if (!isEdited) {
            setEditGlobal(!editGlobal);
        }
        
        const checkedIndex = edited.indexOf(id);
        let newChecked = [];

        if (checkedIndex === -1) {
            newChecked = newChecked.concat(edited, id);
        } else if (checkedIndex === 0) {
            newChecked = newChecked.concat(edited.slice(1));
        } else if (checkedIndex === edited.length - 1) {
            newChecked = newChecked.concat(edited.slice(0, -1));
        } else if (checkedIndex > 0) {
            newChecked = newChecked.concat(edited.slice(0, checkedIndex), edited.slice(checkedIndex + 1));
        }

        if (!isEdited) {
            setEdited(newChecked);
        }
        if (isEdited) {
            let findEdited = rowsAuxParam.find((e) => e._id === id);
            if (findEdited?.new_line && findEdited?.new) {
                if (findEdited.description_xero !== "" && findEdited.unit_price_xero !== "" && findEdited.quantity_xero !== "" && findEdited.account_code_xero !== "") {
                    setEditGlobal(!editGlobal);
                    setEdited(newChecked);
                    let params = {
                        invoice_id: findEdited.invoice_id ?? null,
                        line_item: {
                            new_line: true,
                            item_code_xero: findEdited?.item_code_xero ?? "",
                            description_xero: findEdited?.description_xero ?? "",
                            amount_xero: findEdited?.amount_xero ?? "",
                            unit_price_xero: Number(findEdited?.unit_price_xero) ?? 0,
                            invoice_id: findEdited.invoice_id ?? null,
                            quantity_xero: findEdited.quantity_xero ?? null,
                            account_code_xero: findEdited.account_code_xero ?? null,
                            tax_type_xero: xeroTaxRates.find((item) => item.isZeroTax)?.value ?? null,
                            tax_amount_xero: findEdited.tax_amount_xero ?? null,
                        },
                    };

                    await conversionService
                        .addLineItem(params)
                        .then((response) => {
                            let finalRowsAux = rowsAuxParam.filter((e) => e._id !== id);
                            finalRowsAux = [...finalRowsAux, response.data];

                            setRows(finalRowsAux);
                            setInvoiceData({ ...invoiceData, line_items: finalRowsAux });
                            setInvoices(
                                invoices.map((aux) => {
                                    if (aux._id === invoiceData._id) {
                                        return { ...aux, line_items: finalRowsAux };
                                    }
                                    return aux;
                                })
                            );
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    //setGenError(false)
                } else {
                    //setGenError(true)
                    setTexto(t("converter.cannotBeSaveInv"));
                    setAlertType("error");
                    setShow(true);
                }
            } else {
                if (findEdited.description_xero !== "" && findEdited.unit_price_xero !== "" && findEdited.quantity_xero !== "" && findEdited.account_code_xero !== "") {
                    setEditGlobal(!editGlobal);
                    setEdited(newChecked);
                    setRows(rowsAuxParam);
                    setInvoiceData({ ...invoiceData, line_items: rowsAuxParam });
                    setInvoices(
                        invoices.map((aux) => {
                            if (aux._id === invoiceData._id) {
                                return { ...aux, line_items: rowsAuxParam };
                            }
                            return aux;
                        })
                    );

                let inv = invoices
                    .filter((aux) => aux._id === invoiceData._id)
                    .map((aux) => ({ ...aux, line_items: rowsAuxParam }));

                    // Se actualizan los datos de la factura y sus line_items.
                    const params = {
                        invoices: inv.map((item) => ({
                            _id: item._id,
                            ContactXeroID: item.contactXeroID,
                            ContactXeroName: item.conversion_type === 1 ? item.final_customer_name : item.final_vendor_name,
                            line_items: item.line_items,
                        })),
                    };

                    // console.log("params", params);

                    await invoicesService
                        .updateInvoices(params)
                        .then(() => { })
                        .catch((err) => {
                            console.log(err);
                        });

                    //setGenError(false)
                } else {
                    //setGenError(true)
                    setTexto(t("converter.cannotBeSaveInv"));
                    setAlertType("error");
                    setShow(true);
                }
            }
        } else {
            //setGenError(false)
            if (!fromAddRow) {
                let findEdited = rowsAuxParam.find((e) => e._id === id);
                if (findEdited?.new_line && findEdited?.new) {
                    
                    if (findEdited.description_xero !== "" && findEdited.unit_price_xero !== "" && findEdited.quantity_xero !== "" && findEdited.account_code_xero !== "") {
                        setRowsAux(rowsParam);
                    } else {
                        // Se descarta la línea
                        const newRowsDel = rowsParam.map((item) => {
                            if (item._id === id) {
                                item.deleted = true;
                            }
                            return item;
                        });
                        
                        const filterRows = newRowsDel.filter((item) => item._id !== id);

                        setRows(filterRows);
                        setRowsAux(filterRows);

                        setInvoiceData({ ...invoiceData, line_items: newRowsDel });
                        setInvoices(
                            invoices.map((aux) => {
                                if (aux._id === invoiceData._id) {
                                    return { ...aux, line_items: newRowsDel };
                                }
                                return aux;
                            })
                        );
                    }
                } else {
                    setRowsAux(rowsParam);
                }
            } else {
                setRowsAux(rowsParam);
            }
        }
    };

    const handleDeleteRow = async (id) => {
        let findLine = rowsAux.find((item) => item._id === id);
        const newRows = rowsAux.filter((item) => item._id !== id);

        const newRowsDel = rowsAux.map((item) => {
            if (item._id === id) {
                item.deleted = true;
            }
            return item;
        });

        setRows(newRows);
        setRowsAux(newRows);
        setInvoiceData({ ...invoiceData, line_items: newRowsDel });
        setInvoices(
            invoices.map((aux) => {
                if (aux._id === invoiceData._id) {
                    return { ...aux, line_items: newRowsDel };
                }
                return aux;
            })
        );

        let inv = invoices
            .filter((aux) => aux._id === invoiceData._id)
            .map((aux) => ({ ...aux, line_items: newRowsDel }));

        // Se actualizan los datos de la factura y sus line_items.
        const params = {
            invoices: inv.map((item) => ({
                _id: item._id,
                ContactXeroID: item.contactXeroID,
                ContactXeroName: item.conversion_type === 1 ? item.final_customer_name : item.final_vendor_name,
                line_items: item.line_items,
            })),
        };

        if (!findLine?.new) {
            // Las líneas con el campo new en true aún no fueron creadas en la DB.
            await invoicesService
                .updateInvoices(params)
                .then(() => {})
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChange = (event, id) => {
        setRowsAux(
            rowsAux.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        description: event.target.value,
                        description_xero: event.target.value,
                        isEditDesc: true,
                    };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleChangeAmount = (event, id) => {
        if (event.target.value > 0 || event.target.value === "") {
            setRowsAux(
                rowsAux.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            quantity: Number(event.target.value),
                            quantity_xero: Number(event.target.value),
                            formatted_amount: Number(event.target.value) * (aux.difference || aux.new_line ? aux.unit_price_xero : aux.formatted_unit_price),
                            amount_xero: Number(event.target.value) * (aux.difference || aux.new_line ? aux.unit_price_xero : aux.formatted_unit_price),
                            isEditQTY: true,
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeUP = (value, id, blur = false) => {

        let newValue;
        if (blur) {
            newValue = value === "" ? 0 : Number(value);
        } else {
            newValue = value === "" ? "" : Number(value);
        }

        if (newValue >= 0) {
            setRowsAux(
                rowsAux.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            formatted_unit_price: newValue,
                            unit_price_xero: newValue,
                            formatted_amount: newValue * Number(aux.quantity),
                            amount_xero: newValue * (aux.difference || aux.new_line ? aux.quantity_xero : aux.quantity),
                            isEditUP: true,
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeSelect = async (event, id) => {
        if (editGlobal) {
            const newRows = rowsAux.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        account_code: event.target.value,
                        account_code_xero: event.target.value,
                        isEditLineAcc: true,
                    };
                } else {
                    return aux;
                }
            });
            setRowsAux(newRows);
        } else {
            let isNew = false;

            const newRows = rows.map((aux) => {
                if (id === aux._id) {
                    if (aux?.new) {
                        isNew = true;
                    }
    
                    return {
                        ...aux,
                        account_code: event.target.value,
                        account_code_xero: event.target.value,
                        isEditLineAcc: true,
                    };
                } else {
                    return aux;
                }
            });
            setRows(newRows);
            setInvoiceData({ ...invoiceData, line_items: newRows });
            setInvoices(
                invoices.map((aux) => {
                    if (aux._id === invoiceData._id) {
                        return { ...aux, line_items: newRows };
                    }
                    return aux;
                })
            );
          
            let inv = invoices.filter((aux) => aux._id === invoiceData._id).map((aux) => ({ ...aux, line_items: newRows }));
    
            // Si no es una fila nueva, se editan las rows con los nuevos datos, pero no se almacena hasta que no se agregue cantidad, descripción y precio unitario.
            // El identificador new_line se quita de la línea una vez esté almacenada con estos valores en la DB.
            if (!isNew) {
                // Se actualizan los datos de la factura y sus line_items.
                const params = {
                    invoices: inv.map((item) => ({
                        _id: item._id,
                        line_items: item.line_items,
                    })),
                };
    
                await invoicesService
                    .updateInvoices(params)
                    .then(() => {})
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        
    };

    const handleChangeSelectTax = async (event, id) => {
        let isNew = false;

        const taxRate = xeroTaxRates.find((item) => item.value === event.target.value).taxRate;
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                if (aux?.new) {
                    isNew = true;
                }

                return {
                    ...aux,
                    tax_type: event.target.value,
                    tax_type_xero: event.target.value,
                    tax_amount_xero: taxRate * calcSubtotal(aux) / 100,
                    tax_amount: taxRate * calcSubtotal(aux) / 100,
                    isEditTax: true,
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
        setInvoiceData({ ...invoiceData, line_items: newRows });
        setInvoices(
            invoices.map((aux) => {
                if (aux._id === invoiceData._id) {
                    return { ...aux, line_items: newRows };
                }
                return aux;
            })
        );

        let inv = invoices.filter((aux) => aux._id === invoiceData._id).map((aux) => ({ ...aux, line_items: newRows }));

        // Si no es una fila nueva, se editan las rows con los nuevos datos, pero no se almacena hasta que no se agregue cantidad, descripción y precio unitario.
        // El identificador new_line se quita de la línea una vez esté almacenada con estos valores en la DB.
        if (!isNew) {
            // Se actualizan los datos de la factura y sus line_items.
            const params = {
                invoices: inv.map((item) => ({
                    _id: item._id,
                    line_items: item.line_items,
                })),
            };

            await invoicesService
                .updateInvoices(params)
                .then(() => {})
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeSelectRow = (event, id) => {
        setRowsAux(
            rowsAux.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        item_code: event.target.value,
                        item_code_xero: event.target.value,
                        isEditCode: true,
                    };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleChangeSelectAll = async (event) => {
        setSelectedAllAcc(event.target.value);
        const allLineItems = rows.map((aux) => ({
            ...aux,
            account_code: event.target.value,
            account_code_xero: event.target.value,
        }));

        setRows(allLineItems);
        setInvoiceData({ ...invoiceData, line_items: allLineItems });
        setInvoices(
            invoices.map((aux) => {
                if (aux._id === invoiceData._id) {
                    return { ...aux, line_items: allLineItems };
                }
                return aux;
            })
        );

        let inv = invoices
            .filter((aux) => aux._id === invoiceData._id)
            .map((aux) => ({ ...aux, line_items: allLineItems }));

        // Se actualizan los datos de la factura y sus line_items.
        const params = {
            invoices: inv.map((item) => ({
                _id: item._id,
                line_items: item.line_items,
            })),
        };

        await invoicesService
            .updateInvoices(params)
            .then(() => {})
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChangeSelectAllTaxes = async (event) => {
        setSelectedAllTaxes(event.target.value);

        const taxRate = xeroTaxRates.find((item) => item.value === event.target.value).taxRate;
        
        const allLineItems = rows.map((aux) => ({
            ...aux,
            tax_type: event.target.value,
            tax_type_xero: event.target.value,
            tax_amount_xero: taxRate * calcSubtotal(aux) / 100,
            tax_amount: taxRate * calcSubtotal(aux) / 100,
            isEditTax: true,
        }));

        setRows(allLineItems);
        setInvoiceData({ ...invoiceData, line_items: allLineItems });
        setInvoices(
            invoices.map((aux) => {
                if (aux._id === invoiceData._id) {
                    return { ...aux, line_items: allLineItems };
                }
                return aux;
            })
        );

        let inv = invoices
            .filter((aux) => aux._id === invoiceData._id)
            .map((aux) => ({ ...aux, line_items: allLineItems }));

        // Se actualizan los datos de la factura y sus line_items.
        const params = {
            invoices: inv.map((item) => ({
                _id: item._id,
                line_items: item.line_items,
            })),
        };

        await invoicesService
            .updateInvoices(params)
            .then(() => {})
            .catch((err) => {
                console.log(err);
            });
    };

    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 9);

    // const handleShowEdit = () => {
    //     setShowEdit(true);
    // };

    // const handleSaveChanges = () => {
    //     const newLines = rows.map((item) => {
    //         if (item.new) {
    //             item.new = false;
    //         }
    //         return item;
    //     });

    //     setShowEdit(false);
    //     setRows(newLines);
    //     setInvoiceData({ ...invoiceData, line_items: newLines });
    //     setInvoices(
    //         invoices.map((aux) => {
    //             if (aux._id === invoiceData._id) {
    //                 return { ...aux, line_items: newLines };
    //             }
    //             return aux;
    //         })
    //     );
    // };

    const handleDeleteChanges = () => {
        setShowModalConfirm(!showModalConfirm);
    };

    const confirmDeleteChanges = () => {
        setShowModalConfirm(false);
        // setShowEdit(false);
        setRows(rows.filter((item) => !item.new));
    };

    const handleAddDiff = async () => {
        setNewRowDiff(true);
        const diffAmount =
            invoiceData.formatted_invoice_total -
            invoiceData.line_items?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTotal(obj), 0);

        const formattedVal = diffAmount.toString().includes('.') ? parseFloat(diffAmount).toFixed(2) : diffAmount;

        const baseData = {
            new: true,
            difference: true,
            deleted: false,
            item_code_xero: "",
            description_xero: t("dashboard.diference"),
            amount_xero: formattedVal,
            unit_price_xero: formattedVal,
            invoice_id: rows.length > 0 ? rows[0].invoice_id : null,
            quantity_xero: 1,
            account_code_xero: "",
            tax_type_xero: xeroTaxRates.find((item) => item.isZeroTax)?.value ?? null,
            tax_amount_xero: 0,
        }

        let params = {
            invoice_id: rows.length > 0 ? rows[0].invoice_id : null,
            line_item: baseData,
        };

        setIsLoading(true);

        let insertLine;
        let newRowId;
        await conversionService
            .addLineItem(params)
            .then((response) => {

                insertLine = {
                    _id: response?.data?._id,
                    ...baseData
                }

                newRowId = response?.data?._id

                setRows([ ...rows, insertLine ]);
                setRowsAux([ ...rows, insertLine ]);

                setInvoices(
                    invoices.map((aux) => {
                        if (aux._id === invoiceData._id) {
                            return { ...aux, line_items: [ ...rowsAux, insertLine ], };
                        }
                        return aux;
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        await handleEditRow(newRowId, false, [ ...rows, insertLine ], [ ...rowsAux, insertLine ], false)
    };

    const handleNewRow = async () => {
        let newRowId = generateUniqueId();

        let insertLine = {
            new: true,
            new_line: true,
            _id: newRowId,
            item_code_xero: "",
            description_xero: "",
            amount_xero: 0,
            unit_price_xero: 0,
            invoice_id: rows.length > 0 ? rows[0].invoice_id : null,
            quantity_xero: 1,
            account_code_xero: "",
            tax_type_xero: "",
            tax_amount_xero: 0,
        }

        setRowsAux([ ...rowsAux, insertLine ]);
        setRows([ ...rows, insertLine ]);
        setInvoices(
            invoices.map((aux) => {
                if (aux._id === invoiceData._id) {
                    return { ...aux, line_items: [ ...rowsAux, insertLine] };
                }
                return aux;
            })
        );
        await handleEditRow(newRowId, false, [ ...rows, insertLine ], [ ...rowsAux, insertLine ], true)
    };

    const seeInvoice = async () => {
        setIsLoading(true);
        
        const data = await fetchFileFromS3(invoiceData.file_name);
        if (data) {
            setFileData(data);
        }

        setFileNameFull(invoiceData.uploaded_file)
        setIsLoading(false);
        setBasePreviewData({
            id: invoiceData._id,
            type: "invoice",
        });
        setOpenPreview(true);
    };

    // const canSaveChanges =
    //     rows.find(
    //         (item) =>
    //             item.new &&
    //             (!item.item_code ||
    //                 !item.description ||
    //                 !item.formatted_unit_price ||
    //                 !item.quantity ||
    //                 !item.account_code ||
    //                 !item.tax_type)
    //     ) !== undefined;

    return (
        <Box sx={greyBoxStyles}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="h1">{replaceInvBill(t("converter.invoiceLines"))}</Typography>
                        <BaseButton variantType="primaryOutlined" endIcon={<SearchIcon />} onClick={seeInvoice} disabled={Object.keys(invoiceData).length === 0}>
                            {replaceInvBill(t("converter.viewInvoice"))}
                        </BaseButton>
                        <FormControl variant="filled" sx={{ width: 300 }} disabled={Object.keys(invoiceData).length === 0}>
                            <InputLabel
                                id="simple-select-label-all"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#361A52",
                                }}
                            >
                                {t("converter.chooseAccCont")}
                            </InputLabel>
                            <Select
                                labelId="simple-select-label-all"
                                name="account_code"
                                value={selectedAllAcc}
                                onChange={(event) => handleChangeSelectAll(event)}
                                size="small"
                                MenuProps={MenuProps}
                            >
                                <Typography variant="h2" pl={1} gutterBottom>
                                    {t("converter.kiiperSug")}
                                </Typography>
                                {accountsXero.filter((item) =>
                                    invoiceData.contact_account_code?.map((val) => val.AccountCode).includes(item.value)
                                ).length === 0 ? (
                                    <MenuItem disabled value="">
                                        <em>{t("converter.noHist")}</em>
                                    </MenuItem>
                                ) : (
                                    accountsXero
                                        .filter((item) =>
                                            invoiceData.contact_account_code
                                                ?.map((val) => val.AccountCode)
                                                .includes(item.value)
                                        )
                                        .map((val) => (
                                            <MenuItem key={val.value} value={val.value}>
                                                {val.value} - {val.name}
                                            </MenuItem>
                                        ))
                                )}
                                <Divider />
                                <Typography variant="h2" pl={1} gutterBottom>
                                    {t("converter.otherAcc")}
                                </Typography>
                                {accountsXero
                                    .filter(
                                        (item) =>
                                            !invoiceData.contact_account_code
                                                ?.map((val) => val.AccountCode)
                                                .includes(item.value)
                                    )
                                    .map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.value} - {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="filled" sx={{ width: 300 }} disabled={Object.keys(invoiceData).length === 0}>
                            <InputLabel
                                id="simple-select-label-all"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#361A52",
                                }}
                            >
                                {t("converter.chooseTax")}
                            </InputLabel>
                            <Select
                                labelId="simple-select-label-all"
                                name="account_code"
                                value={selectedAllTaxes}
                                onChange={(event) => handleChangeSelectAllTaxes(event)}
                                size="small"
                                MenuProps={MenuProps}
                            >
                                <Typography variant="h2" pl={1} gutterBottom>
                                    {t("converter.kiiperSug")}
                                </Typography>
                                {xeroTaxRates.filter((item) =>
                                                invoiceData.contact_tax_type
                                                    ?.map((val) => val.TaxType)
                                                    .includes(item.value)
                                            ).length === 0 ? (
                                    <MenuItem disabled value="">
                                        <em>{t("converter.noHist")}</em>
                                    </MenuItem>
                                ) : (
                                    xeroTaxRates
                                        .filter((item) =>
                                            invoiceData.contact_tax_type
                                                ?.map((val) => val.TaxType)
                                                .includes(item.value)
                                        )
                                        .map((val) => (
                                            <MenuItem key={val.value} value={val.value}>
                                                {val.name}
                                            </MenuItem>
                                        ))
                                )}
                                <Divider />
                                <Typography variant="h2" pl={1} gutterBottom>
                                    {t("converter.otherAcc")}
                                </Typography>
                                {xeroTaxRates
                                    .filter(
                                        (item) =>
                                            !invoiceData.contact_tax_type
                                                ?.map((val) => val.TaxType)
                                                .includes(item.value)
                                    )
                                    .map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>
                {/* <Grid item>
                    {showEdit ? (
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Button onClick={handleDeleteChanges}>{t("converter.deleteChanges")}</Button>
                            <Button
                                variant="contained"
                                disableElevation
                                endIcon={<CheckIcon />}
                                onClick={handleSaveChanges}
                                disabled={canSaveChanges}
                            >
                                {t("register.saveChanges")}
                            </Button>
                        </Stack>
                    ) : (
                        <BaseButton sizebutton="m" disableElevation endIcon={<EditIcon />} onClick={handleShowEdit}>
                            {t("converter.edit")}
                        </BaseButton>
                    )}
                </Grid> */}
            </Grid>
            <TableContainer>
                <Table sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1300 }}>
                    <TableHead>
                        <TableRow sx={{ "& th": { border: "none" } }}>
                            {columns.map((headCell) => (
                                <StyledTableCell
                                    key={headCell.name}
                                    align={headCell.align ?? "left"}
                                    style={{ width: headCell.width }}
                                >
                                    {headCell.title}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <StyledTableRow key={row._id}>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={
                                                rowsAux[index]?.isEditDesc
                                                    ? rowsAux[index].description
                                                    : rowsAux[index].description_xero ?? rowsAux[index].description
                                            }
                                            name="description"
                                            onChange={(event) => handleChange(event, row._id)}
                                            size="small"
                                            error={(row.new && !row.description) || (row.new && !row.description_xero)}
                                        />
                                    ) : (
                                        rowsAux[index]?.isEditDesc
                                                    ? rowsAux[index].description
                                                    : rowsAux[index].description_xero ?? rowsAux[index].description
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <FormControl
                                            variant="filled"
                                            fullWidth
                                            hiddenLabel
                                        >
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="item_code"
                                                value={
                                                    rowsAux[index]?.isEditCode
                                                        ? rowsAux[index].item_code ?? ""
                                                        : rowsAux[index].item_code_xero ??
                                                          rowsAux[index].item_code ??
                                                          ""
                                                }
                                                onChange={(event) => handleChangeSelectRow(event, row._id)}
                                                size="small"
                                                MenuProps={MenuProps}
                                                displayEmpty
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{t("converter.chooseCode")}</em>
                                                </MenuItem>
                                                {xeroItems.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : rowsAux[index]?.isEditCode &&
                                      xeroItems.find((item) => item.value === row.item_code_xero)?.name ? (
                                        xeroItems.find((item) => item.value === row.item_code_xero)?.name
                                    ) : (
                                        xeroItems.find((item) => item.value === row.item_code)?.name ?? ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={rowsAux[index].quantity_xero ?? rowsAux[index].quantity}
                                            name="quantity"
                                            onChange={(event) => handleChangeAmount(event, row._id)}
                                            size="small"
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            error={(row.new && !row.quantity) || (row.new && !row.quantity_xero)}
                                        />
                                    ) : row.quantity_xero ? (
                                        ` ${formatAmount(row.quantity_xero)}`
                                    ) : (
                                        ` ${formatAmount(row.quantity)}`
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={
                                                rowsAux[index].isEditUP &&
                                                !rowsAux[index].difference &&
                                                !rowsAux[index].new_line
                                                    ? rowsAux[index].formatted_unit_price ?? ""
                                                    : rowsAux[index].unit_price_xero ??
                                                      rowsAux[index].formatted_unit_price ??
                                                      ""
                                            }
                                            name="formatted_unit_price"
                                            onChange={(event) => handleChangeUP(event.target.value, row._id)}
                                            size="small"
                                            type="number"
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                            onBlur={() => {
                                                let finalValue = rowsAux[index].isEditUP &&
                                                !rowsAux[index].difference &&
                                                !rowsAux[index].new_line
                                                    ? rowsAux[index].formatted_unit_price ?? ""
                                                    : rowsAux[index].unit_price_xero ??
                                                      rowsAux[index].formatted_unit_price ??
                                                      "";

                                                let formattedVal;
                                                // Si el valor está vacío, establecer a 0
                                                if (!finalValue || finalValue === "" || finalValue === 0) {
                                                    formattedVal = "";
                                                } else {
                                                   formattedVal = finalValue.toString()?.includes('.') ? parseFloat(finalValue).toFixed(2) : finalValue;
                                                }
                                                handleChangeUP(formattedVal, row._id, true);
                                            }}
                                            error={(row.new && row.formatted_unit_price === 0) || (row.new && row.unit_price_xero === 0)}
                                        />
                                    ) : (
                                       ` ${formatAmount(
                                            rowsAux[index].isEditUP &&
                                                !rowsAux[index].difference &&
                                                !rowsAux[index].new_line
                                                    ? rowsAux[index].formatted_unit_price ?? 0
                                                    : rowsAux[index].unit_price_xero ??
                                                      rowsAux[index].formatted_unit_price ??
                                                      0
                                        )}`
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id)
                                        ? ` ${formatAmount(
                                              (row.unit_price_xero || row.unit_price_xero === 0) &&
                                                  ((!rowsAux[index].isEditUP && !rowsAux[index].isEditQTY) ||
                                                      rowsAux[index].difference ||
                                                      rowsAux[index].new_line)
                                                  ? (rowsAux[index].unit_price_xero ?? 0) *
                                                        (rowsAux[index].quantity_xero ?? 1)
                                                  : (rowsAux[index].formatted_unit_price ?? 0) *
                                                        (rowsAux[index].quantity ?? 1)
                                          )}`
                                        : ` ${formatAmount(
                                              (row.unit_price_xero || row.unit_price_xero === 0) &&
                                                  !rowsAux[index].isEditUP &&
                                                  !rowsAux[index].isEditQTY &&
                                                  (rowsAux[index].difference || rowsAux[index].new_line)
                                                  ? (row.unit_price_xero ?? 0) * (row.quantity_xero ?? 1)
                                                  : (row.unit_price_xero ?? row.formatted_unit_price ?? 0) *
                                                        (row.quantity_xero ?? row.quantity ?? 1)
                                          )}`}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <FormControl
                                        variant="filled"
                                        fullWidth
                                        hiddenLabel
                                        error={(row.new && !row.account_code) || (row.new && !row.account_code_xero)}
                                    >
                                        <Select
                                            id="demo-simple-select-standard"
                                            name="account_code"
                                            value={
                                                !isEdited(row._id) &&
                                                !rowsAux[index].isEditLineAcc &&
                                                rowsAux[index].account_code_xero &&
                                                rowsAux[index].account_code_xero !== ""
                                                    ? rowsAux[index].account_code_xero
                                                    : rowsAux[index].account_code !== "" && rowsAux[index].account_code
                                                    ? rowsAux[index].account_code
                                                    : ""
                                            }
                                            onChange={(event) => handleChangeSelect(event, row._id)}
                                            size="small"
                                            MenuProps={MenuProps2}
                                        >
                                            <Typography variant="h2" pl={1} gutterBottom>
                                                {t("converter.kiiperSug")}
                                            </Typography>
                                            {accountsXero.filter((item) =>
                                                invoiceData.contact_account_code
                                                    ?.map((val) => val.AccountCode)
                                                    .includes(item.value)
                                            ).length === 0 ? (
                                                <MenuItem disabled value="">
                                                    <em>{t("converter.noHist")}</em>
                                                </MenuItem>
                                            ) : (
                                                accountsXero
                                                    .filter((item) =>
                                                        invoiceData.contact_account_code
                                                            ?.map((val) => val.AccountCode)
                                                            .includes(item.value)
                                                    )
                                                    .map((val) => (
                                                        <MenuItem key={val.value} value={val.value}>
                                                            {val.value} - {val.name}
                                                        </MenuItem>
                                                    ))
                                            )}
                                            <Divider />
                                            <Typography variant="h2" pl={1} gutterBottom>
                                                {t("converter.otherAcc")}
                                            </Typography>
                                            {accountsXero
                                                .filter(
                                                    (item) =>
                                                        !invoiceData.contact_account_code
                                                            ?.map((val) => val.AccountCode)
                                                            .includes(item.value)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.value} - {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <FormControl
                                        variant="filled"
                                        fullWidth
                                        hiddenLabel
                                        error={(row.new && !row.tax_type) || (row.new && !row.tax_type_xero)}
                                    >
                                        <Select
                                            id="demo-simple-select-standard"
                                            name="tax_type"
                                            value={
                                                !isEdited(row._id) && rowsAux[index].tax_type_xero
                                                    ? rowsAux[index].tax_type_xero
                                                    : rowsAux[index].tax_type ??
                                                      xeroTaxRates?.find((item) => item.isZeroTax)?.value ??
                                                      ""
                                            }
                                            onChange={(event) => handleChangeSelectTax(event, row._id)}
                                            size="small"
                                            MenuProps={MenuProps}
                                        >
                                            <Typography variant="h2" pl={1} gutterBottom>
                                                {t("converter.kiiperSug")}
                                            </Typography>
                                            {xeroTaxRates.filter((item) =>
                                                invoiceData.contact_tax_type
                                                    ?.map((val) => val.TaxType)
                                                    .includes(item.value)
                                            ).length === 0 ? (
                                                <MenuItem disabled value="">
                                                    <em>{t("converter.noHist")}</em>
                                                </MenuItem>
                                            ) : (
                                                xeroTaxRates
                                                    .filter((item) =>
                                                        invoiceData.contact_tax_type
                                                            ?.map((val) => val.TaxType)
                                                            .includes(item.value)
                                                    )
                                                    .map((val) => (
                                                        <MenuItem key={val.value} value={val.value}>
                                                            {val.name}
                                                        </MenuItem>
                                                    ))
                                            )}
                                            <Divider />
                                            <Typography variant="h2" pl={1} gutterBottom>
                                                {t("converter.otherTax")}
                                            </Typography>
                                            {xeroTaxRates
                                                .filter(
                                                    (item) =>
                                                        !invoiceData.contact_tax_type
                                                            ?.map((val) => val.TaxType)
                                                            .includes(item.value)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {!isEdited(row._id) ? (
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <LightTooltip title={t("team.edit")}>
                                                <IconButton
                                                    size="small"
                                                    disabled={editGlobal && !isEdited(row._id)}
                                                    onClick={() => handleEditRow(row._id, false, rows, rowsAux, false)}
                                                >
                                                    <EditIcon sx={{ color: edited.length === 0 ? "#4A22D4" : "" }} />
                                                </IconButton>
                                            </LightTooltip>
                                            {rows.length > 1 && (
                                                <LightTooltip title={t("miscellaneous.delete")}>
                                                    <IconButton
                                                        size="small"
                                                        disabled={editGlobal && !isEdited(row._id)}
                                                        onClick={() => handleDeleteRow(row._id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            )}
                                        </Stack>
                                    ) : (
                                        <Stack direction="row" justifyContent="center">
                                            <IconButton size="small" onClick={() => handleEditRow(row._id, true, rows, rowsAux, false)}>
                                                <CheckIcon color="success" />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => handleEditRow(row._id, false, rows, rowsAux, false)}>
                                                <CloseIcon color="error" />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {showEdit && ( */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ pt: 1 }}>
                <BaseButton sizebutton="m" variantType="primaryOutlined" endIcon={<AddIcon />} onClick={handleAddDiff} disabled={!isGreaterExt || editGlobal || Object.keys(invoiceData).length === 0}>
                    {t("converter.addDiff")}
                </BaseButton>
                <BaseButton sizebutton="m" disableElevation endIcon={<AddIcon />} onClick={handleNewRow} disabled={editGlobal || Object.keys(invoiceData).length === 0}>
                    {t("converter.newRow")}
                </BaseButton>
            </Stack>
            {/* )} */}
            <SimpleDialog
                open={showModalConfirm}
                handleClose={handleDeleteChanges}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" alignItems="center" sx={{ pb: 3 }} spacing={2}>
                            <WarningIcon sx={{ color: "#FF5E77", fontSize: 16 }} />
                            <Typography variant="h2" sx={{ color: "#FF5E77" }}>
                                {t("converter.modalDelChanges")}
                            </Typography>
                        </Stack>
                        <Typography sx={{ pb: 3 }}>{t("converter.modalDelChanges2")}</Typography>
                        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                            <Button onClick={handleDeleteChanges}>{t("register.back")}</Button>
                            <BaseButton sizebutton="m" disableElevation onClick={confirmDeleteChanges}>
                                {t("dialog.continue")}
                            </BaseButton>
                        </Stack>
                    </Box>
                }
            />
        </Box>
    );
};

export default InvoicesSyncTable;
