import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InvoicesTable from "./InvoicesTable";
import { useRef } from "react";

import { useSelector } from "react-redux";
import invoicesService from "../../../services/invoices";
import NoddokTabsHeader from "../../ui/NoddokTabsHeader";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ px: 3, backgroundColor: "white", borderRadius: "0px 10px 10px 10px" }}>{children}</Box>
            )}
        </div>
    );
}

const MainInvTabs = (props) => {
    const {
        conversions,
        usersFilters,
        company_id,
        setConversions,
        franchise_id,
        grupal,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        setFilters,
        filters,
        tutorial,
        setConvertDoneNew,
        setCorrelative,
        setConversionId,
        setFileNameSaved,
        setShowPrev,
        setFileData,
        setFileNameFull,
        setConversionData,
        download,
        setDownload,
        setConvFormat,
        setGroupConversions,
        groupConversions,
        setOpenModalGroupedHist,
        setGroupedData,
        setGroupedPeriods,
        setOpenModalGrouped,
        setCorrelativeModalGrouped,
        setValue,
        value,
        setModalType,
        columns,
        setColumns,
        ogColumns,
        columns2,
        setRowsModal,
        setOpenPreview,
        conversion_type,
        setBasePreviewData,
        setShowNewInv,
        allConversions,
        setAllConversions,
        setCurrentInv,
        setSelectedInv,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const controllerRef = useRef(null);

    const attributes = [
        { id: 2, name: t("converter.uploadDate"), query: "uploaded" },
        //{ id: 3, name: t("converter.format"), query: "format" },
        //{ id: 4, name: t("converter.bankStatementDate"), query: "period" },
        { id: 5, name: t("converter.user"), query: "user_id" },
        { id: 6, name: t("converter.status"), query: "id_status" },
    ];

    const handleChangeTab = async (event, newValue) => {
        setShowSkeleton(true);
        if (conversion_type === 1) {
            setColumns(ogColumns);
        } else {
            setColumns(columns2);
        }

        setValue(newValue);
        let params = { company_id: todos.companyID, conversion_type };

        if (filters.filtersArray.filter((e) => e.type !== "")?.length > 0) {
            let aux = [...filters.filtersArray];

            let objectFinal = {};

            aux.forEach((item) => {
                if (item.value && item.type) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD");
                }
            });

            params = {
                ...params,
                ...objectFinal,
            };
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        const options = {
            signal: controllerRef.current.signal,
        };

        await invoicesService
            .getInvoiceConversions(params, options)
            .then((response) => {
                setGroupConversions([]);
                let finalConv =
                    newValue === 0
                        ? response?.conversions
                        : newValue === 1
                        ? response?.invToApprove
                        : newValue === 2
                        ? response?.invToPay
                        : newValue === 3
                        ? response?.invPaid
                        : response?.invRecurring;

                setConversions(finalConv);
                setAllConversions(response);
            })
            .catch((err) => {
                console.log(err);
            });
        controllerRef.current = null;
        setShowSkeleton(false);
    };

    const getTable = (type) => {
        return (
            <InvoicesTable
                conversions={conversions}
                usersFilters={usersFilters}
                company_id={company_id}
                setConversions={setConversions}
                setIsLoading={setIsLoading}
                franchise_id={franchise_id}
                grupal={grupal}
                showSkeleton={showSkeleton}
                setShowSkeleton={setShowSkeleton}
                setFilters={setFilters}
                filters={filters}
                tutorial={tutorial}
                setConvertDoneNew={setConvertDoneNew}
                setCorrelative={setCorrelative}
                setConversionId={setConversionId}
                setFileNameSaved={setFileNameSaved}
                setShowPrev={setShowPrev}
                setFileData={setFileData}
                setFileNameFull={setFileNameFull}
                setConversionData={setConversionData}
                download={download}
                setDownload={setDownload}
                setConvFormat={setConvFormat}
                setGroupConversions={setGroupConversions}
                groupConversions={groupConversions}
                setOpenModalGroupedHist={setOpenModalGroupedHist}
                setGroupedData={setGroupedData}
                setGroupedPeriods={setGroupedPeriods}
                setOpenModalGrouped={setOpenModalGrouped}
                setCorrelativeModalGrouped={setCorrelativeModalGrouped}
                setValue={setValue}
                value={value}
                setModalType={setModalType}
                columns={columns}
                setColumns={setColumns}
                setRowsModal={setRowsModal}
                setOpenPreview={setOpenPreview}
                conversion_type={conversion_type}
                setBasePreviewData={setBasePreviewData}
                setShowNewInv={setShowNewInv}
                type={type}
                setAllConversions={setAllConversions}
                setCurrentInv={setCurrentInv}
                setSelectedInv={setSelectedInv}
            />
        );
    };

    let fTabs = [t("team.all"), `${t("miscellaneous.toApprove")} (${allConversions?.invToApprove?.length ?? 0})`, `${t("miscellaneous.toPay")} (${allConversions?.invToPay?.length ?? 0})`, `${t("miscellaneous.paid")} (${allConversions?.invPaid?.length ?? 0})`, `${t("plans.recu")}s (${allConversions?.invRecurring?.length ?? 0})`]

    return (
        <Box
            component="main"
            sx={{
                mx: { xs: 2, md: 2 },
                px: { xs: 2, md: 3 },
                pt: 3,
            }}
        >
            <Grid container justifyContent="space-between" alignItems="end">
                <Grid item xs={12}>
                    <Typography variant="h2" color="secondary" pl={4}>
                        {conversion_type === 1 ? t("converter.separateInvHist") : t("converter.separateBillsHist")}
                    </Typography>
                    <NoddokTabsHeader
                        value={value}
                        onChange={handleChangeTab}
                        labels={conversion_type === 1 ? fTabs : fTabs.slice(0, 4)}
                    />
                </Grid>
                <Grid item xs={12}  bgcolor="#FFF" p={2}></Grid>
                <Grid item xs={12} bgcolor="#FFF" px={3}>
                    <CustomTabPanel value={value} index={0}>
                        {getTable(0)}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {getTable(1)}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        {getTable(2)}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        {getTable(3)}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        {getTable(4)}
                    </CustomTabPanel>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MainInvTabs;
