import {
    Badge,
    Box,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    ToggleButton,
    Typography,
} from "@mui/material";

import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

import PlansCard from "./PlansCard";
import { useTranslation } from "react-i18next";
import HeaderBar from "../HeaderBar";

import Discount from "../../../assets/15off.svg";
import LightTooltip from "../../ui/LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import plansInfo from "../../Js/plans";
import FormDialog from "../../ui/FormDialog";
import CancelPlanForm from "../CancelPlanForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AlertDialog from "../../ui/AlertDialog";

import subscriptionService from "../../../services/subscription";
import moment from "moment/moment";
import { isCustomPlan } from "../../../utils/utils";
import { useTheme } from '@mui/material/styles';
import BaseButton from "../../ui/BaseButton";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: 6,
    },
    [`& .${toggleButtonGroupClasses.firstButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        width: 120,
    },
}));

const PlanSubs = (props) => {
    const {
        setTitleModal,
        setOpenAlert,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setCurrentPlan,
        paymentType,
        setPaymentType,
        setCurrentLevel,
        setPriceMonthly,
        setPriceMonthlyOG,
        origin,
        activePlan,
        currentPlan,
        setIsLoading,
        setShowCart,
        companyDetails,
        handleShowCart,
        maxFreeCompanies,
        currentFreeCompanies,
        subsType,
        setShow,
        setAlertType,
        setMessage,
        isCancelled = false,
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);
    const [isDowngrade, setIsDowngrade] = useState(companyDetails?.subscription?.last_change ? true : false);
    const [isCancel, setIsCancel] = useState(companyDetails?.subscription?.last_cancel ? true : false);

    const [openAlertCancel, setOpenAlertCancel] = useState(false);
    const [reasons, setReasons] = useState(null);
    const [details, setDetails] = useState("");
    const [isError, setIsError] = useState(false);
    const [downgradePlanInfo, setDowngradePlanInfo] = useState({});
    const [openAlertReactivate, setOpenAlertReactivate] = useState(false);
    const theme = useTheme(); // Obtener el tema actual

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            if (companyDetails?.last_invoice?.line_items[0]?.line_qty === 365) {
                // Si la compra anterior fue anual, solo puede hacer upgrade de manera anual también
                setPaymentType("annual");
            } else {
                setPaymentType(newAlignment);
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (isDowngrade) {
                await subscriptionService
                    .getHistorySubsDetails({
                        history_id: companyDetails?.subscription?.last_change,
                    })
                    .then((response) => {
                        let plan = plansInfo.find((plan) =>
                            plan.levels.some((level) => level.id === response?.changed_plan)
                        );

                        plan.selectLevel = response?.changed_plan;

                        setDowngradePlanInfo(plan);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            if(isCancel){
                let plan = plansInfo.find((plan) =>
                    plan.levels.some((level) => level.id === companyDetails?.subscription?.subscription_plan)
                );

                plan.selectLevel = companyDetails?.subscription?.subscription_plan;

                setDowngradePlanInfo(plan);
            }
        })();
    }, [isDowngrade, companyDetails, isCancel]);

    const handleCloseDialog = () => {
        setOpenAlert(false);
        setOpenAlertCancel(false);
        setReasons(null);
        setDetails("");
        setIsError(false);
    };

    const handleCloseDialogReactivate = () => {
        setOpenAlertReactivate(false);
    };

    const cancelPlan = async () => {
        if (!reasons) {
            setIsError(true);
        } else {
            if (reasons === "6" && details.trim() === "") {
                setIsError(true);
            } else {
                const newVAl = {
                    subscription_plan: activePlan,
                    line_type: 7,
                    line_amount: 0.0,
                    line_unit_amount: 0.0,
                    line_qty: 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                };

                const params = {
                    company_id: todos.companyID,
                    cart_lines: [newVAl],
                };

                setCurrentPlan({
                    value: activePlan,
                    custom: isCustomPlan(companyDetails.subscription.subscription_plan),
                    type: "cancel",
                    endDate: companyDetails.subscription.end_date,
                });

                setPriceMonthlyOG(0);
                setCurrentLevel(activePlan);

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);

                setShowCart(true);
            }
        }
    };

    const handleOpenModal = () => {
        setOpenAlertCancel(true);
    };

    const planFeatures = [t("plans.customFormat"), t("plans.userProfile"), t("plans.unlimitedStorage")];

    const iconStyles = { color: "#361A52", cursor: "pointer", ml: 0.5, mb: "-2px" };

    const cancelPlanButtons = (
        <Grid container justifyContent="space-between" sx={{ width: 600 }}>
            <Grid item>
                <BaseButton variantType="primaryTextButton" onClick={handleCloseDialog} sizebutton="m">{t("team.cancel")}</BaseButton>
            </Grid>
            <Grid item>
                <BaseButton onClick={cancelPlan} sizebutton="m">
                    {t("subscription.confirm")}
                </BaseButton>
            </Grid>
        </Grid>
    );

    const confirmReactivate = async () => {
        setIsLoading(true);

        let params = {
            subscription_id: companyDetails.subscription._id,
        };

        await subscriptionService
            .reactivateSubscriptionChanged(params)
            .then(async () => {
                setShow(true);
                setAlertType("success");
                setMessage(t("subscription.downgradePlanSucess"));

                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    cart_lines: [],
                };

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setShowCart(false);
                handleCloseDialogReactivate();
                setIsDowngrade(false);
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
            });

        setIsLoading(false);
    };

    const handleClickReactivatePlan = async () => {
        setIsLoading(true);

        let params = {
            subscription_id: companyDetails.subscription._id,
        };

        await subscriptionService
            .reactivateSubscription(params)
            .then(async () => {
                setShow(true);
                setAlertType("success");
                setMessage(t("subscription.downgradePlanSucess"));

                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    cart_lines: [],
                };

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setShowCart(false);
                handleCloseDialogReactivate();
                setIsCancel(false);
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
            });

        setIsLoading(false);
    };

    const bodyMess = (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("subscription.reactivatePlanChanged")} {t("subscription.reactivatePlanChanged2")}
                </Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("dialog.warningConfirm2")}
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h2">
                            {origin === "updateSubscription"
                                ? `${t("plans.compPlans")} ${companyDetails.company_name}`
                                : origin === "editCompany" || origin === "addCompany"
                                ? ""
                                : t("plans.choosePlanFirst")}
                        </Typography>
                    </Stack>
                </Grid>
                {(isDowngrade || isCancel) && (
                    <HeaderBar
                        plansInfo={plansInfo}
                        currentPlan={activePlan}
                        currentPlanInfo={downgradePlanInfo}
                        setOpenAlert={setOpenAlert}
                        lastChange={isDowngrade ? true : false}
                        wasCancel={isCancel ? true : false}
                        setOpenAlertReactivate={setOpenAlertReactivate}
                    />
                )}
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                        <Box>
                            {/* <Typography>{t("plans.dontKnow")}</Typography>
                            <Typography color="secondary" fontWeight={600}>{t("plans.answerQu")}</Typography> */}
                        </Box>
                        <Paper
                            elevation={0}
                            sx={{
                                display: "flex",
                                border: `1px solid #DCCFFF`,
                                flexWrap: "wrap",
                                borderRadius: 3,
                            }}
                        >
                            <StyledToggleButtonGroup
                                value={paymentType}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="paymentType"
                            >
                                <ToggleButton
                                    value="monthly"
                                    aria-label="monthly"
                                    sx={{
                                        backgroundColor: paymentType === "monthly" ? "#DCCFFF !important" : "white",
                                        "&:hover": {
                                            backgroundColor:
                                                paymentType === "monthly" ? "#EAE6FA !important" : "lightgray",
                                        },
                                    }}
                                >
                                    {t("navBar.monthly")}
                                </ToggleButton>
                                <Badge badgeContent={<img src={Discount} alt="Badge" />}>
                                    <ToggleButton
                                        value="annual"
                                        aria-label="annual"
                                        sx={{
                                            backgroundColor: paymentType === "annual" ? "#DCCFFF !important" : "white",
                                            "&:hover": {
                                                backgroundColor:
                                                    paymentType === "annual" ? "#EAE6FA !important" : "lightgray",
                                            },
                                        }}
                                    >
                                        {t("navBar.annual")}
                                    </ToggleButton>
                                </Badge>
                            </StyledToggleButtonGroup>
                        </Paper>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md>
                    <Card sx={{ boxShadow: "none", bgcolor: "transparent" }}>
                        <CardContent sx={{ textAlign: { xs: "center", md: "left" } }}>
                            <Stack sx={{ textAlign: { xs: "center", md: "left" }, display: { xs: "flex", md: "block" }, justifyContent: { xs: "center", md: "start" } }}>
                                <Typography variant="h3">{t("plans.allPlansInc")}</Typography>
                                <Box sx={{ textAlign: { xs: "center", md: "left" }, display: { xs: "flex", md: "block" }, justifyContent: { xs: "center", md: "start" } }}>
                                    <List sx={{ pb: 0 }}>
                                        {planFeatures.map((item, index) => (
                                            <ListItem key={index} disablePadding>
                                                <ListItemIcon sx={{ minWidth: 35 }}>
                                                    <CheckIcon color="primary" fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={item} sx={{ my: 0}} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Stack>
                            <Box pl={{ lg: 4, xs: 0 }} sx={{ textAlign: { xs: "center", md: "left" } }}>
                                <Box sx={{minHeight: 55, display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "start" } }}>
                                    {t("plans.pageMonth")}
                                </Box>
                            </Box>
                            <Typography variant="h3" gutterBottom mt={1}>
                                {t("plans.docConv")}
                            </Typography>
                            <Typography gutterBottom>
                                {t("converter.checks")}
                                <LightTooltip title={t("plans.checksTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography gutterBottom>
                                {t("plans.factRec")}
                                <LightTooltip title={t("plans.invTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography gutterBottom>
                                {t("dashboard.transactions")}
                                <LightTooltip title={t("plans.eeccTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography variant="h3" gutterBottom mt={1}>
                                {t("plans.customerSupp")}
                            </Typography>
                            <Typography gutterBottom>
                                Onboarding
                                <LightTooltip title={t("plans.onboardingTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography gutterBottom>
                                {t("plans.support")}
                                <LightTooltip title={t("plans.trainingTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography gutterBottom>
                                {t("plans.people")}
                                <LightTooltip title={t("plans.peopleTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography variant="h3" gutterBottom mt={1}>
                                {t("plans.addFeatures")}
                            </Typography>
                            <Typography gutterBottom>
                                {t("plans.panelMonit")}
                                <LightTooltip title={t("plans.monitoringTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography gutterBottom>
                                {t("plans.productUpd")}
                                <LightTooltip title={t("plans.productTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography gutterBottom>
                                {t("plans.exten")}
                                <LightTooltip title={t("plans.rollOverTooltip")}>
                                    <InfoIcon fontSize="inherit" sx={iconStyles} />
                                </LightTooltip>
                            </Typography>
                            <Typography variant="h3" gutterBottom mt={1}>
                                {t("plans.accDiscounts")}
                            </Typography>
                            <Typography gutterBottom>
                                11-50
                            </Typography>
                            <Typography gutterBottom>
                                51-250
                            </Typography>
                            <Typography gutterBottom>
                                251-1000
                            </Typography>
                        </CardContent>
                    </Card>
                    {/* 
                    <Typography my={3} sx={{ textTransform: "capitalize" }}>
                        {t("plans.pageMonth")}
                    </Typography>
                    
                    <Box pl={2}>
                        
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography>
                            
                        </Typography>
                    </Box>
                    
                    <Box pl={2}>
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography>
                            
                        </Typography>
                    </Box>
                    
                    <Box pl={2}>
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography gutterBottom>
                            
                        </Typography>
                        <Typography>
                           
                        </Typography>
                    </Box> */}
                </Grid>
                
                {plansInfo
                    .filter((item) => (subsType === 1 ? item.planId !== 0 : true))
                    .map((item) => (
                        <Grid item xs={12} 
                        // https://app.clickup.com/t/86b3e9wnd
                        // Se solicitó eliminar el plan Micro definitivamente
                        // md={subsType === 1 ? 3.2 : 2.5} 
                        md={3.2} 
                        key={item.planId}>
                            <PlansCard
                                plan={item}
                                levels={
                                    // https://app.clickup.com/t/86b3e9wnd
                                    // Se solicitó que todos los clientes tengan por igual los niveles 20 y 30 para el plan Small
                                    // item.planId === 1 && subsType !== 1
                                    //     ? item.levels.filter((lev) => lev.id > 2)
                                    //     : 
                                    item.levels
                                }
                                paymentType={paymentType}
                                setTitleModal={setTitleModal}
                                setOpenAlert={setOpenAlert}
                                setAgreeBtnMsg={setAgreeBtnMsg}
                                setDisagreeBtnMsg={setDisagreeBtnMsg}
                                setBodyMess={setBodyMess}
                                setCurrentPlan={setCurrentPlan}
                                setLevelGlobal={setCurrentLevel}
                                setPriceMonthlyGlobal={setPriceMonthly}
                                setPriceMonthlyOG={setPriceMonthlyOG}
                                origin={origin}
                                activePlan={activePlan}
                                currentPlan={currentPlan}
                                handleShowCart={handleShowCart}
                                maxFreeCompanies={maxFreeCompanies}
                                currentFreeCompanies={currentFreeCompanies}
                                subsType={subsType}
                                handleOpenModal={handleOpenModal}
                                isCancelled={isCancelled}
                            />
                        </Grid>
                    ))}
            </Grid>
            <FormDialog
                open={openAlertCancel}
                handleClose={handleCloseDialog}
                maxWidth="md"
                title={
                    <>
                        <Typography component="span" variant="h3" sx={{ color: theme.palette.secondary.main }}>{todos?.userInfo?.user_name ?? ""}</Typography>,{" "}
                        <Typography component="span" variant="h3">{t("plans.regretDes")}</Typography>
                    </>
                }
                content={
                    <CancelPlanForm
                        reasons={reasons}
                        setReasons={setReasons}
                        details={details}
                        setDetails={setDetails}
                        isError={isError}
                        currentPlanInfo={companyDetails}
                    />
                }
                actions={cancelPlanButtons}
                custom={true}
            />
            <AlertDialog
                open={openAlertReactivate}
                onClose={handleCloseDialogReactivate}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={isCancel ? handleClickReactivatePlan : confirmReactivate}
                maxWidth="sm"
            />
        </>
    );
};

export default PlanSubs;
