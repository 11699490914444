import {
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";

import LightTooltip from "../../ui/LightTooltip";
import BaseSelect2 from "../../ui/BaseSelect2";
import CustTextField from "../../ui/CustTextField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const NewInvoiceTableItems = (props) => {
    const {
        rows,
        setRows,
        xeroTaxRates,
        selected,
        setSelected,
        accountsXero,
        xeroItems,
        invInfo,
        integration,
        conversion_type,
        trackingCategories,
        trackingConfig,
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const columns = [
        { name: "item_code", title: t("register.code"), width: "15%" },
        { name: "description", title: t("accounts.description"), width: "20%" },
        { name: "quantity", title: t("converter.qty") },
        { name: "unit_price", title: t("payment.unitPrice") },
        { name: "account_code", title: t("accounts.accountNum"), width: "15%" },
        { name: "discount", title: t("converter.discount") },
        ...(!(integration === 2 && conversion_type === 2)
            ? [{ name: "tax_type", title: t("accounts.tax"), width: "15%" }]
            : []),
        // Add tracking categories if they exist
        ...(trackingConfig && trackingCategories.length > 0
            ? trackingCategories.map((category) => ({
                  name: category._id,
                  title: category.tracking_category_name_xero,
              }))
            : []),
        { name: "line_amount", title: t("converter.totalAmount") },
        // { name: "actions", title: <></>, width: "8%" },
    ];

    const handleChangeRow = (event, id, name) => {
        setRows(
            rows.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        [name]: event.target.value,
                    };
                } else {
                    return aux;
                }
            })
        );
    };

    const getAmount = (unit_price, quantity, discount = 0, tax = 0) => {
        const subTotal = Number(unit_price) * Number(quantity);
        const taxRate = Number(xeroTaxRates.find((item) => item?.value === tax)?.taxRate || 0);
        const discountRate = Number(discount);

        const totalTax = subTotal * (taxRate / 100);
        const totalDiscount = subTotal * (discountRate / 100);

        return subTotal + totalTax - totalDiscount;
    };

    const handleChangeAmount = (value, id, blur = false) => {
        let newValue;
        if (blur) {
            newValue = value === "" ? 0 : Number(value);
        } else {
            newValue = value === "" ? "" : Number(value);
        }

        if (newValue >= 0 || newValue === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            quantity: newValue,
                            amount: getAmount(
                                aux?.unit_price ?? 0,
                                newValue ?? 0,
                                aux?.discount ?? 0,
                                aux?.tax_type ?? 0
                            ),
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeUP = (value, id, blur = false) => {
        let newValue;
        if (blur) {
            newValue = value === "" ? 0 : Number(value);
        } else {
            newValue = value === "" ? "" : Number(value);
        }

        if (newValue >= 0 || newValue === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            unit_price: newValue,
                            amount: getAmount(
                                newValue ?? 0,
                                aux?.quantity ?? 0,
                                aux?.discount ?? 0,
                                aux?.tax_type ?? 0
                            ),
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeSelect = async (event, id) => {
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                return {
                    ...aux,
                    account_code: event.target.value,
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
    };

    const handleChangeDiscount = (value, id, blur = false) => {
        let newValue;
        if (blur) {
            newValue = value === "" ? 0 : Number(value);
        } else {
            newValue = value === "" ? "" : Number(value);
        }

        if (newValue >= 0 || newValue === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            discount: newValue,
                            amount: getAmount(
                                aux?.unit_price ?? 0,
                                aux?.quantity ?? 0,
                                newValue ?? 0,
                                aux?.tax_type ?? 0
                            ),
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeSelectTax = async (event, id) => {
        const taxRate = xeroTaxRates.find((item) => item.value === event.target.value).taxRate;
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                return {
                    ...aux,
                    tax_type: event.target.value,
                    tax_amount: (taxRate * (aux?.unit_price ?? 0 * aux?.quantity ?? 0)) / 100,
                    amount: getAmount(aux?.unit_price ?? 0, aux?.quantity ?? 0, aux?.discount ?? 0, event.target.value),
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
    };

    const handleDeleteRow = (id) => {
        const newRows = rows.map((item) => {
            if (item._id === id) {
                item.deleted = true;
            }
            return item
        });

        setRows(newRows);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClickCheck = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleChangeSelectRow = (event, id) => {
        let newRows = [];

        if (selected.length > 1 && selected.includes(id)) {
            newRows = rows.map((aux) => {
                if (selected.includes(aux._id)) {
                    aux.code = event.target.value;
                }
                return aux;
            });
        } else {
            newRows = rows.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        code: event.target.value,
                    };
                } else {
                    return aux;
                }
            });
        }
        setRows(newRows);
    };

    const handleChangeTrackingCategory = (event, id, categoryId) => {
        setRows(
            rows.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        tracking: {
                            _id: categoryId,
                            name: event.target.value,
                        },
                    };
                } else {
                    return aux;
                }
            })
        );
    };

    return (
        <TableContainer>
            <Table
                sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0 6px",
                    minWidth: 1300,
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            "& th": { border: "none" },
                        }}
                    >
                        <StyledTableCell>
                            <Checkbox
                                color="primary"
                                indeterminate={selected.length > 0 && selected.length < rows.length}
                                checked={rows.length > 0 && selected.length === rows.length}
                                onChange={handleSelectAllClick}
                            />
                        </StyledTableCell>
                        {columns.map((headCell) => (
                            <StyledTableCell
                                key={headCell.name}
                                align={headCell.align ?? "left"}
                                style={{
                                    width: headCell.width,
                                    lineHeight: "1.3",
                                }}
                            >
                                {headCell.title}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        .filter((item) => !item.deleted)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row._id);
                            return (
                                <StyledTableRow key={row._id}>
                                    <StyledTableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onClick={() => handleClickCheck(row._id)}
                                        />
                                    </StyledTableCell>
                                    {/* <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={row.code}
                                            name="code"
                                            onChange={(event) => handleChangeRow(event, row._id, "code")}
                                            size="small"
                                        />
                                    ) : (
                                        row.code
                                    )}
                                </StyledTableCell> */}
                                    <StyledTableCell>
                                        <BaseSelect2
                                            id="demo-simple-select-standard"
                                            name="code"
                                            value={row.code ?? ""}
                                            onChange={(event) => handleChangeSelectRow(event, row._id)}
                                            size="small"
                                            MenuProps={MenuProps}
                                            displayEmpty
                                            isSearch
                                        >
                                            <MenuItem disabled value="">
                                                <em>{t("converter.chooseCode")}</em>
                                            </MenuItem>
                                            {xeroItems.map((item) => (
                                                <MenuItem value={item.value} key={item.value}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </BaseSelect2>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <CustTextField
                                            isSearch
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            value={row.description}
                                            name="description"
                                            onChange={(event) => handleChangeRow(event, row._id, "description")}
                                            size="small"
                                            error={!row.description}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <CustTextField
                                            isSearch
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            value={row.quantity}
                                            name="quantity"
                                            onChange={(event) => handleChangeAmount(event.target.value, row._id)}
                                            onBlur={() => {
                                                let finalValue = row.quantity ?? "";

                                                let formattedVal;
                                                // Si el valor está vacío, establecer a 0
                                                if (!finalValue || finalValue === "" || finalValue === 0) {
                                                    formattedVal = "";
                                                } else {
                                                    formattedVal = finalValue.toString()?.includes(".")
                                                        ? parseFloat(finalValue).toFixed(2)
                                                        : finalValue;
                                                }
                                                handleChangeAmount(formattedVal, row._id, true);
                                            }}
                                            size="small"
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            error={!row.quantity || row.quantity === 0}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <CustTextField
                                            isSearch
                                            id="input-with-icon-textfield"
                                            value={row.unit_price}
                                            name="unit_price"
                                            onChange={(event) => handleChangeUP(event.target.value, row._id)}
                                            size="small"
                                            type="number"
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                            onBlur={() => {
                                                let finalValue = row.unit_price ?? "";

                                                let formattedVal;
                                                // Si el valor está vacío, establecer a 0
                                                if (!finalValue || finalValue === "" || finalValue === 0) {
                                                    formattedVal = "";
                                                } else {
                                                    formattedVal = finalValue.toString()?.includes(".")
                                                        ? parseFloat(finalValue).toFixed(2)
                                                        : finalValue;
                                                }
                                                handleChangeUP(formattedVal, row._id, true);
                                            }}
                                            error={!row.unit_price || row.unit_price === 0}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <BaseSelect2
                                            error={!row.account_code}
                                            id="demo-simple-select-standard"
                                            name="account_code"
                                            value={row.account_code ?? ""}
                                            onChange={(event) => handleChangeSelect(event, row._id)}
                                            isSearch
                                            size="small"
                                            customStyle={true}
                                        >
                                            <Typography variant="h3" pl={1} gutterBottom>
                                                {t("converter.kiiperSug")}
                                            </Typography>
                                            {accountsXero.filter((item) =>
                                                invInfo.contact_account_code
                                                    ?.map((val) => val.AccountCode)
                                                    .includes(item.value)
                                            ).length === 0 ? (
                                                <MenuItem disabled value="">
                                                    <em>{t("converter.noHist")}</em>
                                                </MenuItem>
                                            ) : (
                                                accountsXero
                                                    .filter((item) =>
                                                        invInfo.contact_account_code
                                                            ?.map((val) => val.AccountCode)
                                                            .includes(item.value)
                                                    )
                                                    .map((val) => (
                                                        <MenuItem key={val.value} value={val.value}>
                                                            {val.value} - {val.name}
                                                        </MenuItem>
                                                    ))
                                            )}
                                            <Divider />
                                            <Typography variant="h3" pl={1} gutterBottom>
                                                {t("converter.otherAcc")}
                                            </Typography>
                                            {accountsXero
                                                .filter(
                                                    (item) =>
                                                        !invInfo.contact_account_code
                                                            ?.map((val) => val.AccountCode)
                                                            .includes(item.value)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.value} - {item.name}
                                                    </MenuItem>
                                                ))}
                                        </BaseSelect2>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <CustTextField
                                            isSearch
                                            id="input-with-icon-textfield"
                                            value={row.discount}
                                            name="discount"
                                            onChange={(event) => handleChangeDiscount(event.target.value, row._id)}
                                            inputProps={{ min: 0.0, step: 0.01, max: 100 }}
                                            size="small"
                                            type="number"
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                            onBlur={() => {
                                                let finalValue = row.discount ?? "";

                                                let formattedVal;
                                                // Si el valor está vacío, establecer a 0
                                                if (!finalValue || finalValue === "" || finalValue === 0) {
                                                    formattedVal = "";
                                                } else {
                                                    formattedVal = finalValue.toString()?.includes(".")
                                                        ? parseFloat(finalValue).toFixed(2)
                                                        : finalValue;
                                                }
                                                handleChangeDiscount(formattedVal, row._id, true);
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                        />
                                    </StyledTableCell>
                                    {!(integration === 2 && conversion_type === 2) && (
                                        <StyledTableCell>
                                            <BaseSelect2
                                                error={integration === 1 && !row.tax_type}
                                                id="demo-simple-select-standard"
                                                name="tax_type"
                                                value={row.tax_type ?? ""}
                                                onChange={(event) => handleChangeSelectTax(event, row._id)}
                                                size="small"
                                                MenuProps={MenuProps}
                                                isSearch
                                                customStyle={true}
                                            >
                                                <Typography variant="h3" pl={1} gutterBottom>
                                                    {t("converter.kiiperSug")}
                                                </Typography>
                                                {xeroTaxRates.filter((item) =>
                                                    invInfo.contact_tax_type
                                                        ?.map((val) => val.TaxType)
                                                        .includes(item.value)
                                                ).length === 0 ? (
                                                    <MenuItem disabled value="">
                                                        <em>{t("converter.noHist")}</em>
                                                    </MenuItem>
                                                ) : (
                                                    xeroTaxRates
                                                        .filter((item) =>
                                                            invInfo.contact_tax_type
                                                                ?.map((val) => val.TaxType)
                                                                .includes(item.value)
                                                        )
                                                        .map((val) => (
                                                            <MenuItem key={val.value} value={val.value}>
                                                                {val.name}
                                                            </MenuItem>
                                                        ))
                                                )}
                                                <Divider />
                                                <Typography variant="h3" pl={1} gutterBottom>
                                                    {t("converter.otherTax")}
                                                </Typography>
                                                {xeroTaxRates
                                                    .filter(
                                                        (item) =>
                                                            !invInfo.contact_tax_type
                                                                ?.map((val) => val.TaxType)
                                                                .includes(item.value)
                                                    )
                                                    .map((item) => (
                                                        <MenuItem value={item.value} key={item.value}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </BaseSelect2>
                                        </StyledTableCell>
                                    )}
                                    {trackingConfig &&
                                        trackingCategories.map((category) => (
                                            <StyledTableCell key={category._id}>
                                                <BaseSelect2
                                                    id={`tracking-category-${category._id}`}
                                                    name={category.tracking_category_name_xero}
                                                    value={row.tracking?.name ?? ""}
                                                    onChange={(event) =>
                                                        handleChangeTrackingCategory(
                                                            event,
                                                            row._id,
                                                            category.tracking_category_id_xero
                                                        )
                                                    }
                                                    size="small"
                                                    MenuProps={MenuProps}
                                                    isSearch
                                                    customStyle={true}
                                                >
                                                    {category.tracking_category_options.map((option) => (
                                                        <MenuItem key={option.TrackingOptionId} value={option.Name}>
                                                            {option.Name}
                                                        </MenuItem>
                                                    ))}
                                                </BaseSelect2>
                                            </StyledTableCell>
                                        ))}
                                    <StyledTableCell>
                                        ${" "}
                                        {parseFloat(
                                            getAmount(
                                                row?.unit_price ?? 0,
                                                row?.quantity ?? 0,
                                                row?.discount ?? 0,
                                                row?.tax_type ?? 0
                                            )
                                        ).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {rows.filter((item) => !item.deleted).length > 1 && (
                                            <LightTooltip title={t("miscellaneous.delete")}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDeleteRow(row._id)}
                                                    sx={{
                                                        color: "V030", // Color gris
                                                        "&:hover": {
                                                            color: "C100", // Cambia el color al hacer hover
                                                            cursor: "pointer", // Opcional: cambia el cursor a una mano
                                                        },
                                                    }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </LightTooltip>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NewInvoiceTableItems;
