/** React imports */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import LightTooltip from "../ui/LightTooltip";
import CheckIcon from "@mui/icons-material/Check";
import AlertDialog from "../ui/AlertDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material/styles";

/** MUI imports */
import {
    Dialog,
    DialogContent,
    Grid,
    Card,
    CardContent,
    Box,
    Typography,
    Tabs,
    Tab,
    Stack,
    Link,
    MenuItem,
    Checkbox,
    ListItemText,
    FormControlLabel,
    Switch,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustTextField from "../ui/CustTextField";

/** Images **/
import XeroLogo from "../../assets/xero.svg";
import QuickBooksLogo from "../../assets/quickbooks.svg";
import InfoIcon from "@mui/icons-material/Info";

/** Js imports **/
import companyService from "../../services/company";
import userCompanyService from "../../services/user_company";
import { useDispatch, useSelector } from "react-redux";
import { addCompanyID, addFranchiseID } from "../../redux/userSlice";
import CustomFilledSelectCountries from "../ui/CustomFilledSelectCountries";
import businessActivity from "../Js/businessActivity";
import authService from "../../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import BaseButton from "../ui/BaseButton";
import BaseSelect2 from "../ui/BaseSelect2";

const PREFIX = "add-company-dialog";

const classes = {
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    actions: `${PREFIX}-actions`,
    paper: `${PREFIX}-paper`,
};

const CustomCard = styled(Card)(({ theme }) => ({
    display: "flex",
    minHeight: 140,
    justifyContent: "space-evenly",
    backgroundColor: "#e8f7fb",
    alignItems: "center",
    transition: "transform 0.2s", // Add a transition for a smooth effect
    "&:hover": {
        transform: "scale(1.06)", // Scale the card on hover
        backgroundColor: "#ccffff",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {value === index && <Box sx={{ p: 3, width: "100%" }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const UpdateCompanyDialog = (props) => {
    const {
        setIsLoading,
        data,
        type,
        onClose,
        setOpenUpdate,
        setTitleDialog,
        setMessageDialog,
        setOpenAlert,
        open,
        setReload,
        setTexto,
        setAlertType,
        setShow,
        setShowModalConfig,
        setSelectedCompany,
        setSelectedFranchise,
        companyRedirect,
        companyFirm,
        setCompanyData,
        setFirmSettings,
        setTypeDialog,
        setActions,
        deleteCompany,
        handleCloseDialog,
        searchParams,
        setSearchParams,
        setCompanyRedirect,
        setCompanyFirm,
    } = props;

    const todos = useSelector((state) => state.value);

    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();

    const info = data;

    const theme = useTheme(); // Obtener el tema actual

    const [smbInfo, setSmbInfo] = useState({
        _id: info._id,
        companyType: type === "Firm" || companyFirm ? 1 : info.client_type,
        companyName: type === "Firm" ? info.franchise_name : info.company_name,
        country: info.country_name,
        ecActivity: info.business_activity,
        franchise: undefined,
        sinceDate: moment(info.creation_date)
            .utc()
            .format(todos?.dateFormat ?? "DD/MM/YYYY"),
        count: type === "Firm" ? info.count : undefined,
        monthlyConversions: type === "Firm" ? info?.monthly_conversions : "",
        systems: info.file_formats && info.file_formats !== null ? info.file_formats : [],
        system_subscription_name: info.system_subscription_name,
        taxID: info?.tax_id ?? "",
    });

    const [integ, setInteg] = useState(info.system_integration);
    const [isError, setIsError] = useState(false);
    const [franchises, setFranchises] = useState([]);
    const navigate = useNavigate();
    const [textButton1, setTextButton1] = useState(t("dialog.connect"));
    const [textButton2, setTextButton2] = useState(t("dialog.connect"));
    const [showIntegration, setShowIntegration] = useState(false);
    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const [doubleAction, setDoubleAction] = useState(false);
    const [activeSusDoc, setActiveSusDoc] = useState(info.convert_documents ?? true);
    const [emailInv, setEmailInv] = useState(info.invoice_mail ?? "");
    const [statusInv, setStatusInv] = useState(info.invoice_status ?? "");
    const [activeAutoSync, setActiveAutoSync] = useState(info.sync_documents ?? true);
    const [copied, setCopied] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidTaxId, setIsValidTaxId] = useState(false);
    const [freeTrialIntegration, setFreeTrialIntegration] = useState(false); // Empresa con Plan Micro que si puede tener integraciones.

    const [activeTracking, setActiveTracking] = useState(info?.tracking_config ?? false);

    const location = useLocation();

    const handleChangemailInv = (e) => {
        setEmailInv(e.target.value);
        verifyEmail(e.target.value);
    };

    const handleChangeTaxId = (event) => {
        setSmbInfo({
            ...smbInfo,
            [event.target.name]: event.target.value,
        });
        verifyTaxId(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (email) {
            await companyService
                .validateInvoiceEmail({
                    invoice_mail: email,
                    franchise_id: todos?.franchiseID,
                })
                .then((response) => {
                    // console.log("response", response);
                    setIsValidEmail(true);
                })
                .catch((error) => {
                    // console.log("error", error);
                    switch (error.response.status) {
                        case 404:
                            setIsValidEmail(false);
                            setIsError(false);
                            break;
                        default:
                            break;
                    }
                });
        }
    };

    const verifyTaxId = async (tax_id) => {
        if (tax_id) {
            await companyService
                .validateTaxId({
                    tax_id: tax_id,
                    franchise_id: todos?.franchiseID,
                    company_id: todos?.companyID,
                })
                .then((response) => {
                    // console.log("response", response);
                    setIsValidTaxId(true);
                })
                .catch((error) => {
                    // console.log("error", error);
                    switch (error.response.status) {
                        case 404:
                            setIsValidTaxId(false);
                            setIsError(false);
                            break;
                        default:
                            break;
                    }
                });
        }
    };

    const handleChangeStatusInv = (e) => {
        setStatusInv(e.target.value);
    };

    const handleChangeActiveSusDoc = (event) => {
        setActiveSusDoc(event.target.checked);
    };

    const handleChangeAutoSync = (event) => {
        setActiveAutoSync(event.target.checked);
    };

    const handleChangeTracking = (event) => {
        setActiveTracking(event.target.checked);
    };

    const handleOver = (index) => {
        if (index === 1) {
            if (integ === 1) {
                setTextButton1(t("dialog.disconnect"));
            } else {
                setTextButton1(t("dialog.connect"));
            }
        } else if (index === 2) {
            if (integ === 2) {
                setTextButton2(t("dialog.disconnect"));
            } else {
                setTextButton2(t("dialog.connect"));
            }
        }
    };

    const handleOut = (index) => {
        if (index === 1) {
            if (integ === 1) {
                setTextButton1(t("dialog.connected"));
            } else {
                setTextButton1(t("dialog.connect"));
            }
        } else if (index === 2) {
            if (integ === 2) {
                setTextButton2(t("dialog.connected"));
            } else {
                setTextButton2(t("dialog.connect"));
            }
        }
    };

    let bussinessActFinal = [];

    for (let i = 0; i < businessActivity.length; i++) {
        bussinessActFinal.push({
            id: businessActivity[i].id,
            name: t("dialog." + businessActivity[i].translation),
        });
    }

    useEffect(() => {
        if (todos?.companyID) {
            let currentCompany = todos?.allComData?.find((item) => item._id.toString() === todos?.companyID);

            if (currentCompany && currentCompany?.free_trial_integration) {
                setFreeTrialIntegration(true);
            }
        }
    }, [todos?.companyID, todos?.allComData]);

    useEffect(() => {
        if (companyRedirect) {
            setValue(1);
            setInteg(companyRedirect.integ);
        }
    }, [companyRedirect]);

    /** Component events */
    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await userCompanyService
                .getUserFranchises()
                .then((data) => {
                    setFranchises(data);
                    setSmbInfo({
                        ...smbInfo,
                        franchise: type === "Firm" ? undefined : info.franchise_id ? info.franchise_id._id : undefined,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });

            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAlertType, setIsLoading, setShow, setTexto, type]);

    useEffect(() => {
        (async () => {
            if (integ === 1) {
                setTextButton1(t("dialog.connected"));
                setTextButton2(t("dialog.connect"));
            } else if (integ === 2) {
                setTextButton2(t("dialog.connected"));
                setTextButton1(t("dialog.connect"));
            } else if (integ === 3) {
                setTextButton1(t("dialog.connect"));
                setTextButton2(t("dialog.connect"));
            }
        })();
    }, [integ, t]);

    // const companyTypeList = [
    //     { id: 2, name: t("dialog.smb") },
    //     { id: 1, name: t("dialog.firm") },
    // ];

    const [value, setValue] = useState(0);

    const handleChangeTab = (event, newValue) => {
        if (newValue === 1) {
            setSearchParams({ connected: false, connect: "", company_id: smbInfo._id, type: 2, open_m: true });
            //navigate("/dashboard?connected=false&connect=&company_id=" + smbInfo._id + "&type=2");
        } else {
            searchParams.delete("connected");
            searchParams.delete("connect");
            searchParams.delete("company_id");
            searchParams.delete("redirectType");
            searchParams.delete("type");
            searchParams.delete("open_m");
            setSearchParams(searchParams, { replace: true });
            setCompanyRedirect(null);
            //navigate("/dashboard");
        }
        setValue(newValue);
    };

    // const systemsList = [
    //     "Xero",
    //     "QuickBooks desktop",
    //     "QuickBooks online",
    //     "Profit",
    //     "Excel",
    //     "Odoo",
    //     "Sage 50cloud",
    //     "KashFlow",
    //     "FuseMetrix",
    //     "SortMyBooks",
    //     "FreeAgent",
    //     "Accounts IQ",
    //     "ClearBooks",
    //     "ReckonOne",
    //     "AccountRightLive",
    //     "Exact",
    //     "FreshBooks",
    //     "Wave",
    //     "Zoho Books",
    //     "NetSuite",
    //     "Kashoo",
    //     "AccountEdge",
    //     "Bill.com",
    //     "Otro",
    // ];

    const systemsList = ["Xero", "QuickBooks desktop", "QuickBooks online", "Otro"];

    const conversionList = [
        { id: 1, name: "1-5 " + t("register.conversions") },
        { id: 2, name: "6-15 " + t("register.conversions") },
        { id: 3, name: "16-30 " + t("register.conversions") },
        { id: 4, name: "31-50 " + t("register.conversions") },
        { id: 5, name: "51-200 " + t("register.conversions") },
        { id: 6, name: "+200 " + t("register.conversions") },
    ];

    const handleCloseDialogAlert = () => {
        setOpenDialogAlert(false);
    };

    const saveInteg = async () => {
        if (doubleAction) {
            if (integ === 1) {
                setIsLoading(true);
                // Desconectar Xero y conectar QB.
                await authService
                    .logoutXero({
                        company_id: smbInfo._id,
                    })
                    .then(async (response) => {
                        await authService
                            .signUpQB({
                                company_id: smbInfo._id,
                                type: 2,
                                location: location.pathname.replace("/", ""),
                            })
                            .then((response) => {
                                handleCloseDialogAlert();
                                window.location.href = response.redirectUrl;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                setIsLoading(false);
            }

            if (integ === 2) {
                setIsLoading(true);
                // Desconectar QB y conectar Xero.
                await authService
                    .logoutQB({
                        company_id: smbInfo._id,
                    })
                    .then(async (response) => {
                        await authService
                            .signUpXero({
                                company_id: smbInfo._id,
                                type: 2,
                                location: location.pathname.replace("/", ""),
                            })
                            .then((response) => {
                                handleCloseDialogAlert();
                                window.location.href = response.redirectUrl;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                setIsLoading(false);
            }
        } else {
            if (integ === 1) {
                setIsLoading(true);
                // Desconectar Xero.
                await authService
                    .logoutXero({
                        company_id: smbInfo._id,
                    })
                    .then((response) => {
                        handleCloseDialogAlert();
                        setInteg(3);

                        setAlertType("success");
                        setTexto(t("dialog.disconnectionEstablished"));
                        setShow(true);

                        setTimeout(() => {
                            setShow(false);
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                setIsLoading(false);
            }

            if (integ === 2) {
                setIsLoading(true);
                // Desconectar QB.
                await authService
                    .logoutQB({
                        company_id: smbInfo._id,
                    })
                    .then((response) => {
                        handleCloseDialogAlert();
                        setInteg(3);

                        setAlertType("success");
                        setTexto(t("dialog.disconnectionEstablished"));
                        setShow(true);

                        setTimeout(() => {
                            setShow(false);
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                setIsLoading(false);
            }
        }
    };

    const handleInteg = async (index) => {
        if (index === 1 && integ !== 1) {
            if (integ === 2) {
                // Desconectar QB y Conectar a Xero.
                setDoubleAction(true);
                setOpenDialogAlert(true);
            } else {
                // Conectar Xero.
                await authService
                    .signUpXero({
                        company_id: smbInfo._id,
                        type: 2,
                        location: location.pathname.replace("/", ""),
                    })
                    .then((response) => {
                        window.location.href = response.redirectUrl;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (index === 2 && integ !== 2) {
            if (integ === 1) {
                // Desconectar Xero y Conectar a QB.
                setDoubleAction(true);
                setOpenDialogAlert(true);
            } else {
                // Conectar QB.
                await authService
                    .signUpQB({
                        company_id: smbInfo._id,
                        type: 2,
                        location: location.pathname.replace("/", ""),
                    })
                    .then((response) => {
                        window.location.href = response.redirectUrl;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (index === 1 && integ === 1) {
            // Desconectar Xero.
            setDoubleAction(false);
            setOpenDialogAlert(true);
        } else if (index === 2 && integ === 2) {
            // Desconectar QB.
            setDoubleAction(false);
            setOpenDialogAlert(true);
        } else {
            setInteg(index);
        }
    };

    const bodyMessInteg = (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Typography
                    fontSize={14}
                    dangerouslySetInnerHTML={{
                        __html: integ === 1 ? t("dialog.alertTextXero") : t("dialog.alertTextQB"),
                    }}
                    textAlign="justify"
                ></Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("accounts.desCannotUndo")}
                </Typography>
            </Grid>
        </Grid>
    );

    const handleChange = (event) => {
        setSmbInfo({
            ...smbInfo,
            [event.target.name]: event.target.value,
        });
    };

    const saveCompany = async () => {
        if (!info.franchise_id && smbInfo.franchise) {
            setOpenUpdate(false);
            setTitleDialog(t("dialog.warning"));
            const bodyMess = (
                <>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ mt: 2, textAlign: "justify" }}
                    >
                        <Typography
                            fontStyle="normal"
                            fontSize={14}
                            fontWeight={300}
                            sx={{
                                color: "#361A52",
                            }}
                        >
                            {t("dialog.warningLinkCompany1")}{" "}
                            <Box
                                component="span"
                                sx={{
                                    fontStyle: "normal",
                                    fontSize: 14,
                                    color: "#361A52",
                                    fontWeight: "700",
                                }}
                            >
                                {smbInfo.companyName}
                            </Box>{" "}
                            {t("dialog.warningLinkCompany2")}
                        </Typography>
                    </Stack>
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#361A52",
                            mt: 2,
                            textAlign: "justify",
                        }}
                    >
                        {t("dialog.warningLinkCompany3")}
                    </Typography>
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#361A52",
                            textAlign: "justify",
                            mt: 2,
                            mb: 4,
                        }}
                    >
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </>
            );
            setMessageDialog(bodyMess);
            setActions(() => () => finalSaveCompany(1, smbInfo.franchise));
            setOpenAlert(true);
        } else if (info.franchise_id && !smbInfo.franchise) {
            setOpenUpdate(false);
            setTitleDialog(t("dialog.warning"));
            const bodyMess = (
                <>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ mt: 2, textAlign: "justify" }}
                    >
                        <Typography
                            fontStyle="normal"
                            fontSize={14}
                            fontWeight={300}
                            sx={{
                                color: "#361A52",
                            }}
                        >
                            {t("dialog.warningUnlinkCompany1")}{" "}
                            <Box
                                component="span"
                                sx={{
                                    fontStyle: "normal",
                                    fontSize: 14,
                                    color: "#361A52",
                                    fontWeight: "700",
                                }}
                            >
                                {smbInfo.companyName}
                            </Box>{" "}
                            {t("dialog.warningUnlinkCompany2")}
                        </Typography>
                    </Stack>
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#361A52",
                            textAlign: "justify",
                            mt: 2,
                            mb: 4,
                        }}
                    >
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </>
            );
            setMessageDialog(bodyMess);
            setActions(() => () => finalSaveCompany(2, null));
            setOpenAlert(true);
        } else if (info.franchise_id && smbInfo.franchise && info.franchise_id._id !== smbInfo.franchise) {
            setOpenUpdate(false);
            setTitleDialog(t("dialog.warning"));
            const bodyMess = (
                <>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ mt: 2, textAlign: "justify" }}
                    >
                        <Typography
                            fontStyle="normal"
                            fontSize={14}
                            fontWeight={300}
                            sx={{
                                color: "#361A52",
                            }}
                        >
                            {t("dialog.warningTransferCompany1")}{" "}
                            <Box
                                component="span"
                                sx={{
                                    fontStyle: "normal",
                                    fontSize: 14,
                                    color: "#361A52",
                                    fontWeight: "700",
                                }}
                            >
                                {smbInfo.companyName}
                            </Box>{" "}
                            {t("dialog.warningTransferCompany2")}
                        </Typography>
                    </Stack>
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#361A52",
                            textAlign: "justify",
                            mt: 2,
                            mb: 4,
                        }}
                    >
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </>
            );
            setMessageDialog(bodyMess);
            setActions(() => () => finalSaveCompany(3, smbInfo.franchise));
            setOpenAlert(true);
        } else {
            await finalSaveCompany(4, null);
        }
    };

    const finalSaveCompany = async (param1, franchise) => {
        setIsLoading(true);
        if (type === "Firm") {
            if (smbInfo.companyName.trim() && smbInfo.country && smbInfo.monthlyConversions && smbInfo.ecActivity) {
                const params = {
                    franchise_id: smbInfo._id,
                    franchise_name: smbInfo.companyName,
                    country_name: smbInfo.country,
                    business_activity: smbInfo.ecActivity,
                    monthly_conversions: smbInfo.monthlyConversions,
                };

                await companyService
                    .updateFranchise(params)
                    .then(() => {
                        handleCloseDialog();
                        onClose();
                        setAlertType("success");
                        setTexto(t("dialog.successEditFirm"));
                        setShow(true);

                        setTimeout(() => {
                            setShow(false);
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    });
                setReload((prevState) => !prevState);
            } else {
                setIsError(true);
            }
        } else {
            if (
                smbInfo.companyType &&
                smbInfo.companyName.trim() &&
                smbInfo.country &&
                smbInfo.systems.length > 0 &&
                smbInfo.ecActivity &&
                integ
            ) {
                if (smbInfo.companyType === 1 && smbInfo.franchise !== undefined) {
                    const params = {
                        company_id: smbInfo._id,
                        company_name: smbInfo.companyName,
                        country_name: smbInfo.country,
                        client_type: smbInfo.companyType,
                        business_activity: smbInfo.ecActivity,
                        file_formats: smbInfo.systems,
                        system_integration: integ,
                        franchise_id: smbInfo.franchise,
                        convert_documents: activeSusDoc,
                        invoice_mail: emailInv,
                        invoice_status: statusInv,
                        sync_documents: activeAutoSync,
                        tax_id: smbInfo.taxID,
                        tracking_config: activeTracking,
                    };

                    await companyService
                        .updateCompany(params)
                        .then(() => {
                            handleCloseDialog();
                            onClose();
                            setAlertType("success");
                            setTexto(t("dialog.successEditSmb"));
                            setShow(true);

                            setTimeout(() => {
                                setShow(false);
                            }, 3000);
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                    setReload((prevState) => !prevState);
                } else if (smbInfo.companyType === 2) {
                    const params = {
                        company_id: smbInfo._id,
                        company_name: smbInfo.companyName,
                        country_name: smbInfo.country,
                        client_type: smbInfo.companyType,
                        business_activity: smbInfo.ecActivity,
                        file_formats: smbInfo.systems,
                        system_integration: integ,
                        convert_documents: activeSusDoc,
                        invoice_mail: emailInv,
                        invoice_status: statusInv,
                        sync_documents: activeAutoSync,
                        tax_id: smbInfo.taxID,
                    };

                    await companyService
                        .updateCompany(params)
                        .then(() => {
                            handleCloseDialog();
                            onClose();
                            setAlertType("success");
                            setTexto(t("dialog.successEditSmb"));
                            setShow(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                    setReload((prevState) => !prevState);
                } else {
                    setIsError(true);
                }
            } else {
                setIsError(true);
                setAlertType("error");
                setTexto(t("dialog.snackErrorUpdate"));
                setShow(true);

                setTimeout(() => {
                    setShow(false);
                }, 5000);
            }
        }
        if (param1 === 1 || param1 === 3) {
            const item = todos.allComData.find((item) => item._id === franchise);
            dispatch(addFranchiseID(franchise));
            setSelectedFranchise(item);
            setCompanyFirm(true);
        } else if (param1 === 2) {
            dispatch(addFranchiseID(null));
            setCompanyFirm(false);
        }
        setIsLoading(false);
    };

    const handleClickDelete = () => {
        setOpenUpdate(false);
        setTitleDialog(t("dialog.warning"));
        const bodyMess = (
            <Box>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ mt: 1.5, textAlign: "center", mb: 2 }}
                >
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#361A52",
                        }}
                    >
                        {type === "Firm" ? t("dialog.warningDeleteFranchise1") : t("dialog.warningDeleteCompany1")}{" "}
                        <Box
                            component="span"
                            sx={{
                                fontStyle: "normal",
                                fontSize: 14,
                                color: "#361A52",
                                fontWeight: "700",
                            }}
                        >
                            {smbInfo.companyName}
                        </Box>{" "}
                        {type === "Firm" ? t("dialog.warningDeleteFranchise2") : t("dialog.warningDeleteCompany2")}
                    </Typography>
                </Stack>
                <Typography
                    fontStyle="normal"
                    fontSize={14}
                    fontWeight={300}
                    sx={{
                        color: "#361A52",
                        textAlign: "center",
                        my: 1,
                    }}
                >
                    {t("dialog.warningConfirm1")} {t("dialog.warningConfirm2")}
                </Typography>
            </Box>
        );
        setMessageDialog(bodyMess);
        setActions(() => () => deleteCompany(smbInfo._id));
        setOpenAlert(true);
    };

    const handleFirmSettings = async () => {
        onClose();
        setFirmSettings(true);

        setIsLoading(true);

        await companyService
            .getFranchiseDetailsCustom(smbInfo.franchise)
            .then((data) => {
                setCompanyData(data);
                setTypeDialog("Firm");
                setShowModalConfig((prev) => !prev);
            })
            .catch((err) => {
                console.log(err);
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const handleClickCountCompanies = async () => {
        await handleClickFirm();
        navigate("/dashboard");
    };

    const copyEmail = () => {
        navigator.clipboard.writeText(`bills+${emailInv}@noddok.com`);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    const handleClickFirm = async () => {
        onClose();

        setIsLoading(true);

        await companyService
            .getFranchiseDetailsCustom(smbInfo._id)
            .then((data) => {
                setSelectedFranchise(data);
                setSelectedCompany({});
                dispatch(addFranchiseID(data._id));
                dispatch(addCompanyID(null));
            })
            .catch((err) => {
                console.log(err);
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    useEffect(() => {
        if (todos?.subscription) {
            if (
                (todos.subscription.value !== 2 &&
                    todos.subscription.value !== 5 &&
                    !(todos.subscription.value === 3 && todos.subscription.plan === 0)) ||
                freeTrialIntegration
            ) {
                setShowIntegration(true);
            } else {
                setShowIntegration(false);
            }
        }
    }, [todos.subscription, freeTrialIntegration]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    borderRadius: "50px",
                    px: { xs: 1, md: 2 },
                    py: 0,
                },
            }}
            maxWidth="md"
            fullWidth
        >
            <DialogContent className={classes.content}>
                <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", width: "100%" }}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="Vertical tabs example"
                        sx={{
                            borderRight: 1,
                            borderColor: "divider",
                            mt: 10,
                            minWidth: "200px",
                            textAlign: "left",
                        }}
                    >
                        <Tab
                            label={t("dialog.basicInfo")}
                            {...a11yProps(0)}
                            sx={{
                                whiteSpace: "nowrap",
                                fontStyle: "normal",
                                fontSize: 16,
                                fontWeight: 300,
                                mr: 3,
                                color: "#361A52 !important",
                                "&.Mui-selected": {
                                    backgroundColor: theme.palette.V010,
                                    fontWeight: 600,
                                    borderRadius: 3,
                                },
                            }}
                        />
                        {type === "Smb" && showIntegration && (
                            <Tab
                                label={t("subscription.integ")}
                                {...a11yProps(1)}
                                sx={{
                                    whiteSpace: "nowrap",
                                    fontStyle: "normal",
                                    fontSize: 16,
                                    fontWeight: 300,
                                    mr: 3,
                                    color: "#361A52 !important",
                                    "&.Mui-selected": {
                                        backgroundColor: theme.palette.V010,
                                        fontWeight: 600,
                                        borderRadius: 3,
                                    },
                                }}
                            />
                        )}
                        {type === "Smb" && !showIntegration && (
                            <LightTooltip title={t("dashboard.tooltipIntegrations")}>
                                <Tab
                                    label={t("subscription.integ")}
                                    {...a11yProps(1)}
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontStyle: "normal",
                                        fontSize: 16,
                                        fontWeight: 300,
                                        mr: 3,
                                        color: "rgba(117, 117, 117, 1) !important",
                                        cursor: "default",
                                        "&.Mui-selected": {
                                            backgroundColor: theme.palette.V010,
                                            fontWeight: 600,
                                            borderRadius: 3,
                                        },
                                    }}
                                />
                            </LightTooltip>
                        )}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Stack direction="column" spacing={0}>
                            <Typography fontStyle="normal" variant="h2" component="span">
                                {type === "Firm" ? t("dialog.subscriptionSettings") + " " : t("dialog.settings") + " "}
                                <Typography
                                    fontStyle="normal"
                                    variant="h2"
                                    component="span"
                                    color={theme.palette.secondary.main}
                                    fontWeight={700}
                                >
                                    {smbInfo.companyName}
                                </Typography>
                            </Typography>
                            <Box>
                                <Typography
                                    sx={{
                                        fontStyle: "normal",
                                        fontSize: "8",
                                        fontWeight: "400",
                                    }}
                                >
                                    {t("dialog.sinceKiiper")} {smbInfo.sinceDate}
                                    {type === "Firm" && (
                                        <Box
                                            component="span"
                                            sx={{
                                                fontStyle: "normal",
                                                fontSize: "8",
                                                color: "#3b455f",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {" • "}
                                            <Link
                                                onClick={handleClickCountCompanies}
                                                sx={{ color: "#3b455f", cursor: "pointer", textDecoration: "none" }}
                                            >
                                                <Box component="span" sx={{ color: theme.palette.secondary.main }}>
                                                    {smbInfo.count}
                                                </Box>{" "}
                                                {smbInfo.count === 1
                                                    ? t("dialog.assocCompany")
                                                    : t("dialog.assocCompanies")}
                                            </Link>
                                        </Box>
                                    )}
                                </Typography>
                            </Box>
                        </Stack>
                        <Grid container sx={{ mt: 0 }} spacing={3}>
                            {/* {
                                type !== "Smb" && (<Grid item xs={12} md={6}>
                                    <CustomFilledSelect
                                        error={isError && !smbInfo.companyType}
                                        disabled={type === "Firm"}
                                        required={true}
                                        label={t("dialog.companyType")}
                                        values={companyTypeList}
                                        idName="id"
                                        valueName="name"
                                        value={smbInfo.companyType}
                                        onChange={handleChangeCompanyType}
                                        name="companyType"
                                    />
                                </Grid>)
                            } */}
                            {type === "Smb" && (
                                <>
                                    <Grid item xs={12} md={6} display="flex" alignItems="center">
                                        <LightTooltip title={smbInfo.companyType === 2 ? t("dialog.tooltipFranc") : ""}>
                                            <Box component="span" sx={{ width: "100%" }}>
                                                <BaseSelect2
                                                    required={smbInfo.companyType === 1}
                                                    disabled={smbInfo.companyType === 2 || info.first_company}
                                                    error={isError && smbInfo.companyType === 1 && !smbInfo.franchise}
                                                    label={t("dialog.subscription")}
                                                    name="franchise"
                                                    value={smbInfo.franchise ?? ""}
                                                    onChange={handleChange}
                                                    values={franchises}
                                                    idName="_id"
                                                    valueName="franchise_name"
                                                />
                                            </Box>
                                        </LightTooltip>
                                    </Grid>
                                    {smbInfo.companyType === 1 && smbInfo.franchise && (
                                        <Grid item xs={12} md={6} display="flex" alignItems="center">
                                            <BaseButton
                                                variantType="primaryOutlined"
                                                disableElevation
                                                onClick={handleFirmSettings}
                                            >
                                                {t("dialog.viewFirmConfig")}
                                            </BaseButton>
                                        </Grid>
                                    )}
                                </>
                            )}
                            <Grid item xs={12} md={6}>
                                <CustTextField
                                    fullWidth
                                    label={type === "Firm" ? t("dialog.subscriptionName") : t("dialog.companyName")}
                                    name="companyName"
                                    required={true}
                                    onChange={handleChange}
                                    value={smbInfo.companyName}
                                    error={isError && smbInfo.companyName.trim() === ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomFilledSelectCountries
                                    required={true}
                                    error={isError && !smbInfo.country}
                                    label={t("register.country")}
                                    value={smbInfo.country}
                                    onChange={handleChange}
                                    name="country"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BaseSelect2
                                    error={isError && !smbInfo.ecActivity}
                                    required
                                    label={t("dialog.ecActivity")}
                                    value={smbInfo.ecActivity}
                                    onChange={handleChange}
                                    name="ecActivity"
                                    values={bussinessActFinal}
                                    idName="id"
                                    valueName="name"
                                />
                            </Grid>
                            {type === "Firm" && (
                                <Grid item xs={12} md={6}>
                                    <BaseSelect2
                                        error={isError && !smbInfo.monthlyConversions}
                                        required
                                        label={t("register.estimatedConversions")}
                                        values={conversionList}
                                        idName="id"
                                        valueName="name"
                                        value={smbInfo.monthlyConversions}
                                        onChange={handleChange}
                                        name="monthlyConversions"
                                    />
                                </Grid>
                            )}
                            {type === "Smb" && (
                                <Grid item xs={12} md={6}>
                                    <BaseSelect2
                                        fullWidth
                                        error={isError && !smbInfo.systems.length}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        name="systems"
                                        value={smbInfo.systems}
                                        onChange={handleChange}
                                        label={t("dialog.usedFormats")}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {systemsList.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox checked={smbInfo.systems.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </BaseSelect2>
                                </Grid>
                            )}
                            {type === "Smb" && (
                                <Grid item xs={12} md={6}>
                                    <CustTextField
                                        fullWidth
                                        label={t("dialog.taxId")}
                                        name="taxID"
                                        onChange={handleChangeTaxId}
                                        value={smbInfo.taxID}
                                        error={isValidTaxId}
                                    />
                                    {isValidTaxId && smbInfo.taxID.trim() && (
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            {smbInfo.taxID.trim() && isValidTaxId && (
                                                <Typography size="xs" sx={{ color: "#ff5968" }}>
                                                    {t("dialog.duplicatedTaxId")}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 3 }}>
                            <BaseButton
                                variantType="primaryTextButton"
                                sizebutton="m"
                                //disabled={activeStep === 0}
                                onClick={onClose}
                                sx={{ mr: 1 }}
                            >
                                {t("dialog.skip")}
                            </BaseButton>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Box>
                                {!info.first_company && type !== "Firm" && (
                                    <BaseButton
                                        variantType="errorTextButton"
                                        onClick={handleClickDelete}
                                        sizebutton="m"
                                        sx={{ mr: 1 }}
                                        disabled={!info.first_company && type !== "Firm"}
                                    >
                                        {t("dialog.deleteCompany")}
                                    </BaseButton>
                                )}
                                <BaseButton
                                    onClick={saveCompany}
                                    disableElevation
                                    disabled={isValidTaxId}
                                    sizebutton="m"
                                >
                                    {t("dialog.saveChanges")}
                                </BaseButton>
                            </Box>
                        </Box>
                    </TabPanel>
                    {type === "Smb" && (
                        <TabPanel value={value} index={1}>
                            <Stack direction="column" spacing={0}>
                                <Typography fontStyle="normal" variant="h2" component="span">
                                    {type === "Firm"
                                        ? t("dialog.subscriptionSettings") + " "
                                        : t("dialog.settings") + " "}
                                    <Typography
                                        fontStyle="normal"
                                        variant="h2"
                                        component="span"
                                        color={theme.palette.secondary.main}
                                        fontWeight={700}
                                    >
                                        {smbInfo.companyName}
                                    </Typography>
                                </Typography>
                                <Typography
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}
                                >
                                    {t("dialog.sinceKiiper")} {smbInfo.sinceDate}
                                    {type === "Firm" && (
                                        <Box
                                            component="span"
                                            sx={{
                                                fontStyle: "normal",
                                                fontSize: "8",
                                                color: "#3b455f",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {" • "}
                                            <Link
                                                onClick={handleClickFirm}
                                                sx={{ color: "#3b455f", cursor: "pointer", textDecoration: "none" }}
                                            >
                                                <Box component="span" sx={{ color: theme.palette.secondary.main }}>
                                                    {smbInfo.count}
                                                </Box>{" "}
                                                {smbInfo.count === 1
                                                    ? t("dialog.assocCompany")
                                                    : t("dialog.assocCompanies")}
                                            </Link>
                                        </Box>
                                    )}
                                </Typography>
                            </Stack>
                            <Grid container justifyContent="center" sx={{ mt: 0 }} spacing={3}>
                                <Grid item xs={12}>
                                    <Typography fontStyle="normal" fontSize={18} fontWeight={600}>
                                        {t("dialog.method")}
                                    </Typography>
                                    <Typography fontStyle="normal" sx={{ color: "#361A52" }}>
                                        {t("dialog.method2")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomCard
                                        elevation={0}
                                        sx={{
                                            transform: integ && integ === 1 ? "scale(1.06)" : "scale(1.0)", // Scale the card on hover
                                            backgroundColor: integ && integ === 1 ? "#ccffff" : "#e8f7fb",
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 90,
                                                width: 90,
                                                maxHeight: { xs: 90, md: 90 },
                                                maxWidth: { xs: 90, md: 90 },
                                            }}
                                            src={XeroLogo}
                                        />
                                        <Box>
                                            <CardContent sx={{ flex: "1 0 auto", textAlign: "center" }}>
                                                <BaseButton
                                                    onClick={() => handleInteg(1)}
                                                    disableElevation
                                                    endIcon={
                                                        integ && integ === 1 ? (
                                                            <CheckIcon sx={{ width: "14px" }} />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    size="small"
                                                    // disabled={subscription === "freeTrial" || integ === 2}
                                                    onMouseOver={() => handleOver(1)}
                                                    onMouseOut={() => handleOut(1)}
                                                >
                                                    {textButton1}
                                                </BaseButton>
                                                {integ === 1 && (
                                                    <LightTooltip
                                                        title={
                                                            smbInfo.system_subscription_name.length <= 50
                                                                ? ""
                                                                : smbInfo.system_subscription_name
                                                        }
                                                    >
                                                        <Typography
                                                            sx={{
                                                                paddingTop: 1.5,
                                                                width: 120,
                                                                fontSize: 12,
                                                            }}
                                                            variant="h4"
                                                        >
                                                            {smbInfo.system_subscription_name.length <= 50
                                                                ? smbInfo.system_subscription_name
                                                                : `${smbInfo.system_subscription_name.substring(
                                                                      0,
                                                                      50
                                                                  )}...`}
                                                        </Typography>
                                                    </LightTooltip>
                                                )}
                                            </CardContent>
                                        </Box>
                                    </CustomCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomCard
                                        elevation={0}
                                        sx={{
                                            transform: integ && integ === 2 ? "scale(1.06)" : "scale(1.0)", // Scale the card on hover
                                            backgroundColor: integ && integ === 2 ? "#ccffff" : "#e8f7fb",
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 110,
                                                width: 110,
                                                maxHeight: { xs: 110, md: 110 },
                                                maxWidth: { xs: 110, md: 110 },
                                            }}
                                            src={QuickBooksLogo}
                                        />
                                        <Box>
                                            <CardContent sx={{ flex: "1 0 auto", textAlign: "center" }}>
                                                <BaseButton
                                                    disableElevation
                                                    onClick={() => handleInteg(2)}
                                                    endIcon={
                                                        integ && integ === 2 ? (
                                                            <CheckIcon sx={{ width: "14px" }} />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    // disabled={subscription === "freeTrial" || integ === 2}
                                                    onMouseOver={() => handleOver(2)}
                                                    onMouseOut={() => handleOut(2)}
                                                    size="small"
                                                >
                                                    {textButton2}
                                                </BaseButton>
                                                {integ === 2 && (
                                                    <LightTooltip
                                                        title={
                                                            smbInfo.system_subscription_name.length <= 50
                                                                ? ""
                                                                : smbInfo.system_subscription_name
                                                        }
                                                    >
                                                        <Typography
                                                            sx={{
                                                                paddingTop: 1.5,
                                                                width: 120,
                                                                fontSize: 12,
                                                            }}
                                                            variant="h4"
                                                        >
                                                            {smbInfo.system_subscription_name.length <= 50
                                                                ? smbInfo.system_subscription_name
                                                                : `${smbInfo.system_subscription_name.substring(
                                                                      0,
                                                                      50
                                                                  )}...`}
                                                        </Typography>
                                                    </LightTooltip>
                                                )}
                                            </CardContent>
                                        </Box>
                                    </CustomCard>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" pt={3}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography>{t("dialog.activeEmail")}</Typography>
                                        <LightTooltip title={t("dialog.tooltipSwitch")}>
                                            <InfoIcon fontSize="inherit" />
                                        </LightTooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={<Switch checked={activeSusDoc} onChange={handleChangeActiveSusDoc} />}
                                        label={t("converter.yes")}
                                    />
                                </Grid>
                                {activeSusDoc && (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Typography>{t("dialog.emailToReceive")}</Typography>
                                                <LightTooltip title={t("dialog.tooltipEdit")}>
                                                    <InfoIcon fontSize="inherit" />
                                                </LightTooltip>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <CustTextField
                                                label={t("login.email")}
                                                name="emailInv"
                                                onChange={handleChangemailInv}
                                                value={emailInv}
                                                type="email"
                                                error={isValidEmail}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            sx={{ "& p": { color: "#B3B3B3" } }}
                                                        >
                                                            bills+
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <>
                                                            <InputAdornment
                                                                position="end"
                                                                sx={{ "& p": { color: "#B3B3B3" } }}
                                                            >
                                                                @noddok.com
                                                            </InputAdornment>
                                                            {isValidEmail && (
                                                                <InputAdornment position="end">
                                                                    <CancelIcon color="error" />
                                                                </InputAdornment>
                                                            )}
                                                        </>
                                                    ),
                                                }}
                                                helperText={
                                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                        {emailInv.trim() && isValidEmail && (
                                                            <Typography size="xs">
                                                                {t("dialog.duplicatedEmail")}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                }
                                                shrink={true}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <LightTooltip title={copied ? t("inbox.copied") : t("inbox.copy")}>
                                                <IconButton color="primary" size="small" onClick={copyEmail}>
                                                    <ContentCopyIcon fontSize="inherit" />
                                                </IconButton>
                                            </LightTooltip>
                                        </Grid>
                                        {integ !== 2 && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography>{t("dialog.statusSync")}</Typography>
                                                        <LightTooltip title={t("dialog.tooltipStatus")}>
                                                            <InfoIcon fontSize="inherit" />
                                                        </LightTooltip>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <BaseSelect2
                                                        id="demo-simple-select"
                                                        labelId="demo-simple-select-label"
                                                        label={t("converter.status")}
                                                        name="statusInv"
                                                        value={statusInv}
                                                        onChange={handleChangeStatusInv}
                                                        fullWidth
                                                    >
                                                        <MenuItem value={1}>{t("dialog.draft")}</MenuItem>
                                                        <MenuItem value={2}>{t("dialog.approve")}</MenuItem>
                                                    </BaseSelect2>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} md={6}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Typography>{t("dialog.syncInvAuto")}</Typography>
                                                <LightTooltip title={t("dialog.tooltipSyncAuto")}>
                                                    <InfoIcon fontSize="inherit" />
                                                </LightTooltip>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={activeAutoSync} onChange={handleChangeAutoSync} />
                                                }
                                                label={t("converter.yes")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Typography>{t("dialog.tracking")}</Typography>
                                                <LightTooltip title={""}>
                                                    <InfoIcon fontSize="inherit" />
                                                </LightTooltip>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={activeTracking} onChange={handleChangeTracking} />
                                                }
                                                label={t("converter.yes")}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Stack direction="row" justifyContent="space-between" pt={2}>
                                <BaseButton
                                    variantType="primaryTextButton"
                                    sizebutton="m"
                                    onClick={onClose}
                                    sx={{ mr: 1 }}
                                >
                                    {t("dialog.skip")}
                                </BaseButton>
                                <Box>
                                    {!info.first_company && type !== "Firm" && (
                                        <BaseButton
                                            variantType="errorTextButton"
                                            onClick={handleClickDelete}
                                            sizebutton="m"
                                            sx={{ mr: 1 }}
                                            disabled={!info.first_company && type !== "Firm"}
                                        >
                                            {t("dialog.deleteCompany")}
                                        </BaseButton>
                                    )}
                                    <BaseButton
                                        onClick={saveCompany}
                                        disableElevation
                                        disabled={isValidEmail}
                                        sizebutton="m"
                                    >
                                        {t("dialog.finally")}
                                    </BaseButton>
                                </Box>
                            </Stack>
                        </TabPanel>
                    )}
                </Box>
            </DialogContent>
            <AlertDialog
                open={openDialogAlert}
                onClose={handleCloseDialogAlert}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMessInteg}
                agreeAction={saveInteg}
                maxWidth="sm"
            />
        </Dialog>
    );
};

export default UpdateCompanyDialog;
