import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import conversionService from "../../../services/conversion";
import companyService from "../../../services/company";

import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    Stack,
    styled,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";

import XeroLogo from "../../../assets/xero.svg";
import QuickBooksLogo from "../../../assets/quickbooks.svg";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/WarningRounded";
import EditIcon from "@mui/icons-material/Edit";

import LightTooltip from "../../ui/LightTooltip";
import InvoicesSyncTable from "./InvoicesSyncTable";
import InvoiceSlider from "./InvoiceSlider";
import SimpleDialog from "../../ui/SimpleDialog";
import FormDialog from "../../ui/FormDialog";
import EditContactChecks from "../checks/EditContactChecks";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import { getContactIcon } from "../../../utils/utils";
import BaseButton from "../../ui/BaseButton";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#361A52 !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "15px",
    },
});

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#361A52",
        fontWeight: 600,
    },
});

const InvoicesSync = (props) => {
    const {
        setIsLoading,
        handleGoBack,
        downloadInv,
        invoicesData,
        returnInvoiceModal,
        setOpenPreview,
        setFileData,
        handleHistSync,
        setBasePreviewData,
        setOpenHeavy,
        replaceInvBill,
        conversionType,
        setTexto,
        setAlertType,
        setShow,
        setFileNameFull,
        conversionContacts
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const filteredInvoicesData = JSON.parse(JSON.stringify(invoicesData));
    let filteredCurrentCompany = todos?.allComData.find((item) => item._id.toString() === todos?.companyID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const invoicesOriginal =
        filteredInvoicesData.invoices?.length > 0
            ? filteredInvoicesData.invoices.filter((item) => item.id_status !== 2 && item.id_status !== 5)
            : [{ ...filteredInvoicesData }];

    const [status, setStatus] = useState(0);
    const [invoiceData, setInvoiceData] = useState({});
    const [invoices, setInvoices] = useState([]);

    const [selectedAllAcc, setSelectedAllAcc] = useState("");
    const [selectedContact, setSelectedContact] = useState({});
    const [accountsXero, setAccountsXero] = useState([]);
    const [contactsXero, setContactsXero] = useState([]);
    const [xeroTaxRates, setXeroTaxRates] = useState([]);
    const [xeroItems, setXeroItems] = useState([]);
    const [valueTab, setValueTab] = useState(0);
    const [showModalLeftSync, setShowModalLeftSync] = useState(false);
    const [contactXeroUpdated, setContactXeroUpdated] = useState("");
    const [extContactName, setExtContactName] = useState("");
    const [openModalEditContact, setOpenModalEditContact] = useState(false);
    const [edited, setEdited] = useState([]);
    const [rowsAux, setRowsAux] = useState([]);
    const [newRowDiff, setNewRowDiff] = useState(false);
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const [toReview, setToReview] = useState([]);
    const [readyToSync, setReadyToSync] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [selectedInvoiceDate, setSelectedInvoiceDate] = useState(null);
    const [editGlobal, setEditGlobal] = useState(false);

    const [allCurrencies, setAllCurrencies] = useState([]);
    const [systemCurrency, setSystemCurrency] = useState("");
    const [selectedRate, setSelectedRate] = useState("");
    const [warningMod, setWarningMod] = useState(false);
    const [currentInvoicesOriginal] = useState(JSON.parse(JSON.stringify(invoicesOriginal)));

    const calcSubtotal = (obj) => {
        const unitPrice = parseFloat(obj.unit_price_xero) || parseFloat(obj.formatted_unit_price) || 0;
        const quantity = parseFloat(obj.quantity_xero) || parseFloat(obj.formatted_quantity) || 1;

        return unitPrice * quantity;
    };

    const calcTax = (obj) => {
        if (xeroTaxRates.length > 0 && "tax_type_xero" in obj && obj.tax_type_xero) {
            const taxRate = xeroTaxRates.find((item) => item.value === obj.tax_type_xero).taxRate;
            return (taxRate * calcSubtotal(obj)) / 100;
        } else {
            return parseFloat(obj.tax_amount_xero ?? obj.tax_amount ?? 0);
        }
    };

    const calcTotal = (obj) => {
        return calcTax(obj) + calcSubtotal(obj);
    };

    const isGreaterExt =
        rowsAux.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTotal(obj), 0) <
        invoiceData.formatted_invoice_total;

    const isEqual =
        invoiceData.formatted_invoice_total ===
        (edited.length > 0
            ? parseFloat(
                  rowsAux
                      .filter((item) => !item.deleted)
                      .reduce((sum, obj) => sum + calcTotal(obj), 0)
                      .toFixed(2)
              )
            : parseFloat(
                  invoiceData.line_items
                      ?.filter((item) => !item.deleted)
                      .reduce((sum, obj) => sum + calcTotal(obj), 0)
                      .toFixed(2)
              ));

    useEffect(() => {
        let currenCopyInvOg = JSON.parse(JSON.stringify(currentInvoicesOriginal));
        const found = currenCopyInvOg.some(
            (item) => item.contact_account_code?.length !== 0 || item.contact_tax_type?.length !== 0
        );

        let invoicesOriginalFinal = currenCopyInvOg.map((invoice) => {
            invoice.line_items = invoice.line_items.filter((line) => line.formatted_unit_price || line.unit_price_xero);
            return invoice;
        });

        if (found) {
            invoicesOriginalFinal = invoicesOriginalFinal.map((invoice) => {
                if (invoice.contact_account_code?.length === 1 || invoice.contact_tax_type?.length === 1) {
                    invoice.line_items = invoice.line_items.map((line) => {
                        if (
                            !line.account_code_xero &&
                            !line.account_code &&
                            invoice.contact_account_code?.length === 1
                        ) {
                            line.account_code_xero = invoice.contact_account_code[0].AccountCode;
                        }
                        if (!line.tax_type_xero && !line.tax_type && invoice.contact_tax_type?.length >= 1) {
                            line.tax_type_xero = invoice.contact_tax_type[0].TaxType;

                            if (xeroTaxRates.length > 0) {
                                const taxRate = xeroTaxRates.find(
                                    (item) => item.value === invoice.contact_tax_type[0].TaxType
                                ).taxRate;
                                line.tax_amount_xero = (taxRate * calcSubtotal(line)) / 100;
                            }
                        }
                        return line;
                    });
                }
                return invoice;
            });
        }

        if (toBeCompleted.length !== 0 || invoicesOriginalFinal.length === 1) {
            if (toBeCompleted.length !== 0) {
                const findInv = invoicesOriginalFinal.find((item) => item._id === toBeCompleted[0]._id)
                setInvoiceData(findInv);
            } else {
                setInvoiceData({ ...invoicesOriginalFinal[0] });
            }
        }

        setInvoices(invoicesOriginalFinal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xeroTaxRates, currentInvoicesOriginal]);

    useEffect(() => {

        if (!editGlobal) {
            const approximatelyEqual = (v1, v2, epsilon = 0.01) => Math.abs(v1 - v2) < epsilon;

            function validateFields(item, primaryField, secondaryField) {
                if (item[primaryField] !== undefined && item[primaryField] !== "") {
                    return true;
                } else if (item[secondaryField] !== undefined && item[secondaryField] !== "") {
                    return true;
                }
                return false;
            }
    
            const clonedInvoices = JSON.parse(JSON.stringify(invoices));

            // Listo para sincronizar
            const filter3 = clonedInvoices.filter((item) => {
                const lines = item?.line_items?.filter((item) => !item.deleted) ?? [];
    
                return (
                    lines?.every((item) => validateFields(item, "account_code_xero", "account_code")) &&
                    lines?.every((item) => validateFields(item, "quantity_xero", "quantity")) &&
                    lines?.every((item) => validateFields(item, "unit_price_xero", "unit_price")) &&
                    (item.contactXeroID !== undefined) && item.contactXeroID !== '' && item.contactXeroID !== null &&
                    item.final_date !== undefined && item.final_date !== null &&
                    (edited.length > 0
                        ? approximatelyEqual(
                              rowsAux.reduce((sum, obj) => sum + calcTotal(obj), 0),
                              item.formatted_invoice_total
                          )
                        : approximatelyEqual(
                              lines?.reduce((sum, obj) => sum + calcTotal(obj), 0),
                              item.formatted_invoice_total
                          ))
                );
            });
    
            // Por revisar
            const filter2 = clonedInvoices.filter((item) => {
                const lines = item?.line_items?.filter((item) => !item.deleted) ?? [];
    
                return (
                    lines?.every((item) => validateFields(item, "account_code_xero", "account_code")) &&
                    lines?.every((item) => validateFields(item, "quantity_xero", "quantity")) &&
                    lines?.every((item) => validateFields(item, "unit_price_xero", "unit_price")) &&
                    (item.contactXeroID !== undefined) && item.contactXeroID !== '' && item.contactXeroID !== null &&
                    item.final_date !== undefined && item.final_date !== null &&
                    (edited.length > 0
                        ? !approximatelyEqual(
                              rowsAux.reduce((sum, obj) => sum + calcTotal(obj), 0),
                              item.formatted_invoice_total
                          )
                        : !approximatelyEqual(
                              lines?.reduce((sum, obj) => sum + calcTotal(obj), 0),
                              item.formatted_invoice_total
                          ))
                );
            });
    
            const filter1 = clonedInvoices.filter((item) => {
                return !filter2.some((item2) => item2._id === item._id) && !filter3.some((item2) => item2._id === item._id);
            });
    
            // Revisar si hay cambios en general
            if (JSON.stringify(toBeCompleted) !== JSON.stringify(filter1)) {
                // Cambios de ids
                if (
                    JSON.stringify(toBeCompleted.map((item) => item._id)) !==
                    JSON.stringify(filter1.map((item) => item._id))
                ) {
                    if (clonedInvoices.length > 1) {
                        //if (valueTab === 0) {
                            // if (filter1.length > 0) {
                            //     setInvoiceData({ ...filter1[0] });
                            // } else {
                            //     setInvoiceData({});
                            // }
                        //}
                    }
                }
                setToBeCompleted(filter1);
                if (filter1.length > 0 && filter1.map((inv) => inv?._id?.toString()).includes(invoiceData?._id?.toString())) {
                    setValueTab(0)
                }
            }
            if (JSON.stringify(toReview) !== JSON.stringify(filter2)) {
                // Cambios de ids
                if (JSON.stringify(toReview.map((item) => item._id)) !== JSON.stringify(filter2.map((item) => item._id))) {
                    //if (clonedInvoices.length > 1) {
                        // if (valueTab === 1) {
                        //     if (filter2.length > 0) {
                        //         setInvoiceData({ ...filter2[0] });
                        //     } else {
                        //         setInvoiceData({});
                        //     }
                        // }
                    //}
                }
                setToReview(filter2);
                if (filter2.length > 0 && filter2.map((inv) => inv?._id?.toString()).includes(invoiceData?._id?.toString())) {
                    setValueTab(1)
                }
            }
            if (JSON.stringify(readyToSync) !== JSON.stringify(filter3)) {
                if (
                    JSON.stringify(readyToSync.map((item) => item._id)) !== JSON.stringify(filter3.map((item) => item._id))
                ) {
                    //if (clonedInvoices.length > 1) {
                        // if (valueTab === 2) {
                        //     if (filter3.length > 0) {
                        //         setInvoiceData({ ...filter3[0] });
                        //     } else {
                        //         setInvoiceData({});
                        //     }
                        // }
                    //}
                }
                setReadyToSync(filter3);
                if (filter3.length > 0 && filter3.map((inv) => inv?._id?.toString()).includes(invoiceData?._id?.toString())) {
                    setValueTab(2)
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices, editGlobal]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                    type: conversionType === 1 ? 3 : 2,
                })
                .then((data) => {
                    setAccountsXero(data.map((item) => ({ value: item.Code, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTaxRates({
                    company_id: todos.companyID,
                    type: conversionType === 1 ? 3 : 2, // 2 o 3
                })
                .then((data) => {
                    setXeroTaxRates(
                        data.map((item) => ({
                            value: item.TaxType,
                            name: `${item.Name} - ${item.EffectiveRate}%`,
                            taxRate: item.EffectiveRate,
                            EffectiveRate: item.EffectiveRate,
                            isZeroTax: item.TaxComponents[0].Name === "No Tax",
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getXeroItems({
                    company_id: todos.companyID,
                    type: conversionType === 1 ? 1 : 2,
                })
                .then((data) => {
                    setXeroItems(data.map((item) => ({ value: item.ItemID, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            let companyCurrency = "";
            await companyService
                .getCompanyDetails(todos.companyID)
                .then((data) => {
                    setSystemCurrency(data.system_currency);
                    companyCurrency = data.system_currency;
                })
                .catch((error) => {
                    console.log(error);
                });

            await conversionService
                .getXeroCurrencies({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    if (data.length > 0) {

                        let currencyMap = data.map((item) => ({
                            id: item.Code,
                            name: item.Description,
                            setup: item.setup,
                        }));

                        const findCurrencySystem = currencyMap.find((currency) => currency.id === companyCurrency);
                        currencyMap = currencyMap.map((item) => {
                            if (findCurrencySystem.id === item.id) {
                                return {
                                    ...item,
                                    setup: true
                                }
                            }
                            return item
                        })
                        
                        setAllCurrencies(currencyMap);
                        // const findCurrency = currencyMap.find((currency) => currency.id === companyCurrency);
                        // setCurrency(
                        //     invoicesData.currency_iso_code ? invoicesData.currency_iso_code : findCurrency?.id ?? ""
                        // );
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
    }, [conversionContacts, conversionType, invoicesData.currency_iso_code, setIsLoading, todos.companyID]);

    useEffect(() => {
        (async () => {
            if (conversionContacts.length > 0 && Object.keys(invoiceData).length > 0) {
                let newContacts = [];
                
                const currency = invoiceData.final_currency;
                const contactXero = invoiceData.contactXeroID;

                let rate;
                if (currency !== systemCurrency && invoiceData.id_status === 3) {
                    await conversionService
                        .getXECurrencyRate({
                            from: currency,
                            to: systemCurrency,
                            date: moment.utc(invoiceData.formatted_invoice_date).format("YYYY-MM-DD"),
                        })
                        .then((data) => {
                            let newRate = data.amount.toString();
                            newRate = newRate.includes(".") ? parseFloat(newRate).toFixed(4) : newRate
                            rate = newRate
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    rate = invoiceData?.exchange_rate ?? invoiceData?.final_currency_rate ?? 1;
                }

                let selectedContactNew = {};

                if (contactXero && contactXero !== null && contactXero !== "first") { // Existe contacto sugerido o ya existe la sincronización
                    // console.log(conversionContacts)
                    // console.log(invoiceData.contactXeroID)
                    const findContactSim1 = conversionContacts.find((contact) => contact.value === invoiceData.contactXeroID);

                    selectedContactNew.name = findContactSim1.name;
                    selectedContactNew.value = findContactSim1.value;
                    newContacts = [...conversionContacts];
                } else { 
                    const contactName = invoiceData.conversion_type === 1 ? invoiceData.final_customer_name : invoiceData.final_vendor_name;

                    selectedContactNew = {
                        value: "first",
                        name: contactName,
                    };

                    newContacts = [
                        {
                            value: "first",
                            name:
                                invoiceData.conversion_type === 1 ? invoiceData.final_customer_name : invoiceData.final_vendor_name,
                        },
                        ...conversionContacts,
                    ];
                }
                setContactsXero(newContacts);
                setSelectedContact(selectedContactNew);
                setSelectedCurrency(currency);

                if (invoiceData?.final_date && invoiceData.final_date !== null) {
                    const dateF = moment.utc(invoiceData.final_date)
                    setSelectedInvoiceDate(dateF);
                } else {
                    setSelectedInvoiceDate(null);
                }
                // const dateF = invoiceData?.formatted_invoice_date ?? null;
                // console.log(dateF)
                // setSelectedInvoiceDate(dateF);
                setSelectedRate(rate)
            }
        })();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversionContacts, systemCurrency, invoiceData.conversion_type, invoiceData.final_customer_name, invoiceData.final_vendor_name]);

    const handleChangeSelectAcc = (event) => {
        setSelectedAllAcc(event.target.value);
        setInvoices(
            invoices.map((aux) => ({
                ...aux,
                line_items: aux.line_items.map((item) => ({
                    ...item,
                    account_code: event.target.value,
                    account_code_xero: event.target.value,
                })),
            }))
        );
        setInvoiceData({
            ...invoiceData,
            line_items: invoiceData.line_items.map((aux) => ({
                ...aux,
                account_code: event.target.value,
                account_code_xero: event.target.value,
            })),
            global_acc_code: event.target.value,
        });
    };

    const handleChangeSelectContact = async (newValue) => {
        setSelectedContact(newValue);

        if (newValue !== null) {
            setIsLoading(true);

            const newContactID = contactsXero.find((item) => item.value === newValue.value).idSys;
            const params = {
                company_id: todos.companyID,
                contact_id_system: newContactID,
                invoiceType: invoiceData.conversion_type,
            };
            let newSuggestKiiper = {};
            let selSuggestKiiper = {};

            await conversionService.getLastInvoicesFromContactArrays(params).then((response) => {
                if (response) {
                    newSuggestKiiper = response;
                }
            });

            const updatedInvoices = invoices.map((aux) => {
                const invoiceCopy = {
                    ...aux,
                    line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
                };

                if (invoiceCopy._id === invoiceData._id) {
                    invoiceCopy.contactXeroID = newValue.value;
                    if (invoiceData.conversion_type === 1) {
                        invoiceCopy.final_customer_name = newValue.name;
                    } else {
                        invoiceCopy.final_vendor_name = newValue.name;
                    }
                    invoiceCopy.contact_account_code = newSuggestKiiper.contact_account_code;
                    invoiceCopy.contact_tax_type = newSuggestKiiper.contact_tax_type;

                    if (
                        newSuggestKiiper.contact_account_code?.length === 1 ||
                        newSuggestKiiper.contact_tax_type?.length === 1
                    ) {
                        invoiceCopy.line_items = invoiceCopy.line_items.map((line) => {
                            if (
                                !line.account_code_xero &&
                                !line.account_code &&
                                newSuggestKiiper.contact_account_code.length === 1
                            ) {
                                line.account_code_xero = newSuggestKiiper.contact_account_code[0].AccountCode;
                                selSuggestKiiper.account_code_xero =
                                    newSuggestKiiper.contact_account_code[0].AccountCode;
                            }
                            if (
                                !line.tax_type_xero &&
                                !line.tax_type &&
                                newSuggestKiiper?.contact_tax_type?.length >= 1
                            ) {
                                line.tax_type_xero = newSuggestKiiper.contact_tax_type[0].TaxType;
                                selSuggestKiiper.tax_type_xero = newSuggestKiiper.contact_tax_type[0].TaxType;

                                if (xeroTaxRates.length > 0) {
                                    const taxRate = xeroTaxRates.find(
                                        (item) => item.value === newSuggestKiiper.contact_tax_type[0].TaxType
                                    ).taxRate;
                                    line.tax_amount_xero = (taxRate * calcSubtotal(line)) / 100;
                                }
                            }
                            return line;
                        });
                    }
                }
                return invoiceCopy;
            });

            setInvoices(updatedInvoices);
            setInvoiceData({
                ...invoiceData,
                contact_account_code: newSuggestKiiper.contact_account_code,
                contact_tax_type: newSuggestKiiper.contact_tax_type,
                line_items: invoiceData.line_items.map((aux) => ({
                    ...aux,
                    account_code_xero: selSuggestKiiper.account_code_xero ?? aux.account_code_xero,
                    tax_type_xero: selSuggestKiiper.tax_type_xero ?? aux.tax_type_xero,
                })),
            });
            setIsLoading(false);
        }
    };

    const handleClearContact = async (newValue) => {
        setSelectedContact({});

        const updatedInvoices = invoices.map((aux) => {
            const invoiceCopy = {
                ...aux,
                line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
            };

            if (invoiceCopy._id === invoiceData._id) {
                invoiceCopy.contactXeroID = null;
                if (invoiceData.conversion_type === 1) {
                    invoiceCopy.final_customer_name = '';
                } else {
                    invoiceCopy.final_vendor_name = '';
                }
            }
            return invoiceCopy;
        });

        setInvoices(updatedInvoices);
    };

    const handleCloseSyncModal = () => setShowModalLeftSync(false);

    //Funcion de sincronizacion
    const syncInvoices = async () => {
        let readyToSyncAmounts = readyToSync.concat(toReview);

        if (readyToSyncAmounts.length !== currentInvoicesOriginal.length) {
            setShowModalLeftSync(true);
        } else {
            setIsLoading(true);

            // Sincronizamos las facturas
            const params = {
                invoices: readyToSyncAmounts.map((item) => {
                    return {
                        _id: item._id,
                        ContactXeroID: item.contactXeroID !== "first" ? item.contactXeroID : undefined,
                        ContactXeroName: item.conversion_type === 1 ? item.final_customer_name : item.final_vendor_name,
                        currency: item.final_currency,
                        currency_rate: item.final_currency_rate === "" ? "1" : item.final_currency_rate,
                        invoice_date: item.final_date,
                        line_items: JSON.stringify(item.line_items),
                    }
                }),
            };

            // console.log("params", params);

            if (invoicesData?.invoices?.length > 0) {
                params.grouped_invoice_id = invoicesData._id;
            }

            await conversionService
                .syncInvoices(params)
                .then(async (response) => {
                    if (response.data.status === 203) {
                        setIsLoading(false);
                        endSync();
                        setOpenHeavy(true);
                    } else {
                        let params = { sync_id: response.data };
                        await handleHistSync(params);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
    };

    const handleClickEditContact = (event, name) => {
        event.stopPropagation();
        setContactXeroUpdated(name);
        setExtContactName(invoiceData.conversion_type === 1 ? invoiceData.final_customer_name : invoiceData.final_vendor_name);
        setOpenModalEditContact(true);
    };

    const endSync = () => {
        setStatus(0);
        handleGoBack();
    };

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setOpenModalEditContact(false);
        setContactXeroUpdated("");
    };

    const handleSync = async () => {
        setIsLoading(true);
        // Sincronizamos las facturas
        const params = {
            invoices: readyToSync.concat(toReview).map((item) => {
                return {
                    _id: item._id,
                    ContactXeroID: item.contactXeroID !== "first" ? item.contactXeroID : undefined,
                    ContactXeroName: item.conversion_type === 1 ? item.final_customer_name : item.final_vendor_name,
                    currency: item.final_currency,
                    currency_rate: item.final_currency_rate === "" ? "1" : item.final_currency_rate,
                    invoice_date: item.final_date,
                    line_items: JSON.stringify(item.line_items),
                }
            }),
        };

        await conversionService
            .syncInvoices(params)
            .then(async (response) => {
                let params = { sync_id: response.data };
                await handleHistSync(params);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
        setShowModalLeftSync(false);
    };

    const handleInvoice = (invoice) => {
        if (invoiceData._id !== invoice._id) {
            setInvoiceData(invoice);
            setContactsXero([
                { name: invoice.conversion_type === 1 ? invoice.final_customer_name : invoice.final_vendor_name, value: "first" },
                ...conversionContacts,
            ]);
            setSelectedContact({
                value: invoice.contactXeroID ?? "first",
                name: invoice.conversion_type === 1 ? invoice.final_customer_name : invoice.final_vendor_name,
            });
            setSelectedCurrency(invoice.final_currency ?? "")
            setSelectedRate(invoice.final_currency_rate ?? "")
            if (invoice?.final_date) {
                const dateF = moment.utc(invoice.final_date)
                setSelectedInvoiceDate(dateF);
            } else {
                setSelectedInvoiceDate(null);
            }
            
        }
    };

    const handleChangeTab = async (event, newValue) => {
        setValueTab(newValue);

        let newData = {};

        if (newValue === 0) {
            newData = toBeCompleted[0] ?? {};
        }
        if (newValue === 1) {
            newData = toReview[0] ?? {};
        }
        if (newValue === 2) {
            newData = readyToSync[0] ?? {};
        }

        setInvoiceData(newData);
    };

    const handleConfirmUpdateXeroContact = () => {
        const finalRows = invoices.map((aux) => {
            const invoiceCopy = {
                ...aux,
                line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
            };

            if (invoiceCopy._id === invoiceData._id) {
                invoiceCopy.contactXeroID = "first";
                if (invoiceData.conversion_type === 1) {
                    invoiceCopy.final_customer_name = contactXeroUpdated;
                } else {
                    invoiceCopy.final_vendor_name = contactXeroUpdated;
                }

            }
            return invoiceCopy;
        });
        setInvoices(finalRows);
        setContactsXero(
            contactsXero.map((item) => {
                if (item.value === "first") {
                    item.name = contactXeroUpdated;
                    item.new = true;
                }
                return item;
            })
        );
        setSelectedContact({
            value: "first",
            name: contactXeroUpdated,
        });
        setOpenModalEditContact(false);
    };

    // const handleOpenWarnings = () => setWarningMod(true);

    const handleCloseWarnings = () => setWarningMod(false);

    const dialogContactButtons = (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                    color="primary"
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                >
                    {t("team.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmUpdateXeroContact}
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                    disabled={!contactXeroUpdated || contactXeroUpdated === ""}
                >
                    {t("subscription.confirm")}
                </Button>
            </Grid>
        </Grid>
    );

    const handleChangeCurrency = async (event) => {
        setSelectedCurrency(event.target.value);
        if (event.target.value === systemCurrency) {
            setSelectedRate(invoiceData?.exchange_rate ?? invoiceData?.final_currency_rate ?? "");
            const updatedInvoices = invoices.map((aux) => {
                const invoiceCopy = {
                    ...aux,
                    line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
                };

                if (invoiceCopy._id === invoiceData._id) {
                    invoiceCopy.final_currency = event.target.value;
                    invoiceCopy.final_currency_rate = 1;
                }
                return invoiceCopy;
            });

            setInvoices(updatedInvoices);
            setInvoiceData({
                ...invoiceData,
                final_currency: event.target.value,
                final_currency_rate: 1
            });
        } else {
            await conversionService
                .getXECurrencyRate({
                    from: event.target.value,
                    to: systemCurrency,
                    date: moment.utc(invoiceData.formatted_invoice_date).format("YYYY-MM-DD"),
                })
                .then((data) => {
                    let newRate = data.amount.toString();
                    newRate = newRate.includes(".") ? parseFloat(newRate).toFixed(4) : newRate
                    setSelectedRate(newRate.includes(".") ? parseFloat(newRate).toFixed(4) : newRate);
                    const updatedInvoices = invoices.map((aux) => {
                        const invoiceCopy = {
                            ...aux,
                            line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
                        };
        
                        if (invoiceCopy._id === invoiceData._id) {
                            invoiceCopy.final_currency = event.target.value;
                            invoiceCopy.final_currency_rate = newRate;
                        }
                        return invoiceCopy;
                    });
        
                    setInvoices(updatedInvoices);
                    setInvoiceData({
                        ...invoiceData,
                        final_currency: event.target.value,
                        final_currency_rate: newRate
                    });

                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeRate = (value) => {
        // Permitir solo números y un punto decimal
        if (/^\d*\.?\d*$/.test(value) || value === "") {
            setSelectedRate(value);
            const updatedInvoices = invoices.map((aux) => {
                const invoiceCopy = {
                    ...aux,
                    line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
                };

                if (invoiceCopy._id === invoiceData._id) {
                    invoiceCopy.final_currency_rate = value;
                }
                return invoiceCopy;
            });

            setInvoices(updatedInvoices);
            setInvoiceData({
                ...invoiceData,
                final_currency_rate: value
            });
        }
    };

    const formatAmount = (amount) => {
        return parseFloat(amount).toLocaleString(todos.amountFormat, { maximumFractionDigits: 2 });
    };

    const printSubtotalCalc = (data) => {
        return formatAmount(
            data?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcSubtotal(obj), 0) ?? 0
        );
    };

    const printTaxCalc = (data) => {
        return formatAmount(data?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTax(obj), 0) ?? 0);
    };

    const printTotalCalc = (data) => {
        return formatAmount(data?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTotal(obj), 0) ?? 0);
    };
    // console.log(invoiceData.final_currency)
    // console.log(allCurrencies)

    const handleChangeInvoiceDate = async (event) => {
        setSelectedInvoiceDate(event)
        const updatedInvoices = invoices.map((aux) => {
            const invoiceCopy = {
                ...aux,
                line_items: aux.line_items ? [...aux.line_items.map((line) => ({ ...line }))] : [],
            };

            if (invoiceCopy._id === invoiceData._id) {
                invoiceCopy.final_date = event.format("YYYY-MM-DD");
            }
            return invoiceCopy;
        });

        setInvoices(updatedInvoices);
        setInvoiceData({
            ...invoiceData,
            final_date: event.format("YYYY-MM-DD"),
            line_items: updatedInvoices.find((inv) => inv._id === invoiceData._id).line_items,
        });
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: status === 0 ? "#F6F4FD" : status === 1 || status === 2 ? "#DBF4E5" : "#FFD8D5",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    {status === 0 ? (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">
                                    {replaceInvBill(
                                        t(
                                            filteredCurrentCompany?.system_integration === 1
                                                ? "converter.syncXeroInvoice"
                                                : "converter.syncQBOInvoice"
                                        )
                                    )}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                        width: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                    }}
                                    src={filteredCurrentCompany?.system_integration === 1 ? XeroLogo : QuickBooksLogo}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <BaseButton variantType="primaryOutlined" endIcon={<DownloadIcon />} onClick={downloadInv}>
                                    {t("payment.download")}
                                </BaseButton>
                                <LightTooltip
                                    title={
                                        readyToSync.length + toReview.length === 0 ? t("converter.disabledSync") : ""
                                    }
                                >
                                    <span>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            endIcon={<SyncIcon />}
                                            onClick={syncInvoices}
                                            disabled={readyToSync.length + toReview.length === 0 ? true : false}
                                        >
                                            {t("converter.sync")}
                                        </Button>
                                    </span>
                                </LightTooltip>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">
                                    {status === 1 || status === 2
                                        ? t("converter.checksSyncSucc")
                                        : t("converter.bannerErr")}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                        width: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                    }}
                                    src={filteredCurrentCompany?.system_integration === 1 ? XeroLogo : QuickBooksLogo}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={4}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        backgroundColor: status !== 3 ? "#E4F7EB" : "#FFE1E6",
                                        borderRadius: 10,
                                        px: 2,
                                        py: 1,
                                    }}
                                >
                                    {status === 1 ? (
                                        <CheckIcon color="success" />
                                    ) : status === 2 ? (
                                        <ErrorIcon color="warning" />
                                    ) : (
                                        <CloseIcon color="error" />
                                    )}
                                    <Typography>
                                        {status === 1
                                            ? t("converter.bannerCheck")
                                            : status === 2
                                            ? t("converter.bannerWarning")
                                            : t("converter.bannerError")}
                                    </Typography>
                                </Stack>
                                <BaseButton sizebutton="m" disableElevation onClick={endSync}>
                                    {t("dialog.finally")}
                                </BaseButton>
                            </Stack>
                        </>
                    )}
                </Stack>
                {currentInvoicesOriginal.length > 1 && (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                            <b>{currentInvoicesOriginal.length}</b> {replaceInvBill(t("converter.totalInvo"))}
                        </Typography>
                        <Box>|</Box>
                        <Typography sx={{ color: "#00943B", fontWeight: 600 }}>
                            {readyToSync.length + toReview.length}
                            {replaceInvBill(t("converter.invReadyToSync"))}
                        </Typography>
                    </Stack>
                )}
            </Box>
            <Box sx={greyBoxStyles}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h1">{replaceInvBill(t("converter.invSync"))}</Typography>
                    {currentInvoicesOriginal.length > 1 && (
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography>{replaceInvBill(t("converter.selectAccAllInv"))}:</Typography>
                            <FormControl variant="filled" sx={{ width: 300 }}>
                                <InputLabel
                                    id="simple-select-label-all"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#361A52",
                                    }}
                                >
                                    {t("accounts.accountNum")}
                                </InputLabel>
                                <Select
                                    labelId="simple-select-label-all"
                                    name="accXero"
                                    value={selectedAllAcc}
                                    onChange={handleChangeSelectAcc}
                                    size="small"
                                    MenuProps={MenuProps}
                                >
                                    {accountsXero.map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Button
                                variant="outlined"
                                sx={{ backgroundColor: "white", px: 4, py: 1 }}
                                startIcon={<FilterAltIcon />}
                            >
                                {t("dashboard.filters")}
                            </Button> */}
                        </Stack>
                    )}
                </Stack>
                {currentInvoicesOriginal.length >= 1 && (
                    <Tabs
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                        aria-label="Horizontal tabs"
                        sx={{
                            p: 1,
                            mt: 2,
                        }}
                        TabIndicatorProps={{
                            style: { display: "none" },
                        }}
                        value={valueTab}
                        onChange={handleChangeTab}
                    >
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 0 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toComplete")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {toBeCompleted.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(0)}
                            sx={TabProps}
                        />
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 1 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toReview")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {toReview.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(1)}
                            sx={TabProps}
                        />
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 2 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toBeSynch")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {readyToSync.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(2)}
                            sx={TabProps}
                        />
                    </Tabs>
                )}
                <TabPanel value={valueTab} index={0}>
                    <InvoiceSlider
                        invoices={toBeCompleted}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon={
                            readyToSync.length === 1 && readyToSync.find((e) => e._id === invoiceData._id)
                                ? "check"
                                : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                ? "warning"
                                : "alert"
                        }
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    <InvoiceSlider
                        invoices={toReview}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon="warning"
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                    <InvoiceSlider
                        invoices={readyToSync}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon="check"
                    />
                </TabPanel>
            </Box>
            <Box sx={greyBoxStyles}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item={12} md={5}>
                        <Stack direction="row" alignItems="center" spacing={0.5} mb={0}>
                            <Typography variant="h1">
                                {replaceInvBill(t("payment.invoice"))}{" "}
                                <span style={{ color: "#4a22d4" }}>{invoiceData.invoice_id}</span>
                            </Typography>
                            {Object.keys(invoiceData).length > 0 && (
                                <LightTooltip title={t("converter.tooltipReturnInv")}>
                                    <>
                                        <IconButton
                                            edge="end"
                                            aria-label="archive"
                                            onClick={() => returnInvoiceModal(invoiceData._id)}
                                            sx={{ p: 0.3 }}
                                        >
                                            <DeleteIcon sx={{ fontSize: "22px" }} color="primary" />
                                        </IconButton>
                                    </>
                                </LightTooltip>
                            )}
                            {/* <Button
                                variant="outlined"
                                endIcon={<WarningIcon htmlColor="#ffb23f" />}
                                onClick={handleOpenWarnings}
                            >
                                {t("converter.warnings")}
                            </Button> */}
                        </Stack>
                        <Typography sx={{ width: 350 }} gutterBottom mb={3}>
                            <b>{t("converter.contactExt")}:</b>{" "}
                            {invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Autocomplete
                                PopperComponent={StyledPopper}
                                sx={{ minWidth: "40%" }}
                                options={contactsXero}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                value={selectedContact}
                                onChange={(event, newValue, reason) => {
                                    if (reason === 'clear') {
                                        handleClearContact()
                                    } else {
                                        handleChangeSelectContact(newValue)
                                    }
                                }}
                                renderOption={(props, option, { index }) => {
                                    const { key, ...optionProps } = props;
                                    if (option.value === "first") {
                                        return (
                                            <Box
                                                key={key + index}
                                                component="li"
                                                {...optionProps}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <Stack direction="column">
                                                        <Typography fontWeight={600} color="primary">
                                                            {t("converter.edit")} {option.name}
                                                        </Typography>
                                                        <Typography fontSize={11} lineHeight={1} color="#131f3e">
                                                            {t("converter.editContactMsg")}
                                                        </Typography>
                                                    </Stack>
                                                    <IconButton
                                                        onClick={(event) => handleClickEditContact(event, option.name)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        );
                                    } else {
                                        return (
                                            <Box
                                                key={key + index}
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                {...optionProps}
                                            >
                                                <Typography color="#131f3e">{option.name}</Typography>
                                            </Box>
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            <Typography fontWeight={600} color="#361A52">
                                                {selectedContact?.value === "first"
                                                    ? t("converter.createContact")
                                                    : t("converter.chooseContact")
                                                }
                                            </Typography>
                                        }
                                        variant="filled"
                                        size="small"
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    if (!value || Object.keys(value).length === 0) return false;
                                    return option.value === value.value;
                                }}
                                noOptionsText={
                                    <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                        <Typography fontWeight={600} fontSize={13} mt={0.5} color="#131f3e">
                                            {t("converter.noContact")}
                                        </Typography>
                                    </Box>
                                }
                                disabled={Object.keys(invoiceData).length === 0}
                            />
                            {getContactIcon(invoiceData.conversion_type === 1 ? invoiceData.final_customer_name : invoiceData.final_vendor_name, conversionContacts, contactsXero, selectedContact, t)}
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                                adapterLocale={t("language.locale")}
                                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                            >
                                <DatePicker
                                    label={
                                        <Typography
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("converter.invoiceDate")}
                                        </Typography>
                                    }
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            style: { minWidth: "35%" },
                                            variant: "filled",
                                            readOnly: true,
                                        },
                                    }}
                                    value={selectedInvoiceDate}
                                    onChange={handleChangeInvoiceDate}
                                    format={todos.dateFormat} // Usa el formato dinámico
                                    disabled={Object.keys(invoiceData).length === 0}
                                />
                            </LocalizationProvider>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2} mt={2}>
                            <FormControl variant="filled" sx={{ minWidth: "40%" }} size="small" >
                                <InputLabel id={"customized-base-select-label"} sx={{ color: "#131f3e" }}>
                                    {t("dashboard.currency")}
                                </InputLabel>
                                <Select
                                    labelId={"customized-base-select-label"}
                                    id={"customized-base-select"}
                                    value={selectedCurrency || ""}
                                    onChange={handleChangeCurrency}
                                    MenuProps={MenuProps}
                                    disabled={Object.keys(invoiceData).length === 0}
                                >
                                    <Typography variant="h2" pl={1} gutterBottom>
                                        {t("converter.kiiperSug")}
                                    </Typography>
                                    {allCurrencies.filter((item) => item.setup).length === 0 ? (
                                        <MenuItem disabled value="">
                                            <em>{t("converter.noCurreConf")}</em>
                                        </MenuItem>
                                    ) : (
                                        allCurrencies
                                            .filter((item) => item.setup)
                                            .map((val) => (
                                                <MenuItem key={val.id} value={val.id}>
                                                    {val.name}
                                                </MenuItem>
                                            ))
                                    )}
                                    <Divider />
                                    <Typography variant="h2" pl={1} gutterBottom>
                                        {t("converter.otherCoins")}
                                    </Typography>
                                    {allCurrencies
                                        .filter((item) => !item.setup)
                                        .map((val) => (
                                            <MenuItem key={val.id} value={val.id}>
                                                {val.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <CustomTextField
                                sx={{ minWidth: "40%" }}
                                size="small"
                                label={t("converter.rate")}
                                name="rate"
                                value={selectedRate}
                                onChange={(e) => handleChangeRate(e.target.value)}
                                onBlur={() => {
                                    const formattedRate = selectedRate?.includes('.') ? parseFloat(selectedRate).toFixed(4) : selectedRate;
                                    handleChangeRate(formattedRate);
                                }}
                                variant="filled"
                                inputProps={{
                                    fontStyle: "normal",
                                }}
                                type="text"
                                disabled={selectedRate === "" || selectedCurrency === systemCurrency || Object.keys(invoiceData).length === 0}
                            />
                        </Stack>
                    </Grid>
                    <Grid item={12} md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} alignItems="center" display="flex" justifyContent="end">
                                <Typography variant="h3" textAlign="end">
                                    {t("converter.extracted")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.subtotalExt")}</Typography>
                                    <Typography>{formatAmount(invoiceData.formatted_sub_total ?? 0)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.totalImpExt")}</Typography>
                                    <Typography>{formatAmount(invoiceData.formatted_total_tax ?? 0)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor:
                                            valueTab === 2 ||
                                            (isEqual && readyToSync.find((e) => e._id === invoiceData._id))
                                                ? "#DBF4E5"
                                                : valueTab === 1 ||
                                                  (!isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id))
                                                ? "CIT020"
                                                : "#FFD8DE",
                                        p: 2,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h3">{t("converter.totalExt")}</Typography>
                                        <LightTooltip
                                            title={
                                                readyToSync.length > 0 &&
                                                readyToSync.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.readySyncLines")
                                                    : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.amountLines")
                                                    : t("converter.pendingLines")
                                            }
                                        >
                                            <InfoIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="primary"
                                            />
                                        </LightTooltip>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>
                                            {formatAmount(invoiceData.formatted_invoice_total ?? 0)}
                                        </Typography>
                                        {readyToSync.length > 0 &&
                                        readyToSync.find((e) => e._id === invoiceData._id) ? (
                                            <CheckIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="success"
                                            />
                                        ) : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id) ? (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"warning"}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"error"}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3} alignItems="center" display="flex" justifyContent="end">
                                <Typography variant="h3" textAlign="end">
                                    {t("converter.calculated")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.subtotalCalc")}</Typography>
                                    <Typography>
                                        {`${printSubtotalCalc(
                                            edited.length === 0 ? invoiceData.line_items : rowsAux
                                        )}`}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.totalImpCalc")}</Typography>
                                    <Typography>
                                        {`${printTaxCalc(edited.length === 0 ? invoiceData.line_items : rowsAux)}`}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor:
                                            valueTab === 2 ||
                                            (isEqual && readyToSync.find((e) => e._id === invoiceData._id))
                                                ? "#DBF4E5"
                                                : valueTab === 1 ||
                                                  (!isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id))
                                                ? "CIT020"
                                                : "#FFD8DE",
                                        p: 2,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h3">{t("converter.totalCalc")}</Typography>
                                        <LightTooltip
                                            title={
                                                isEqual && readyToSync.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.readySyncLines")
                                                    : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.amountLines")
                                                    : t("converter.pendingLines")
                                            }
                                        >
                                            <InfoIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="primary"
                                            />
                                        </LightTooltip>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>{`${printTotalCalc(rowsAux)}`}</Typography>
                                        {readyToSync.length > 0 &&
                                        readyToSync.find((e) => e._id === invoiceData._id) ? (
                                            <CheckIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="success"
                                            />
                                        ) : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id) ? (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"warning"}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"error"}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <InvoicesSyncTable
                invoiceData={invoiceData}
                accountsXero={accountsXero}
                isGreaterExt={isGreaterExt}
                setOpenPreview={setOpenPreview}
                setIsLoading={setIsLoading}
                setFileData={setFileData}
                xeroTaxRates={xeroTaxRates}
                xeroItems={xeroItems}
                setInvoiceData={setInvoiceData}
                setInvoices={setInvoices}
                invoices={invoices}
                setBasePreviewData={setBasePreviewData}
                rowsAux={rowsAux}
                setRowsAux={setRowsAux}
                edited={edited}
                setEdited={setEdited}
                setNewRowDiff={setNewRowDiff}
                newRowDiff={newRowDiff}
                calcTotal={calcTotal}
                replaceInvBill={replaceInvBill}
                formatAmount={formatAmount}
                calcSubtotal={calcSubtotal}
                setTexto={setTexto}
                setAlertType={setAlertType}
                setShow={setShow}
                editGlobal={editGlobal}
                setEditGlobal={setEditGlobal}
                setFileNameFull={setFileNameFull}
            />
            <SimpleDialog
                open={showModalLeftSync}
                handleClose={handleCloseSyncModal}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" alignItems="center" sx={{ pb: 3 }} spacing={2}>
                            <WarningIcon sx={{ color: "#FFB23F", fontSize: 16 }} />
                            <Typography variant="h2" sx={{ color: "#FFB23F" }}>
                                {t("dialog.dialogSyncLeftTitle")}
                            </Typography>
                        </Stack>
                        <Typography sx={{ pb: 2 }}>{t("dialog.dialogSyncLeftText")}</Typography>
                        <Typography sx={{ pb: 3 }}>{t("dialog.dialogSyncLeftText2")}</Typography>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <Button onClick={handleCloseSyncModal}>{t("register.back")}</Button>
                            <BaseButton sizebutton="m" disableElevation onClick={handleSync}>
                                {t("dialog.continue")}
                            </BaseButton>
                        </Stack>
                    </Box>
                }
            />
            <FormDialog
                open={openModalEditContact}
                handleClose={handleCloseDialog}
                maxWidth="sm"
                title={
                    <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                        <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                        <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                            {t("converter.dialogContactXero")}
                        </Typography>
                    </Stack>
                }
                content={
                    <EditContactChecks
                        extContactName={extContactName}
                        contactXeroUpdated={contactXeroUpdated}
                        setContactXeroUpdated={setContactXeroUpdated}
                    />
                }
                actions={dialogContactButtons}
                align="center"
            />
            <SimpleDialog
                open={warningMod}
                handleClose={handleCloseWarnings}
                maxWidth="md"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pb: 3 }} spacing={2}>
                            <WarningIcon htmlColor="#ffb23f" />
                            <Typography variant="h1" sx={{ color: "#FFB23F" }}>
                                {t("converter.warnings")}
                            </Typography>
                        </Stack>
                        <Typography>{t("converter.warntitle1")}</Typography>
                        <Typography pb={2}>{t("converter.warntitle2")}</Typography>
                        <Typography color="primary" fontWeight={600}>
                            {t("converter.warntext1")}
                        </Typography>
                        <Typography pb={2}>{t("converter.warntext2")}</Typography>
                    </Box>
                }
            />
        </>
    );
};

export default InvoicesSync;
