import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MenuItem, styled } from "@mui/material";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const MenuProps2 = {
    PaperProps: {
        style: {
            maxHeight: 500,
        },
    },
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: theme.palette.V080,     // Color por defecto
    fontWeight: 600,               // Peso de la fuente por defecto

    "&.Mui-focused": {
        color: theme.palette.V090, // Color cuando el label está enfocado
    },
}));

// Estilo personalizado para el Select
const StyledSelect = styled(Select)(({ theme }) => ({
    // Fondo blanco para el contenedor principal
    backgroundColor: "white",

    // "& .MuiOutlinedInput-root": {
    //     height: isSearch ? 42 : 48, // Ajusta la altura del contenedor del input
    // },

    // Aplica el color del borde por defecto
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.V050,
    },

    // Aplica el color del borde en hover
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.V090,
    },

    // Aplica el color del borde en focus
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.V090,
    },

}));


export default function BaseSelect2(props) {
    const { children, customStyle, id, value, onChange, values, label, error, type, disabled, hasTooltip, language, name, required, idName, valueName, shrink, isSearch = false, multiple, renderValue, sx, ...rest } = props;

    return (
        <FormControl fullWidth error={error} disabled={disabled} required={required} sx={{ height: isSearch ? 42 : 48, width: customStyle ? 200 : "100%" }}>
            <StyledInputLabel id={"customized-base-select-label" + id} shrink={shrink}>
                {label}
            </StyledInputLabel>
            <StyledSelect
                {...rest}
                labelId={"customized-base-select-label" + id}
                id={"customized-base-select" + id}
                value={value}
                onChange={onChange}
                name={name}
                MenuProps={customStyle ? MenuProps2 : MenuProps}
                label={label}
                sx={{ height: isSearch ? 42 : 48, ...sx }}  // Establecer la altura del Select
                variant="outlined" // Asegúrate de usar la variante "outlined"
                multiple={multiple}
                renderValue={renderValue}
            >
                {children ? children : 
                    values.map((item) => (
                        <MenuItem key={item[valueName] + "_" + item[idName]} value={item[idName]} sx={{display: item[idName] === "hidden" ? "none" : "block"}}>
                            {item[valueName]}
                        </MenuItem>
                    ))
                }
            </StyledSelect>
        </FormControl>
    );
}
