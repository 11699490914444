/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI icons */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Loading from "../../../assets/loading.png";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import checksService from "../../../services/checks";
import TableChartIcon from "@mui/icons-material/TableChart";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ChatIcon from "@mui/icons-material/Chat";

/** MUI imports */
import {
    Stack,
    Typography,
    Skeleton,
    ButtonGroup,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Grid,
    InputAdornment,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TablePagination,
    Popover,
    FormControlLabel,
    Switch,
    Checkbox,
} from "@mui/material";
// import ChatModal from "../../ui/Chat";

import LightTooltip from "../../ui/LightTooltip";
import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";
import BaseSnackbar from "../../ui/BaseSnackbar";

import { useDispatch, useSelector } from "react-redux";
import { addChangeRoute, addTutorial } from "../../../redux/userSlice";

import FiltersButtonInvoices from "../FiltersButtonInvoices";

import { fetchFileFromS3, zeroPad } from "../../../utils/utils";
// import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import invoicesService from "../../../services/invoices";
// import casesService from "../../../services/case";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import BaseButton from "../../ui/BaseButton";
import CustTextField from "../../ui/CustTextField";
import NoddokTableCell from "../../ui/NoddokTableCell";
import NoddokTableRow from "../../ui/NoddokTableRow";
import NoddokTableRowHeader from "../../ui/NoddokTableRowHeader";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        uploadDate: <Skeleton variant="text" animation="wave" />,
        invoiceNumber: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        invoiceDate: <Skeleton variant="text" animation="wave" />,
        reference: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        synchronized: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

const InvoicesTable = (props) => {
    const {
        conversions,
        usersFilters,
        company_id,
        setConversions,
        franchise_id,
        grupal,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        setFilters,
        filters,
        tutorial,
        setCorrelative,
        setConversionId,
        setFileNameSaved,
        setFileData,
        setFileNameFull,
        setConversionData,
        download,
        setDownload,
        setGroupConversions,
        groupConversions,
        setOpenModalGroupedHist,
        setGroupedData,
        setGroupedPeriods,
        setOpenModalGrouped,
        setCorrelativeModalGrouped,
        value,
        setModalType,
        columns,
        setRowsModal,
        setOpenPreview,
        conversion_type,
        setBasePreviewData,
        setShowNewInv,
        type,
        setAllConversions,
        setSelectedInv,
        setConversionContacts,
    } = props;

    const [t] = useTranslation("global");
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchState] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [rows, setRows] = useState([]);
    const [rowsOG, setRowsOG] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [room, setRoom] = useState(null);
    // const [open, setOpen] = useState(false);
    // const [chat, setChat] = useState([]);
    // const [users, setUsers] = useState([]);
    // const [status, setStatus] = useState();
    // const [caseInfo, setCaseInfo] = useState({});
    const [openBackdrop, setOpenBackdrop] = useState(false);

    //Buttom group
    const [openBtnGroupSend, setOpenBtnGroupSend] = useState(false);
    const [openBtnColumnsEdit, setOpenBtnColumnsEdit] = useState(false);
    const anchorRefBtnGroup = useRef(null);
    const anchorRefBtnColumns = useRef(null);
    const [selectedIndexBtnGroup, setSelectedIndexBtnGroup] = useState(null);
    const [checkedCol, setCheckedCol] = useState([true, true, true, true, true, true, true, true, true, true]);
    const [columnsTable, setColumnsTable] = useState([]);
    const [showGrouped, setShowGrouped] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setPage(0);
        setRowsPerPage(10);
        setSelected([]);
    }, [conversion_type]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleChangeSwitch = (event) => {
        setShowGrouped(event.target.checked);
    };

    const handleChangeCheck = (event, index) => {
        const newCheckeds = checkedCol.map((item, i) => {
            if (index === i) {
                item = event.target.checked;
            }
            return item;
        });
        setCheckedCol(newCheckeds);
        setColumnsTable(columns.filter((item, index) => newCheckeds[index]));
    };
    useEffect(() => {
        setSearchState("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversion_type]);

    useEffect(() => {
        setColumnsTable(columns);
    }, [columns]);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchValue = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchState(query);

        if (!event.target.value) {
            setRows(rowsOG);
        } else {
            // Filtrar filas basadas en cualquier campo
            const filterCases = rows.filter((row) =>
                Object.values(row).some(
                    (value) => value !== null && value !== undefined && value.toString().toLowerCase().includes(query)
                )
            );
            setRows(filterCases);
        }
    };

    const handleShowPrev = async (invoice, isGrouped) => {
        setOpenBackdrop(true);
        let converInfo = {};

        setSelectedInv({
            id: invoice.id,
            isGrouped: isGrouped,
        });

        if (isGrouped) {
            let params2 = { grouped_invoice_id: invoice.id };
            await invoicesService
                .getGroupedInvoiceDetails(params2)
                .then(async (data) => {
                    const newData = data.groupedInvoice;
                    let statusSucc = 0;
                    let statusRev = 0;
                    let statusConv = 0;
                    let statusHeavy = 0;
                    let finalStatus = 0;

                    for (const value of data.groupedInvoice.invoices) {
                        if (value.id_status === 9) {
                            statusSucc++;
                        } else if (value.id_status === 2) {
                            statusRev++;
                        } else if (value.id_status === 1) {
                            statusHeavy++;
                        } else if (value.id_status === 3) {
                            statusConv++;
                        }
                    }

                    if (statusSucc > 0) {
                        finalStatus = 9;
                    } else if (statusRev > 0) {
                        finalStatus = 2;
                    } else if (statusHeavy > 0) {
                        finalStatus = 1;
                    } else if (statusConv > 0) {
                        finalStatus = 3;
                    } else {
                        finalStatus = 10;
                    }
                    newData.id_status = finalStatus;

                    setConversionData(newData);
                    setGroupedData(newData);
                    converInfo = newData;
                    setFileNameSaved("GROUP-" + zeroPad(newData.correlative ?? 0, 5));
                    const filteredConv = newData.invoices.filter(
                        (item) =>
                            item.id_status === 3 ||
                            item.id_status === 4 ||
                            item.id_status === 5 ||
                            item.id_status === 6 ||
                            item.id_status === 9
                    );

                    // Ordena el arreglo por la fecha
                    const sortedFilteredConv = filteredConv.sort((a, b) => {
                        const dateA = moment.utc(a.final_invoice_date);
                        const dateB = moment.utc(b.final_invoice_date);
                        return dateA - dateB; // Orden ascendente (para descendente usa `dateB - dateA`)
                    });
                    setGroupedPeriods(sortedFilteredConv);
                    setConversionContacts(
                        data.contactsXero.map((item) => ({
                            value: item.ContactID,
                            name: item.Name,
                            contact_tax_number: item.contact_tax_number,
                            idSys: item.contact_id_system,
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setGroupedData(null);
            let params2 = { invoice_id: invoice.id, conversion_type: conversion_type };
            await invoicesService
                .getAIInvoiceDetails(params2)
                .then((data) => {
                    setConversionData(data.invoice);
                    converInfo = data.invoice;
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (!isGrouped) {
            setCorrelative(converInfo?.correlative);
            setConversionId(converInfo?._id);
            setFileNameSaved(converInfo?.uploaded_file?.replace(/\.[^/.]+$/, ""));

            const data = await fetchFileFromS3(converInfo?.file_name);

            if (!data) {
                setTexto(t("converter.dnldError"));
                setAlertType("error");
                setShow(true);
            } else {
                setFileData(data);
            }
            setFileNameFull(converInfo?.uploaded_file);

            setBasePreviewData({
                id: converInfo?._id,
                type: "invoice",
            });
        } else {
            setCorrelative(converInfo.correlative);

            const data = await fetchFileFromS3(converInfo.invoices[0].file_name);

            if (!data) {
                setTexto(t("converter.dnldError"));
                setAlertType("error");
                setShow(true);
            } else {
                setFileData(data);
            }
            setFileNameFull(converInfo.invoices[0].uploaded_file);

            setBasePreviewData({
                id: converInfo.invoices[0]._id,
                type: "invoice",
            });
        }

        dispatch(addChangeRoute(""));

        setShowNewInv(true);
        setOpenBackdrop(false);
    };

    useEffect(() => {
        if ((todos.steps.step8 || todos.steps.step9) && tutorial && download && !todos.dontShowAgain) {
            let number = "";

            if (todos.steps.step8) {
                number = "8";
            } else {
                number = "9";
            }
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} ${number}`,
                                element: "#account8-tutorial",
                                intro: t("miscellaneous.tutorialConv5"),
                            },
                        ],
                    })
                    .onexit(() => {
                        if (todos.steps.step8) {
                            dispatch(addTutorial({ ...todos.steps, step8: false }));
                        } else {
                            dispatch(addTutorial({ ...todos.steps, step9: false }));
                        }
                        setDownload(false);
                    })
                    .start();
            }, 1000);
        }
    }, [dispatch, download, setDownload, t, todos.dontShowAgain, todos.steps, tutorial]);

    useEffect(() => {
        // const connectSocket = async (case_id, correlative, status) => {
        //     setRoom(case_id);
        //     initiateSocket(case_id, todos.userInfo, "invoice", correlative);
        //     subscribeToChat((err, data) => {
        //         if (err) return;
        //         setChat((oldChats) => [...oldChats, data]);
        //     });
        //     newUserResponse((err, data) => {
        //         if (err) return;
        //         setUsers(data);
        //     });
        //     setOpen(true);
        // };

        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            // const handleClickChat = async (statusConv, conversionID, correlative) => {
            //     setIsLoading(true);

            //     let params = {
            //         company_id: todos.companyID,
            //         franchise_id: todos.franchiseID,
            //         user_email: todos.userInfo.email,
            //         type: "conversion",
            //         conversion_id: conversionID,
            //         conversion_type: "invoice",
            //     };

            //     let caseInfo = null;

            //     await casesService
            //         .getCaseInfo(params)
            //         .then(async (response) => {
            //             if (response) {
            //                 setCaseInfo(response.caseInfo);
            //                 caseInfo = response.caseInfo;
            //                 setStatus("");
            //             }
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });

            //     if (!caseInfo) {
            //         await casesService
            //             .createNewCase(params)
            //             .then(async () => {
            //                 await casesService
            //                     .getCaseInfo(params)
            //                     .then(async (data) => {
            //                         setCaseInfo(data.caseInfo);
            //                         caseInfo = data.caseInfo;
            //                         setStatus(1);
            //                     })
            //                     .catch((err) => {
            //                         console.log(err);
            //                     });
            //             })
            //             .catch((err) => {
            //                 console.log(err);
            //             });
            //     } else {
            //         setStatus(caseInfo.case_reason);
            //     }

            //     await connectSocket(caseInfo._id, correlative, statusConv);

            //     setIsLoading(false);
            // };

            // const showConversions = (index) => {
            //     setModalType("invoice");
            //     setOpenModalGroupedHist(true);
            //     setOpenModalGrouped(true);
            //     setCorrelativeModalGrouped(groupConversions[index]?.correlative);

            //     let newRows = groupConversions[index].conversions.map((item) => ({
            //         id: item._id,
            //         invoice: item.invoice_id ? item.invoice_id : "-",
            //         contact:
            //             parseInt(item.conversion_type) === 1 ? item.final_customer_name : item.final_vendor_name ?? "-",
            //         date: item.final_invoice_date ? moment.utc(item.final_invoice_date).format(todos.dateFormat) : "-",
            //         amount: item.formatted_invoice_total
            //             ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
            //                   minimumFractionDigits: 2,
            //               })
            //             : "-",
            //         number: item.correlative,
            //         file_name: item.uploaded_file,
            //         file_name_s3: item.file_name,
            //         period: "",
            //         status: item.id_status,
            //     }));
            //     setRowsModal(newRows);
            // };

            const previewInvoice = async (e, filename, id) => {
                e.stopPropagation();
                setIsLoading(true);

                const data = await fetchFileFromS3(filename);
                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                }

                setBasePreviewData({
                    id: id,
                    type: "invoice",
                });

                setFileNameFull(filename);
                setOpenPreview(true);

                setIsLoading(false);
            };

            if (conversions?.length > 0) {
                // 1: En proceso
                // 2: En revisión
                // 3: Por sincronizar
                // 4: Por descargar
                // 5: Sincronizando
                // 6: Sincronizado
                // 7: Registro manual
                // 8: Sincronizado parcial
                // 9: Descargado
                // 10: Inválida

                const truncateText = (text, maxLength = 15) => {
                    if (!text) return ""; // Manejo de texto nulo o indefinido
                    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
                };

                const newRows = conversions.map((item) => ({
                    id: item._id,
                    number:
                        // <BaseButton size="small" variantType="primaryText" onClick={() => showConversions(index)}>
                        item.correlative != null && item.correlative !== undefined ? zeroPad(item.correlative, 5) : "",
                    // </BaseButton>
                    uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                    invoiceNumber: item.final_invoice_id ? (
                        <LightTooltip title={item.final_invoice_id} position="bottom">
                            <div>
                                <BaseButton
                                    variantType="errorText"
                                    onClick={(e) => previewInvoice(e, item.file_name, item._id)}
                                    sizebutton="m"
                                >
                                    {truncateText(item.final_invoice_id)}
                                </BaseButton>
                            </div>
                        </LightTooltip>
                    ) : (
                        "-"
                    ),
                    amount: item.formatted_invoice_total
                        ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : "",
                    contact:
                        parseInt(item.conversion_type) === 1
                            ? "final_customer_name" in item
                                ? item.final_customer_name
                                : item.formatted_customer_name
                            : "final_vendor_name" in item
                            ? item.final_vendor_name
                            : item.formatted_vendor_name,
                    invoiceDate: !item.final_invoice_date
                        ? "N/A"
                        : moment.utc(item.final_invoice_date).format(todos.dateFormat),
                    user: item?.user_id?.user_name ?? "",
                    original_status: item.id_status,
                    grouped: false,
                    reference: item.final_control_number ? item.final_control_number : "-",
                    status: (
                        <Stack direction="row" alignItems="center">
                            {item.id_status === 1 && <img src={Loading} alt="loading" width={20} />}
                            {item.id_status === 2 && <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />}
                            {item.id_status === 3 && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                            {item.id_status === 4 && <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                            {item.id_status === 5 && <CheckIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                            {item.id_status === 6 && <UploadFileIcon sx={{ color: "#361A52" }} fontSize="inherit" />}
                            &nbsp;
                            <Typography>
                                {/* // 1: Borrador, 2: Por aprobar, 3: No aprobada, 4: Por pagar / Aprobada, 5: Pagada, 6: Recurrente */}
                                {item.id_status === 1
                                    ? t("dialog.draft")
                                    : item.id_status === 2
                                    ? t("miscellaneous.toApprove")
                                    : item.id_status === 3
                                    ? t("invoicing.notApproved")
                                    : item.id_status === 4
                                    ? t("invoicing.approved")
                                    : item.id_status === 5
                                    ? t("invoicing.paid")
                                    : t("plans.recu")}
                            </Typography>
                        </Stack>
                    ),
                    chat:
                        item.id_status === 1 || item.id_status === 3 ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {/* <LightTooltip title={t("converter.tooltipChat")} position="top">
                                    <IconButton
                                        onClick={() =>
                                            handleClickChat(
                                                item.id_status,
                                                item._id,
                                                `#${zeroPad(item.correlative, 5)}`
                                            )
                                        }
                                        size="small"
                                        color="primary"
                                    >
                                        <ChatIcon fontSize="small" />
                                    </IconButton>
                                </LightTooltip> */}
                                {/* {item.id_status === 3 && (
                                    <IconButton
                                        onClick={() => handleShowPrev(item, false)}
                                        size="small"
                                        color="primary"
                                        id={
                                            lastSuccess !== null && lastSuccess === item._id
                                                ? "account8-tutorial"
                                                : ""
                                        }
                                    >
                                        <VisibilityIcon color="primary" sx={{ fontSize: 20 }} />
                                    </IconButton>
                                )} */}
                            </Stack>
                        ) : item.id_status === 2 || item.id_status >= 4 ? (
                            <></>
                        ) : (
                            // <LightTooltip title={t("converter.seeDetails")}>
                            //     <IconButton
                            //         size="small"
                            //         onClick={() => handleShowPrev(item, false)}
                            //         color="primary"
                            //     >
                            //         <VisibilityIcon color="primary" sx={{ fontSize: 20 }} />
                            //     </IconButton>
                            // </LightTooltip>
                            ""
                        ),
                    synchronized: item?.synchronized ? (
                        <CheckIcon sx={{ color: "E100" }} fontSize="inherit" />
                    ) : (
                        <CloseIcon sx={{ color: "C100" }} fontSize="inherit" />
                    ),
                }));
                setRows(newRows);
                setRowsOG(newRows);
            } else {
                setRows([]);
                setRowsOG([]);
            }
        }
    }, [
        conversion_type,
        conversions,
        dispatch,
        groupConversions,
        setBasePreviewData,
        setConversionContacts,
        setConversionData,
        setConversionId,
        setCorrelative,
        setCorrelativeModalGrouped,
        setFileData,
        setFileNameFull,
        setFileNameSaved,
        setGroupedData,
        setGroupedPeriods,
        setIsLoading,
        setModalType,
        setOpenModalGrouped,
        setOpenModalGroupedHist,
        setOpenPreview,
        setRowsModal,
        setSelectedInv,
        setShowNewInv,
        showSkeleton,
        t,
        todos.amountFormat,
        todos.companyID,
        todos.dateFormat,
        todos.franchiseID,
        todos.userInfo,
    ]);

    const showEditColumns = () => {
        setOpenBtnColumnsEdit((prevOpenBtn) => !prevOpenBtn);
    };

    const downloadReport = async () => {
        setIsLoading(true);

        let fileName = `InvoiceHistory-${moment().format("MMDDYYYY")}.csv`;
        let conversionIds = rows?.map((e) => e.id);

        if (!showSkeleton && conversionIds?.join(",") !== "0,1,2,3,4,5,6,7,8,9") {
            conversionIds = conversionIds?.length > 0 ? conversionIds.join(",") : "";

            await checksService
                .downloadHistoryInvoices({
                    company_id: todos.companyID,
                    file_name: fileName,
                    conversionIds: conversionIds,
                })
                .then((response) => {
                    // Crea un enlace temporal y haz clic en él para iniciar la descarga
                    const link = document.createElement("a");
                    link.href = response.url;
                    document.body.appendChild(link);
                    link.click();

                    // Limpia el enlace temporal
                    document.body.removeChild(link);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setIsLoading(false);
    };

    const handleClick = async () => {
        setShowNewInv(true);
    };

    const handleClickCheck = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const optionsGroupSend = [
        { data: t("converter.createInvRec"), id: 0 },
        { data: t("converter.createCredNote"), id: 1 },
    ];

    const handleCloseBtnGroup = (event) => {
        if (anchorRefBtnGroup.current && anchorRefBtnGroup.current.contains(event.target)) {
            return;
        }
        if (anchorRefBtnColumns.current && anchorRefBtnColumns.current.contains(event.target)) {
            return;
        }

        setOpenBtnColumnsEdit(false);
        setOpenBtnGroupSend(false);
    };

    const handleMenuItemClickBtnGroup = (event, index) => {
        setSelectedIndexBtnGroup(index);
        setOpenBtnGroupSend(false);
        handleClick(index);
    };

    const handleToggleBtnGroup = () => {
        setOpenBtnGroupSend((prevOpenBtnGroupSend) => !prevOpenBtnGroupSend);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    return (
        <>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <ButtonGroup
                            disableElevation
                            variant="outlined"
                            ref={anchorRefBtnGroup}
                            aria-label="split button"
                            color="inherit"
                        >
                            <BaseButton
                                disableElevation
                                variant="contained"
                                color="primary"
                                sx={{ mr: 0.3 }}
                                sizebutton="m"
                                onClick={handleClick}
                            >
                                {t("converter.newInvoice")}
                            </BaseButton>
                            <BaseButton
                                size="small"
                                aria-controls={openBtnGroupSend ? "split-button-menu" : undefined}
                                aria-expanded={openBtnGroupSend ? "true" : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={handleToggleBtnGroup}
                            >
                                <ArrowDropDownIcon />
                            </BaseButton>
                        </ButtonGroup>
                        <Popover
                            open={openBtnGroupSend}
                            anchorEl={anchorRefBtnGroup.current}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <ClickAwayListener onClickAway={handleCloseBtnGroup}>
                                <Paper style={{ minWidth: 200 }}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {optionsGroupSend.map(({ id, data }, index) => (
                                            <MenuItem
                                                key={"convertOption_" + id}
                                                selected={index === selectedIndexBtnGroup}
                                                onClick={(event) => handleMenuItemClickBtnGroup(event, index)}
                                                sx={{ color: "#361A52" }}
                                            >
                                                {data}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Paper>
                            </ClickAwayListener>
                        </Popover>
                        {type === 1 && (
                            <BaseButton sizebutton="m" disableElevation>
                                {t("converter.aprove")}
                            </BaseButton>
                        )}
                        {type === 2 && (
                            <BaseButton sizebutton="m" disableElevation>
                                {t("converter.send")}
                            </BaseButton>
                        )}
                    </Stack>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <BaseButton sizebutton="icon" variantType="primaryOutlined" onClick={downloadReport}>
                            <DownloadIcon fontSize="small" />
                        </BaseButton>
                        <BaseButton
                            sizebutton="icon"
                            anchorRefBtnColumns={anchorRefBtnColumns}
                            variantType="primaryOutlined"
                            onClick={showEditColumns}
                        >
                            <TableChartIcon fontSize="small" />
                        </BaseButton>
                        <FiltersButtonInvoices
                            users={usersFilters}
                            company={company_id}
                            setConversions={setConversions}
                            setShowSkeleton={setShowSkeleton}
                            franchise={franchise_id}
                            grupal={grupal}
                            setFilters={setFilters}
                            filters={filters}
                            setGroupConversions={setGroupConversions}
                            tabValue={value}
                            invoiceType="invoice"
                            conversion_type={conversion_type}
                            setAllConversions={setAllConversions}
                        />
                        <Grid item>
                            <CustTextField
                                id="input-with-icon-textfield"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                                hiddenLabel
                                placeholder={t("navBar.search")}
                                value={searchValue}
                                onChange={handleChangeSearchValue}
                                isSearch
                            />
                        </Grid>
                        <Popover
                            open={openBtnColumnsEdit}
                            anchorEl={anchorRefBtnColumns.current}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <ClickAwayListener onClickAway={handleCloseBtnGroup}>
                                <Stack style={{ minWidth: 200, padding: 20 }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={<Switch checked={showGrouped} onChange={handleChangeSwitch} />}
                                        label={t("converter.showGrouped")}
                                    />
                                    {columns.map(
                                        (item, index) =>
                                            item.name !== "chat" && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkedCol[index]}
                                                            disabled={[0, 1, 2, 3].includes(index)}
                                                            onChange={(event) => handleChangeCheck(event, index)}
                                                            size="small"
                                                        />
                                                    }
                                                    label={item.title}
                                                />
                                            )
                                    )}
                                </Stack>
                            </ClickAwayListener>
                        </Popover>
                    </Stack>
                </Grid>
            </Grid>
            <TableContainer sx={{ pt: 2 }}>
                <Table size="small" sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}>
                    <TableHead>
                        <NoddokTableRowHeader>
                            {(type === 1 || type === 2) && (
                                <NoddokTableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selected.length > 0 && selected.length < rows.length}
                                        checked={rows.length > 0 && selected.length === rows.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </NoddokTableCell>
                            )}
                            {columnsTable.map((item) => (
                                <NoddokTableCell
                                    key={item.name}
                                    align={item.align ?? "left"}
                                    style={{ width: item.minWidth, lineHeight: "1.3" }}
                                >
                                    {item.title}
                                </NoddokTableCell>
                            ))}
                        </NoddokTableRowHeader>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => {
                            const isItemSelected = isSelected(row.id);

                            return (
                                <NoddokTableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        cursor:
                                            row.original_status === 2 ||
                                            row.original_status === 3 ||
                                            row.original_status >= 4
                                                ? "pointer"
                                                : "default", // Cambia el cursor para indicar que es clicable
                                    }}
                                    onClick={() => {
                                        if (
                                            row.original_status === 2 ||
                                            row.original_status === 3 ||
                                            row.original_status >= 4
                                        ) {
                                            handleShowPrev(row, false);
                                        }
                                    }}
                                >
                                    {(type === 1 || type === 2) && (
                                        <NoddokTableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={() => handleClickCheck(row.id)}
                                            />
                                        </NoddokTableCell>
                                    )}
                                    {checkedCol[0] && <NoddokTableCell>{row.number}</NoddokTableCell>}
                                    {checkedCol[1] && (
                                        <NoddokTableCell align={"right"}>{row.uploadDate}</NoddokTableCell>
                                    )}
                                    {checkedCol[2] && <NoddokTableCell>{row.invoiceNumber}</NoddokTableCell>}
                                    {checkedCol[3] && (
                                        <NoddokTableCell>
                                            {conversion_type === 2 ? row.reference : row.contact}
                                        </NoddokTableCell>
                                    )}
                                    {checkedCol[4] && (
                                        <NoddokTableCell align={conversion_type === 2 ? "left" : "right"}>
                                            {conversion_type === 2 ? row.contact : row.invoiceDate}
                                        </NoddokTableCell>
                                    )}
                                    {checkedCol[5] && (
                                        <NoddokTableCell align={conversion_type === 2 ? "right" : "left"}>
                                            {conversion_type === 2 ? row.invoiceDate : row.reference}
                                        </NoddokTableCell>
                                    )}
                                    {checkedCol[6] && <NoddokTableCell align={"right"}>{row.amount}</NoddokTableCell>}
                                    {checkedCol[7] && <NoddokTableCell align={"left"}>{row.user}</NoddokTableCell>}
                                    {checkedCol[8] && <NoddokTableCell align={"left"}>{row.status}</NoddokTableCell>}
                                    {checkedCol[9] && (
                                        <NoddokTableCell align={"center"}>{row.synchronized}</NoddokTableCell>
                                    )}
                                    <NoddokTableCell>{row.chat}</NoddokTableCell>
                                </NoddokTableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <NoddokTableRowHeader style={{ height: 53 * emptyRows }}>
                                <NoddokTableCell colSpan={columns.length} />
                            </NoddokTableRowHeader>
                        )}
                    </TableBody>
                    <TableFooter>
                        <NoddokTableRowHeader>
                            <TablePagination
                                showFirstButton
                                showLastButton
                                rowsPerPageOptions={[10, 20, 30, { label: t("miscellaneous.all"), value: -1 }]}
                                colSpan={12}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={t("dashboard.rowsPerPage")}
                                labelDisplayedRows={(page) =>
                                    `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${
                                        page.count
                                    }`
                                }
                            />
                        </NoddokTableRowHeader>
                    </TableFooter>
                </Table>
            </TableContainer>
            {/* <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                type={status}
                users={users}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
            /> */}
            <SimpleBackdrop open={openBackdrop} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
        </>
    );
};

export default InvoicesTable;
