import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const NoddokTableRowHeader = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff",
  // Hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& td": {
    border: 0,
  },
  "& td:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& td:last-child": {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

export default NoddokTableRowHeader;