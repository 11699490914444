/** MUI imports */
import {
    Box,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    List,
    ListItem,
    Popover,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

/** MUI icons */
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import companyService from "../../services/user_company";
import { useNavigate } from "react-router-dom";
import FiltersButtonCompany from "./FiltersButtonCompany";
import classes from "../team/TeamList.module.css";
import { addCompanyID, addCredits, addIsGrouped } from "../../redux/userSlice";
import { getCompanyCredits, getPlanInfo, getPlanLevelInfo, isCustomPlan } from "../../utils/utils"
import plansInfo from "../Js/plans";
import BaseButton from "../ui/BaseButton";
import CustTextField from "../ui/CustTextField";
import NoddokTable from "../ui/NoddokTable";

// const defaultFilter = {
//     filtersArray: [
//         {
//             type: "",
//             value: "",
//         },
//     ],
// };



const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        company: <Skeleton variant="text" animation="wave" />,
        integ: <Skeleton variant="text" animation="wave" />,
        users: <Skeleton variant="text" animation="wave" />,
        plans: <Skeleton variant="text" animation="wave" />,
    });
}

const CompaniesTable = (props) => {
    const { setOpenModalNewCompany, setAddNew, reload, setCompanyDetails, setOpenCompanyDet, setIsLoading, setAllCompanies, allCompanies, setCurrentFreePlan, setMaxFreePlan, setFranchiseSubsType, setCompanyInfo, franchiseInfo } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);
    const [rowsOG, setRowsOG] = useState([]);
    const [filters, setFilters] = useState({
        filtersArray: [
            {
                type: "",
                value: "",
            },
        ],
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [usersData, setUsersData] = useState([]);

    const rolNames = [
        { id: 1, name: t("team.owner") },
        { id: 2, name: t("team.admin") },
        { id: 3, name: t("team.colab") },
    ];

    const columns = [
        { name: "company", title: t("dashboard.company"), minWidth: 160 },
        { name: "integ", title: t("subscription.integ") },
        { name: "users", title: t("management.assignUsers") },
        { name: "plans", title: t("management.activePlan") },
    ];

    const handleNewCompany = () => {
        setOpenModalNewCompany(true);
        setAddNew(false);
        setCompanyInfo({
            id: null,
            companyType: 1, // 2: SMB | 1: Firma
            companyName: "",
            country: franchiseInfo.country_name,
            systems: [],
            ecActivity: "",
            system_subscription_name: "",
        })
    };

    useEffect(() => {
        (async () => {
            const handleOpenCompany = async (companyID) => {
                setIsLoading(true);
                await companyService
                    .getBusinessDirectoryDetails({ company_id: companyID })
                    .then((data) => {
                        setCompanyDetails(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                setOpenCompanyDet(true);
                setIsLoading(false);
            };

            const goToCompany = async (companyID) => {
                setIsLoading(true);
                dispatch(addIsGrouped(false));
                const credits = await getCompanyCredits(companyID);
                dispatch(addCredits(credits));
                dispatch(addCompanyID(companyID));
                if (todos.selected === "ddlSalesInv") {
                    navigate(`/convert/invoices`);
                } else if (todos.selected === "ddlPurchaseInv") {
                    navigate(`/convert/bills`);
                } else {
                    navigate("/dashboard");
                }
                setOpenCompanyDet(false);
                setIsLoading(false);
            };

            const params = {
                franchise_id: todos.franchiseID,
            };

            const getPlanName = (item) => {
                let itemPlan = item.subscription_plan > 17 // Sin plan.
                    ? t("management.noPlan")
                    : (item?.subscription && "last_cancel" in item?.subscription)
                        ? (!isCustomPlan(item.subscription_plan)
                            ? getPlanInfo(item.subscription_plan)?.planTitle + ` - ${getPlanLevelInfo(item.subscription_plan)?.pages} ${t("plans.pageMonth")} (${t("navBar.cancelled")})`
                            : getPlanInfo(item.subscription_plan)?.planTitle + ` - ${t("plans.customize")} (${t("navBar.cancelled")})`)
                        : (!isCustomPlan(item.subscription_plan)
                            ? getPlanInfo(item.subscription_plan)?.planTitle + ` - ${getPlanLevelInfo(item.subscription_plan)?.pages} ${t("plans.pageMonth")}`
                            : getPlanInfo(item.subscription_plan)?.planTitle + ` - ${t("plans.customize")}`);
                return item.subscription_plan > 17 ? itemPlan : `Plan ${itemPlan}`;
            }

            await companyService
                .getBusinessDirectory(params)
                .then((data) => {
                    const companies = [];

                    const handleShowUsers = (event, usersData) => {
                        setUsersData(usersData);
                        setAnchorEl(event.currentTarget);
                    };

                    const notAssingData = data.companies.map((item) => {
                        companies.push({
                            value: item._id,
                            name: item.company_name,
                        });

                        return {
                            id: item._id,
                            company_name: item.company_name,
                            company: (
                                <>
                                    {item.subscription_plan === 19 || (item?.subscription && "last_cancel" in item?.subscription) ? (
                                        <BaseButton
                                            variantType="primaryText"
                                            onClick={() => handleOpenCompany(item._id)}
                                        >
                                            {item.company_name}
                                        </BaseButton>
                                    ) : (
                                        <BaseButton
                                            variantType="primaryText"
                                            onClick={() => goToCompany(item._id)}
                                        >
                                            {item.company_name}
                                        </BaseButton>)
                                    }
                                </>
                            ),
                            integ:
                                item.system_integration === 1
                                    ? "Xero"
                                    : item.system_integration === 2
                                        ? "Quickbooks"
                                        : t("miscellaneous.withoutInt"),
                            
                            users: <BaseButton
                                variantType="primaryText"
                                aria-describedby={id}
                                disabled={item.total_users === 0}
                                onClick={(e) => handleShowUsers(e, item.users)}
                            >
                                {item.total_users}
                            </BaseButton>,
                            usersData: item.users,
                            plans: (
                                <BaseButton variantType="primaryText" onClick={() => handleOpenCompany(item._id)}>
                                    <b>{getPlanName(item)}</b>
                                </BaseButton>
                            ),
                            plan_name: getPlanName(item)
                        };
                    });

                    setAllCompanies(companies);
                    setRows(notAssingData);
                    setRowsOG(notAssingData);
                    setCurrentFreePlan(data.free_companies_count)
                    setMaxFreePlan(data.free_companies_max_count)
                    setFranchiseSubsType(data.franchise_type)
                })
                .catch((err) => {
                    console.log(err);
                });

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, reload, todos.franchiseID, navigate, dispatch, setCompanyDetails]);

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rowsOG.filter(
            (item) =>
                item.company_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.plan_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.integ.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <Card>
                <CardContent>
                    
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pb: 2, pt: 2, px: { xs: 1, md: 5 } }}
                    >
                        
                    </Grid>
                    <Box
                        component="main"
                        sx={{
                            mx: { xs: 2, md: 2 },
                            px: { xs: 2, md: 3 },
                            pt: 3,
                        }}
                    >
                        <Grid container justifyContent="space-between" alignItems="end">
                            <Grid item xs={12}>
                                <Typography variant="h2">
                                    {t("miscellaneous.companyDir")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} py={3}>
                                <Grid container spacing={2} justifyContent="end" display="flex">
                                    <Grid item sx={{ flexGrow: 1 }}>
                                        <BaseButton
                                            disableElevation
                                            endIcon={<AddIcon />}
                                            onClick={handleNewCompany}
                                            sizebutton="m"
                                        >
                                            {t("navBar.newCompany")}
                                        </BaseButton>
                                    </Grid>
                                    <Grid item>
                                        <FiltersButtonCompany
                                            franchiseID={todos.franchiseID}
                                            filters={filters}
                                            setFilters={setFilters}
                                            setRows={setRows}
                                            setRowsOG={setRowsOG}
                                            rowsSkeleton={rowsSkeleton}
                                            allCompanies={allCompanies}
                                            plansInfo={plansInfo}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustTextField
                                            id="input-with-icon-textfield"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            isSearch
                                            hiddenLabel
                                            placeholder={t("team.search")}
                                            value={searchValue}
                                            onChange={handleChangeSearchValue}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <NoddokTable
                                    columns={columns}
                                    rows={rows}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    page={page}
                                    setPage={setPage}
                                    showCheckbox={false}
                                />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    sx={{
                                        "& .MuiPopover-paper": {
                                            borderRadius: 3,
                                        },
                                    }}
                                >
                                    <Box p={4}>
                                        <Typography color="primary" variant="h3" gutterBottom>
                                            {t("management.assignUsers")}
                                        </Typography>
                                        <List>
                                            {usersData.map((item) => (
                                                <ListItem
                                                    key={item.user_id._id}
                                                    sx={{ backgroundColor: "#f8f8f9", borderRadius: 3, py: 1.5 }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        spacing={6}
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <Typography>{item.user_id.user_name}</Typography>
                                                        <Box
                                                            className={
                                                                item.user_permission === 1
                                                                    ? classes.owner
                                                                    : item.user_permission === 2
                                                                        ? classes.admin
                                                                        : classes.colab
                                                            }
                                                            sx={{ width: "130px", textAlign: "center" }}
                                                        >
                                                            <Typography>
                                                                {
                                                                    rolNames.find(
                                                                        (x) => x.id === item.user_permission
                                                                    )?.name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default CompaniesTable;
