import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const NoddokTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#f8f8f9",
  "& td, & th": {
    height: "42px", // Altura fija para asegurar el mínimo
  },
  // Hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& td": {
    border: 0,
  },
  "& td:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& td:last-child": {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

export default NoddokTableRow;