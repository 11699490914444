/* MUI Imports */
import { Box, Stack } from "@mui/material";
import UserRegister from "../components/auth/UserRegister/UserRegister";

const UserProfile = () => {
    return (
        <Box sx={{ backgroundColor: "rgba(19, 31, 62, 0.03)", height: "100%" }}>
            <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                <UserRegister editMode={true} />
            </Stack>
        </Box>
    );
};

export default UserProfile;
