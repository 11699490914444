import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import BaseButton from "../ui/BaseButton";

const DownloadFormatButtons = (props) => {
    const { handleCloseDialog, downloadQBO, accountNum, accountType, bankInst, downloadFormat } = props;

    const [t] = useTranslation("global");

    return (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <BaseButton
                    disableElevation
                    variantType="primaryTextButton"
                    onClick={handleCloseDialog}
                    sizebutton="m"
                >
                    {t("team.cancel")}
                </BaseButton>
            </Grid>
            <Grid item>
                <BaseButton
                    disableElevation
                    onClick={downloadQBO}
                    sizebutton="m"
                    disabled={((downloadFormat === 15 || downloadFormat === 16) && (!accountNum.trim() || accountType === "" || !bankInst)) || !downloadFormat}
                >
                    {t("payment.download")}
                </BaseButton>
            </Grid>
        </Grid>
    );
};

export default DownloadFormatButtons;
