import {
    Box,
    Typography,
    Grid,
    Autocomplete,
    TextField,
    Link,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import LightTooltip from "../ui/LightTooltip";
import BaseSelect from "../ui/BaseSelect";

import HelpIcon from "@mui/icons-material/Help";

import QuickBooksTool from "../../assets/QBOTooltip.png";
import QuickBooksTool2 from "../../assets/QBOTooltip2.png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CustTextField from "../ui/CustTextField";

const accountTypes = [
    { id: 0, name: "Checking" },
    { id: 1, name: "Savings" },
    { id: 2, name: "Credit card" },
    { id: 3, name: "Credit Line" },
];

let conversionFormats = [
    {
        id: 13,
        name: "Xero (.csv) - DD/MM/YYYY",
        name_en: "Xero (.csv) - DD/MM/YYYY"
    },
    {
        id: 14,
        name: "Xero (.csv) - MM/DD/YYYY",
        name_en: "Xero (.csv) - MM/DD/YYYY"
    },
    {
        id: 7,
        name: "Quickbooks Online (.csv)",
        name_en: "Quickbooks Online (.csv)"
    },
    {
        id: 15,
        name: "Quickbooks Online (.qbo)",
        name_en: "Quickbooks Online (.qbo)"
    },
    {
        id: 16,
        name: "Quickbooks Desktop (.qbo)",
        name_en: "Quickbooks Desktop (.qbo)",
    },
    {
        id: 17,
        name: "Odoo (.csv)",
        name_en: "Odoo (.csv)",
    },
    {
        id: 3,
        name: "Estándar (.csv)",
        name_en: "Standard (.csv)"
    },
    {
        id: 4,
        name: "Estándar (.xlsx)",
        name_en: "Standard (.xlsx)"
    },
    {
        id: 1,
        name: "Accounts IQ (.xml)",
        name_en: "Accounts IQ (.xml)"
    },
    {
        id: 2,
        name: "AccountsPrep (.csv)",
        name_en: "AccountsPrep (.csv)"
    },
    {
        id: 5,
        name: "Iris Accounts Production (.csv)",
        name_en: "Iris Accounts Production (.csv)"
    },
    {
        id: 8,
        name: "ReckonOne (.csv)",
        name_en: "ReckonOne (.csv)"
    },
    {
        id: 9,
        name: "Sage 50 (.csv)",
        name_en: "Sage 50 (.csv)"
    },
    {
        id: 10,
        name: "Sage Accounting (.csv)",
        name_en: "Sage Accounting (.csv)"
    },
    {
        id: 12,
        name: "Twinfield (.csv)",
        name_en: "Twinfield (.csv)"
    },
];

const filterOptions = createFilterOptions({
    limit: 10,
});

const DownloadFormatContent = (props) => {
    const {
        setBankInst,
        setAccountNum,
        setAccountType,
        accountNum,
        accountType,
        finalQBOBanks,
        bankInst,
        downloadFormat,
        handleChangeDownloadFormat,
        qbFormat,
        type
    } = props;

    let [finalFormats, setFinalFormats] = useState([])

    useEffect(() => {
        if (type === "invoices") {
            let fFormats = conversionFormats.filter((item) => item.id === 3 || item.id === 4 || item.id === 14 || item.id === 7);
            setFinalFormats(fFormats);
        } else if (type === "checks") {
            let fFormats = conversionFormats.filter((item) => item.id === 3 || item.id === 4);
            setFinalFormats(fFormats);
        } else {
            setFinalFormats(conversionFormats)
        }
    }, [type])


    const [t] = useTranslation("global");

    const handleChangeBankInst = (e, info) => {
        setBankInst(info);
    };

    const handleChangeNum = (event) => {
        setAccountNum(event.target.value);
    };

    const handleChangeAccType = (event) => {
        setAccountType(event.target.value);
    };

    const handleClickDefault = () => {
        setBankInst({
            value: "03000",
            value2: "03000",
            value3: "03000",
            value4: "03000",
            name: `${t("converter.defaultBank")} (03000)`,
        });
    };

    return (
        // Contenido de modal para QB Desktop
        <Grid id="qboBox" container alignItems="center" spacing={2} pl={5} pr={1}>
            <Grid item xs={11}>
                <BaseSelect
                    id={'selectdownloadFormat'}
                    value={downloadFormat}
                    values={finalFormats}
                    onChange={handleChangeDownloadFormat}
                    label={t("converter.downloadAs")}
                />
            </Grid>
            {qbFormat && <>
                <Grid item xs={11}>
                    <CustTextField
                        fullWidth
                        label={t("converter.bankAccNum")}
                        value={accountNum}
                        onChange={handleChangeNum}
                        variant="filled"
                        size="small"
                    />
                </Grid>
                <Grid item xs={1}>
                    <LightTooltip
                        title={
                            <>
                                <Typography sx={{ pb: 2 }}>{t("converter.qboTooltip")}</Typography>
                                <img src={QuickBooksTool} alt="toolQBO" width={470} loading="lazy" />
                            </>
                        }
                        custom={true}
                        position="right"
                    >
                        <HelpIcon fontSize="small" sx={{ color: "#4a22d4" }} />
                    </LightTooltip>
                </Grid>
                <Grid item xs={11}>
                    <BaseSelect
                        value={accountType}
                        values={accountTypes}
                        onChange={handleChangeAccType}
                        label={t("converter.accType")}
                    />
                </Grid>
                <Grid item xs={11}>
                    <Autocomplete
                        options={finalQBOBanks}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<Typography sx={{ color: "#131f3e" }}>{t("converter.finalInst")}</Typography>}
                                variant="filled"
                                size="small"
                            />
                        )}
                        value={bankInst}
                        onChange={handleChangeBankInst}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterOptions={filterOptions}
                        noOptionsText={
                            <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                <Link
                                    fontWeight={600}
                                    sx={{ cursor: "pointer", textDecoration: "none" }}
                                    onClick={handleClickDefault}
                                >
                                    {t("converter.bankNotFound")}
                                </Link>
                                <Typography fontStyle="italic" fontSize={13} mt={0.5} color="#131f3e">
                                    {t("converter.bankNotFound2")}
                                </Typography>
                            </Box>
                        }
                    />
                </Grid>
                <Grid item xs={1}>
                    <LightTooltip
                        title={
                            <>
                                <Typography sx={{ pb: 2 }}>{t("converter.qboTooltip2")}</Typography>
                                <img src={QuickBooksTool2} alt="toolQBO" width={470} loading="lazy" />
                            </>
                        }
                        custom={true}
                        position="right"
                    >
                        <HelpIcon fontSize="small" sx={{ color: "#4a22d4" }} />
                    </LightTooltip>
                </Grid>
            </>}
        </Grid>
    );
};

export default DownloadFormatContent;
